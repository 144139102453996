import NetworkCall from "network/networkCall";
import Request from "network/request";
import { camelCaseKeysRecursively } from "utilities/generalUtility";

export default class BillingSchedule {
  static async getScheduledbilling(clientsId) {
    const res = await NetworkCall.fetch(
      Request.getBillingScheduleByClientId(clientsId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getClientBillingScheduled(clientsId) {
    const res = await NetworkCall.fetch(
      Request.getClientBillingScheduled(clientsId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async updateJobScheduledbilling(body, id) {
    const res = await NetworkCall.fetch(
      Request.updateJobScheduledbilling(body, id),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async updateClientScheduledbilling(body, id) {
    const res = await NetworkCall.fetch(
      Request.updateClientScheduledbilling(body, id),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getScheduledBillingByCandidateId(id) {
    const res = await NetworkCall.fetch(
      Request.getScheduledBillingByCandidateId(id),
    );
    return camelCaseKeysRecursively(res.data);
  }
}
