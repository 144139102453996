import {
  Card,
  Row,
  Col,
  Typography,
  Descriptions,
  Modal,
  Divider,
  Empty,
} from "antd";
import React, { useState } from "react";
import K from "utilities/constants";
import { titleCase } from "utilities/generalUtility";
import { formateDollar, getCalendarDay } from "utilities/tableUtility";

const { Text, Title, Link } = Typography;

export default function ClientBillingRuleComponent({ styles, currentRule }) {
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (item) => {
    setModalData(item);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const displayAmountType = (item) => {
    if (item.currency === "CAD") {
      return K.CurrencyType.CAD;
    } else {
      return K.CurrencyType.Dollar;
    }
  };

  const getMonthBillingRule = (item) => {
    return (
      <Col xs={24} sm={12} md={12} lg={8}>
        <Card className="feeCard">
          <Descriptions
            className="feeCardDescription"
            title={
              <div title={formateDollar(item.amount)}>
                <sup>{displayAmountType(item)}</sup>
                {formateDollar(item.amount)}
              </div>
            }
            extra={item.currency}
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
          >
            <Descriptions.Item label="Repeat Every">
              {item.calendarEntry.repetitiveQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Repeat Type">
              {titleCase(item.calendarEntry.repeatType)}
            </Descriptions.Item>

            <Descriptions.Item label="On this date">
              {item.calendarEntry.repetitiveMonthDay ?? K.NullPlaceholder}
            </Descriptions.Item>
            <Descriptions.Item label="Occurrences">
              {item.calendarEntry.endAfterOccurances !== null
                ? item.calendarEntry.isInfinite === 1
                  ? "Ongoing"
                  : item.calendarEntry.endAfterOccurances
                : K.NullPlaceholder}
            </Descriptions.Item>
          </Descriptions>
          <Title className="mt-3" level={5}>
            Description:
          </Title>
          <div className="d-flex desc-text">
            <Text ellipsis={true} title={item.aliasName}>
              {item.aliasName}
            </Text>
            {item.aliasName.length >= K.DiscriptionLength && (
              <Link onClick={() => showModal(item)}>Read More</Link>
            )}
          </div>
        </Card>
      </Col>
    );
  };

  const getWeekBillingRule = (item) => {
    return (
      <Col xs={24} sm={12} md={12} lg={8}>
        <Card className="feeCard">
          <Descriptions
            className="feeCardDescription"
            title={
              <>
                <sup>{displayAmountType(item)}</sup>
                {formateDollar(item.amount)}
              </>
            }
            extra={item.currency}
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
          >
            <Descriptions.Item label="Repeat Every">
              {item.calendarEntry.repetitiveQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Repeat Type">
              {titleCase(item.calendarEntry.repeatType)}
            </Descriptions.Item>

            <Descriptions.Item className="daysLabel" label="Days">
              {getCalendarDay(item.calendarEntry).length
                ? getCalendarDay(item.calendarEntry)
                : K.NullPlaceholder}
            </Descriptions.Item>
            <Descriptions.Item label="Occurrences">
              {item.calendarEntry.endAfterOccurances !== null
                ? item.calendarEntry.isInfinite === 1
                  ? "Ongoing"
                  : item.calendarEntry.endAfterOccurances
                : K.NullPlaceholder}
            </Descriptions.Item>
          </Descriptions>
          <Title className="mt-3" level={5}>
            Description:
          </Title>
          <div className="d-flex desc-text">
            <Text>{item.aliasName}</Text>
            {item.aliasName.length >= K.DiscriptionLength && (
              <Link onClick={() => showModal(item)}>Read More</Link>
            )}
          </div>
        </Card>
      </Col>
    );
  };

  const ReadMoreComponent = () => {
    if (modalData?.calendarEntry?.repeatType === "month") {
      return (
        <>
          <Descriptions
            className="feeCardDescription"
            title={
              <>
                <sup>{displayAmountType(modalData)}</sup>
                {formateDollar(modalData?.amount)}
              </>
            }
            extra={modalData.currency}
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
          >
            <Descriptions.Item label="Repeat Every">
              {modalData.calendarEntry.repetitiveQuantity}
            </Descriptions.Item>

            <Descriptions.Item label="Repeat Type">
              {titleCase(modalData.calendarEntry.repeatType)}
            </Descriptions.Item>

            <Descriptions.Item label="On this date">
              {modalData.calendarEntry.repetitiveMonthDay ?? K.NullPlaceholder}
            </Descriptions.Item>

            <Descriptions.Item label="Occurrences">
              {modalData.calendarEntry.endAfterOccurances !== null
                ? modalData.calendarEntry.isInfinite === 1
                  ? "Ongoing"
                  : modalData.calendarEntry.endAfterOccurances
                : K.NullPlaceholder}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Title className="mt-3" level={5}>
            Description:
          </Title>
          <div className="d-flex desc-text">
            <Text>{modalData.aliasName}</Text>
          </div>
        </>
      );
    } else if (modalData?.calendarEntry?.repeatType === "week") {
      return (
        <>
          <Descriptions
            className="feeCardDescription"
            title={
              <>
                <sup>{displayAmountType(modalData)}</sup>
                {formateDollar(modalData?.amount)}
              </>
            }
            extra={modalData.currency}
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
          >
            <Descriptions.Item label="Repeat Every">
              {modalData.calendarEntry.repetitiveQuantity}
            </Descriptions.Item>

            <Descriptions.Item label="Repeat Type">
              {titleCase(modalData.calendarEntry.repeatType)}
            </Descriptions.Item>

            <Descriptions.Item label="Days">
              {getCalendarDay(modalData.calendarEntry).length
                ? getCalendarDay(modalData.calendarEntry)
                : K.NullPlaceholder}
            </Descriptions.Item>

            <Descriptions.Item label="Occurrences">
              {modalData.calendarEntry.endAfterOccurances !== null
                ? modalData.calendarEntry.isInfinite === 1
                  ? "Ongoing"
                  : modalData.calendarEntry.endAfterOccurances
                : K.NullPlaceholder}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Title className="mt-3" level={5}>
            Description:
          </Title>
          <div className="d-flex desc-text">
            <Text>{modalData.aliasName}</Text>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Descriptions
            className="feeCardDescription"
            title={
              <>
                <sup>{displayAmountType(modalData)}</sup>
                {formateDollar(modalData?.amount)}
              </>
            }
            extra={modalData?.currency}
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
          ></Descriptions>
          <Divider />
          <Title className="mt-3" level={5}>
            Description:
          </Title>
          <div className="d-flex desc-text">
            <Text>{modalData?.aliasName}</Text>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {Object.keys(currentRule.clientRuleEntries).length > 0 ? (
        Object.keys(currentRule.clientRuleEntries).map((key, index) => {
          return (
            <Card
              title={
                currentRule.clientRuleEntries[key][0].clientLevelBillingType
                  .name
              }
              className={styles.resourceFeeWrapper}
            >
              <Row
                gutter={[
                  { xs: 8, sm: 10, lg: 30 },
                  { xs: 8, sm: 15, lg: 30 },
                ]}
              >
                {currentRule.clientRuleEntries[key].map((item, index) => {
                  if (
                    item.calendarEntry &&
                    item.calendarEntry.repeatType === "month"
                  ) {
                    return getMonthBillingRule(item, index);
                  } else if (
                    item.calendarEntry &&
                    item.calendarEntry.repeatType === "week"
                  ) {
                    return getWeekBillingRule(item, index);
                  } else {
                    return (
                      <Col xs={24} sm={12} md={12} lg={8}>
                        <Card className="feeCard">
                          <Descriptions
                            className="feeCardDescription"
                            title={
                              <>
                                <sup>{displayAmountType(item)}</sup>
                                {formateDollar(item.amount)}
                              </>
                            }
                            extra={item.currency}
                            column={{ xs: 1, sm: 1, md: 1 }}
                            colon={false}
                          ></Descriptions>
                          <Title className="mt-3" level={5}>
                            Description:
                          </Title>
                          <div className="d-flex desc-text">
                            <Text>{item.aliasName}</Text>
                            {item.aliasName.length >= K.DiscriptionLength && (
                              <Link onClick={() => showModal(item)}>
                                Read More
                              </Link>
                            )}
                          </div>
                        </Card>
                      </Col>
                    );
                  }
                })}
              </Row>
            </Card>
          );
        })
      ) : (
        <Empty />
      )}

      <Modal
        title={modalData?.clientLevelBillingType?.name}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Close"
        closable={false}
        centered
        className="resourceFeeModal"
        cancelButtonProps={{ className: "d-none" }}
      >
        <ReadMoreComponent />
      </Modal>
    </>
  );
}
