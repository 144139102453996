import { Checkbox, Form } from "antd";
export default function UDFCheckbox({
  title = "",
  isFormItem = true,
  value = true,
  className = "",
  placeholder = "",
  formItemName = "",
  required = true,
  options = [],
}) {
  const checkboxOptions = options.map((opt) => ({
    label: opt.value,
    value: opt.id,
  }));

  return (
    <>
      {isFormItem ? (
        <Form.Item
          label={title}
          name={formItemName}
          rules={
            required ? [{ required: true, message: `Select ${title}` }] : null
          }
        >
          <Checkbox.Group options={checkboxOptions} />
        </Form.Item>
      ) : (
        <>
          <Checkbox.Group options={checkboxOptions} />
        </>
      )}
    </>
  );
}
