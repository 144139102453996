import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Modal,
  Typography,
  message,
} from "antd";
import moment from "moment";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import ClientBillingRuleFields from "features/clients/create/clientBillingRuleFields";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Client from "redux/models/client";
import {
  convertIntoDashUSFormat,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";
import {
  formatClientRulesEntries,
  isPermissionPresent,
} from "utilities/generalUtility";
import ClientBillingRuleComponent from "./clientBillingRuleComponent";
import K from "utilities/constants";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";

const { Title, Paragraph } = Typography;

export default function CurrentBillingRule({
  sourceData,
  clientRulesData,
  getClientBillingRulesDetails,
}) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const userSlice = useSelector(selectUser);
  const [editStartDateForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editStartDateModal, setEditStartDateModal] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showEditModal = () => {
    populateFormValues(form);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    form.submit();
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  const populateFormValues = (form) => {
    const formValues = formatClientRulesEntries(sourceData.currentRule);
    form.setFieldsValue(formValues);
  };
  const showEditStartDate = () => {
    populateFormValues(form);
    setEditStartDateModal(true);
  };
  const handleEditStartDateCancel = () => {
    setEditStartDateModal(false);
  };

  const disabledEditStartDateDate = (current) => {
    // * Can not select days before today and today
    const historyDate = clientRulesData.histroyRules.length
      ? moment
          .unix(clientRulesData.histroyRules[0].startDate)
          .format(K.DateFormat.Response)
      : null;

    return (
      (current && current < moment(historyDate).endOf("day")) ||
      (current && current > moment().endOf("day"))
    );
  };

  const onFinish = async (values, isBackDate = false) => {
    const clientLevelBilligRules = values.billingTypes.reduce((prev, curr) => {
      const ruleList = values[curr].map((rule) => ({
        alias: rule.alias,
        amount: rule.amount,
        currency: rule.currency,
        repetitiveQuantity: rule.repetitiveQuantity,
        recurringType: rule.recurringType,
        selectedDays: rule.selectedDays,
        repetitiveMonthDay: rule.repetitiveMonthDay,
        clientLevelBillingTypeId: curr,
        isInfinite: rule.isInfinite,
        endAfterOccurances: rule.isInfinite === 1 ? 0 : rule.endAfterOccurances,
      }));
      return [...prev, ...ruleList];
    }, []);
    const body = {
      clientId: id,
      clientLevelBilligRules: [...clientLevelBilligRules],
      effectiveSince: values["effectiveSince"].unix(),
    };

    setIsLoading(true);
    try {
      if (isBackDate) {
        const payload = {
          ...body,
          billingRuleId: clientRulesData.currentRule?.id,

          clientLevelBilligRules: [...clientLevelBilligRules],
          effectiveSince: values["effectiveSince"].unix(),
        };
        await Client.editBillingRuleStartDate(payload, true);
        handleEditStartDateCancel();
      } else {
        await Client.addClientLevelBillingRules(body);
        setIsModalVisible(false);
      }
      setIsLoading(false);
      getClientBillingRulesDetails();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Card className={styles.clientBillingRulesCard}>
      <div className={`${styles.clientBillings} ${styles.currentBillingRule}`}>
        <div className={styles.cbrTitle}>
          <Title level={5}>Current Billing Rule</Title>
          <Paragraph>
            <i className="icon-calendar"></i>
            {clientRulesData.currentRule?.startDate &&
              epochTimeToDashUSFormat(
                clientRulesData.currentRule.startDate,
              )}{" "}
            &nbsp; &nbsp;
            <i className="icon-edit" onClick={showEditStartDate}></i>
          </Paragraph>
          <Paragraph>
            <i className="icon-user"></i>
            Updated by :{" "}
            {`${
              clientRulesData.currentRule?.user?.name ?? "N/A"
            } at ${convertIntoDashUSFormat(
              clientRulesData.currentRule.createdAt,
            )}`}
          </Paragraph>
        </div>
        <Divider className={styles.dividerLine} plain />
        {isPermissionPresent(
          K.Permissions.EditClientBillingRule,
          userSlice.roles,
        ) && (
          <>
            {Object.keys(sourceData?.currentRule).length === 0 && (
              <Button className={styles.addButton} onClick={showModal}>
                Add New
              </Button>
            )}

            <Button className={styles.addButton} onClick={showEditModal}>
              Edit
            </Button>
          </>
        )}
      </div>

      <ClientBillingRuleComponent
        styles={styles}
        currentRule={clientRulesData?.currentRule}
      />
      <Modal
        width={568}
        centered
        className={styles.addButtonModule + " modal-overflow-auto"}
        open={isModalVisible}
        closable={false}
        onCancel={handleCancel}
        afterClose={afterModalClose}
        footer={
          <>
            <Button
              className={styles.cancelBillingRules}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              className={styles.saveBillingRules}
              onClick={handleOk}
            >
              Save
            </Button>
          </>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <fieldset className={styles.fieldSet}>
            <legend className={styles.fieldTitle}>Billing Rules</legend>
            <ClientBillingRuleFields
              styles={styles}
              showEffectiveSince={true}
              form={form}
            />
          </fieldset>
        </Form>
      </Modal>
      <Modal
        width={568}
        centered
        className={styles.addButtonModule}
        open={editStartDateModal}
        closable={false}
        onCancel={handleEditStartDateCancel}
        afterClose={afterModalClose}
        footer={
          <>
            <Button
              className={styles.cancelBillingRules}
              onClick={handleEditStartDateCancel}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              className={styles.saveBillingRules}
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values, true)}
        >
          <fieldset className={styles.fieldSet}>
            <legend className={styles.fieldTitle}>Billing Rules Date</legend>

            <ClientBillingRuleFields
              styles={styles}
              showEffectiveSince={true}
              form={editStartDateForm}
              isBackDate={true}
              backDateDisabledDate={disabledEditStartDateDate}
            />
          </fieldset>
        </Form>
      </Modal>
    </Card>
  );
}
