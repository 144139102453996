import { Checkbox, Form, Input, InputNumber, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Job from "redux/models/job";
import { setJobStatisticsShouldUpdate } from "redux/slices/candidateSlice";
import K from "utilities/constants";
export default function CancelJobForm({
  form,
  jobId,
  styles,
  clientId,
  setJobData,
  jobDetails,
  setIsLoading,
  getJobSettings,
  handleOkCancelJob,
}) {
  const [reasonState, setReasonState] = useState({
    reason: null,
    selectedReason: null,
  });
  const dispatch = useDispatch();

  const onChange = ({ target: { checked } }) => {
    setJobData((prev) => ({ ...prev, moveCandidates: checked }));
  };
  const onFinish = async (values) => {
    setIsLoading(true);
    values.clientId = +clientId;
    values.jobSourcesId = +jobId;

    try {
      await Job.cancelById(values);
      // getJobSettings();
      setJobData((prev) => ({
        ...prev,
        shouldUpdateListing: !prev.shouldUpdateListing,
      }));
      setIsLoading(false);
      Job.syncCandidateTTM(+jobId);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
    handleOkCancelJob();
  };

  const validateOpeningsToBill = (value) => {
    const openings = jobDetails.vacancies.totalOpenings;
    if (value <= openings) {
      return Promise.resolve();
    }
    return Promise.reject(`Value should be less than or equal to ${openings}`);
  };
  const handleSelect = (value) => {
    const founded = K.CancelJobReasons.find((item) => item.value === value);
    setReasonState((prev) => {
      return { ...prev, selectedReason: founded };
    });
    founded.input
      ? form.setFieldsValue({ reason: null })
      : form.setFieldsValue({ reason: value });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className={styles.cancelJobFrom}
    >
      <Form.Item
        className={styles.jobFieldLabel}
        label="Enter Number of Opening to Bill"
        name="jobCancelledOpenings"
        rules={[
          {
            required: true,
            message: "Number of openings to bill is required",
          },
          () => ({
            validator(_, value) {
              return validateOpeningsToBill(value);
            },
          }),
        ]}
      >
        <InputNumber
          placeholder="0"
          min={0}
          className={styles.jobInputFields}
        />
      </Form.Item>
      <Form.Item
        name="reasonDropdown"
        label="Reason"
        className={styles.jobFieldLabel}
        rules={[{ required: true, message: "Enter reason to cancel job." }]}
      >
        <Select
          showSearch
          allowClear
          className={styles.addCandidateInputFields}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          options={K.CancelJobReasons.map((item) => ({
            value: item.value,
            label: item.value,
          }))}
          onChange={handleSelect}
        ></Select>
      </Form.Item>

      <Form.Item
        name="reason"
        hidden={!reasonState.selectedReason?.input}
        className={styles.jobFieldLabel}
        rules={[{ required: true, message: "Enter reason to cancel job." }]}
      >
        <Input
          size="large"
          placeholder={"Enter your reason"}
          className={styles.jobInputFields}
        />
      </Form.Item>

      <Form.Item>
        <Checkbox onChange={onChange}>
          Move all active candidates to Declined - Requisitions Closed
        </Checkbox>
      </Form.Item>
    </Form>
  );
}
