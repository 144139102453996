import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Popconfirm,
} from "antd";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import { nullRenderer } from "features/billingCommissions/submitToBilling/tableColumns";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
import ClientContact from "redux/models/clientContacts";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  isPermissionPresent,
  noTrailingSpaceAllowedRule,
  validateMobileNumber,
} from "utilities/generalUtility";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
} from "utilities/tableUtility";

const { Search } = Input;
const searchFilterPrefix = "client_contact";
const getSearchKey = "client_contact_search";

export default function ClientContacts() {
  const [form] = Form.useForm();
  const gridRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [clientContacts, setClientContacts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userSlice = useSelector(selectUser);
  const [setter, getter] = useSearchAndFilter();

  const phoneExtRenderer = (params) =>
    params.data.phoneExt ?? K.NullPlaceholder;
  const actionColumnRenderer = (params) => {
    return (
      <div>
        {isPermissionPresent(
          K.Permissions.EditClientContact,
          userSlice.roles,
        ) && (
          <Button
            type="link"
            onClick={() => {
              editRecord(params.data);
            }}
            className={LayoutCss.appListingCardActions}
          >
            Edit
          </Button>
        )}
        {isPermissionPresent(
          K.Permissions.DeleteClientContact,
          userSlice.roles,
        ) && (
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => {
              confirm(params.data.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              style={{ color: "red" }}
              className={LayoutCss.appListingCardActions}
            >
              Delete
            </Button>
          </Popconfirm>
        )}
      </div>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      tooltipField: "email",
    },
    {
      headerName: "Job Title",
      field: "jobTitle",
      sortable: true,
      tooltipField: "jobTitle",
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      sortable: true,
      tooltipField: "phoneNumber",
    },
    {
      headerName: "Phone Ext",
      field: "phoneExt",
      sortable: true,
      tooltipField: "phoneExt",
      tooltipComponent: phoneExtRenderer,
      cellRenderer: phoneExtRenderer,
    },
    {
      headerName: "Created By",
      field: "createdBy",
      sortable: true,
      tooltipField: "createdBy",
      ...nullRenderer,
    },
    {
      headerName: "Edited By",
      field: "editedBy",
      sortable: true,
      tooltipField: "editedBy",
      ...nullRenderer,
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      resizable: false,
      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRenderer: actionColumnRenderer,
    },
  ]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const editRecord = (record) => {
    setEditData(record);
    showModal();
  };
  const afterModalClose = () => {
    setEditData({});
  };

  const confirm = async (id) => {
    try {
      console.log(id);
      await ClientContact.deleteClientContact(id);
      setClientContacts((prev) => prev.filter((item) => item.id !== id));
      setIsModalVisible(false);
      message.success("Client contact deleted successfully");
    } catch (err) {
      console.error(err);
    }
  };
  const onFinish = async (values) => {
    setIsLoading(true);
    if (Object.keys(editData).length) {
      values.editedBy = userSlice.details.name;
      try {
        const res = await ClientContact.update(values, editData.id);
        setClientContacts(
          clientContacts.map((item) => {
            if (item.id === res.id)
              return { ...res, createdBy: item.createdBy };
            return item;
          }),
        );
        setIsLoading(false);
        setIsModalVisible(false);
        form.resetFields();
        message.success("Client updated successfully");
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
      setEditData({});
    } else {
      try {
        values.createdBy = userSlice.details.name;
        const created = await ClientContact.create(values);
        setClientContacts([...clientContacts, created]);
        setIsLoading(false);
        setIsModalVisible(false);
        form.resetFields();
        message.success("Client created successfully");
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }
  };

  const getAllContacts = async () => {
    try {
      const res = await ClientContact.getAll();
      setClientContacts(res);
    } catch (err) {
      console.error(err);
    }
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.ClientContactsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.ClientContactsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions")
          return { ...item, cellRenderer: actionColumnRenderer };
        else if (item.field === "phoneExt")
          return {
            ...item,
            cellRenderer: phoneExtRenderer,
            tooltipComponent: phoneExtRenderer,
          };
        else if (["createdBy", "editedBy"].includes(item.field)) {
          return {
            ...item,
            ...nullRenderer,
          };
        }

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  const onChange = useCallback((event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);
    setter({ search: event.target.value }, searchFilterPrefix);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [editData]);

  useEffect(() => {
    (async () => {
      await Promise.all([getAllContacts(), getColumnsConfigrations()]);
    })();
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Client Contacts"
        className={LayoutCss.appPageHeader}
        extra={
          isPermissionPresent(
            K.Permissions.CreateClientContact,
            userSlice.roles,
          ) && (
            <Button type="primary" onClick={showModal}>
              Create
            </Button>
          )
        }
      />
      <Card
        title="Client Contact List"
        className={"appCard " + LayoutCss.appCard}
        extra={
          <Search
            allowClear
            placeholder="Search"
            value={getter(getSearchKey)}
            onChange={onChange}
            className={LayoutCss.appListingCardSearchBar1}
          />
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={clientContacts}
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            ref={gridRef}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>
      <Modal
        forceRender
        className="s2-theme-style"
        title={`${
          Object.keys(editData).length ? "Edit" : "Create"
        } Client Contact`}
        open={isModalVisible}
        centered
        closeIcon={<i className="icon-closeable"></i>}
        okText={`${Object.keys(editData).length ? "Update" : "Create"}`}
        wrapClassName="vertical-center-modal"
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
        afterClose={afterModalClose}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={editData}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Name is required." },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required.",
              },
              {
                type: "email",
                message: "Enter a valid email address",
              },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Phone Number is required.",
              },
              {
                validator: (_, value) => {
                  if (!value || validateMobileNumber(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please enter correct number");
                  }
                },
              },
            ]}
          >
            <PatternFormat
              placeholder="Enter Phone Number"
              customInput={Input}
              format={K.PhoneFormat.USNational}
            />
          </Form.Item>
          <Form.Item
            label="Phone Ext"
            name="phoneExt"
            rules={[
              // {
              //   required: true,
              //   message: "Phone Ext is required.",
              // },
              {
                pattern: new RegExp("^[0-9]{1,6}$", "g"),
                message: "Maximum of 6 digits is allowed",
              },
            ]}
          >
            <Input placeholder="Enter Phone Ext" />
          </Form.Item>
          <Form.Item
            label="Job Title"
            name="jobTitle"
            rules={[
              {
                required: true,
                message: "Job title is required.",
              },
            ]}
          >
            <Input placeholder="Enter job title" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
