import React from "react";
import Spinner from "common/components/spinner/spinner";
import styles from "./guest-page-layout.module.scss";
import LogoImage from "assets/images/logo.png";

export default function GuestPageLayout({ children }) {
  return (
    <>
      <div className={styles.guestLayoutWrapper}>
        <div className={styles.guestLayoutBanner}>
          <img src={LogoImage} alt="" className={styles.logoImg} />
        </div>
        <div className={styles.guestLayoutContent}>
          <div className={styles.glContent}>{children}</div>
          <Spinner isGuest={true}></Spinner>
        </div>
      </div>
    </>
  );
}
