import { Link } from "react-router-dom";
import { Form, Input, Button, message, Typography } from "antd";
import {
  ArrowLeftOutlined,
  MailOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import User from "redux/models/user";
import { setFieldErrorsFromServer } from "utilities/generalUtility";
import K from "utilities/constants";
import styles from "../login/login.module.scss";

const { Paragraph, Title } = Typography;

export default function ForgotPassword() {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const clientHost = `${K.Network.URL.Protocol}//${K.Network.URL.Client.BaseHost}:${K.Network.URL.Client.BasePort}/set-password`;
    try {
      await User.forgotPassword(values.email, clientHost);
      message.success(`An email has been sent to ${values.email}`);
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
    }
  };

  return (
    <div className={styles.cardFrom}>
      <div className={styles.frownIcon}>
        <FrownOutlined className={styles.frownOutlined} />
      </div>
      <Title level={5} className={styles.cardTitle}>
        Forgot Password?
      </Title>
      <Paragraph className={styles.associatedAddress}>
        Please Enter the address associated with your account.
      </Paragraph>
      <Form
        className={styles.ForgotPasswordForm}
        form={form}
        name="loginForm"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            className={styles.formInputs}
            suffix={<MailOutlined />}
            placeholder="Email ID"
            size="large"
          />
        </Form.Item>

        <Form.Item className="mb-0">
          <Button block size="large" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>

        <Form.Item className="mb-0 text-center">
          <Link to="/login" className={styles.backLogin}>
            <ArrowLeftOutlined className={styles.arrowIcon} /> Back to Login
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}
