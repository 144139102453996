import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: {},
  filter: {},
  shouldLoad: false,
};

export const searchAndFilterSlice = createSlice({
  name: "searchAndFilter",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },

    setSearchShouldLoad: (state) => {
      state.shouldLoad = !state.shouldLoad;
    },
  },
});

export const selectSearchAndFilter = (state) => state.searchAndFilter;
export const { setSearch, setFilter, setSearchShouldLoad } =
  searchAndFilterSlice.actions;

export default searchAndFilterSlice.reducer;
