import { createSlice } from "@reduxjs/toolkit";
import K from "utilities/constants";
import { uniqBy } from "lodash";

const { Redux } = K;

const initialState = {
  [Redux.Roles]: [],
  [Redux.Recruiters]: [],
  [Redux.Clients]: [],
  [Redux.Details]: null,
  [Redux.HomeRoute]: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedInUser: (state, action) => {
      const { data, homeRoute } = action.payload;
      const { totalClients, roles, ...details } = data.basicDetail;
      state[Redux.Details] = details;
      state[Redux.Roles] = roles;
      state[Redux.TotalClients] = totalClients;
      if (details.isSuperAdmin) {
        state[Redux.HomeRoute] = "/";
        state[Redux.Clients] = data.clients;
        state[Redux.Recruiters] = [
          // { id: details.id, name: details.name },
          ...data.recruiters,
        ];
      } else {
        state[Redux.HomeRoute] = homeRoute;
        if (data.basicDetail.isManager === 1) {
          const { users, clients } = data.basicDetail.clients.reduce(
            (prev, { pivot, users, ...rest }) => ({
              users: [...prev.users, ...users],
              clients: [...prev.clients, rest],
            }),
            {
              users: [{ id: details.id, name: details.name }],
              clients: [],
              totalClients: details.totalClients,
            },
          );
          state[Redux.Clients] = clients;
          state[Redux.Recruiters] = uniqBy(users, "id");
        } else {
          const { clients } = data.basicDetail;
          state[Redux.Clients] = clients;
          state[Redux.Recruiters] = [
            {
              id: data.basicDetail.id,
              name: data.basicDetail.name,
            },
          ];
        }
      }
    },
    updateUserDetail: (state, action) => {
      state[Redux.Details] = action.payload;
    },
  },
});

export const selectUser = (state) => state.user;
export const { setLoggedInUser, updateUserDetail } = userSlice.actions;

export default userSlice.reducer;
