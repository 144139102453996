import K from "./constants";

export const stringSorting = (a, b, dataIndex) => {
  return a && b && a[dataIndex]?.localeCompare(b[dataIndex]);
};

export const numberSorting = (a, b, dataIndex) => {
  return a[dataIndex] - b[dataIndex];
};
export const descNumberSorting = (a, b, dataIndex) => {
  return b[dataIndex] - a[dataIndex];
};

export const sortObjectsArrayByKey = (array, key) => {
  return array.sort((a, b) => numberSorting(a, b, key));
};
export const sortObjectsArrayDescByKey = (array, key) => {
  return array.sort((a, b) => descNumberSorting(a, b, key));
};

export const sortDescendingOrder = (array) => {
  return array.sort((a, b) => b.id - a.id);
};

export const displayDollar = (value, isSign = true) => {
  if ([null, undefined, ""].includes(value)) {
    return K.NullPlaceholder;
  } else {
    let symbol = isSign ? "$" : "";
    let formattedValue = Number(value);

    let generatedValue = formattedValue.toLocaleString("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    if (generatedValue === "-0.00") {
      generatedValue = "0.00";
    }
    return symbol + generatedValue;
  }
};

export const displayHire = (value, isSign = true) => {
  if ([null, undefined, ""].includes(value)) {
    return K.NullPlaceholder;
  } else {
    return (
      Number(value).toLocaleString("en", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }) + " Hires"
    );
  }
};

export const formateDollar = (value) => {
  if ([null, undefined, ""].includes(value)) {
    return K.NullPlaceholder;
  } else {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }
};

export const displayPercentage = (value) => {
  if ([null, undefined, ""].includes(value)) {
    return K.NullPlaceholder;
  } else {
    return (
      value.toLocaleString("en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + "%"
    );
  }
};

export const onFilterTextBoxChanged = (value, gridRef) => {
  gridRef?.current?.api?.setQuickFilter(value);
};

export const filterColumnListing = (columnDefs, newCol) => {
  const cols = newCol
    .map((el) => ({
      ...columnDefs.find((column) => column.field === el.colId),
      ...el,
    }))
    .map((el) => ({
      pinned: el.pinned,
      width: el.width,
      headerName: el.headerName,
      hide: el.hide,
      field: el.field,
      sortable: el.sortable,
      tooltipField: el.tooltipField,
      suppressMovable: el.suppressMovable,
    }));

  return cols;
};

export const getCalendarDay = (calendarEntry) => {
  const daysList = [];
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  for (let item = 0; item < weekDays.length; item++) {
    if (calendarEntry[`repetitiveWeekDay_${item}`]) {
      daysList.push(weekDays[item]);
    }
  }
  return daysList.join(", ");
};

export const dollarformatter = (value) =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
