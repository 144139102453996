export const breakdownDefaultResponsiveSettings = [
  {
    breakpoint: 1318,
    settings: {
      slidesToShow: 3,
      // slidesToScroll: 3,
      infinite: false,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 3,
      infinite: true,
    },
  },

  {
    breakpoint: 1050,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 670,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
    },
  },
  {
    breakpoint: 576,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: true,
    },
  },
  {
    breakpoint: 380,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export const statisticsSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  // slidesToScroll: 5,
  centerPadding: 20,

  responsive: [
    {
      breakpoint: 1318,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const breakdownSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  // slidesToShow: 7,
  // slidesToScroll: 4,
  // responsive: breakdownDefaultResponsiveSettings,
};
