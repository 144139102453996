import { useState } from "react";
import { Popover, Button, Form, Select, Radio, Grid, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./sider.module.scss";
import { selectUser } from "redux/slices/userSlice";
import {
  setClientSelectionType,
  selectGlobalSelector,
  setGlobalClients,
} from "redux/slices/globalSelectorSlice";
import { isPermissionPresent } from "utilities/generalUtility";
import K from "utilities/constants";

const { useBreakpoint } = Grid;

export default function SiderPopover() {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const userSlice = useSelector(selectUser);
  const globalSelector = useSelector(selectGlobalSelector);
  const isSuperAdmin = isPermissionPresent(
    K.Permissions.SuperAdmin,
    userSlice.roles,
  );

  const onCancel = () => {
    setOpen(false);
    if (globalSelector.selectedClients.length)
      dispatch(setClientSelectionType("none"));
    else {
      dispatch(setClientSelectionType("all"));
    }
    form.setFieldValue("selectedClients", globalSelector.selectedClients);
  };
  const handleOpenChange = (newVisible) => {
    setOpen(newVisible);
  };

  const onChange = ({ target: { value } }) => {
    dispatch(setClientSelectionType(value));
    form.setFieldValue("selectedClients", []);
    form.validateFields();
  };

  const onFinish = (values) => {
    dispatch(setGlobalClients(values.selectedClients));
    setOpen(false);
  };

  const content = (
    <Form
      form={form}
      layout="vertical"
      initialValues={globalSelector}
      onFinish={onFinish}
    >
      {isSuperAdmin && (
        <Radio.Group
          className="mb-2"
          onChange={onChange}
          value={globalSelector.clientSelection}
        >
          <Radio value="all">Select All Clients</Radio>
          <Radio value="none">Select Specific</Radio>
        </Radio.Group>
      )}
      <Form.Item
        name="selectedClients"
        label="Select Client"
        rules={
          globalSelector.clientSelection !== "all"
            ? [{ required: true, message: "Client is required." }]
            : []
        }
      >
        <Select
          size="medium"
          optionFilterProp="label"
          placeholder="Select Clients"
          mode="multiple"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          showArrow
          allowClear
          disabled={globalSelector.clientSelection === "all" && isSuperAdmin}
          showSearch={true}
          options={userSlice.clients.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
      </Form.Item>
      {/* <Form.Item name="displayData" label="Display">
        <Radio.Group>
          <Radio value="allData">All Data</Radio>
          <Radio value="myData">My Data</Radio>
          <Radio value="recruiter">Recruiter</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="selectedRecruiters" label="Select Recruiter">
        <Select 
        allowClear   
        optionFilterProp="children" showSearch size="medium" placeholder="Select Recruiter">
          {userSlice.recruiters.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
          
        </Select>
      </Form.Item> */}
      <div className="d-flex justify-content-end">
        <Button className="mr-2" onClick={onCancel}>
          Cancel
        </Button>
        <Form.Item className="mb-0">
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </div>
    </Form>
  );

  const getSelectedClientName = () => {
    const selectClients =
      globalSelector.selectedClients.length > 0
        ? globalSelector.selectedClients.reduce((prev, curr) => {
            const clients = userSlice.clients.find((uc) => uc.id === curr);

            return [...prev, { ...clients }];
          }, [])
        : null;

    return selectClients;
  };

  return (
    <Popover
      overlayClassName={styles.siderPopoverForm}
      placement={
        screens["lg"] === undefined || screens["lg"] ? "right" : "bottom"
      }
      content={content}
      trigger="click"
      arrow={{ pointAtCenter: false }}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {screens["lg"] === undefined || screens["lg"] ? (
        <Button className={styles.siderPopover + " sider-popover"}>
          <i className={"icon-document " + styles.documentIcon}></i>

          {globalSelector.selectedClients.length === 0 ? (
            <span className="sider-text">All Clients</span>
          ) : (
            <>
              <span
                title={getSelectedClientName()[0]?.name}
                className="sider-text"
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "20ch",
                  fontSize: 12,
                }}
              >
                {getSelectedClientName()[0]?.name}
              </span>
            </>
          )}
          {globalSelector.selectedClients.length > 1 && (
            <Tag className="sider-tag">
              {`+${globalSelector.selectedClients.length - 1}`}
            </Tag>
          )}

          {/* <span>
            Select Clients */}
          {/* <em>
              Recruiter:
              <span>
                &nbsp;
                {
                  userSlice.recruiters.find(
                    (item) => item.id === globalSelector.selectedRecruiters
                  )?.name
                }
              </span>
            </em> */}
          {/* <em>
              Clients:
              <span style={{ display: "inline-block" }}>
                &nbsp;
                {globalSelector.selectedClients.length > 0 ? (
                  globalSelector.selectedClients.length > 1 ? (
                    <>
                      <span
                        style={{
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "15ch",
                          fontSize: 11,
                        }}
                      >
                        {getSelectedClientName()[0]?.name}
                      </span>

                      <span style={{ fontSize: 11 }}>
                        {`+ ${globalSelector.selectedClients.length - 1}`}
                      </span>
                    </>
                  ) : (
                    <span>{getSelectedClientName()[0]?.name}</span>
                  )
                ) : (
                  "N/A"
                )}
              </span>
            </em>
          </span> */}
          {/* <span className="sider-text">
            All Clients */}
          {/* <em>
              Recruiter:
              <span>
                &nbsp;
                {
                  userSlice.recruiters.find(
                    (item) => item.id === globalSelector.selectedRecruiters
                  )?.name
                }
              </span>
            </em> */}
          {/* <em>
              Clients:
              <span style={{ display: "inline-block" }}>
                &nbsp;
                {globalSelector.selectedClients.length > 0 ? (
                  globalSelector.selectedClients.length > 1 ? (
                    <>
                      <span
                        style={{
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "15ch",
                          fontSize: 11,
                        }}
                      >
                        {getSelectedClientName()[0]?.name}
                      </span>

                      <span style={{ fontSize: 11 }}>
                        {`+ ${globalSelector.selectedClients.length - 1}`}
                      </span>
                    </>
                  ) : (
                    <span>{getSelectedClientName()[0]?.name}</span>
                  )
                ) : (
                  "All Clients"
                )}
              </span>
            </em> */}
          {/* </span> */}
          {/* <i className={"icon-sider-wifi " + styles.wifiIcon}></i> */}
        </Button>
      ) : (
        <Button
          size="large"
          className={
            styles.siderPopover +
            " " +
            styles.siderPopoverResponsive +
            " sider-popover"
          }
        >
          <i className={"icon-document " + styles.documentIcon}></i>
        </Button>
      )}
    </Popover>
  );
}
