import { Button, Divider, InputNumber, Typography } from "antd";
import {
  convertIntoDashUSFormat,
  formatTimeStamp,
} from "utilities/dateUtility";
import { displayDollar } from "utilities/tableUtility";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars-2";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";

const { Paragraph, Title } = Typography;

export default function CommissionApprovalDetails({
  pageStates,
  toggleEdit,
  inputRef,
  editAmount,
}) {
  return (
    <>
      <span className="clientBillingType">
        {pageStates.selectedRecord?.submittedJobBill.billingTypeName}
      </span>
      <div className="clientBillingWrap">
        <div className="clientBillingTitle">
          <div className="clientBilling">
            <Title level={4}>ID:</Title>
            <Paragraph>{pageStates.selectedRecord?.id}</Paragraph>
          </div>
          <div className="clientBilling">
            <Title level={4}>Candidate Name:</Title>
            <Paragraph>
              {pageStates.selectedRecord?.submittedJobBill.candidateName}
            </Paragraph>
          </div>
          <div className="clientBilling">
            <Title level={4}>Job Title:</Title>
            <Paragraph>
              {pageStates.selectedRecord?.submittedJobBill.jobTitle}
            </Paragraph>
          </div>
          <div className="clientBilling">
            <Title level={4}>Create Date:</Title>
            <Paragraph>
              {convertIntoDashUSFormat(
                pageStates.selectedRecord?.createdAt,
                false,
              )}
            </Paragraph>
          </div>
          <div className="clientBilling">
            <Title level={4}>Original Commision Earned:</Title>
            <Paragraph>
              {[
                displayDollar(+pageStates.selectedRecord?.amount.toFixed(2)),
                `(USD)`,
              ].join(" ")}
            </Paragraph>
          </div>
          <div className="clientBilling billed-amount">
            {pageStates.isEditMode ? (
              <>
                <div className="billingField">
                  <Title level={4} className="orignalAmount">
                    Billed Commission Earned:
                  </Title>
                  <InputNumber
                    ref={inputRef}
                    defaultValue={pageStates.selectedRecord?.duplicateAmount}
                  />
                  <Button
                    type="link"
                    onClick={toggleEdit}
                    icon={<CloseCircleOutlined />}
                  />
                  <Button
                    type="link"
                    onClick={editAmount}
                    icon={<CheckCircleOutlined />}
                  />
                </div>
              </>
            ) : (
              <div>
                <Title level={4} className="orignalAmount">
                  Billed Commission Earned:{" "}
                  <span className="cbOrignalAmount">
                    {[
                      displayDollar(
                        +pageStates.selectedRecord?.duplicateAmount,
                      ),
                      `(USD)`,
                    ].join(" ")}
                    <Button
                      type="link"
                      onClick={toggleEdit}
                      disabled={(pageStates.selectedRecord?.amount ?? 0) < 0}
                      icon={<i className="icon-edit"></i>}
                    />
                  </span>
                </Title>
              </div>
            )}
          </div>
        </div>
      </div>
      <Divider className="divider" />
      <Title level={5} className="clientHistoryTitle">
        History
      </Title>
      <div className="clientHistoryWrap">
        <Scrollbars style={{ height: 460 }}>
          {pageStates.conversionHistory.length ? (
            pageStates.conversionHistory.map((item) => (
              <div key={item.id} className="clientHistoryDetail">
                <div className="clientAmountHistory">
                  <div className="clientName">
                    <Title level={5}>
                      {item.edit ? "Edited " : "Converted "} By:
                    </Title>
                    <Paragraph>{item.user.name}</Paragraph>
                  </div>
                  <div className="clientDate">
                    <Title level={5}>Converted Date:</Title>
                    <Paragraph>
                      {formatTimeStamp(item.createdAt ?? "N/A")}
                    </Paragraph>
                  </div>
                </div>
                <Divider className="historyDivider" />
                <div className="clientAmount">
                  <Title level={5}>
                    Billed Amount:{" "}
                    {displayDollar(+item.billingAmount?.toFixed(2)) + ` (USD)`}
                  </Title>
                  {!item.edit && (
                    <Paragraph>
                      Conversion Rate: {item.conversionRate?.toFixed(3)}
                    </Paragraph>
                  )}
                </div>
              </div>
            ))
          ) : (
            <EmptyPlaceholder />
          )}
        </Scrollbars>
      </div>
    </>
  );
}
