import K from "utilities/constants";

const { Lookup } = K.Network.URL;

const tabsConfig = {
  [K.LookupTabKeys.Source]: {
    reduxKey: K.Redux.Source,
    apiEndPoint: Lookup.Sources,
    name: "Source",
    table: K.LookupTableNames.Source,
  },
  [K.LookupTabKeys.AdSource]: {
    reduxKey: K.Redux.AdSource,
    apiEndPoint: Lookup.AddSources,
    table: K.LookupTableNames.AdSource,
    name: "Ad Sources",
  },
  [K.LookupTabKeys.ReportingSchedule]: {
    reduxKey: K.Redux.ReportingSchedule,
    apiEndPoint: Lookup.ReportingSchedule,
    table: K.LookupTableNames.ReportingSchedule,
    name: "Reporting Schedule",
  },
  [K.LookupTabKeys.Stage]: {
    reduxKey: K.Redux.Stage,
    apiEndPoint: Lookup.Stage,
    name: "Stage",
    table: K.LookupTableNames.Stage,
  },
  [K.LookupTabKeys.ATSNames]: {
    reduxKey: K.Redux.AtsName,
    apiEndPoint: Lookup.AtsNames,
    name: "ATS Names",
    table: K.LookupTableNames.ATSNames,
  },
  [K.LookupTabKeys.CandidateStatus]: {
    reduxKey: K.Redux.CandidateStatus,
    apiEndPoint: Lookup.CandidateStatus,
    name: "Candidate Status",
    table: K.LookupTableNames.CandidateStatus,
  },
  [K.LookupTabKeys.JobStatus]: {
    reduxKey: K.Redux.JobStatus,
    apiEndPoint: Lookup.JobStatus,
    name: "Job Status",
    table: K.LookupTableNames.JobStatus,
  },

  [K.LookupTabKeys.ContactTypes]: {
    reduxKey: K.Redux.ContactTypes,
    apiEndPoint: Lookup.ContactTypes,
    name: "Contact Types",
    table: K.LookupTableNames.ContactTypes,
  },
  [K.LookupTabKeys.UserDesignation]: {
    reduxKey: K.Redux.UserDesignation,
    apiEndPoint: Lookup.UserDesignation,
    name: "User Designations",
    table: K.LookupTableNames.UserDesignation,
  },
  [K.LookupTabKeys.Ownership]: {
    reduxKey: K.Redux.Ownership,
    apiEndPoint: Lookup.Ownership,
    name: "Ownership",
    table: K.LookupTableNames.Ownership,
  },
  [K.LookupTabKeys.BillingTypes]: {
    reduxKey: K.Redux.BillingTypes,
    apiEndPoint: Lookup.BillingTypes,
    name: "One Time Billing Types",
    table: K.LookupTableNames.BillingTypes,
  },
  [K.LookupTabKeys.ClassStatus]: {
    reduxKey: K.Redux.ClassStatus,
    apiEndPoint: Lookup.ClassStatus,
    name: "Class Status",
    table: K.LookupTableNames.ClassStatus,
  },
};

export const clientTabsConfig = {
  [K.LookupTabKeys.JobLocations]: {
    reduxKey: K.Redux.JobLocation,
    apiEndPoint: Lookup.JobLocations,
    name: "Job Locations",
    table: K.LookupTableNames.JobLocations,
  },
  [K.LookupTabKeys.JobDepartments]: {
    reduxKey: K.Redux.JobDepartment,
    apiEndPoint: Lookup.JobDepartments,
    name: "Job Departments",
    table: K.LookupTableNames.JobDepartments,
  },
  [K.LookupTabKeys.JobGroups]: {
    reduxKey: K.Redux.JobGroup,
    apiEndPoint: Lookup.JobGroups,
    name: "Job Groups",
    table: K.LookupTableNames.JobGroups,
  },
  [K.LookupTabKeys.JobTitle]: {
    reduxKey: K.Redux.JobTitle,
    apiEndPoint: Lookup.JobTitle,
    name: "Job Title",
    table: K.LookupTableNames.JobTitle,
  },
};

export default tabsConfig;
