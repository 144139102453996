import {
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import K from "utilities/constants";
import "./oneTimeComponent.scss";

const { Text } = Typography;

export default function OneTimeComponent({
  fieldObj,
  index,
  parentIndex,
  currencyList,
}) {
  const formItemName =
    parentIndex !== undefined ? [parentIndex, fieldObj.id] : [fieldObj.id];

  return (
    <div className="mb-0 oneTimeComponentWrap">
      <div className="oneTimeHeader">
        <Text className="oneTimeTitle">{fieldObj.name}</Text>
      </div>
      <Divider className="headerDvider" />
      <div className="hireFeeData">
        <Row key={index}>
          <Col flex="auto">
            <Row align="bottom" gutter={[12, 0]}>
              <Col
                xs={24}
                md={!fieldObj.hasMarkup ? 24 : 12}
                xl={!fieldObj.hasMarkup ? 12 : 6}
              >
                <Form.Item
                  label="Amount"
                  name={[...formItemName, "amount"]}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Amount!",
                    },
                  ]}
                >
                  <InputNumber className="w-100" placeholder="Enter Amount" />
                </Form.Item>
              </Col>
              {fieldObj.hasMarkup ? (
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    label="Markup"
                    name={[...formItemName, "markup"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter markup!",
                      },
                    ]}
                  >
                    <InputNumber
                      className="w-100"
                      placeholder="Enter Markup"
                      min={0}
                    />
                  </Form.Item>
                </Col>
              ) : null}
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={[...formItemName, "currency"]}
                  initialValue="USD"
                >
                  <Select
                    showSearch={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Currency"
                    options={currencyList.map(({ code }) => ({
                      value: code,
                      label: code,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={[...formItemName, "amountType"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    disabled={!fieldObj.isDependentOn}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    options={[
                      { value: K.Format.Dollar, label: "$" },
                      { value: K.Format.Percentage, label: "%" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Referral Fee"
                  name={[...formItemName, "referral"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter referral fee!",
                    },
                  ]}
                >
                  <InputNumber placeholder="Referral Fee" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Rehire Fee"
                  name={[...formItemName, "reHired"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter rehire fee!",
                    },
                  ]}
                >
                  <InputNumber placeholder="Rehire Fee" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  initialValue={false}
                  valuePropName="checked"
                  name={[...formItemName, "isCommissionable"]}
                >
                  <Checkbox>Is Commissionable</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  initialValue={fieldObj.isOfferAccepted}
                  hidden={true}
                  valuePropName="checked"
                  name={[...formItemName, "isOfferAccepted"]}
                >
                  <Checkbox>Is Offer Acepted</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
