import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
  downloadFile,
} from "utilities/generalUtility";

export default class Invoice {
  static async getAll(clientIds) {
    const res = await NetworkCall.fetch(Request.getAllInvoices(clientIds));
    return camelCaseKeysRecursively(res.data);
  }

  static async edit(values) {
    const res = await NetworkCall.fetch(Request.editInvoice(values));
    return camelCaseKeys(res.data);
  }

  static async getInvoiceDetail(id) {
    const res = await NetworkCall.fetch(Request.getInvoiceDetails(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async exportInvoiceDetail(id) {
    const res = await NetworkCall.fetch(Request.exportInvoiceDetail(id));
    downloadFile(res, "invoide_detail_export");
  }
  static async delete(values) {
    const res = await NetworkCall.fetch(Request.deleteInvoice(values));
    return camelCaseKeys(res.data);
  }
  static async bulkUpdate(body) {
    const res = await NetworkCall.fetch(Request.bulkUpdateInvoice(body));
    return camelCaseKeys(res.data);
  }
}
