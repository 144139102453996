import { AgGridReact } from "ag-grid-react";
import { Button, Card, Input, message, PageHeader, Select, Tag } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Job from "redux/models/job";
import User from "redux/models/user";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";

import {
  displayDollar,
  filterColumnListing,
  stringSorting,
} from "utilities/tableUtility";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { tooltipTagRenderer } from "features/billingCommissions/submitToBilling/tableColumns";
import { camelToSnakeCase } from "utilities/generalUtility";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;
const { Lookup } = K.Network.URL;
const sortDict = {
  asc: "ASC",
  desc: "DESC",
};

const searchFilterPrefix = "global_jobs";
const getSearchKey = "global_jobs_search";
const getFilterKey = "global_jobs_status";

export default function Jobs() {
  const gridRef = useRef();

  const [gridApi, setGridApi] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [listing, setListing] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const globalSelector = useSelector(selectGlobalSelector);
  const userDetails = useSelector(selectUser).details;
  const lookupTables = useSelector(selectConfigration).lookup;
  const [setter, getter] = useSearchAndFilter();

  const statusRef = useRef(getter(getFilterKey));
  const searchRef = useRef(getter(getSearchKey));

  const actionColumnRenderer = (event) => (
    <>
      <Button type="link" className={LayoutCss.appListingCardActions}>
        Edit
      </Button>
      <Button type="link" className={LayoutCss.appListingCardActions}>
        Delete
      </Button>
    </>
  );
  const payRenderer = (params) => displayDollar(params.value);

  const changeFormat = (params) => convertIntoDashUSFormat(params.value, false);

  const tagRenderer = (params, key, color) =>
    params.data[key].map((item, i) => (
      <Tag key={i} color={color}>
        {item.name}
      </Tag>
    ));

  const jobStatus = (params) => (params.data.isCancel === 1 ? "Yes" : "No");

  const tooltipJobStatus = (params) => (
    <div className="client-list-tooltip">
      {params.data.isCancel === 1 ? "Yes" : "No"}
    </div>
  );
  const tooltipWorkFormHome = (params) => (
    <div className="client-list-tooltip">
      {params.data.isWorkFromHome === 1 ? "Yes" : "No"}
    </div>
  );
  const tooltipChangeFormat = (params) => (
    <div className="client-list-tooltip">
      {" "}
      {convertIntoDashUSFormat(params.value, false)}
    </div>
  );
  const tooltipPayRenderer = (params) => (
    <div className="client-list-tooltip"> {displayDollar(params.value)}</div>
  );
  const tooltipJobDepartment = (params) => (
    <div className="client-list-tooltip">
      {params.data.jobDepartment?.name ?? "N/A"}
    </div>
  );

  const tooltipJobGroup = (params) => (
    <div className="client-list-tooltip">
      {params.data.jobGroup?.name ?? "N/A"}
    </div>
  );

  const tooltipAtsId = (params) => {
    return (
      <div className="client-list-tooltip">
        {params.data.extAtsId ?? K.NullPlaceholder}
      </div>
    );
  };

  const workFormHome = (params) =>
    params.data.isWorkFromHome === 1 ? "Yes" : "No";

  const atsId = (params) => {
    return params.data.extAtsId ?? K.NullPlaceholder;
  };
  const jobDepartment = (params) => params.data.jobDepartment?.name ?? "N/A";
  const jobGroup = (params) => params.data.jobGroup?.name ?? "N/A";

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      suppressMovable: false,
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Recruiter",
      field: "searchRecruiters",
      sortable: true,
      cellRenderer: (params) => tagRenderer(params, "jobRecruiters", "#0070C0"),
      tooltipField: "searchRecruiters",
      tooltipComponent: (params) => tooltipTagRenderer(params, "jobRecruiters"),
    },
    {
      headerName: "Job Title",
      field: "title",
      tooltipField: "title",
      sortable: true,
      suppressMovable: false,
    },
    {
      headerName: "Current Openings",
      field: "currentOpenings",
      sortable: true,
      tooltipField: "currentOpenings",
    },
    {
      headerName: "Job Location",
      field: "jobLocation.name",
      sortable: true,
      tooltipField: "jobLocation.name",
    },
    {
      headerName: "Job Department",
      field: "jobDepartment",
      sortable: true,
      tooltipField: "jobDepartment.name",
      getQuickFilterText: jobDepartment,
      tooltipComponent: tooltipJobDepartment,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: jobDepartment,
    },
    {
      headerName: "Job Group",
      field: "jobGroup",
      sortable: true,
      tooltipField: "jobGroup.name",
      getQuickFilterText: jobGroup,
      tooltipComponent: tooltipJobGroup,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: jobGroup,
    },
    {
      headerName: "Job Hiring Manager",
      field: "searchHiringManagers",
      sortable: true,
      tooltipField: "searchHiringManagers",
      tooltipComponent: (params) =>
        tooltipTagRenderer(params, "jobHiringManagers"),
      cellRenderer: (params) =>
        tagRenderer(params, "jobHiringManagers", "#BDD7EE"),
    },
    {
      headerName: "Recruiting Start Date",
      field: "recruitingStartDate",
      sortable: true,
      suppressMovable: false,
      tooltipField: "recruitingStartDate",
      tooltipComponent: tooltipChangeFormat,
      cellRenderer: changeFormat,
    },
    {
      headerName: "Max Pay",
      field: "maxPay",
      sortable: true,
      suppressMovable: false,
      tooltipField: "maxPay",
      tooltipComponent: tooltipPayRenderer,
      cellRenderer: payRenderer,
    },
    {
      headerName: "Min Pay",
      field: "minPay",
      sortable: true,
      suppressMovable: false,
      tooltipField: "minPay",
      tooltipComponent: tooltipPayRenderer,
      cellRenderer: payRenderer,
    },
    {
      headerName: "Tier",
      field: "jobTier",
      sortable: true,
      tooltipField: "jobTier",
    },
    {
      headerName: "ATS ID",
      field: "extAtsId",
      sortable: true,
      tooltipField: "extAtsId",
      cellRenderer: atsId,
      tooltipComponent: tooltipAtsId,
    },
    {
      headerName: "Job Status",
      field: "jobStatus.name",
      sortable: true,
      tooltipField: "jobStatus.name",
    },

    {
      headerName: "WFH?",
      field: "isWorkFromHome",
      sortable: true,
      tooltipField: "isWorkFromHome",
      tooltipComponent: tooltipWorkFormHome,
      cellRenderer: workFormHome,
    },
    {
      headerName: "Cancelled?",
      field: "isCancel",
      sortable: true,
      tooltipField: "isCancel",
      tooltipComponent: tooltipJobStatus,
      cellRenderer: jobStatus,
    },
  ]);

  // const onChange = useCallback(
  //   (event) => onFilterTextBoxChanged(event.target.value, gridRef),
  //   [],
  // );

  const onChange = (event) => {
    searchRef.current = event.target.value;
    setter({ search: event.target.value }, searchFilterPrefix);

    setRefreshTable((prev) => !prev);
  };

  const onStatusChange = (newValue) => {
    statusRef.current = newValue;
    setter({ status: newValue }, searchFilterPrefix);
    setRefreshTable((prev) => !prev);
  };

  // const externalFilterChanged = useCallback((newValue) => {
  //   statusRef.current = newValue;
  //   setter({ status: newValue }, searchFilterPrefix);

  //   gridRef.current.api.onFilterChanged();
  //   //statusRef.current = newValue;
  // }, []);

  // const isExternalFilterPresent = useCallback(() => {
  //   return statusRef.current !== undefined;
  // }, []);

  // const doesExternalFilterPass = useCallback(
  //   (node) => {
  //     if (node.data) {
  //       return node.data.jobStatus.name === statusRef.current;
  //     }
  //     return true;
  //   },
  //   [statusRef.current],
  // );

  const rowClickedHandler = (event) => {
    const { id, clientsId } = event.data;
    history.push(`/clients/${clientsId}/job/${id}`);
  };

  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userDetails.id,
          tableName: K.AgGridTable.Keys.GlobalJobsVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.GlobalJobsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.GlobalJobsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "recruitingStartDate")
          return {
            ...item,
            cellRenderer: changeFormat,
            tooltipComponent: changeFormat,
          };
        else if (["minPay", "maxPay"].includes(item.field))
          return {
            ...item,
            cellRenderer: payRenderer,
            tooltipComponent: tooltipPayRenderer,
          };
        else if (item.field === "isCancel")
          return {
            ...item,
            cellRenderer: jobStatus,
            tooltipComponent: tooltipJobStatus,
          };
        else if (item.field === "isWorkFromHome")
          return {
            ...item,
            cellRenderer: workFormHome,
            tooltipComponent: tooltipWorkFormHome,
          };
        else if (item.field === "extAtsId")
          return {
            ...item,
            cellRenderer: atsId,
            tooltipComponent: tooltipAtsId,
          };
        else if (item.field === "jobDepartment")
          return {
            ...item,
            cellRenderer: jobDepartment,
            tooltipComponent: tooltipJobDepartment,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            getQuickFilterText: jobDepartment,
          };
        else if (item.field === "jobGroup")
          return {
            ...item,
            cellRenderer: jobGroup,
            tooltipComponent: tooltipJobGroup,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            getQuickFilterText: jobGroup,
          };
        else if (
          ["searchRecruiters", "searchHiringManagers"].includes(item.field)
        ) {
          const columnKeys = {
            searchRecruiters: ["jobRecruiters", "#0070C0"],
            searchHiringManagers: ["jobHiringManagers", "#BDD7EE"],
          };

          return {
            ...item,
            cellRenderer: (params) =>
              tagRenderer(
                params,
                columnKeys[item.field][0],
                columnKeys[item.field][1],
              ),
            tooltipComponent: (params) =>
              tooltipTagRenderer(params, columnKeys[item.field][0]),
          };
        } else if (item.field === "actions")
          return { ...item, cellRenderer: actionColumnRenderer };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  const getLookupData = async () => {
    try {
      await dispatch(LookupTable.getData(K.Redux.JobStatus, Lookup.JobStatus));
    } catch (error) {
      console.error(error);
    }
  };
  const getJobsByClientIds = async (body) => {
    try {
      const res = await Job.getByClientIdsWithServerSide(body);

      const tableData = res.jobs.map((el) => {
        let searchRecruiters = "",
          searchHiringManagers = "";
        const jobRecruiters = el.jobRecruiters.map((obj) => {
          searchRecruiters += obj.recruiter.name;
          return obj.recruiter;
        });
        const jobHiringManagers = el.jobHiringManagers.map((obj) => {
          searchHiringManagers += obj.hiringManager.name;
          return obj.hiringManager;
        });
        return {
          id: el.id,
          title: el.title,
          clientsId: el.clientsId,
          currentOpenings: el.vacancies[0]?.currentOpenings ?? 0,
          jobLocation: el.jobLocation,
          jobDepartment: el.jobDepartment,
          recruitingStartDate: el.recruitingStartDate,
          jobRecruiters,
          jobStatus: el.jobStatus,
          jobHiringManagers,
          jobTier: el.jobTier.name,
          minPay: el.minPay,
          maxPay: el.maxPay,
          extAtsId: el.extAtsId,
          jobGroup: el.jobGroup,
          isWorkFromHome: el.isWorkFromHome,
          searchRecruiters,
          searchHiringManagers,
        };
      });
      return { jobs: tableData, totalCount: res.totalCount };
      // setListing(tableData);
    } catch (err) {
      console.error(err);
    }
  };

  const datasource = useCallback(
    {
      async getRows(params) {
        const { startRow, endRow } = params.request;

        const pageSize = endRow - startRow;
        const page = startRow / pageSize + 1;

        const body = {
          page,
          pageSize,
          clientIds: globalSelector.selectedClients,
          search: getter(getSearchKey),
          filter: {
            jobStatusId:
              statusRef.current === undefined ? null : statusRef.current,
          },
          sortBy: camelToSnakeCase(params.request.sortModel[0]?.colId ?? "id"),
          sortOrder:
            params.request.sortModel.length > 0
              ? sortDict[params.request.sortModel[0]["sort"]]
              : "asc",
        };
        try {
          const data = await getJobsByClientIds(body);

          params.success({
            rowData: data.jobs,
            rowCount: data.totalCount,
          });
        } catch (err) {
          params.fail();
        }
      },
    },
    [refreshTable, globalSelector.selectedClients],
  );
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getColumnsConfigrations(), getLookupData()]);
    })();
  }, [globalSelector.selectedClients]);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Jobs"
        className={LayoutCss.appPageHeader}
      />
      <Card
        className={`${LayoutCss.appCard} ${LayoutCss.rolesPermissionCard}`}
        extra={
          <div
            className={"candidateSearch " + LayoutCss.appListingCardRolesTable}
          >
            <Search
              allowClear
              placeholder="Search"
              value={getter(getSearchKey)}
              onChange={onChange}
              className={LayoutCss.appListingCardRolesTableSearch}
            />

            <Select
              showArrow
              showSearch={true}
              allowClear
              value={getter(getFilterKey)}
              optionFilterProp="label"
              placeholder="Select Job Status"
              className={`commision-select ${LayoutCss.appListingCardStatusSelect1}`}
              options={lookupTables.jobStatus.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              style={{ width: 200 }}
              onChange={onStatusChange}
            />
          </div>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            ref={gridRef}
            rowData={listing}
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            serverSideDatasource={datasource}
            columnDefs={columnDefs}
            onRowClicked={rowClickedHandler}
            onColumnMoved={updateColumns}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onColumnPinned={onColumnVisible}
            quickFilterText={getter(getSearchKey)}
            onColumnVisible={onColumnVisible}
            loadingCellRenderer={false}
            // isExternalFilterPresent={isExternalFilterPresent}
            // doesExternalFilterPass={doesExternalFilterPass}
          />
        </div>
      </Card>
    </>
  );
}
