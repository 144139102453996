import { Checkbox, Col, DatePicker, Form, Row, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import { disabledDate } from "utilities/dateUtility";
import ClientLevelOneTime from "./billingComponents/clientLevelOneTime";
import ClientLevelRecursive from "./billingComponents/clientLevelRecursive";
import { useState } from "react";

export default function ClientBillingRuleFields({
  styles,
  showEffectiveSince,
  form,
  isBackDate = false,
  backDateDisabledDate,
}) {
  const configration = useSelector(selectConfigration);
  const billingClientsList = configration.billingClients.filter(
    ({ isAssignable }) => isAssignable,
  );

  const shouldUpdate = (prevValues, currValues) =>
    prevValues.billingTypes !== currValues.billingTypes;

  const billingComponents = {
    [K.BillingTypes.OneTime]: ClientLevelOneTime,
    [K.BillingTypes.Recursive]: ClientLevelRecursive,
  };

  return (
    <>
      {showEffectiveSince && (
        <>
          <Row>
            <Col span={24}>
              <Form.Item
                className={styles.brFormItems}
                label="Effective Date"
                name="effectiveSince"
                rules={[
                  { required: true, message: "Please select effective date!" },
                ]}
              >
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className={styles.datePicker}
                  disabledDate={
                    isBackDate ? backDateDisabledDate : disabledDate
                  }
                  format={K.DateFormat.DashUSFormat}
                />
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Form.Item
              className="reason-field"
              name={"isEmptyClientRule"}
              valuePropName="checked"
              initialValue={false}
              hidden={isBackDate}
            >
              <Checkbox
                onChange={(e) => {
                  form.setFieldsValue({ billingTypes: [] });
                }}
              >
                <div
                  className="d-flex  justify-content-between"
                  onClick={(e) => e.preventDefault()}
                  hidden={isBackDate}
                >
                  Stop All Client Billing Rules &nbsp;
                  <div>
                    <Tooltip
                      title="Only select this box if you would like to stop all Client Billing Rules."
                      color={K.TooltipColor}
                      placement="top"
                    >
                      <i className="icon-info-circle"></i>
                    </Tooltip>
                  </div>
                </div>
              </Checkbox>
            </Form.Item>
          </div>
        </>
      )}
      {/* TODO: show effective since is used to check is component rendered from client creation or add new  */}

      <Form.Item shouldUpdate={true}>
        {({ getFieldValue }) => (
          <Form.Item
            label="Billing Types"
            name="billingTypes"
            hidden={getFieldValue("isEmptyClientRule") || isBackDate}
            rules={[
              {
                required:
                  getFieldValue("isEmptyClientRule") === false &&
                  showEffectiveSince,
                message: "Please select billing type !",
              },
            ]}
            initialValue={[]}
          >
            <Select
              showArrow
              mode="multiple"
              showSearch={true}
              allowClear
              optionFilterProp="label"
              className={styles.selectField}
              placeholder="Select Billing Type"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={billingClientsList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item shouldUpdate={shouldUpdate} hidden={isBackDate}>
        {({ getFieldValue }) =>
          getFieldValue("billingTypes") &&
          getFieldValue("billingTypes").map((value, i) => {
            const filtered = billingClientsList.filter(
              ({ id }) => value === id,
            );
            const Component = billingComponents[filtered[0].type];
            return (
              <Component
                key={i}
                fieldObj={filtered[0]}
                index={i}
                currencyList={configration.currencies}
              />
            );
          })
        }
      </Form.Item>
    </>
  );
}
