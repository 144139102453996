import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BillingJob from "redux/models/billingJobs";
import Client from "redux/models/client";
import LookupTable from "redux/models/lookupTable";
import { selectClient } from "redux/slices/clientSlice";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import TestBillingRule from "../testBillingRules/testBillingRules";
import TierComponent from "./billingComponents/tierComponent";
import styles from "./jobBilling.module.scss";
import { JobBillingRulesForm } from "./jobBillingRulesForm";

const { Title } = Typography;
const { Lookup } = K.Network.URL;

export default function JobBilling({ form, setCreateJobBillingRule }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientSlice = useSelector(selectClient);
  const configrationSlice = useSelector(selectConfigration);
  const params = new URLSearchParams(window.location.search);
  // *Client ID
  const { id } = useParams();
  const onFinish = async (values) => {
    const jobLevelBillingRules = [];
    values.jobLevelBillingRules.forEach((rule) => {
      const jobLevelBilligRulesValues = rule.billingTypes.reduce(
        (prev, curr) => {
          console.log(curr);
          const ruleObj = { isInfinite: 0, ...rule[curr] };
          ruleObj.jobBillingTypesId = curr;
          return [
            ...prev,
            {
              ...ruleObj,
              endAfterOccurances:
                ruleObj.isInfinite === 1 ? 0 : ruleObj.endAfterOccurances,
            },
          ];
        },
        [],
      );
      jobLevelBillingRules.push({
        jobLevelBilligRulesValues,
        tierName: rule.tierName,
        guaranteeDays: rule.guaranteeDays,
        isWorkingDays: rule.isWorkingDays === true ? 1 : 0,
        titleAssociated: rule.titleAssociated,
      });
    });
    console.log({ jobLevelBillingRules });
    if (params.has("tab")) setCreateJobBillingRule(jobLevelBillingRules);
    else {
      try {
        await Client.create({
          ...clientSlice.createClientData,
          jobLevelBillingRules,
        });
        success();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getAllCurrencies = async () => {
    try {
      await dispatch(BillingJob.getCurrencies());
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          dispatch(
            LookupTable.getData(K.Redux.JobTitle, `${Lookup.JobTitle}/${id}`),
          ),
          getAllCurrencies(),
        ]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const success = () => {
    Modal.success({
      centered: true,
      className: styles.headerModal,
      title: (
        <Title className={styles.modalTitle} level={5}>
          Client Successfully Created!
        </Title>
      ),
      onOk: () => history.push("/clients"),
    });
  };

  return (
    <>
      <Card
        title="Job Level Billing Rules"
        className={`${styles.cardWrapper} ${LayoutCss.appCard} ${styles.jobBillingCardComponent}`}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          scrollToFirstError
          initialValues={{
            jobLevelBillingRules: [
              {
                // billingTypes: [],
                tierName: undefined,
                titleAssociated: undefined,
              },
            ],
          }}
          className={styles.cardForm}
        >
          <Form.List name="jobLevelBillingRules">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div className={styles.jobBillingUnitForm} key={index}>
                    {index > 0 && (
                      <div className={styles.jobBillingUnitRemoveBtn}>
                        <Popconfirm
                          title="Are you sure ?"
                          onConfirm={() => {
                            remove(field.name);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            danger
                            icon={<i className="icon-delet"></i>}
                          />
                        </Popconfirm>
                      </div>
                    )}
                    <Row gutter={[16, 16]}>
                      <Col md={12} sm={24} xs={24}>
                        <TierComponent form={form} parentIndex={index} />
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <JobBillingRulesForm
                          key={index}
                          parentIndex={index}
                          form={form}
                          styles={styles}
                          showEffectiveSince={false}
                          currencyList={configrationSlice.currencies}
                          billingJobsList={configrationSlice.billingJobs.filter(
                            ({ isAssignable }) => isAssignable,
                          )}
                        />
                        <TestBillingRule form={form} parentIndex={index} />
                      </Col>
                    </Row>
                  </div>
                ))}
                {params.get("tab") === "job-billing-rules" ? (
                  <Button
                    onClick={form.submit}
                    disabled={!clientSlice.isTestPassed}
                    type="primary"
                    className={styles.clientSaveButton}
                  >
                    Save
                  </Button>
                ) : (
                  <div className={styles.moreTierBtn}>
                    <Button
                      className={styles.cardButton}
                      type="primary"
                      onClick={add}
                      icon={<PlusOutlined />}
                    >
                      More Tiers
                    </Button>
                  </div>
                )}
              </>
            )}
          </Form.List>
        </Form>
      </Card>
    </>
  );
}
