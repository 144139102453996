import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  camelCaseKeysRecursively,
  camelCaseKeys,
  downloadFile,
} from "utilities/generalUtility";

export default class Job {
  static async getById(clientId, jobId) {
    const res = await NetworkCall.fetch(
      Request.getJobDetailsById(clientId, jobId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async updateVacancies(jobId, openings) {
    await NetworkCall.fetch(Request.updateJobVacancies(jobId, openings));
  }

  static async getSettings(jobId, clientId) {
    const res = await NetworkCall.fetch(
      Request.getJobSettings(jobId, clientId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async updateSettings(values) {
    const res = await NetworkCall.fetch(Request.updateJobSettings(values));
    return camelCaseKeysRecursively(res.data);
  }

  static async cancelById(values) {
    await NetworkCall.fetch(Request.cancelJobById(values), false);
  }

  static async CustomCancelBillings(values) {
    await NetworkCall.fetch(Request.CustomCancelBilling(values), false);
  }

  static async getByClientIds(ids, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getJobsByClientIds(ids),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getByClientIdsWithServerSide(body, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getJobsByClientIdsWithServerSide(body),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getOnlyJobsByClientIds(ids, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getOnlyJobsByClientIds(ids),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getStatistics(id) {
    const res = await NetworkCall.fetch(Request.getJobStatistics(id));
    return camelCaseKeys(res.data);
  }

  static async exportJobDetail(payload) {
    const res = await NetworkCall.fetch(Request.exportJobDetail(payload));
    downloadFile(res, "job_data_export");
  }

  static async exportJobCandidates(payload) {
    const res = await NetworkCall.fetch(Request.exportJobCandidates(payload));
    // downloadFile(res, "candidate_data_export");
  }

  static async updateJobStatus(body) {
    await NetworkCall.fetch(Request.updateJobStatusById(body));
  }

  static async deleteJob(jobId) {
    const res = await NetworkCall.fetch(Request.deleteJob(jobId));
    return res;
  }

  static async syncCandidateTTM(jobId) {
    const res = await NetworkCall.fetch(Request.syncCanidateTtm(jobId), false);
    return res;
  }

  static async getAllJobsForExcelUpload(loader = true) {
    const res = await NetworkCall.fetch(
      Request.getAllJobsForExcelUpload(),
      loader,
    );
    return camelCaseKeysRecursively(res);
  }

  static async filledLastOpening(jobId, loader = true) {
    const res = await NetworkCall.fetch(
      Request.filledLastJobOpening(jobId),
      loader,
    );
    return camelCaseKeysRecursively(res);
  }
}
