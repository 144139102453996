import { AgGridReact } from "ag-grid-react";
import { Button, Card, DatePicker, Form, Input, Modal, Switch } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BillingSchedule from "redux/models/billingSchedule";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import K from "utilities/constants";
import {
  epochTimeToUTCDashUSFormat,
  formatUnixToMoment,
} from "utilities/dateUtility";
import { displayDollar, onFilterTextBoxChanged } from "utilities/tableUtility";
import {
  amountRenderer,
  dueDate,
  inFiniteRenderer,
  isActive,
  isCommissionable,
  tooltipAmountRenderer,
  tooltipDueDate,
  tooltipInFiniteRenderer,
  tooltipIsActive,
  tooltipIsCommissionable,
} from "./scheduledTableRender";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;
const searchFilterPrefix = "job_scheduled_list";
const getSearchKey = "job_scheduled_list_search";
export default function JobScheduledList() {
  const gridRef = useRef(null);
  const [form] = Form.useForm();
  const editIdRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsModalVisible] = useState(false);
  const globalSelector = useSelector(selectGlobalSelector);
  const [setter, getter] = useSearchAndFilter();

  const [columnDefs] = useState([
    {
      headerName: "Id",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Candidate Name",
      field: "candidateName",
      sortable: true,
      tooltipField: "candidateName",
    },
    {
      headerName: "Billing Type Name",
      field: "billingTypeName",
      sortable: true,
      tooltipField: "billingTypeName",
    },
    {
      headerName: "Job Title",
      field: "jobTitle",
      sortable: true,
      tooltipField: "jobTitle",
    },
    {
      headerName: "Currency",
      field: "currency",
      sortable: true,
      tooltipField: "currency",
    },
    {
      headerName: "Amount",
      field: "duplicateAmount",
      sortable: true,
      tooltipField: "duplicateAmount",
      tooltipComponent: tooltipAmountRenderer,
      cellRenderer: amountRenderer,
    },
    {
      headerName: "Due Date",
      field: "dueDate",
      sortable: true,
      tooltipField: "dueDate",
      tooltipComponent: tooltipDueDate,
      cellRenderer: dueDate,
    },
    {
      headerName: "Commissionable?",
      field: "isCommissionable",
      sortable: true,
      tooltipField: "isCommissionable",
      tooltipComponent: tooltipIsCommissionable,
      cellRenderer: isCommissionable,
    },
    {
      headerName: "Active?",
      field: "isActive",
      sortable: true,
      tooltipField: "isActive",
      tooltipComponent: tooltipIsActive,
      cellRenderer: isActive,
    },
    {
      headerName: "Repeat",
      field: "isInfinite",
      sortable: true,
      minWidth: 200,
      tooltipField: "isInfinite",
      tooltipComponent: tooltipInFiniteRenderer,
      cellRenderer: inFiniteRenderer,
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      resizable: false,
      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <>
          <Button
            type="link"
            className={LayoutCss.appListingCardActions}
            onClick={() => editRecord(params.data)}
          >
            Edit
          </Button>
        </>
      ),
    },
  ]);

  const afterModalClose = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onOk = () => {
    form.submit();
  };

  const onChange = useCallback((event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);
    setter({ search: event.target.value }, searchFilterPrefix);
  }, []);

  const getScheduledbilling = async () => {
    try {
      const data = await BillingSchedule.getScheduledbilling(
        globalSelector.selectedClients,
      );
      setRowData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const editRecord = (data) => {
    setIsModalVisible(true);
    editIdRef.current = data.id;
    form.setFieldsValue({
      amount: data.amount,
      dueDate: formatUnixToMoment(data.dueDate).utc(),
      isActive: data.isActive,
    });
  };

  const onFinish = async (item) => {
    setIsLoading(true);
    const body = {
      dueDate: item.dueDate.format(K.DateFormat.Display),
      amount: item.amount,
      isActive: +item.isActive,
    };
    try {
      const res = await BillingSchedule.updateJobScheduledbilling(
        body,
        editIdRef.current,
      );
      const updateResponse = rowData.map((item) =>
        item.id === res.id ? res : item,
      );
      setIsLoading(false);
      setIsModalVisible(false);
      setRowData(updateResponse);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    getScheduledbilling();
  }, [globalSelector.selectedClients]);

  return (
    <>
      <Card
        className={"appCard " + LayoutCss.appCard}
        extra={
          <Search
            allowClear
            value={getter(getSearchKey)}
            onChange={onChange}
            placeholder="Search"
            className={LayoutCss.appListingCardSearchBar1}
          />
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 675,
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            {...K.AgGridTable.DefaultProps}
            defaultColDef={K.AgGridTable.DefaultColDef}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>

      <Modal
        forceRender
        centered
        open={isVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okText="Update"
        className="s2-theme-style"
        title="Edit Job Scheduled"
        wrapClassName="vertical-center-modal"
        okButtonProps={{ loading: isLoading }}
        onOk={onOk}
        onCancel={handleCancel}
        afterClose={afterModalClose}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            rules={[{ required: true, message: "Due Date is required." }]}
          >
            <DatePicker format={K.DateFormat.DashUSFormat} />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Amount is required." }]}
          >
            <Input placeholder="Enter amount" />
          </Form.Item>
          <Form.Item label="Active?" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
