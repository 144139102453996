import { AgGridReact } from "ag-grid-react";
import { Button, Card, Divider, Input, message, PageHeader, Tabs } from "antd";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent, pascalCase } from "utilities/generalUtility";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
} from "utilities/tableUtility";

const { Search } = Input;

export default function Users() {
  const gridRef = useRef();
  const history = useHistory();

  const userSlice = useSelector(selectUser);
  const displayBoolean = (params) => (params.data.isActive ? "Yes" : "No");

  const tooltipDisplayBoolean = (params) => (
    <div className="client-list-tooltip">
      {params.data.isActive ? "Yes" : "No"}
    </div>
  );

  const displayType = (params) =>
    pascalCase(params.data?.type ?? K.NullPlaceholder);

  const tooltipDisplayType = (params) => (
    <div className="client-list-tooltip">
      {pascalCase(params.data?.type ?? K.NullPlaceholder)}
    </div>
  );

  const displayOptional = (params) => params.data.jobTitle ?? K.NullPlaceholder;

  const tooltipDisplayOptional = (params) => (
    <div className="client-list-tooltip">
      {params.data.jobTitle ?? K.NullPlaceholder}
    </div>
  );

  const phoneExtRenderer = (params) =>
    params.data.phoneExt ?? K.NullPlaceholder;

  const toolTipPhoneExtRenderer = (params) => (
    <div className="client-list-tooltip">
      {params.data.phoneExt ?? K.NullPlaceholder}
    </div>
  );
  const actionColumnRenderer = (params) => (
    <>
      {isPermissionPresent(K.Permissions.EditUser, userSlice.roles) && (
        <Link to={`/configurations/users/edit/${params.data.id}`}>
          <Button type="link" className={LayoutCss.appListingCardActions}>
            Edit
          </Button>
        </Link>
      )}
    </>
  );

  const params = new URLSearchParams(window.location.search);
  const [pageStates, setPageStates] = useState({
    rowData: {
      Source2: [],
      External: [],
    },
    activeTab: params.has("tab") ? params.get("tab") : "External",
    isModalVisible: false,
  });

  const searchFilterPrefix = `user_${params.has("tab")}`;
  const getSearchKey = `user_${params.has("tab")}_search`;
  const [setter, getter] = useSearchAndFilter();

  const [columnDefs, setColumnDefs] = useState({
    External: [
      {
        headerName: "ID",
        field: "id",
        sortable: true,
        cellClass: "cursor-pointer",
        suppressColumnsToolPanel: true,
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        tooltipField: "name",
        cellClass: "cursor-pointer",
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        tooltipField: "email",
        cellClass: "cursor-pointer",
      },
      {
        headerName: "Phone Number",
        field: "phone",
        sortable: true,
        tooltipField: "phone",
        cellClass: "cursor-pointer",
      },
      {
        headerName: "Phone Ext",
        field: "phoneExt",
        sortable: true,
        tooltipField: "phoneExt",
        cellClass: "cursor-pointer",
        tooltipComponent: toolTipPhoneExtRenderer,
        cellRenderer: phoneExtRenderer,
      },
      {
        headerName: "Job Title",
        field: "jobTitle",
        cellClass: "cursor-pointer",
        sortable: true,
        tooltipField: "jobTitle",
        tooltipComponent: (params) => tooltipDisplayOptional(params),
        cellRenderer: (params) => displayOptional(params),
      },
      {
        headerName: "Active",
        field: "isActive",
        cellClass: "cursor-pointer",
        sortable: true,
        tooltipField: "isActive",
        tooltipComponent: (params) => tooltipDisplayBoolean(params),
        cellRenderer: (params) => displayBoolean(params),
      },
      {
        headerName: "Type",
        field: "type",
        cellClass: "cursor-pointer",
        sortable: true,
        tooltipField: "type",
        tooltipComponent: (params) => tooltipDisplayType(params),
        cellRenderer: (params) => displayType(params),
      },

      {
        headerName: "Actions",
        field: "actions",
        sortable: true,
        resizable: false,
        headerClass: "text-center",
        cellStyle: { textAlign: "center" },
        cellRenderer: actionColumnRenderer,
      },
    ],
    Source2: [
      {
        headerName: "ID",
        field: "id",
        sortable: true,
        cellClass: "cursor-pointer",
        suppressColumnsToolPanel: true,
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        tooltipField: "name",
        cellClass: "cursor-pointer",
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        tooltipField: "email",
        cellClass: "cursor-pointer",
      },
      {
        headerName: "Phone Number",
        field: "phone",
        sortable: true,
        tooltipField: "phone",
        cellClass: "cursor-pointer",
      },
      {
        headerName: "Phone Ext",
        field: "phoneExt",
        cellClass: "cursor-pointer",
        sortable: true,
        tooltipField: "phoneExt",
        tooltipComponent: toolTipPhoneExtRenderer,

        cellRenderer: phoneExtRenderer,
      },

      {
        headerName: "Job Title",
        field: "jobTitle",
        cellClass: "cursor-pointer",
        sortable: true,
        tooltipField: "jobTitle",
        tooltipComponent: (params) => tooltipDisplayOptional(params),
        cellRenderer: (params) => displayOptional(params),
      },
      {
        headerName: "Active",
        field: "isActive",
        cellClass: "cursor-pointer",
        sortable: true,
        tooltipField: "isActive",
        tooltipComponent: (params) => tooltipDisplayBoolean(params),
        cellRenderer: (params) => displayBoolean(params),
      },
      {
        headerName: "Type",
        field: "type",
        cellClass: "cursor-pointer",
        sortable: true,
        tooltipField: "type",
        tooltipComponent: (params) => tooltipDisplayType(params),
        cellRenderer: (params) => displayType(params),
      },
      {
        headerName: "Actions",
        field: "actions",
        sortable: true,
        resizable: false,
        headerClass: "text-center",
        cellStyle: { textAlign: "center" },
        cellRenderer: actionColumnRenderer,
      },
    ],
  });

  const onColumnVisible = async (event) => {
    const currentTab = params.get("tab");
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs[params.get("tab")],
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName:
          K.AgGridTable.Keys.UserVisibleColumn + "-" + K.Users.Type[currentTab],
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getAllUser = async () => {
    const currentTab = params.get("tab");
    try {
      const res = await User.getAll({ type: K.Users.Type[currentTab] });
      setPageStates((prev) => ({
        ...prev,
        rowData: { ...prev.rowData, [currentTab]: res },
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const onChange = (event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);
    setter({ search: event.target.value }, searchFilterPrefix);
  };

  const getColumnsConfigrations = async () => {
    try {
      const currentTab = params.get("tab");
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.UserVisibleColumn + "-" + K.Users.Type[currentTab],
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions")
          return { ...item, cellRenderer: actionColumnRenderer };
        else if (item.field === "isActive")
          return {
            ...item,
            cellRenderer: (params) => displayBoolean(params),
            tooltipComponent: (params) => tooltipDisplayBoolean(params),
          };
        else if (item.field === "type")
          return {
            ...item,
            cellRenderer: (params) => displayType(params),
            tooltipComponent: (params) => tooltipDisplayType(params),
          };
        else if (item.field === "jobTitle")
          return {
            ...item,
            cellRenderer: (params) => displayOptional(params),
            tooltipComponent: (params) => tooltipDisplayOptional(params),
          };
        else if (item.field === "phoneExt")
          return {
            ...item,
            cellRenderer: (params) => phoneExtRenderer(params),
            tooltipComponent: (params) => toolTipPhoneExtRenderer(params),
          };
        return item;
      });
      setColumnDefs((prev) => {
        return { ...prev, [currentTab]: parsed };
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onTabChange = (key) => {
    params.set("tab", key);
    history.push({ search: params.toString() });
    setPageStates((prev) => ({
      ...prev,
      activeTab: key,
    }));
  };

  useEffect(() => {
    if (params.has("tab")) {
      (async () => {
        await Promise.all([getAllUser(), getColumnsConfigrations()]);
      })();
    }
  }, [pageStates.activeTab]);

  useEffect(() => {
    const currentKey = params.get("tab");
    if (!(currentKey in K.UserType)) {
      history.push({ search: "?tab=Source2" });
      setPageStates((prev) => ({
        ...prev,
        activeTab: params.get("tab"),
      }));
    }
  }, [history.location.search]);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Users"
        extra={
          isPermissionPresent(K.Permissions.CreateUser, userSlice.roles) && (
            <Link to="/configurations/users/create">
              <Button type="primary">Create User</Button>
            </Link>
          )
        }
        footer={
          <>
            <Divider className={LayoutCss.appPageHeaderDivider} />
            <Tabs
              activeKey={params.get("tab")}
              onChange={onTabChange}
              className={LayoutCss.appPageHeaderTabs}
              items={Object.keys(K.UserType).map((item) => ({
                key: item,
                label: K.UserType[item].name,
              }))}
            />
          </>
        }
        className={LayoutCss.appPageHeader}
      />

      <Card
        title={params.get("tab") + " Users"}
        className={"appCard " + LayoutCss.appCard}
        extra={
          <Search
            allowClear
            placeholder="Search"
            value={getter(getSearchKey)}
            onChange={onChange}
            className={LayoutCss.appListingCardSearchBar1}
          />
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            ref={gridRef}
            onRowClicked={(event) =>
              history.push(`/configurations/users/detail/${event.data.id}`)
            }
            rowData={pageStates.rowData[params.get("tab")]}
            columnDefs={columnDefs[params.get("tab")]}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
            {...K.AgGridTable.DefaultProps}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>
    </>
  );
}
