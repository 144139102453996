import { useEffect, useState } from "react";
import { Select } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useParams } from "react-router-dom";
import CurrentCommissionPlans from "./currentCommissionPlans";
import UpcomingCommissionPlans from "./upcomingCommissionPlans";
import HistoryCommissionPlans from "./historyCommissionPlans";
import CommissionPlan from "redux/models/commissionPlan";

export default function CommissionPlansDetail() {
  const { planId } = useParams();
  const [plansDetails, setPlansDetails] = useState(null);
  const [commissionPlan, setCommissionPlan] = useState("upcomingPlans");

  const handleChange = (value) => {
    setCommissionPlan(value);
  };

  const getCommissionPlansById = async () => {
    try {
      const res = await CommissionPlan.getCommissionPlans(planId);
      setPlansDetails(res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCommissionPlansById();
  }, []);

  const commissionPlansComponents = {
    upcomingPlans: (
      <UpcomingCommissionPlans
        plansDetails={plansDetails}
        setPlansDetails={setPlansDetails}
        getCommissionPlansById={getCommissionPlansById}
      />
    ),
    historyPlans: <HistoryCommissionPlans plansDetails={plansDetails} />,
  };

  return (
    <>
      {plansDetails && <CurrentCommissionPlans plansDetails={plansDetails} />}
      <div className={LayoutCss.invoicesButton}>
        <Select
          showSearch={false}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          defaultValue="upcomingPlans"
          onChange={handleChange}
          className={LayoutCss.invoicesButtonSet}
          options={[
            { value: "historyPlans", label: "History" },
            { value: "upcomingPlans", label: "Upcoming" },
          ]}
        />
      </div>

      {plansDetails && commissionPlansComponents[commissionPlan]}
    </>
  );
}
