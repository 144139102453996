import { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import qs from "qs";
import md5 from "md5";
import { useLocation, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Typography } from "antd";
import {
  deleteQueryParam,
  redirectToUrl,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";
import styles from "./login.module.scss";
import User from "redux/models/user";

const { Title } = Typography;

export default function Login() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const paramJson = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    console.log("paramJson: ", paramJson);
    if (paramJson.err) {
      message.error(paramJson.err);
      deleteQueryParam("err");
    }
  }, []);

  const onFinish = async (values) => {
    const encryptedPass = md5(values.password);
    try {
      setIsLoading(true);
      const user = await User.loginCall(
        values.email,
        encryptedPass,
        values.remember,
      );
      setIsLoading(false);
      if (user) redirectToUrl("/");
    } catch (error) {
      setFieldErrorsFromServer(error, form, values);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.cardFrom}>
      <Title level={5} className={styles.cardTitle}>
        Login
      </Title>
      <Form
        className={styles.loginForm}
        form={form}
        name="loginForm"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            className={styles.formInputs}
            type="email"
            suffix={
              <UserOutlined className="site-form-item-icon text-primary" />
            }
            placeholder="Email"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            className={styles.formInputs}
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox className={styles.inputCheckbox}>Remember me</Checkbox>
          </Form.Item>

          <Link
            to="/forgot-password"
            className={"float-right " + styles.forgotPassword}
          >
            Forgot your password?
          </Link>
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            block
            size="large"
            type="primary"
            loading={isLoading}
            htmlType="submit"
            className={styles.formButton}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
