import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";
export default function UDFOptions({ form, disabled }) {
  const [optCount, setOptCount] = useState(0);
  const validateOptions = (value, index, field) => {
    if (disabled && !value) {
      return Promise.reject(`Option ${index} is required!`);
    }
    if (!disabled && !value && field.fieldKey === 0) {
      return Promise.reject(`Option ${index} is required!`);
    } else if (!disabled && value && optCount === field.fieldKey) {
      return Promise.reject("Click on + to add.");
    } else {
      return Promise.resolve();
    }
  };
  return (
    <>
      <Form.List
        name="options"
        dependencies={["options"]}
        initialValue={[undefined]}
      >
        {(fields, { add, remove }) => (
          <div className="answer-field-wrap">
            {fields.map((field, index) => {
              return (
                <Row align="middle" key={index} className="mb-4">
                  <Col flex="auto">
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        // gap: 16,
                      }}
                      size={16}
                      align="baseline"
                    >
                      <Form.Item
                        name={[field?.name, "value"]}
                        initialValue={null}
                        rules={[
                          {
                            required: true,
                            //message: `Enter Option Value ${index + 1}`,
                            validator: (_, value) => {
                              return validateOptions(value, index, field);
                            },
                          },
                        ]}

                        // style={{ flex: 1 }}
                      >
                        <Input
                          disabled={!disabled && index !== fields.length - 1}
                          placeholder={`Option ${index}`}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col flex="25px" className="reasonLabel">
                    {!disabled && (
                      <>
                        {index !== fields.length - 1 ? (
                          <Button
                            type="link"
                            icon={<i className={`icon-del-threshold`}></i>}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : (
                          <Button
                            className="plus-icon"
                            disabled={["", null].includes(
                              form.getFieldValue([
                                "options",
                                field.name,
                                "value",
                              ]),
                            )}
                            onClick={() => {
                              form.validateFields();
                              setOptCount((prev) => prev + 1);
                              add();
                            }}
                            icon={<PlusOutlined />}
                          />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              );
            })}
            {/* <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item> */}
          </div>
        )}
      </Form.List>
    </>
  );
}
