import NetworkCall from "network/networkCall";
import Request from "network/request";
import { downloadFile } from "utilities/generalUtility";

export default class Export {
  static async getReportJobsVacancies(params) {
    const res = await NetworkCall.fetch(
      Request.v2.exportReportJobsVacancies(params),
    );
    downloadFile(res, "job_data_export");
  }

  static async getReportCandidateJobEntries(params) {
    return NetworkCall.fetch(
      Request.v2.exportReportCandidateJobEntries(params),
    );
  }
}
