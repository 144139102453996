import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Candidate from "redux/models/candidate";
import moment from "moment";
import { displayDollar } from "utilities/tableUtility";
import { InfoCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import BillingApproval from "redux/models/billingApproval";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import {
  convertIntoDashUSFormat,
  convertIntoUTCDashUSFormat,
} from "utilities/dateUtility";
import SubmitOneTimeJobBilling from "common/submitOneTimeBilling/submitOneTimeJobBilling";
import { useParams } from "react-router-dom";
import BillingJob from "redux/models/billingJobs";
import { selectConfigration } from "redux/slices/configrationSlice";
import { useDispatch, useSelector } from "react-redux";
import { isPermissionPresent } from "utilities/generalUtility";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";

const { Title, Paragraph } = Typography;

export default function CandidateIssueCredits({ styles, candidateActivity }) {
  const [issuesListing, setIssuesListing] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [issueCreditModal, setIssueCreditModal] = useState(false);
  const [reSubmittedTrigger, setReSubmittedTrigger] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const configrationSlice = useSelector(selectConfigration);
  const userRoles = useSelector(selectUser).roles;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { clientId, jobId } = useParams();
  const [issueCreditForm] = Form.useForm();
  const issueCreditRef = useRef(null);

  const issueJobCredit = async (values) => {
    setIsLoading(true);
    try {
      const body = {
        submittedBillId: issueCreditRef.current.id,
        issueCreditReason: values.reason,
      };
      await BillingApproval.issueJobCredit(body);

      setIssuesListing((prev) =>
        prev.map((item) => {
          if (item.id === issueCreditRef.current.id) {
            return { ...item, active: 0 };
          }
          return item;
        }),
      );
      setIsLoading(false);
      issueCreditRef.current = null;
      handleIssueCreditCancel();
      message.success("Issue Credited Successfully.");
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };
  const getAllCurrencies = async () => {
    try {
      await dispatch(BillingJob.getCurrencies());
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        getAllCurrencies();
        const res = await Candidate.getCandidateBillings(candidateActivity.id);
        setIssuesListing([...res]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [candidateActivity, reSubmittedTrigger, refresh]);

  const resubmitBillingHandler = () => {
    const date = moment().unix();
    (async () => {
      try {
        await Candidate.calculateBilling(candidateActivity.id, date);
        setReSubmittedTrigger((prev) => !prev);
      } catch (err) {
        console.error(err);
      }
    })();
  };
  const show = () => {
    setIsModalVisible(true);
  };
  const handleIssueCreditCancel = () => {
    issueCreditForm.resetFields();
    setIssueCreditModal(false);
  };

  return (
    <>
      <div className={styles.issueCredits + " issue-credit-head"}>
        <div className={styles.clientAmount}>
          <span>Total Amount</span>
          <Title level={5}>
            {displayDollar(
              issuesListing
                .reduce((prev, curr) => prev + curr.dublicateAmount, 0)
                .toFixed(2),
            )}
          </Title>
        </div>
        <div className="issue-btn-wrap">
          <div className={styles.submitBtns}>
            {issuesListing.length &&
            issuesListing.every(
              ({ active, billingTypeName }) =>
                !active || billingTypeName.includes("(credit)"),
            ) ? (
              <Button
                type="primary"
                className={styles.resubmitBtn}
                onClick={resubmitBillingHandler}
                disabled={candidateActivity.isConsiderable === 0}
              >
                Resubmit Billing
              </Button>
            ) : null}
          </div>
          {isPermissionPresent(
            K.Permissions.CreateOneTimeBilling,
            userRoles,
          ) && (
            <Button
              type="primary"
              className={styles.resubmitBtn}
              onClick={show}
              disabled={candidateActivity.isConsiderable === 0}
            >
              Submit One Time Billing
            </Button>
          )}
        </div>
      </div>
      <Scrollbars style={{ height: 400 }}>
        {issuesListing.length ? (
          issuesListing.map((item) => (
            <div className={styles.invoiceWrap}>
              {item.active && !item.billingTypeName.includes("(credit)") ? (
                <>
                  {/* <Popconfirm
                    title="Are you sure you'd like to submit this invoice for a credit?"
                    onConfirm={() => {
                      issueJobCredit(item.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  > */}
                  <Button
                    type="link"
                    className={styles.reloadBtn}
                    onClick={() => {
                      issueCreditRef.current = item;

                      setIssueCreditModal(true);
                    }}
                  >
                    <FileAddOutlined />
                  </Button>
                </>
              ) : (
                <Button type="link" className={styles.reloadBtn}>
                  <Tooltip
                    title={
                      item.billingTypeName.includes("(credit)")
                        ? "Can't issue a credit on credit fee"
                        : "Billing already submitted for credit"
                    }
                    color="orange"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Button>
              )}

              <div className={styles.invoiceDetail}>
                <div className={styles.invoiceDate}>
                  <Title level={5}>
                    Created Date:{" "}
                    <span>
                      {item.isManual
                        ? convertIntoDashUSFormat(item.createdAt, false)
                        : convertIntoUTCDashUSFormat(item.createdAt, false)}
                      ,
                    </span>
                  </Title>
                  <Title level={5} className={styles.invoiceId}>
                    ID: <span>{item.id}</span>
                  </Title>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title level={5}>
                    Billing Type: <span>{item.billingTypeName}</span>
                  </Title>
                  <Title level={5}>
                    Amount: {displayDollar(item.dublicateAmount.toFixed(2))}
                  </Title>
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyPlaceholder />
        )}
      </Scrollbars>
      <SubmitOneTimeJobBilling
        clientId={clientId}
        candidateActivity={candidateActivity}
        isOpen={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        currencyList={configrationSlice.currencies}
        setRefresh={setRefresh}
      />

      <Modal
        centered
        width={520}
        title="Issue a Credit"
        open={issueCreditModal}
        onCancel={handleIssueCreditCancel}
        className="s2-theme-style credit-modal modal-overflow-auto"
        closeIcon={<i className="icon-closeable"></i>}
        footer={
          <>
            <Button
              className={styles.jobCancelButton}
              onClick={handleIssueCreditCancel}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={issueCreditForm.submit}
              type="primary"
            >
              Confirm
            </Button>
          </>
        }
      >
        <>
          <Form
            form={issueCreditForm}
            layout="vertical"
            onFinish={issueJobCredit}
          >
            <Form.Item
              name="reason"
              colon
              label="Reason for Credit"
              className={styles.jobFieldLabel}
              rules={[{ required: true, message: "Credit Reason is Required" }]}
            >
              <Input size="medium" placeholder={"Enter Reason for Credit"} />
            </Form.Item>
            <div className="clientBilling">
              <Title className="credit-text" level={5}>
                Credit Amount:
              </Title>
              <Paragraph>
                &nbsp;
                {displayDollar(
                  `-${issueCreditRef.current?.dublicateAmount ?? 0}`,
                )}
              </Paragraph>
            </div>
          </Form>
        </>
      </Modal>
    </>
  );
}
