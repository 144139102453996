import { Button, Typography } from "antd";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  convertIntoDashUSFormat,
  formatDisplayTime,
} from "utilities/dateUtility";
import { isPermissionPresent } from "utilities/generalUtility";

const { Paragraph } = Typography;

export default function DisplayEditableDate({
  dateKey,
  styles,
  dateString,
  setEditDates,
  isTime = false,
  isOtherItem = false,
  itemType = K.EditComponentType.CheckBox,
  candidateActivity,
}) {
  const showEditField = () => {
    setEditDates((prev) => ({ ...prev, [dateKey]: true }));
  };
  const userRoles = useSelector(selectUser).roles;
  return (
    <div className="d-flex align-items-center">
      <Paragraph className={styles.viewActivityValue}>
        {isOtherItem ? (
          <>
            {itemType === K.EditComponentType.CheckBox
              ? dateString
                ? "Yes"
                : "No"
              : dateString}
          </>
        ) : !isTime ? (
          convertIntoDashUSFormat(dateString, false)
        ) : (
          formatDisplayTime(dateString)
        )}
      </Paragraph>
      {isPermissionPresent(
        K.Permissions.EditCandidateStausDates,
        userRoles,
      ) && (
        <Button
          onClick={showEditField}
          type="link"
          disabled={candidateActivity.isConsiderable === 0}
          icon={<i className="icon-edit"></i>}
        />
      )}
    </div>
  );
}
