import { Button, PageHeader, Tabs, Typography } from "antd";
import PdfLogo from "assets/images/pdflogo.png";
import LayoutCss from "layout/layout.module.scss";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalSelector,
  setDashboardActiveTab,
} from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  getFirstDayPrevMonth,
  getLastDayPrevMonth,
} from "utilities/dateUtility";
import HistoricalPerformance from "./components/historicalPerformance";
import PipelineDashboard from "./components/pipelineDashboard";
import "./dashboard.scss";

const { Title, Text } = Typography;

export default function Dashboard() {
  const userSlice = useSelector(selectUser);
  const globalSelector = useSelector(selectGlobalSelector);
  const dispatch = useDispatch();
  const activeTab = globalSelector[K.Redux.DashboardTab];
  const [printData, setPrintData] = useState({
    clients: K.NullPlaceholder,
    historicalPerformance: {
      type: "Month",
      job: K.NullPlaceholder,
      location: K.NullPlaceholder,
      department: K.NullPlaceholder,
      manager: K.NullPlaceholder,
      requisition: K.NullPlaceholder,
      range: `${moment(getFirstDayPrevMonth()).format(
        K.DateFormat.USFormat,
      )} to ${moment(getLastDayPrevMonth()).format(K.DateFormat.USFormat)}`,
    },
    pipelineDashboard: {
      requisition: K.NullPlaceholder,
      location: K.NullPlaceholder,
      department: K.NullPlaceholder,
      manager: K.NullPlaceholder,
      title: K.NullPlaceholder,
    },
  });

  const onChange = (key) => {
    dispatch(setDashboardActiveTab(key));
  };
  const pageHeader = (
    <>
      <Tabs
        className="graphHeaderTabs"
        animated={false}
        type="card"
        activeKey={activeTab}
        onChange={onChange}
        items={[
          { key: "pipelineDashboard", label: "Pipeline Dashboard" },
          { key: "historicalPerformance", label: "Historical Performance" },
        ]}
      />
      <Button onClick={window.print} type="link" className="printDashboard">
        <i className="icon-print printIcon"></i>
        <span className="headerPragraph">print</span>
      </Button>
    </>
  );

  const tabComponents = {
    historicalPerformance: (
      <HistoricalPerformance
        globalSelector={globalSelector}
        setPrintData={setPrintData}
        userDetails={userSlice.details}
      />
    ),
    pipelineDashboard: (
      <PipelineDashboard
        setPrintData={setPrintData}
        globalSelector={globalSelector}
      />
    ),
  };

  useEffect(() => {
    setPrintData((prev) => ({
      ...prev,
      clients: !globalSelector.selectedClients.length
        ? userSlice.clients.map(({ name }) => name).join(", ")
        : userSlice.clients
            .filter(({ id }) => globalSelector.selectedClients.includes(id))
            .map(({ name }) => name)
            .join(", "),
    }));
  }, [globalSelector.selectedClients]);

  return (
    <>
      <div className="pdf-wrap">
        <div className="pdf-header">
          <div className="pdf-heading">
            <Title level={5}>Source 2</Title>
          </div>
          <div className="pdf-header-img">
            <img src={PdfLogo} alt="" />
          </div>
        </div>
        {activeTab === "historicalPerformance" ? (
          <div className="report-list-wrap">
            <Title level={2}>REPORT</Title>
            <ul className="report-list">
              <li>
                <b>Clients:</b>
                <Text>{printData.clients}</Text>
              </li>
              <li>
                <b>Group By:</b>
                <Text>{printData[activeTab].type}</Text>
              </li>
              <li>
                <b>Job Location:</b>
                <Text>{printData[activeTab].location}</Text>
              </li>
              <li>
                <b>Job Department:</b>
                <Text>{printData[activeTab].department}</Text>
              </li>
            </ul>
            <ul className="report-list-ul">
              <li>
                <b>Job:</b>
                <Text>{printData[activeTab].job}</Text>
              </li>
              <li>
                <b>Requisition ID:</b>
                <Text>{printData[activeTab].requisition}</Text>
              </li>
            </ul>
            <ul className="report-list-ul">
              <li>
                <b>Manager:</b>
                <Text>{printData[activeTab].manager}</Text>
              </li>
              <li>
                <b>DATE:</b>
                <Text>{printData[activeTab].range}</Text>
              </li>
            </ul>
          </div>
        ) : (
          <div className="report-list-wrap">
            <Title level={2}>REPORT</Title>
            <ul className="report-list">
              <li>
                <b>Clients:</b>
                <Text>{printData.clients}</Text>
              </li>
              <li>
                <b>Job Location:</b>
                <Text>{printData[activeTab].location}</Text>
              </li>
              <li>
                <b>Job Department:</b>
                <Text>{printData[activeTab].department}</Text>
              </li>
              <li>
                <b>Job Location:</b>
                <Text>{printData[activeTab].location}</Text>
              </li>
            </ul>
            <ul className="report-list-ul">
              <li>
                <b>Job Title:</b>
                <Text>{printData[activeTab].title}</Text>
              </li>
              <li>
                <b>Requisition ID:</b>
                <Text>{printData[activeTab].requisition}</Text>
              </li>
            </ul>
          </div>
        )}
        <div className="header-divider"></div>
      </div>

      <PageHeader
        className={`sitePageHeader ${LayoutCss.appPageHeader}`}
        title="Dashboard"
        extra={pageHeader}
      />

      {tabComponents[activeTab]}
    </>
  );
}
