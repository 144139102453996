import { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import K from "utilities/constants";
import {
  breakdownDefaultResponsiveSettings,
  breakdownSliderSettings,
  statisticsSliderSettings,
} from "config/sliderConfig";
import { useParams, useHistory } from "react-router-dom";
import Candidate from "redux/models/candidate";
import { setCandidateList } from "redux/slices/candidateSlice";
import { useDispatch } from "react-redux";
import {
  checkStatusExist,
  convertIntoSnakeCase,
  removeSpacesAndPascalSentenceCase,
} from "utilities/generalUtility";
import { displayPercentage } from "utilities/tableUtility";

const { Title, Paragraph } = Typography;

export default function ClassStatistics({ styles, classData }) {
  const { statistics, status, subStatus, classDetail } = classData;
  const {
    clientId,
    classId,
    statusesId,
    statusName,
    subStatusesId,
    subStatusName,
  } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const [viewMore, setViewMore] = useState(false);

  const [pageState, setPageState] = useState({
    statusId: null,
    selectedStatusName: "",
    subStatusId: null,
    selectedSubStatusName: "",
  });

  const [breakdownResponsive, setBreakdownResponsive] = useState(
    breakdownDefaultResponsiveSettings,
  );

  const selectStatus = (
    statusName = "",
    id,
    subStatusName = "",
    subStatusId = null,
  ) => {
    let path = `/clients/${clientId}/class/${classId}`;
    if (id) {
      path = path + `/${id}/${convertIntoSnakeCase(statusName)}`;
    }
    if (subStatusId) {
      path = path + `/${subStatusId}/${convertIntoSnakeCase(subStatusName)}`;
    }

    setPageState((prev) => {
      return {
        statusId: id,
        selectedStatusName: id
          ? removeSpacesAndPascalSentenceCase(statusName)
          : "",
        selectedSubStatusName: subStatusId ? subStatusName : "",
        subStatusId: subStatusId ? subStatusId : prev.subStatusId,
      };
    });

    if (id !== statusesId && subStatusId !== subStatusesId) {
      history.push(path);
    }
  };
  const selectSubStatus = (subStatusName = "", id) => {
    let path = `/clients/${clientId}/class/${classId}/${
      pageState.statusId
    }/${convertIntoSnakeCase(
      pageState.selectedStatusName,
    )}/${id}/${convertIntoSnakeCase(subStatusName)}`;

    history.push(path);

    setPageState((prev) => {
      return {
        ...prev,
        selectedSubStatusName: subStatusName,
        subStatusId: id,
      };
    });
  };

  const getCandidateByClassId = async () => {
    try {
      console.log("HI inside ");
      const data = await Candidate.getByClassId(classId);
      dispatch(setCandidateList(data.candidateJobEntries));
    } catch (err) {
      console.error(err);
    }
  };

  //   try {
  //     const data = await Candidate.getByJobId(jobId);
  //     dispatch(setCandidateList(data));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const sortCandidateByStatus = async (statusId, subStatusId = null) => {
  //   try {
  //     const data = await Candidate.sortCandidateByStatus(
  //       statusesId ? statusesId : statusId,
  //       jobId,
  //       subStatusesId ? subStatusesId : subStatusId,
  //     );
  //     dispatch(setCandidateList(data));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const hiredCandidates =
    status.find(({ name }) => name === "Hired")?.total ?? 0;

  useEffect(() => {
    if (statusesId) {
      if (statusesId > 6) {
        setViewMore(true);
      }
      if (subStatusesId && checkStatusExist(statusName, subStatusName)) {
        selectStatus(statusName, statusesId, subStatusName, subStatusesId);
      } else {
        checkStatusExist(statusName, subStatusName) &&
          selectStatus(statusName, statusesId);
      }
    } else {
      selectStatus("", null, "", null);
    }
  }, [statusesId, subStatusesId]);

  useEffect(() => {
    if (Object.keys(subStatus)?.length > 0) {
      if (pageState.selectedStatusName) {
        if (subStatus[pageState.selectedStatusName]?.length < 3) {
          const updated = breakdownDefaultResponsiveSettings.map((item) => {
            if (item.breakpoint === 380) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              };
            } else {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: subStatus[pageState.selectedStatusName]?.length,
                  slidesToScroll:
                    subStatus[pageState.selectedStatusName]?.length,
                },
              };
            }
          });
          setBreakdownResponsive(updated);
        } else {
          const updated = breakdownDefaultResponsiveSettings.map((item) => {
            if (item.breakpoint === 1318) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow:
                    subStatus[pageState.selectedStatusName]?.length < 7
                      ? subStatus[pageState.selectedStatusName]?.length % 7
                      : 7,
                  slidesToScroll:
                    subStatus[pageState.selectedStatusName]?.length < 7
                      ? subStatus[pageState.selectedStatusName]?.length % 7
                      : 7,
                },
              };
            } else if (item.breakpoint === 576) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              };
            } else if (item.breakpoint === 1200) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 6,
                  slidesToScroll: 3,
                },
              };
            } else if (item.breakpoint === 1050) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 4,
                  slidesToScroll: 3,
                },
              };
            } else if (item.breakpoint === 670) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              };
            } else {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              };
            }
          });
          setBreakdownResponsive(updated);
        }

        // sortCandidateByStatus();
        getCandidateByClassId();
      }
    }
  }, [pageState.selectedStatusName, pageState.selectedSubStatusName]);

  return (
    <>
      <Card
        className={"statistics-card " + styles.statisticsCard}
        title="Statistics"
      >
        <Slider
          {...statisticsSliderSettings}
          className={"statistics-wrap " + styles.statisticsWrap}
        >
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Original Openings
            </Title>
            <span className={styles.statisticsCount}>
              {classData.classDetails?.vacancies?.totalOpenings ??
                K.NullPlaceholder}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Current Openings
            </Title>
            <span className={styles.statisticsCount}>
              {" "}
              {classData.classDetails?.vacancies?.currentOpenings ??
                K.NullPlaceholder}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Canceled
            </Title>
            <span className={styles.statisticsCount}>
              {" "}
              {classData.classDetails?.vacancies?.canceled ?? K.NullPlaceholder}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Filled
            </Title>
            <span className={styles.statisticsCount}>
              {isNaN(statistics?.classStart)
                ? K.NullPlaceholder
                : statistics?.filled}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Class Start
            </Title>
            <span className={styles.statisticsCount}>
              {isNaN(statistics?.classStart)
                ? K.NullPlaceholder
                : statistics?.classStart}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Fill Rate
            </Title>
            <span className={styles.statisticsCount}>
              {isNaN(statistics?.classFillRate)
                ? K.NullPlaceholder
                : displayPercentage(statistics?.classFillRate.toFixed(2))}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Total No-Show
            </Title>
            <span className={styles.statisticsCount}>
              {isNaN(statistics?.noShow)
                ? K.NullPlaceholder
                : statistics?.noShow}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              No-Show Rate
            </Title>
            <span className={styles.statisticsCount}>
              {isNaN(statistics?.noShowRate)
                ? K.NullPlaceholder
                : displayPercentage(statistics?.noShowRate.toFixed(2))}
            </span>
          </div>

          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Average Days Retained
            </Title>
            <span className={styles.statisticsCount}>
              {isNaN(statistics?.averageDaysRetained)
                ? K.NullPlaceholder
                : statistics?.averageDaysRetained.toFixed(2)}
            </span>
          </div>
          <div className={styles.statisticsData}>
            <Title level={5} className={styles.statisticsTitle}>
              Shorts
            </Title>
            <span className={styles.statisticsCount}>
              {isNaN(statistics?.shorts)
                ? K.NullPlaceholder
                : statistics?.shorts}
            </span>
          </div>
        </Slider>
      </Card>
      <Card
        bordered={false}
        className={
          styles.statusCard + `${!pageState.selectedStatusName ? " mb-4" : ""}`
        }
      >
        <div className={styles.statusCardHeader}>
          <Title level={5}>
            Status <Divider type="vertical" className={styles.titleDivider} />
            <span>
              &nbsp;Total{" "}
              {status.reduce((prev, curr) => {
                return prev + curr.total;
              }, 0)}
            </span>
          </Title>
          <Button
            className={styles.viewMoreBtn}
            type="link"
            onClick={() => setViewMore(!viewMore)}
          >
            {viewMore ? "Show Less" : "View More"}
          </Button>
        </div>
        <Row
          gutter={[20, 20]}
          style={{ height: viewMore ? "auto" : "68px", overflow: "hidden" }}
        >
          {status.map(({ name, total, id }) => (
            <Col
              key={name}
              xs={24}
              sm={8}
              md={6}
              lg={4}
              className={
                convertIntoSnakeCase(pageState.selectedStatusName) ===
                convertIntoSnakeCase(name)
                  ? styles.active
                  : ""
              }
              onClick={() => selectStatus(convertIntoSnakeCase(name), id)}
            >
              <div className={styles.statusCardInnerBoxes}>
                <Title level={5}>{total}</Title>
                <Paragraph className={styles.jobsDescription}>{name}</Paragraph>
              </div>
            </Col>
          ))}
        </Row>
      </Card>

      {pageState.selectedStatusName && (
        <Card
          title="Status Count Breakdown"
          extra={
            <Button
              type="link"
              className={styles.cardCloseIcon}
              icon={<i className="icon-closeable"></i>}
              onClick={() => {
                history.push(`/clients/${clientId}/class/${classId}`);
                getCandidateByClassId();
              }}
            />
          }
          className={"status-count-card " + styles.statusCountCard}
        >
          <Slider
            className={"status-count-slider " + styles.statusCountSlider}
            slidesToShow={
              subStatus[pageState.selectedStatusName]?.length < 7
                ? subStatus[pageState.selectedStatusName].length % 7
                : 7
            }
            {...breakdownSliderSettings}
            responsive={breakdownResponsive}
          >
            {subStatus[pageState.selectedStatusName]?.map(
              ({ id, name, candidatesToSubStatusCount }) => (
                <div
                  key={name}
                  className={
                    convertIntoSnakeCase(pageState.selectedSubStatusName) ===
                    convertIntoSnakeCase(name)
                      ? `${styles.active} ${styles.StatusCountCardBoxes}`
                      : styles.StatusCountCardBoxes
                  }
                  // onClick={() =>
                  //   selectSubStatus(convertIntoSnakeCase(name), id)
                  // }
                >
                  <Title level={5}>{candidatesToSubStatusCount}</Title>
                  <Paragraph className={styles.jobsDescription}>
                    {name}
                  </Paragraph>
                </div>
              ),
            )}
          </Slider>
        </Card>
      )}
    </>
  );
}
