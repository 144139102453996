import { useState, useEffect, useCallback, useRef } from "react";

import {
  Input,
  Button,
  Typography,
  Form,
  Modal,
  Transfer,
  Select,
  message,
  Tag,
  Pagination,
} from "antd";

import K from "utilities/constants";

import {
  camelToSnakeCase,
  customJobHandleSearch,
} from "utilities/generalUtility";

import Job from "redux/models/job";
import Candidate from "redux/models/candidate";
import JobSelectOption from "common/components/jobSelect/jobSelect";
import "./assignCandidateClass.scss";
const { Title, Paragraph } = Typography;
const { Search } = Input;

const { Option } = Select;
const pageSize = 10;
export default function AssignCandidate({
  clientId,
  classId,
  classCandidates,
  setClassData,
  isModalVisible,
  setIsModalVisible,
}) {
  const gridRef = useRef();
  const searchRef = useRef(null);
  const [form] = Form.useForm();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [candidateList, setCandidateList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setTargetKeys([]);
    setCandidateList([]);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const payload = {
        classId: classId,
        candidateJobEntryIds: targetKeys,
      };
      await Candidate.assignCandidate(payload);
      setClassData((prev) => {
        return { ...prev, shouldUpdateListing: !prev.shouldUpdateListing };
      });
      setCandidateList([]);
      handleCancel();
      message.success("Candidate Assigned Successfully");
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };
  // const searchHandler = useCallback((event) => {
  //   onFilterTextBoxChanged(event.target.value, gridRef);
  // }, []);

  const onChange = (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);
  };
  const onJobChange = (value) => {
    setSelectedJob(value);
    setCurrentPage(1);
    setSelectedKeys([]);
  };

  const filterOption = (inputValue, option) =>
    option?.candidate.firstName
      .toLowerCase()
      .indexOf(inputValue.toLowerCase()) > -1 ||
    option?.candidate.lastName.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1;

  const getJobsByClientIds = async () => {
    setIsLoading(true);
    try {
      const body = {
        clientIds: [clientId],
      };
      const res = await Job.getOnlyJobsByClientIds(body, false);

      setJobList(res);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const getCandidateByJobId = async () => {
    try {
      const body = {
        page: currentPage,
        pageSize,
        job_source_id: selectedJob,
        search: searchRef.current,
        filter: null,
        sortBy: camelToSnakeCase("candidateJobEntry.id"),
        sortOrder: "asc",
      };
      const data = await Candidate.getByJobId(body);
      setTotalItems(data.totalCount);
      const updateData = data?.candidate.reduce((prev, curr) => {
        const isDisabeld = classCandidates.includes(curr.id);
        const updatedObject = { ...curr, disabled: isDisabeld };
        return [...prev, updatedObject];
      }, []);

      setCandidateList(updateData);
    } catch (err) {
      console.error(err);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getJobsByClientIds();
  }, []);

  useEffect(() => {
    if (selectedJob) {
      getCandidateByJobId();
    } else {
      setCandidateList([]);
    }
  }, [selectedJob, currentPage]);

  return (
    <>
      <Modal
        open={isModalVisible}
        okText={"Save"}
        wrapClassName="vertical-center-modal"
        onCancel={handleCancel}
        centered
        destroyOnClose
        closeIcon={<i className="icon-closeable"></i>}
        width={1050}
        onOk={form.submit}
        okButtonProps={{
          loading: isLoading,
          disabled: targetKeys.length === 0,
        }}
        className="s2-theme-style assign-candidate-modal candidates-modals"
        title="Assign Candidate"
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          {/* <p className="instructions">Please </p> */}
          <Form.Item
            label="Select from"
            name="jobId"
            rules={[{ required: true, message: "Please select a job !" }]}
          >
            <Select
              showSearch
              allowClear
              onChange={onJobChange}
              optionFilterProp="children"
              filterOption={customJobHandleSearch}
              placeholder="Select Job"
              onSearch={(e) => console.log("Params", e)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              size="large"
              className="select-job"
            >
              {jobList.map(({ id, title, client, jobLocation, extAtsId }) => (
                <Option key={id} value={id}>
                  <JobSelectOption
                    title={title}
                    location={jobLocation.name}
                    client={client}
                    extAtsId={extAtsId}
                  >
                    {title}
                  </JobSelectOption>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Title level={5} className="rolesTableTypography">
          Select Candidate
        </Title>

        <Transfer
          oneWay
          showSearch
          className="rolesPermissionTransfer transfer-candidate"
          // pagination={{
          //   // current: currentPage,
          //   pageSize: pageSize,
          //   // total: 1904,
          //   onChange: handlePageChange,
          // }}
          // pagination={true}
          titles={["Source", "Target"]}
          dataSource={candidateList}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          rowKey={(record) => record.id}
          onChange={onChange}
          filterOption={filterOption}
          onSelectChange={onSelectChange}
          footer={(currentItems) => {
            return (
              currentItems.titleText === "Source" && (
                <Pagination
                  defaultCurrent={currentPage}
                  current={currentPage}
                  showSizeChanger={false}
                  total={totalItems}
                  pageSize={pageSize} // Set your custom page size
                  onChange={handlePageChange}
                />
              )
            );
          }}
          render={(item) => {
            const isDisabled = classCandidates.includes(item.id);
            return (
              <div className="candidate-names">
                <Paragraph disabled={isDisabled}>
                  {`${item?.candidate?.firstName} ${item?.candidate?.lastName}`}

                  <Tag
                    className="tagTextColor"
                    color={K.StatusTagColors[item?.status.name]}
                  >
                    {item?.status.name}
                  </Tag>

                  <Tag
                    className="tagTextColor"
                    color={
                      item.status.name === "Terminated"
                        ? K.StatusTagColors[item?.subStatus.name]
                        : K.StatusTagColors[item?.status.name]
                    }
                  >
                    {item?.subStatus.name}
                  </Tag>
                </Paragraph>
                <div className="tags"></div>
              </div>
            );
          }}
        />
      </Modal>
    </>
  );
}
