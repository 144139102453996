import { useParams } from "react-router-dom";
import { Form, Card, Button } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import JobBilling from "features/clients/create/jobBilling";
import Client from "redux/models/client";
import LayoutCss from "layout/layout.module.scss";

export default function CreateJobBillingRule({ setCurrentPage }) {
  const { id } = useParams();
  const [jobBillingForm] = Form.useForm();

  const backHandler = () => {
    setCurrentPage(0);
  };

  const setCreateJobBillingRule = async (props) => {
    const body = { clientId: id, jobLevelBillingRules: [...props] };
    try {
      await Client.addTier(body);
      setCurrentPage(0);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card className={` ${styles.clientBillingRulesCard} ${LayoutCss.appCard}`}>
      <Button type="link" className="backButton" onClick={backHandler}>
        <i className="icon-back-arrow"></i>Back
      </Button>

      <JobBilling
        form={jobBillingForm}
        setCreateJobBillingRule={setCreateJobBillingRule}
      />
    </Card>
  );
}
