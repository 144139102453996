import K from "utilities/constants";

const detailsConfig = {
  [K.DetailsKeys.Overview]: {
    name: "Overview",
  },
  [K.DetailsKeys.Jobs]: {
    name: "Jobs",
    permission: K.Permissions.ViewJobs,
  },
  [K.DetailsKeys.ClientBillingRules]: {
    name: "Client Billing Rules",
    permission: K.Permissions.ViewBillingRules,
  },

  [K.DetailsKeys.ClientInvoices]: {
    name: "Client Invoices",
    permission: K.Permissions.ViewInvoices,
  },

  [K.DetailsKeys.JobBillingRules]: {
    name: "Job Billing Rules",
    permission: K.Permissions.ViewBillingRules,
  },
  [K.DetailsKeys.JobInvoices]: {
    name: "Job Invoices",
    permission: K.Permissions.ViewInvoices,
  },

  [K.DetailsKeys.Lookup]: {
    name: "Client Tables",
    permission: K.Permissions.ViewClientLookupTables,
  },
  [K.DetailsKeys.HiringManager]: {
    name: "Hiring Managers",
    permission: K.Permissions.ViewHiringManager,
  },
  [K.DetailsKeys.UserDefineds]: {
    name: "User Defineds ",
    permission: K.Permissions.UDFView,
  },
  [K.DetailsKeys.Attachment]: {
    name: "Attachments",
    permission: K.Permissions.ViewAttachment,
  },
  [K.DetailsKeys.Class]: {
    name: "Class",
    permission: K.Permissions.ViewClass,
  },
};

export default detailsConfig;
