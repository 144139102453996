import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Popconfirm } from "antd";
import ContactPersonDetails from "common/contactPersonDetails/contactPersonDetails";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ClientContact from "redux/models/clientContacts";
import LookupTable from "redux/models/lookupTable";
import { updateCreateData } from "redux/slices/clientSlice";
import K from "utilities/constants";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";
import styles from "./billingUnit.module.scss";

const { Lookup } = K.Network.URL;

export default function CreateBillingUnit({ form }) {
  const dispatch = useDispatch();
  const [contactList, setContactList] = useState([]);

  const onFinish = (values) => {
    dispatch(
      updateCreateData({
        key: K.Redux.BillingUnit,
        values: values.billingUnits.map(({ name, contactPersonDetail }) => ({
          name,
          contactPersonDetail,
        })),
      }),
    );
  };

  const getClientContacts = async () => {
    try {
      const list = await ClientContact.getAll();
      setContactList(list);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          dispatch(
            LookupTable.getData(K.Redux.ContactTypes, Lookup.ContactTypes),
          ),
          getClientContacts(),
        ]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      <Card
        title="Billing Unit"
        className={`${styles.cardWrapper} ${LayoutCss.appCard}`}
      >
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          className={styles.cardForm}
          onFinish={onFinish}
          initialValues={{
            billingUnits: [
              {
                name: undefined,
                contactPersonDetail: [],
              },
            ],
          }}
        >
          <Form.List name="billingUnits">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div className={styles.billingUnitForm} key={index}>
                    {index > 0 && (
                      <div className={styles.billingUnitRemoveBtn}>
                        <Popconfirm
                          title="Are you sure ?"
                          onConfirm={() => {
                            remove(field.name);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            danger
                            icon={<i className="icon-delet"></i>}
                          />
                        </Popconfirm>
                      </div>
                    )}
                    <Form.Item
                      label="Name"
                      name={[index, "name"]}
                      rules={[noTrailingSpaceAllowedRule()]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <ContactPersonDetails
                      form={form}
                      parentIndex={index}
                      contactList={contactList}
                    />
                  </div>
                ))}
                <Button
                  className={styles.cardButton}
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={add}
                >
                  More Billing Unit
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Card>
    </>
  );
}
