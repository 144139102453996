import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Button, Card, Input, message, PageHeader } from "antd";
import { useSelector } from "react-redux";
import LayoutCss from "layout/layout.module.scss";
import K from "utilities/constants";
import Candidate from "redux/models/candidate";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
} from "utilities/tableUtility";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import {
  camelToSnakeCase,
  checkNullPlaceHolder,
  removeUnderScore,
  toolTipCheckNullPlaceHolder,
} from "utilities/generalUtility";
import { debounce } from "lodash";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;
const sortDict = {
  asc: "ASC",
  desc: "DESC",
};
const searchFilterPrefix = "global_candidate";
const getSearchKey = "global_candidate_search";

export default function Candidates() {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const history = useHistory();

  const [shouldUpdate, setShouldUpdate] = useState(false);
  const globalSelector = useSelector(selectGlobalSelector);
  const userDetails = useSelector(selectUser).details;
  const [setter, getter] = useSearchAndFilter();

  const searchRef = useRef(getter(getSearchKey));

  const actionColumnRenderer = (event) => (
    <>
      <Button type="link" className={LayoutCss.appListingCardActions}>
        Edit
      </Button>
      <Button type="link" className={LayoutCss.appListingCardActions}>
        Delete
      </Button>
    </>
  );

  const candidateJobEntriesRender = (params) => {
    return params.data.hireType
      .split(",")
      ?.map((item) => removeUnderScore(item))
      .join(", ");
  };
  const tooltipCandidateJobEntriesRender = (params) => {
    return (
      <div className="client-list-tooltip">
        {params.data.hireType
          .split(",")
          .map((item) => removeUnderScore(item))
          .join(", ")}
      </div>
    );
  };
  const candidateJobEntriesSearch = (params) => {
    return params.data.hireType
      .split(",")
      .map((item) => removeUnderScore(item))
      .join(" ");
  };
  const candidateTypeComparator = (value1, value2) => {
    // Split the values by commas and trim spaces
    const values1 = value1.map((v) => v.hireType.trim());
    const values2 = value2.map((v) => v.hireType.trim());

    // Compare the sorted values
    for (let i = 0; i < Math.min(values1.length, values2.length); i++) {
      const comparison = values1[i].localeCompare(values2[i]);

      if (comparison !== 0) {
        return comparison;
      }
    }

    // If all values are the same so far, compare the lengths
    return values1.length - values2.length;
  };
  const isBlacklist = (params) => (params.data.isBlacklist ? "Yes" : "No");
  const tooltipIsBlacklist = (params) => (
    <div className="client-list-tooltip">
      {params.data.isBlacklist ? "Yes" : "No"}
    </div>
  );

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      suppressMovable: false,
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "First Name",
      field: "firstName",
      suppressMovable: false,
      sortable: true,
      tooltipField: "firstName",
    },
    {
      headerName: "Last Name",
      field: "lastName",
      suppressMovable: false,
      sortable: true,
      tooltipField: "lastName",
    },
    {
      headerName: "Email",
      field: "email",
      suppressMovable: false,
      sortable: true,
      tooltipField: "email",
    },
    {
      headerName: "Mobile Number",
      field: "mobileNumber",
      suppressMovable: false,
      sortable: true,
      tooltipField: "mobileNumber",
    },
    {
      headerName: "Candidate Type",
      field: "hireType",
      sortable: true,
      tooltipField: "hireType",
      tooltipComponent: (params) => tooltipCandidateJobEntriesRender(params),
      getQuickFilterText: candidateJobEntriesSearch,
      comparator: candidateTypeComparator,
      cellRenderer: (params) => candidateJobEntriesRender(params),
    },
    {
      headerName: "Candidate ATS ID",
      field: "atsLookupId",
      sortable: true,
      tooltipField: "atsLookupId",
      tooltipComponent: (params) => toolTipCheckNullPlaceHolder(params.value),
      cellRenderer: (params) => checkNullPlaceHolder(params.value),
    },
    {
      headerName: "Current Title",
      field: "currentTitle",
      sortable: true,
      tooltipField: "currentTitle",
      tooltipComponent: (params) => toolTipCheckNullPlaceHolder(params.value),
      cellRenderer: (params) => checkNullPlaceHolder(params.value),
    },
    {
      headerName: "Blacklist ?",
      field: "isBlacklist",
      sortable: true,
      tooltipField: "isBlacklist",
      tooltipComponent: (params) => tooltipIsBlacklist(params),
      cellRenderer: (params) => isBlacklist(params),
    },

    /* {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      suppressMovable: false,
      resizable: false,
      cellRendererFramework: actionColumnRenderer,
    }, */
  ]);

  const onChange = (event) => {
    searchRef.current = event.target.value;

    setter({ search: event.target.value }, searchFilterPrefix);
    debounceOnChange();
  };

  const debounceOnChange = debounce(() => {
    // Handle search logic here (e.g., fetch data)
    setRefreshTable((prev) => !prev);
    console.log("Search value:");
  }, 500);

  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userDetails.id,
          tableName: K.AgGridTable.Keys.Candidates,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const datasource = useCallback(
    {
      async getRows(params) {
        const { startRow, endRow } = params.request;

        const pageSize = endRow - startRow;
        const page = startRow / pageSize + 1;

        const body = {
          page,
          pageSize,
          clientIds: globalSelector.selectedClients,
          search: getter(getSearchKey),
          sortBy: camelToSnakeCase(params.request.sortModel[0]?.colId ?? "id"),
          sortOrder:
            params.request.sortModel.length > 0
              ? sortDict[params.request.sortModel[0]["sort"]]
              : "asc",
        };
        try {
          const data = await Candidate.getByClientId(body);

          params.success({
            rowData: data.candidate,
            rowCount: data.totalCount,
          });
        } catch (err) {
          params.fail();
        }
      },
    },
    [refreshTable, globalSelector.selectedClients],
  );

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.Candidates,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(K.AgGridTable.Keys.Candidates);
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "currentTitle") {
          return {
            ...item,
            cellRenderer: (params) => checkNullPlaceHolder(params.value),
            tooltipComponent: (params) =>
              toolTipCheckNullPlaceHolder(params.value),
          };
        } else if (item.field === "isBlacklist") {
          return {
            ...item,
            cellRenderer: (params) => isBlacklist(params),
            tooltipComponent: (params) => tooltipIsBlacklist(params),
          };
        } else if (item.field === "actions") {
          return { ...item, cellRenderer: actionColumnRenderer };
        } else if (item.field === "candidateJobEntries") {
          return {
            ...item,
            comparator: candidateTypeComparator,
            getQuickFilterText: candidateJobEntriesSearch,
            cellRenderer: (params) => candidateJobEntriesRender(params),
            tooltipComponent: (params) =>
              tooltipCandidateJobEntriesRender(params),
          };
        }
        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getColumnsConfigrations()]);
    })();
  }, [globalSelector.selectedClients]);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Candidates"
        className={LayoutCss.appPageHeader}
      />
      <Card
        className={LayoutCss.appCard + " " + LayoutCss.rolesPermissionCard}
        extra={
          <>
            <div
              className={
                "candidateSearch " + LayoutCss.appListingCardRolesTable
              }
            >
              <Search
                allowClear
                value={searchRef.current}
                placeholder="Search"
                onChange={onChange}
                className={LayoutCss.appListingCardRolesTableSearch}
              />
            </div>
          </>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            // rowData={listing}
            columnDefs={columnDefs}
            onRowClicked={(event) =>
              history.push(`/candidates/detail/${event.data.id}`)
            }
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            serverSideDatasource={datasource}
            onColumnMoved={updateColumns}
            onColumnPinned={onColumnVisible}
            onColumnVisible={onColumnVisible}
            defaultColDef={K.AgGridTable.DefaultColDef}
            ref={gridRef}
            quickFilterText={getter(getSearchKey)}
            loadingCellRenderer={false}
          />
        </div>
      </Card>
    </>
  );
}
