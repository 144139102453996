import { AgGridReact } from "ag-grid-react";
import { Button, Card, Form, message, Modal, PageHeader } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingJob from "redux/models/billingJobs";
import User from "redux/models/user";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  isPermissionPresent,
  removeUnderScore,
  toolTipRemoveUnderScore,
} from "utilities/generalUtility";
import { filterColumnListing } from "utilities/tableUtility";
import AddNewBillingJob from "./addNewBillingJob";

export default function BillingJobs() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pageState, setPageState] = useState({
    editData: null,
    isLoading: false,
    isModalVisible: false,
  });
  const userSlice = useSelector(selectUser);
  const billingJobsData = useSelector(selectConfigration).billingJobs;

  const actionColumnRenderer = (params) => (
    <>
      {isPermissionPresent(K.Permissions.EditJobBilling, userSlice.roles) && (
        <Button
          type="link"
          className={LayoutCss.appListingCardActions + " hover-underlined"}
          onClick={() => editHandler(params.data)}
        >
          Edit
        </Button>
      )}
    </>
  );

  const dependentColumnRenderer = (params) => {
    if (params.data.isDependentOn) {
      return params.data.isDependentOnSalary
        ? "Candidate Salary"
        : params.data.dependantOnJobBillingType.name;
    }
    return "false";
  };

  const tooltipDependentColumnRenderer = (params) => {
    if (params.data.isDependentOn) {
      return (
        <div className="client-list-tooltip">
          {params.data.isDependentOnSalary
            ? "Candidate Salary"
            : params.data.dependantOnJobBillingType.name}
        </div>
      );
    }
    return <div className="client-list-tooltip">{"false"}</div>;
  };

  const toolTipTypeRenderer = (params) =>
    toolTipRemoveUnderScore(params.value) === "Time Based"
      ? "After Certain Period "
      : toolTipRemoveUnderScore(params.value);

  const typeRenderer = (params) =>
    removeUnderScore(params.value) === "Time Based"
      ? "After Certain Period "
      : removeUnderScore(params.value);

  const editHandler = (record) => {
    setPageState((prev) => {
      return {
        ...prev,
        isModalVisible: true,
        editData: record,
      };
    });
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
      suppressMovable: true,
    },
    //replace timebased to dsplay after certain period
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      suppressMovable: true,
      tooltipField: "type",
      tooltipComponent: toolTipTypeRenderer,
      cellRenderer: typeRenderer,
    },
    {
      headerName: "Status",
      field: "isAssignable",
      sortable: true,
      tooltipField: "isAssignable",
    },
    {
      headerName: "Has MarkUp",
      field: "hasMarkup",
      sortable: true,
      tooltipField: "hasMarkup",
    },
    {
      headerName: "Required",
      field: "isRequired",
      sortable: true,
      tooltipField: "isRequired",
    },
    {
      headerName: "Dependent On",
      field: "isDependentOn",
      sortable: true,
      tooltipField: "isDependentOn",
      tooltipComponent: tooltipDependentColumnRenderer,
      cellRenderer: dependentColumnRenderer,
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      resizable: false,

      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRendererFramework: actionColumnRenderer,
    },
  ]);

  const showModal = () => {
    setPageState((prev) => ({ ...prev, isModalVisible: true }));
  };

  const handleCancel = () => {
    setPageState((prev) => ({
      ...prev,
      editData: null,
      isModalVisible: false,
    }));
  };

  const handleOk = () => {
    form.submit();
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  const getBillingJobsData = () => {
    return billingJobsData.map((item) => ({
      ...item,
      isAssignable: item.isAssignable ? "Assignable" : "UnAssignable",
      hasMarkup: item.hasMarkup ? "true" : "false",
      isRequired: item.isRequired ? "true" : "false",
    }));
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.BillingJobsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getAllBillings = async () => {
    try {
      await dispatch(BillingJob.getAll());
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.BillingJobsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions") {
          return { ...item, cellRenderer: actionColumnRenderer };
        } else if (item.field === "isDependentOn") {
          return {
            ...item,
            cellRenderer: dependentColumnRenderer,
            tooltipComponent: tooltipDependentColumnRenderer,
          };
        } else if (item.field === "type") {
          return {
            ...item,
            cellRenderer: (params) => typeRenderer(params.value),
            tooltipComponent: toolTipTypeRenderer,
          };
        }
        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getAllBillings(), getColumnsConfigrations()]);
    })();
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Job Level Configuration"
        extra={
          isPermissionPresent(
            K.Permissions.CreateJobBilling,
            userSlice.roles,
          ) && (
            <Button
              type="primary"
              onClick={showModal}
              className={LayoutCss.appHeaderBtn}
            >
              Add New
            </Button>
          )
        }
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
      />
      <Card className={LayoutCss.appCard} bordered={false} title="Job Level">
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={getBillingJobsData()}
            columnDefs={columnDefs}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
            defaultColDef={K.AgGridTable.DefaultColDef}
          />
        </div>
      </Card>

      <Modal
        open={pageState.isModalVisible}
        okText={pageState.editData ? "Edit" : "Add"}
        wrapClassName="vertical-center-modal"
        closeIcon={<i className="icon-closeable"></i>}
        centered
        width={462}
        title={
          pageState.editData ? "Edit Configuration" : "Jobs New Configuration"
        }
        className="s2-theme-style billingClient"
        confirmLoading={pageState.isLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={afterModalClose}
        destroyOnClose
      >
        <AddNewBillingJob
          form={form}
          pageState={pageState}
          setPageState={setPageState}
          billingTypes={billingJobsData}
        />
      </Modal>
    </>
  );
}
