import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import clientReducer from "redux/slices/clientSlice";
import configrationReducer from "redux/slices/configrationSlice";
import userReducer from "redux/slices/userSlice";
import globalSelectorReducer from "redux/slices/globalSelectorSlice";
import candidateReducer from "redux/slices/candidateSlice";
import udfReducer from "redux/slices/udfSlice";
import searchAndFilterReducer from "redux/slices/search&FilterSlice";

export default configureStore({
  reducer: {
    globalSelector: globalSelectorReducer,
    configration: configrationReducer,
    clients: clientReducer,
    user: userReducer,
    candidates: candidateReducer,
    udfs: udfReducer,
    searchAndFilter: searchAndFilterReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
