import { Spin } from "antd";
import { usePromiseTracker } from "react-promise-tracker";

export default function Spinner({ children, isGuest = false }) {
  const { promiseInProgress } = usePromiseTracker();
  const hasSider = document.getElementsByClassName("siderWrap");
  const classNames = hasSider.length > 0 && !isGuest ? "global-spinner" : "";
  return (
    <Spin className={classNames} spinning={promiseInProgress} size="large">
      {children}
    </Spin>
  );
}
