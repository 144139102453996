import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  addBillingJobs,
  editBillingJobs,
  setBillingJobs,
  setCurrencies,
} from "redux/slices/configrationSlice";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
} from "utilities/generalUtility";

export default class BillingJob {
  static getAll() {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.getBillingJob());
      dispatch(setBillingJobs(camelCaseKeysRecursively(res.data)));
    };
  }

  static editData(values, id) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.editBillingJob({ ...values, jobBillingTypeId: id }),
        false,
      );
      const payload = {
        data: camelCaseKeys(res.data),
      };
      dispatch(editBillingJobs(payload));
    };
  }

  static addNewType(values) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.addBillingJob(values), false);
      const payload = {
        data: camelCaseKeys(res.data),
      };
      dispatch(addBillingJobs(payload));
    };
  }

  static async getClientBillingRules(clientId) {
    const res = await NetworkCall.fetch(
      Request.getClientBillingRulesById(clientId),
    );
    return res;
  }

  static async getJobTierDetails(tierId, clientId) {
    const res = await NetworkCall.fetch(
      Request.getJobTierDetailsById(tierId, clientId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static getCurrencies() {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.getAllCurrencies());
      dispatch(setCurrencies(camelCaseKeysRecursively(res.data)));
    };
  }
  static async submitOnetimeJobBilling(data) {
    const res = await NetworkCall.fetch(Request.submitOnetimeJobBilling(data));
    return res;
  }
  static async submitOnetimeCancelBilling(data) {
    const res = await NetworkCall.fetch(Request.submitOnetimeJobBilling(data));
    return res;
  }
}
