import { Row, Col, Typography, Divider } from "antd";
import K from "utilities/constants";
import { displayDollar, displayPercentage } from "utilities/tableUtility";
const { Title, Paragraph } = Typography;

export default function CommissionPlanComponent({ styles, currentRule }) {
  return (
    <>
      <div className="commission-default-card">
        <h1 className="new-commission-title">Default Plans</h1>
        <Row gutter={50}>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              {currentRule.defaultPlan.plan.thresholdType ===
              K.CommissionThresholdType.Amount
                ? `Default Billing Threshold`
                : `Default Hires Threshold`}
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.thresholdType ===
              K.CommissionThresholdType.Amount
                ? displayDollar(
                    currentRule.defaultPlan.plan.defaultBillingThreshold,
                  )
                : `${currentRule.defaultPlan.plan.defaultBillingThreshold} hires `}
            </Paragraph>
          </Col>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Applied Hires
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.appliedHireUnit === K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.appliedHire)
                : displayPercentage(currentRule.defaultPlan.plan.appliedHire)}
            </Paragraph>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Sourced Hires
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.sourceHiredUnit === K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.sourceHired)
                : displayPercentage(currentRule.defaultPlan.plan.sourceHired)}
            </Paragraph>
          </Col>
        </Row>

        <Row gutter={50}>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Referrals
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.referralUnit === K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.referral)
                : displayPercentage(currentRule.defaultPlan.plan.referral)}
            </Paragraph>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Cancellations
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.cancellationsUnit ===
              K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.cancellations)
                : displayPercentage(currentRule.defaultPlan.plan.cancellations)}
            </Paragraph>
          </Col>

          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Hiring Events
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.hiringEventUnit === K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.hiringEvent)
                : displayPercentage(currentRule.defaultPlan.plan.hiringEvent)}
            </Paragraph>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Rehire Referral
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.reHireReferralUnit ===
              K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.reHireReferral)
                : displayPercentage(
                    currentRule.defaultPlan.plan.reHireReferral,
                  )}
            </Paragraph>
          </Col>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Rehire Applied
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.reHireAppliedUnit ===
              K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.reHireApplied)
                : displayPercentage(currentRule.defaultPlan.plan.reHireApplied)}
            </Paragraph>
          </Col>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Rehire Sourced
            </Title>
            <Paragraph className={styles.cbrValue}>
              {currentRule.defaultPlan.plan.reHireSourcedUnit ===
              K.Format.Dollar
                ? displayDollar(currentRule.defaultPlan.plan.reHireSourced)
                : displayPercentage(currentRule.defaultPlan.plan.reHireSourced)}
            </Paragraph>
          </Col>
        </Row>
      </div>
      {currentRule.clinetException.map((item, index) => {
        return (
          <div key={index} className="commission-client-card">
            <h1 className="new-commission-title">{item.plan.client.name}</h1>
            <Row gutter={50}>
              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Applied Hires
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.appliedHireUnit === K.Format.Dollar
                    ? displayDollar(item.plan.appliedHire)
                    : displayPercentage(item.plan.appliedHire)}
                </Paragraph>
              </Col>

              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Sourced Hires
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.sourceHiredUnit === K.Format.Dollar
                    ? displayDollar(item.plan.sourceHired)
                    : displayPercentage(item.plan.sourceHired)}
                </Paragraph>
              </Col>

              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Referrals
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.referralUnit === K.Format.Dollar
                    ? displayDollar(item.plan.referral)
                    : displayPercentage(item.plan.referral)}
                </Paragraph>
              </Col>
            </Row>

            <Row gutter={50}>
              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Cancellations
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.cancellationsUnit === K.Format.Dollar
                    ? displayDollar(item.plan.cancellations)
                    : displayPercentage(item.plan.cancellations)}
                </Paragraph>
              </Col>

              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Hiring Events
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.hiringEventUnit === K.Format.Dollar
                    ? displayDollar(item.plan.hiringEvent)
                    : displayPercentage(item.plan.hiringEvent)}
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Rehire Referral
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.reHireReferralUnit === K.Format.Dollar
                    ? displayDollar(item.plan.reHireReferral)
                    : displayPercentage(item.plan.reHireReferral)}
                </Paragraph>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Rehire Applied
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.reHireAppliedUnit === K.Format.Dollar
                    ? displayDollar(item.plan.reHireApplied)
                    : displayPercentage(item.plan.reHireApplied)}
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} xl={6}>
                <Title level={5} className={styles.cbrLabel}>
                  Rehire Sourced
                </Title>
                <Paragraph className={styles.cbrValue}>
                  {item.plan.reHireSourcedUnit === K.Format.Dollar
                    ? displayDollar(item.plan.reHireSourced)
                    : displayPercentage(item.plan.reHireSourced)}
                </Paragraph>
              </Col>
            </Row>
            <Divider />
          </div>
        );
      })}
    </>
  );
}
