import { Button, Popconfirm, List, Typography, Row, Col } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";

import {
  convertIntoUtcLocalTime,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder.jsx";

const { Text } = Typography;

export default function HistoryUserCommissionPlans({
  plansDetails,
  isEdit = false,
  handleUpcomingPlanDelete = null,
}) {
  return (
    <>
      {plansDetails?.histroyPlans?.length === 0 ? (
        <EmptyPlaceholder />
      ) : (
        // </Card>
        <List
          size="large"
          className="userInfoListing"
          dataSource={plansDetails.histroyPlans}
          renderItem={(item) => (
            <List.Item>
              <Row gutter={5} className="cnt-wrap">
                <Col xs={24} sm={12} md={8}>
                  <Text className="upcoming-date">
                    <span>Plan Name</span>
                    <br />
                    {item.commisions.name}
                  </Text>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Text className="upcoming-date">
                    <span>Created Date</span>
                    <br />
                    {convertIntoUtcLocalTime(item.createdAt)}
                  </Text>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Text className="upcoming-date">
                    <span>Effective Since</span>
                    <br />
                    {epochTimeToDashUSFormat(item.startDate)}
                  </Text>
                </Col>
              </Row>
              {isEdit && (
                <Popconfirm
                  title="Are you sure to delete this record?"
                  onConfirm={() => {
                    handleUpcomingPlanDelete(item.id, item.planId);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="link"
                    icon={<i className={"icon-delet " + styles.deletIcon}></i>}
                  />
                </Popconfirm>
              )}
            </List.Item>
          )}
        />
      )}
    </>
  );
}
