import Login from "features/login/login";
import ForgotPassword from "features/forgotPassword/forgotPassword";
import SetPassword from "features/setPassword/setPassword";
import Dashboard from "features/dashboard/dashboard.jsx";
import Clients from "features/clients/clientsListing/clients";
import ClientDetails from "features/clients/details/detail";
import ClientJobs from "features/clients/create/clientJobs";
import CreateClient from "features/clients/create/create";
import CommissionStatement from "features/billingCommissions/commission/commissionStatement";
import Invoices from "features/billingCommissions/invoices/invoices";
import SubmitToBilling from "features/billingCommissions/submitToBilling/submitToBilling";
import BillingClients from "features/configurations/billingClients/billingClients";
import BillingJobs from "features/configurations/billingJobs/billingJobs";
import CommissionPlans from "features/configurations/commissionPlans/commissionPlans";
import CommissionPlansDetail from "features/configurations/commissionPlans/details/commissionPlansDetail";
import CreateCommissionPlans from "features/configurations/commissionPlans/createCommissionPlans";
import LookupTableWidget from "features/configurations/lookupTables/lookupTableWidget";
import RolesPermission from "features/configurations/rolesPermissions/rolesPermissions";
import Users from "features/configurations/users/users";
import CreateUsers from "features/configurations/users/createUsers";
import ClientContacts from "features/configurations/clientContacts/clientContacts";
import NotFound from "features/notFound/notFound";
import Unauthorized from "features/unauthorized/unauthorized";
import GuestPageLayout from "layout/guestPageLayout";
import LoggedInPageLayout from "layout/loggedInPageLayout";
import UsersDetails from "features/configurations/users/userDetail";
import Candidates from "features/candidates/candidate";
import CandidatesDetails from "features/candidateDetails/candidateDetails";
import Profile from "features/profile/profile";
import K from "utilities/constants";
import Jobs from "features/jobs/jobs";
import Logs from "features/logs/logs";
import InvoiceDetails from "features/billingCommissions/invoices/invoiceDetails";
import Schedule from "features/billingCommissions/scheduledBillings/schedule";
import CommissionApproval from "features/billingCommissions/commission/commissionApproval";
import CommissionStatementDetail from "features/billingCommissions/commission/commissionStatementDetail";
import PreviewUploads from "features/jobs/uploadCandidate/previewUpload";
import ClientClassDetail from "features/clients/create/clientClassDetail";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    exact: true,
    component: Dashboard,
    authenticated: true,
    isClientRequired: true,
    permission: [K.Permissions.ViewDashboard],
    layout: LoggedInPageLayout,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: GuestPageLayout,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: SetPassword,
    layout: GuestPageLayout,
  },
  {
    path: "/clients",
    name: "Clients",
    exact: true,
    component: Clients,
    authenticated: true,
    isClientRequired: true,
    // permission: [K.Permissions.ViewClients],
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients/create",
    name: "Create Client",
    exact: true,
    component: CreateClient,
    authenticated: true,
    isClientRequired: true,
    // permission: [K.Permissions.ViewClients],
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients/:id",
    name: "Client Details",
    exact: true,
    authenticated: true,
    component: ClientDetails,
    isClientRequired: true,
    // permission: [K.Permissions.ViewClients],
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients/:clientId/class/:classId",
    name: "Client Class",
    authenticated: true,
    exact: true,
    component: ClientClassDetail,
    isClientRequired: true,
    // permission: [K.Permissions.ViewClients],
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients/:clientId/class/:classId/:statusesId?/:statusName?/:subStatusesId?/:subStatusName?",
    name: "Client Class",
    authenticated: true,
    component: ClientClassDetail,
    isClientRequired: true,
    // permission: [K.Permissions.ViewClients],
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients/:clientId/job/:jobId/:statusesId?/:statusName?/:subStatusesId?/:subStatusName?",
    name: "Client Jobs",
    authenticated: true,
    component: ClientJobs,
    isClientRequired: true,
    // permission: [K.Permissions.ViewClients],
    layout: LoggedInPageLayout,
  },

  {
    path: "/profile",
    name: "Profile",
    authenticated: true,
    exact: true,
    component: Profile,
    layout: LoggedInPageLayout,
  },
  {
    path: "/jobs",
    name: "Jobs",
    authenticated: true,
    exact: true,
    component: Jobs,
    isClientRequired: true,
    permission: [K.Permissions.ViewJobs],
    layout: LoggedInPageLayout,
  },
  {
    path: "/clients/:id/preview-upload",
    name: "PreviewUpload",
    authenticated: true,
    exact: true,
    component: PreviewUploads,
    isClientRequired: true,
    permission: [K.Permissions.ViewJobs],
    layout: LoggedInPageLayout,
  },
  {
    path: "/candidates",
    name: "Candidates",
    authenticated: true,
    exact: true,
    component: Candidates,
    isClientRequired: true,
    permission: [K.Permissions.ViewCandidates],
    layout: LoggedInPageLayout,
  },
  {
    path: "/candidates/detail/:id",
    name: "CandidateDetails",
    component: CandidatesDetails,
    authenticated: true,
    exact: true,
    isClientRequired: true,
    permission: [K.Permissions.ViewCandidates],
    layout: LoggedInPageLayout,
  },
  {
    path: "/billings/commission-statement",
    name: "Commission Statements",
    authenticated: true,
    exact: true,
    component: CommissionStatement,
    isClientRequired: true,
    permission: [K.Permissions.ViewCommissionStatement],
    layout: LoggedInPageLayout,
  },
  {
    path: "/billings/commission-statement/detail/:id",
    name: "Commission Statements",
    authenticated: true,
    exact: true,
    component: CommissionStatementDetail,
    isClientRequired: true,
    permission: [K.Permissions.ViewCommissionStatement],
    layout: LoggedInPageLayout,
  },
  {
    path: "/billings/commission-approval",
    name: "Commission Approval",
    authenticated: true,
    component: CommissionApproval,
    isClientRequired: true,
    permission: [K.Permissions.ViewCommissionApproval],
    layout: LoggedInPageLayout,
  },
  {
    path: "/billings/invoices",
    name: "Invoices",
    exact: true,
    authenticated: true,
    component: Invoices,
    isClientRequired: true,
    permission: [K.Permissions.ViewInvoices],
    layout: LoggedInPageLayout,
  },
  {
    path: "/billings/invoices/detail/:id",
    name: "Invoice Details",
    exact: true,
    authenticated: true,
    component: InvoiceDetails,
    isClientRequired: true,
    permission: [K.Permissions.ViewInvoices],
    layout: LoggedInPageLayout,
  },
  {
    path: "/billings/submit",
    name: "Submit To Billing",
    authenticated: true,
    component: SubmitToBilling,
    isClientRequired: true,
    permission: [K.Permissions.ViewBillingApproval],
    layout: LoggedInPageLayout,
  },
  {
    path: "/billings/scheduled",
    name: "Scheduled Billing",
    authenticated: true,
    component: Schedule,
    isClientRequired: true,
    permission: [K.Permissions.ViewScheduledBilling],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/billing-clients",
    name: "BillingClients",
    authenticated: true,
    component: BillingClients,
    isClientRequired: false,
    permission: [K.Permissions.ViewBillingClients],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/billing-jobs",
    name: "Billingjobs",
    authenticated: true,
    component: BillingJobs,
    isClientRequired: false,
    permission: [K.Permissions.ViewBillingJobs],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/lookup-tables",
    name: "LookupTables",
    authenticated: true,
    component: LookupTableWidget,
    isClientRequired: false,
    permission: [K.Permissions.ViewLookupTable],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/commission-plans/create",
    name: "Create Commission Plans",
    authenticated: true,
    exact: true,
    component: CreateCommissionPlans,
    isClientRequired: false,
    permission: [K.Permissions.ViewCommissionPlan],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/commission-plans",
    name: "Commission Plans",
    authenticated: true,
    exact: true,
    component: CommissionPlans,
    isClientRequired: false,
    permission: [K.Permissions.ViewCommissionPlan],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/commission-plans/detail/:planId",
    name: "Commission Plans Detail",
    authenticated: true,
    exact: true,
    component: CommissionPlansDetail,
    isClientRequired: false,
    permission: [K.Permissions.ViewCommissionPlan],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/commission-plan/add-new/:planId",
    name: "Create Commission Plans",
    authenticated: true,
    exact: true,
    component: CreateCommissionPlans,
    isClientRequired: false,
    permission: [K.Permissions.ViewCommissionPlan],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/roles-permission",
    name: "RolesPermission",
    authenticated: true,
    component: RolesPermission,
    isClientRequired: false,
    permission: [K.Permissions.ViewRolesAndPermissions],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/users",
    name: "Users",
    authenticated: true,
    exact: true,
    component: Users,
    isClientRequired: false,
    permission: [K.Permissions.ViewUsers],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/users/create",
    name: "Create Users",
    component: CreateUsers,
    authenticated: true,
    exact: true,
    isClientRequired: false,
    permission: [K.Permissions.ViewUsers],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/users/edit/:id",
    name: "Edit User",
    component: CreateUsers,
    authenticated: true,
    exact: true,
    isClientRequired: false,
    permission: [K.Permissions.ViewUsers],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/users/detail/:id",
    name: "User Detail",
    component: UsersDetails,
    authenticated: true,
    exact: true,
    isClientRequired: false,
    permission: [K.Permissions.ViewUsers],
    layout: LoggedInPageLayout,
  },
  {
    path: "/configurations/client-contacts",
    name: "Client Contacts",
    authenticated: true,
    component: ClientContacts,
    isClientRequired: false,
    permission: [K.Permissions.ViewClientContacts],
    layout: LoggedInPageLayout,
  },
  {
    path: "/logs",
    name: "Logs",
    authenticated: true,
    component: Logs,
    exact: true,
    isClientRequired: false,
    permission: [K.Permissions.ViewLogs],
    layout: LoggedInPageLayout,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    authenticated: true,
    layout: GuestPageLayout,
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    layout: GuestPageLayout,
  },
];

export default routes;
