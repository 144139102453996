import {
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import K from "utilities/constants";

const { Text } = Typography;

export default function RetentionComponent({
  fieldObj,
  form,
  parentIndex,
  currencyList,
}) {
  const formItemName =
    parentIndex !== undefined ? [parentIndex, fieldObj.id] : [fieldObj.id];

  const onMonthsChange = (value) => {
    console.log(value);
    let retentionEntries = [];
    if (value)
      retentionEntries = [...Array(parseInt(value))].map((_, i) => ({
        monthOrder: i + 1,
        amount: null,
      }));

    if (parentIndex !== undefined) {
      const jobLevelBillingRules = form.getFieldValue("jobLevelBillingRules");
      const retentionObj = jobLevelBillingRules[parentIndex][fieldObj.id];
      jobLevelBillingRules[parentIndex][fieldObj.id] = {
        ...retentionObj,
        retentionEntries,
      };
      form.setFieldsValue({ jobLevelBillingRules });
    } else form.setFieldsValue({ [fieldObj.id]: { retentionEntries } });
  };

  const onDaysChange = (value) => {
    console.log(value);
    let retentionEntries = [];
    if (value)
      retentionEntries = [...Array(parseInt(value))].map((_, i) => ({
        monthOrder: null,
        amount: null,
      }));

    if (parentIndex !== undefined) {
      const jobLevelBillingRules = form.getFieldValue("jobLevelBillingRules");
      const retentionObj = jobLevelBillingRules[parentIndex][fieldObj.id];
      jobLevelBillingRules[parentIndex][fieldObj.id] = {
        ...retentionObj,
        retentionEntries,
      };

      form.setFieldsValue({ jobLevelBillingRules });
    } else form.setFieldsValue({ [fieldObj.id]: { retentionEntries } });
  };

  const basedOnchange = () => {
    if (parentIndex !== undefined) {
      const jobLevelBillingRules = form.getFieldValue("jobLevelBillingRules");
      const retentionObj = jobLevelBillingRules[parentIndex][fieldObj.id];
      jobLevelBillingRules[parentIndex][fieldObj.id] = {
        ...retentionObj,
        retentionEntries: [],
        noOfMonths: null,
      };

      form.setFieldsValue({ jobLevelBillingRules });
    } else
      form.setFieldsValue({
        [fieldObj.id]: { retentionEntries: [], noOfMonths: null },
      });
  };

  const retentionComponent = {
    [K.RecurringType.Day]: (
      <>
        <Row align="bottom" gutter={[12, 0]}>
          <Col flex={"auto"} xl={12} md={24} xs={24}>
            <Form.Item
              label="Number of Occurences"
              name={[...formItemName, "noOfMonths"]}
              rules={[
                {
                  required: true,
                  message: "Enter Number of Occurences!",
                },
                {
                  pattern: new RegExp("^[0-9]*$", "g"),
                  message: "Decimal values are not allowed!",
                },
              ]}
            >
              <InputNumber
                placeholder="Enter Occurences"
                onChange={onDaysChange}
                min={1}
                max={24}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name={[...formItemName, "currency"]} initialValue="USD">
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="Currency"
                options={currencyList.map(({ code }) => ({
                  value: code,
                  label: code,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name={[...formItemName, "amountType"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                disabled={!fieldObj.isDependentOn}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { value: K.Format.Dollar, label: "$" },
                  { value: K.Format.Percentage, label: "%" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              initialValue={false}
              valuePropName="checked"
              name={[...formItemName, "isCommissionable"]}
            >
              <Checkbox>Is Commissionable</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.List name={[...formItemName, "retentionEntries"]}>
          {(fields) =>
            fields.map((field, index) => (
              <Row gutter={[12, 0]} key={index}>
                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    label={`Days `}
                    name={[index, "monthOrder"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter days!",
                      },
                      {
                        pattern: new RegExp("^[0-9]*$", "g"),
                        message: "Decimal values are not allowed!",
                      },
                    ]}
                  >
                    <InputNumber min={1} placeholder="Enter days" />
                  </Form.Item>
                </Col>
                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    label={`Amount`}
                    name={[index, "amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Enter Amount" />
                  </Form.Item>
                </Col>
                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    label="Referral Fee"
                    name={[index, "referral"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter referral fee!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Referral Fee" />
                  </Form.Item>
                </Col>
                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    label="Rehire Fee"
                    name={[index, "reHired"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter rehire fee!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Rehire Fee" />
                  </Form.Item>
                </Col>
              </Row>
            ))
          }
        </Form.List>
      </>
    ),
    [K.RecurringType.Month]: (
      <>
        <Row align="bottom" gutter={[12, 0]}>
          <Col flex={"auto"} xl={12} md={24} xs={24}>
            <Form.Item
              label="Number of Months"
              name={[...formItemName, "noOfMonths"]}
              rules={[
                {
                  required: true,
                  message: "Enter Number of Months!",
                },
                {
                  pattern: new RegExp("^[0-9]*$", "g"),
                  message: "Decimal values are not allowed!",
                },
              ]}
            >
              <InputNumber
                placeholder="Enter Months"
                onChange={onMonthsChange}
                min={1}
                max={24}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item name={[...formItemName, "currency"]} initialValue="USD">
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="Currency"
                options={currencyList.map(({ code }) => ({
                  value: code,
                  label: code,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name={[...formItemName, "amountType"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                disabled={!fieldObj.isDependentOn}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { value: K.Format.Dollar, label: "$" },
                  { value: K.Format.Percentage, label: "%" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              initialValue={false}
              valuePropName="checked"
              name={[...formItemName, "isCommissionable"]}
            >
              <Checkbox>Is Commissionable</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.List name={[...formItemName, "retentionEntries"]}>
          {(fields) =>
            fields.map((field, index) => (
              <Row gutter={[12, 0]} key={index}>
                <Col lg={8} md={8} xs={24}>
                  <Form.Item
                    label={`Month ${index + 1}`}
                    name={[index, "amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Enter Amount" />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <Form.Item
                    label="Referral Fee"
                    name={[index, "referral"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter referral fee!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Referral Fee" />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <Form.Item
                    label="Rehire Fee"
                    name={[index, "reHired"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter rehire fee!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Rehire Fee" />
                  </Form.Item>
                </Col>
              </Row>
            ))
          }
        </Form.List>
      </>
    ),
  };

  return (
    <div className="mb-0 oneTimeComponentWrap">
      <div className="oneTimeHeader">
        <Text className="oneTimeTitle">{fieldObj.name}</Text>
      </div>
      <Divider className="headerDvider" />
      <div className="hireFeeData">
        <Row>
          <Col flex="auto">
            <Col flex={"auto"} xl={12} md={24} xs={24}>
              <Form.Item
                name={[...formItemName, "retentionType"]}
                initialValue={K.RecurringType.Day}
                label={"Based on: "}
              >
                <Select
                  showSearch={true}
                  placeholder="Days"
                  optionFilterProp="label"
                  onChange={basedOnchange}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={[
                    { value: K.RecurringType.Day, label: "Days" },
                    { value: K.RecurringType.Month, label: "Month" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <Form.Item shouldUpdate>
              {({ getFieldValue }) =>
                parentIndex !== undefined
                  ? retentionComponent[
                      getFieldValue("jobLevelBillingRules")[parentIndex][
                        fieldObj.id
                      ].retentionType
                    ]
                  : retentionComponent[getFieldValue(fieldObj.id).retentionType]
              }
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.List name={[...formItemName, "retentionEntries"]}>
          {(fields) =>
            fields.map((field, index) => (
              <Row gutter={[12, 0]} key={index}>
                <Col lg={8} md={8} xs={24}>
                  <Form.Item
                    label={`Month ${index + 1}`}
                    name={[index, "amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Enter Amount" />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <Form.Item
                    label="Referral Fee"
                    name={[index, "referral"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter referral fee!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Referral Fee" />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <Form.Item
                    label="Rehire Fee"
                    name={[index, "reHired"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter rehire fee!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Rehire Fee" />
                  </Form.Item>
                </Col>
              </Row>
            ))
          }
        </Form.List> */}
      </div>
    </div>
  );
}
