import { epochTimeToUTCDashUSFormat } from "utilities/dateUtility";
import { displayDollar } from "utilities/tableUtility";

export const amountRenderer = (params) => displayDollar(params.value);
export const tooltipAmountRenderer = (params) => (
  <div className="client-list-tooltip"> {displayDollar(params.value)}</div>
);

export const dueDate = (params) => epochTimeToUTCDashUSFormat(params.value);
export const tooltipDueDate = (params) => (
  <div className="client-list-tooltip">
    {epochTimeToUTCDashUSFormat(params.value)}
  </div>
);

export const tooltipIsCommissionable = (params) => {
  return (
    <div className="client-list-tooltip">
      {params.data.isCommissionable ? "Yes" : "No"}
    </div>
  );
};
export const isCommissionable = (params) => {
  return params.data.isCommissionable ? "Yes" : "No";
};

export const isActive = (params) => (params.data.isActive ? "Yes" : "No");
export const tooltipIsActive = (params) => (
  <div className="client-list-tooltip">
    {params.data.isActive ? "Yes" : "No"}
  </div>
);

export const inFiniteRenderer = (params) => {
  return params.value
    ? "Until next billing rule"
    : `${params.data.repeatOccurances ?? 0} billing(s) pending`;
};
export const tooltipInFiniteRenderer = (params) => {
  return (
    <div className="client-list-tooltip">
      {params.value
        ? "Until next billing rule"
        : `${params.data.repeatOccurances ?? 0} billing(s) pending`}
    </div>
  );
};
