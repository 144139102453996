import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { PageHeader, Input, Card } from "antd";
import LayoutCss from "layout/layout.module.scss";
import K from "utilities/constants";
import { onFilterTextBoxChanged } from "utilities/tableUtility";
import User from "redux/models/user";
import { formatActivityTimestamp } from "utilities/dateUtility";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
// import { isJsonValid } from "utilities/generalUtility";

const { Search } = Input;
//Depricated Input
//const limits = [50, 100, 150, 200, 300, 400, 500];
const searchFilterPrefix = "logs";
const getSearchKey = "logs_search";
export default function Logs() {
  const gridRef = useRef();
  const [logs, setLogs] = useState([]);
  const [setter, getter] = useSearchAndFilter();
  // const [limitValue, setLimitValue] = useState(50);

  useEffect(() => {
    (async () => {
      try {
        const body = null;
        const res = await User.getLogs(body);

        //===================Depricated used in cloud watch
        // const data = res.results.map((item) =>
        //   item.reduce((prev, curr) => {
        //     return {
        //       ...prev,
        //       [curr.field]:
        //         curr.field === "@message"
        //           ? isJsonValid(curr.value) && JSON.parse(curr.value)
        //           : convertDateStringToMoment(curr.value).format(
        //               K.DateFormat.LongDisplay
        //             ),
        //     };
        //   }, {})
        // );
        setLogs(res);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const [columnDefs] = useState([
    {
      headerName: "User Name",
      field: "userName",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Message",
      field: "message",
      sortable: true,
    },

    {
      headerName: "TimeStamp",
      field: "createdAt",
      sortable: true,
      cellRenderer: (params) => formatActivityTimestamp(params.data.createdAt),
    },
  ]);

  const onChange = useCallback((event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);
    setter({ search: event.target.value }, searchFilterPrefix);
  }, []);

  // const limitHandler = (value) => {
  //   setLimitValue(value);
  // };

  return (
    <>
      <PageHeader
        title="User Logs"
        ghost={false}
        className={[LayoutCss.appPageHeader, LayoutCss.userLogsPageHeader]}
      />
      <Card className={"appCard " + LayoutCss.appCard}>
        <div className={LayoutCss.userCardHeaderWrap}>
          <Search
            allowClear
            placeholder="Search"
            value={getter(getSearchKey)}
            onChange={onChange}
            className={LayoutCss.userLogCardSearchBar}
          />
          {/* <div className={LayoutCss.userLogCardHeader}>
            <Select
            showSearch
            allowClear
              defaultValue={limitValue}
              onChange={limitHandler}
              className={"user-log-card-select " + LayoutCss.userLogCardSelect}
              options={limits.map((item) => ({
                value: item,
                label: `Show ${item}`,
              }))}
            />
            <Button key="1" type="primary" className={LayoutCss.userLogButton}>
              Export All
            </Button>
          </div>*/}
        </div>

        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={logs ?? []}
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            ref={gridRef}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>
    </>
  );
}
