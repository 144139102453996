import K from "utilities/constants";

const { Lookup } = K.Network.URL;

export const lookupTablesForClient = [
  {
    reduxKey: K.Redux.AtsName,
    apiEndPoint: Lookup.AtsNames,
  },
  {
    reduxKey: K.Redux.ReportingSchedule,
    apiEndPoint: Lookup.ReportingSchedule,
  },
  {
    reduxKey: K.Redux.Stage,
    apiEndPoint: Lookup.Stage,
  },
  {
    reduxKey: K.Redux.Ownership,
    apiEndPoint: Lookup.Ownership,
  },
];

export const lookupTablesForJobs = [
  {
    reduxKey: K.Redux.AtsName,
    apiEndPoint: Lookup.AtsNames,
    isClient: false,
  },
  {
    reduxKey: K.Redux.JobLocation,
    apiEndPoint: Lookup.JobLocations,
    isClient: true,
  },
  {
    reduxKey: K.Redux.JobDepartment,
    apiEndPoint: Lookup.JobDepartments,
    isClient: true,
  },
  {
    reduxKey: K.Redux.JobStatus,
    apiEndPoint: Lookup.JobStatus,
    isClient: false,
  },
  {
    reduxKey: K.Redux.JobGroup,
    apiEndPoint: Lookup.JobGroups,
    isClient: true,
  },
  {
    reduxKey: K.Redux.UserDesignation,
    apiEndPoint: Lookup.UserDesignation,
    isClient: false,
  },
];

export const lookupTablesForCandidates = [
  {
    reduxKey: K.Redux.AtsName,
    apiEndPoint: Lookup.AtsNames,
  },
  {
    reduxKey: K.Redux.Source,
    apiEndPoint: Lookup.Sources,
  },
  {
    reduxKey: K.Redux.CandidateStatus,
    apiEndPoint: Lookup.CandidateStatus,
  },
];
