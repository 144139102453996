import { DatePicker, Form, TimePicker } from "antd";
import moment from "moment-business-days";
import K from "utilities/constants";
export default function UDFDatePicker({
  title = "Title",
  isFormItem = true,
  value,
  className,
  placeholder = "",
  formItemName = "",
  required = true,
  props,
}) {
  return (
    <>
      {isFormItem ? (
        <Form.Item
          className={className}
          label={title}
          name={formItemName}
          initialValue={null}
          rules={required && [{ required: true, message: `Enter  ${title}` }]}
        >
          <DatePicker
            placeholder={placeholder}
            format={K.DateFormat.DashUSFormat}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </Form.Item>
      ) : (
        <DatePicker
          placeholder={placeholder}
          format={K.DateFormat.DashUSFormat}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
      )}
    </>
  );
}
