import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import BillingJob from "redux/models/billingJobs";
import CreateJobBillingRule from "../createJobBillingRule/createJobBillingRule";
import JobBillingList from "./jobBillingList";
import JobBillingRulesDetail from "./jobBillingRulesDetail";

export default function JobBillingRules() {
  const dispatch = useDispatch();
  const tierId = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([dispatch(BillingJob.getAll())]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const jobBillingComponents = {
    0: <JobBillingList tierId={tierId} setCurrentPage={setCurrentPage} />,
    1: <CreateJobBillingRule setCurrentPage={setCurrentPage} />,
    2: (
      <JobBillingRulesDetail tierId={tierId} setCurrentPage={setCurrentPage} />
    ),
  };

  return <>{jobBillingComponents[currentPage]}</>;
}
