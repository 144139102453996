import { Button, Divider, Form, Input, Modal, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";

import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import {
  convertIntoDashUSFormat,
  convertIntoUTCDashUSFormat,
} from "utilities/dateUtility";

import { useSelector } from "react-redux";
import {
  isPermissionPresent,
  noTrailingSpaceAllowedRule,
} from "utilities/generalUtility";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import Client from "redux/models/client";
import DragUpload from "common/components/uploadComponent/dragger";

const { Title } = Typography;

export default function CandidateAttachment({ styles, candidateActivity }) {
  const [form] = Form.useForm();

  const [attachments, setAttachments] = useState([]);
  const [file, setFile] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userSlice = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const afterModalClose = () => {};

  const getAttachments = async () => {
    const body = {
      entityId: candidateActivity.id,
      type: K.AttachmentType.Candidate,
    };

    try {
      const res = await Client.getAttachment(body);
      setAttachments([...res]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAttachments();
  }, [candidateActivity]);

  const onRemove = (file) => {
    setFile(null);
  };
  const show = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const res = await Client.uploadAttachement(
        file,
        K.S3Folders.CandidateAttachments + candidateActivity.id,
      );

      const payload = {
        entityId: candidateActivity.id, //Candidate Id
        type: K.AttachmentType.Candidate,
        title: values.title,
        attachment: res.filepath,
        userName: userSlice.details.name,
        userId: userSlice.details.id,
      };

      await Client.createAttachment(payload);
      getAttachments();
      setIsLoading(false);
      setIsModalVisible(false);
      form.resetFields();
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const downloadFile = async (url) => {
    const downloadUrl = await Client.getPreSignedUrl(url);
    window.open(downloadUrl);
  };

  const deleteAttachment = async (record) => {
    try {
      await Client.deleteFileFromS3(record.attachment);
      await Client.deleteAttachement(record.id);
      message.success("Attachment Deleted Successfully");
      getAttachments();
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };
  return (
    <>
      <div className={styles.issueCredits + " issue-credit-head"}>
        <div className="issue-btn-wrap">
          {isPermissionPresent(K.Permissions.AddAttchment, userSlice.roles) && (
            <Button
              type="primary"
              className={styles.resubmitBtn}
              onClick={show}
              disabled={candidateActivity.isConsiderable === 0}
            >
              Add Document
            </Button>
          )}
        </div>
      </div>
      <Scrollbars style={{ height: 400 }}>
        {attachments.length ? (
          attachments.map((item) => (
            <div className={styles.invoiceWrap}>
              {/* <>
                <Button
                  type="link"
                  className={styles.reloadBtn}
                  onClick={() => {
                    downloadFile(item.attachment);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </> */}

              <div className={styles.invoiceDetail}>
                <div className={styles.invoiceDate}>
                  <Title level={5}>
                    Title: <span>{item.title}</span>
                  </Title>
                  {/* <Title level={5} className={styles.invoiceId}>
                    ID: <span>{item.id}</span>
                  </Title> */}
                  <div style={{ justifyContent: "end" }}>
                    <Button
                      className={styles.reloadBtn}
                      type="link"
                      onClick={() => {
                        downloadFile(item.attachment);
                      }}
                    >
                      <DownloadOutlined />
                    </Button>
                    {isPermissionPresent(
                      K.Permissions.DeleteAttachment,
                      userSlice.roles,
                    ) && (
                      <Button
                        type="link"
                        onClick={() => deleteAttachment(item)}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 14, color: "red" }}
                        />
                      </Button>
                    )}
                  </div>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title level={5}>
                    Uploaded Date:
                    <span>
                      {item.isManual
                        ? convertIntoDashUSFormat(item.createdAt, false)
                        : convertIntoUTCDashUSFormat(item.createdAt, false)}
                      ,
                    </span>
                  </Title>
                  <Title level={5}>
                    Uploaded By: <span>{item.userName}</span>
                  </Title>
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyPlaceholder />
        )}
      </Scrollbars>

      <Modal
        forceRender
        className="s2-theme-style"
        title={`Add Documents`}
        open={isModalVisible}
        centered
        closeIcon={<i className="icon-closeable"></i>}
        okText={`Add`}
        wrapClassName="vertical-center-modal"
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
        afterClose={afterModalClose}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={null}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Title is required." },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            label="Document"
            name="document"
            rules={[
              {
                required: true,
                validator: (_, obj) => {
                  if (file) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please upload documents!");
                  }
                },
              },
            ]}
          >
            <DragUpload setCSVFile={setFile} onRemove={onRemove} accept="all" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
