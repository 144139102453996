import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import {
  getIsVisibleLookups,
  noTrailingSpaceAllowedRule,
} from "utilities/generalUtility";
import styles from "../jobBilling.module.scss";

const { Title } = Typography;

export default function TierComponent({ form, parentIndex, tierData }) {
  const configrationSlice = useSelector(selectConfigration);

  // *Client ID
  const { id } = useParams();

  const [pageState, setPageState] = useState({
    isDisabled: true,
    titleListing: [],
  });
  const isIndexDefined = parentIndex !== undefined;

  const getTitleListing = (disabled = true) => {
    let list;
    if (isIndexDefined) {
      const jobLevelBillingRules = form.getFieldValue("jobLevelBillingRules");
      const selectedTitles =
        jobLevelBillingRules[parentIndex].titleAssociated?.filter(Boolean) ??
        [];
      list = selectedTitles.map(({ jobTitleId }) => jobTitleId);
    } else {
      const jobLevelBillingRules = form.getFieldValue("jobLevelBillingRules");
      const selectedTitles =
        jobLevelBillingRules[parentIndex].titleAssociated?.filter(Boolean) ??
        [];
      list = jobLevelBillingRules
        ? jobLevelBillingRules
        : selectedTitles.map(({ jobTitleId }) => jobTitleId);
    }

    if (!disabled) list.pop();

    return configrationSlice[K.Redux.Lookup][K.Redux.JobTitle].filter(
      ({ id }) => !list.includes(id),
    );
  };

  const getTitles = () => {
    const name = isIndexDefined ? "jobLevelBillingRules" : "titleAssociated";
    const value = form.getFieldValue(name);

    if (isIndexDefined) {
      return value[parentIndex].titleAssociated;
    }
    return value;
  };

  const setTitleListing = (index) => {
    const titles = getTitles();

    setPageState({
      titleListing: getTitleListing(),
      isDisabled: !titles[index + 1],
    });
  };

  const toggleDisabled = (index) => {
    const titles = getTitles();
    setPageState((prev) => ({ ...prev, isDisabled: !titles[index] }));
  };

  useEffect(() => {
    if (tierData) {
      const tierTitleAssociated = tierData?.jobTierTitle.map((item) => {
        return {
          jobTitleId: item.jobTitlesId,
          tierName: item.jobTitle.name,
        };
      });
      console.log("");
      form.setFieldsValue({
        tierName: tierData.name,
        titleAssociated: [...tierTitleAssociated],
      });
    }
  }, [tierData]);

  return (
    <>
      <Form.Item
        label="Tier Name"
        name={isIndexDefined ? [parentIndex, "tierName"] : "tierName"}
        rules={[
          { required: true, message: "Tier name is required" },
          { min: 3, message: "Name must be at least 3 characters" },
          {
            max: 150,
            message: "Name cannot be longer than 150 characters",
          },
          noTrailingSpaceAllowedRule(),
        ]}
      >
        <Input placeholder="Tier Name" />
      </Form.Item>
      <p className="form-label">Titles Associated </p>
      <Form.List
        name={
          isIndexDefined ? [parentIndex, "titleAssociated"] : "titleAssociated"
        }
        initialValue={[undefined]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row align="middle" key={index} className="mb-4">
                <Col flex="auto">
                  <Form.Item
                    className="mb-0 add-title-plus-btn"
                    name={[index, "jobTitleId"]}
                    rules={
                      index > 0
                        ? []
                        : [{ required: true, message: "Select titles" }]
                    }
                  >
                    {id ? (
                      <Select
                        showSearch
                        disabled={index !== fields.length - 1}
                        placeholder="Select"
                        onChange={() => toggleDisabled(index)}
                        optionFilterProp="label"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={
                          fields.length > 1 || pageState.titleListing.length
                            ? getIsVisibleLookups(
                                pageState.titleListing.filter(
                                  (lookup) =>
                                    parseInt(lookup.clientId) === parseInt(id),
                                ),
                              ).map(({ id, name, isVisible }) => ({
                                value: id,
                                label: name,
                                disabled: isVisible === 1 ? false : true,
                              }))
                            : getIsVisibleLookups(
                                configrationSlice[K.Redux.Lookup][
                                  K.Redux.JobTitle
                                ].filter((lookup) => +lookup.clientId === +id),
                              ).map(({ id, name, isVisible }) => ({
                                value: id,
                                label: name,
                                disabled: isVisible === 1 ? false : true,
                              }))
                        }
                      />
                    ) : (
                      <Input
                        disabled={index !== fields.length - 1}
                        onChange={() => toggleDisabled(index)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col flex="25px" className="reasonLabel">
                  {index !== fields.length - 1 ? (
                    <Button
                      type="link"
                      icon={
                        <i className={`icon-delet ${styles.deletIcon}`}></i>
                      }
                      onClick={() => {
                        remove(field.name);
                        setPageState((prev) => ({
                          ...prev,
                          titleListing: getTitleListing(pageState.isDisabled),
                        }));
                      }}
                    />
                  ) : (
                    <Button
                      disabled={pageState.isDisabled}
                      className="plus-icon"
                      onClick={() => {
                        add();
                        setTitleListing(index);
                      }}
                      icon={<PlusOutlined />}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </>
        )}
      </Form.List>
    </>
  );
}
