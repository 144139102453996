import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useHistory } from "react-router-dom";
import { Button, PageHeader, Input, Card, message, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LayoutCss from "layout/layout.module.scss";
import { selectClient } from "redux/slices/clientSlice";
import Client from "redux/models/client";
import K from "utilities/constants";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
  stringSorting,
} from "utilities/tableUtility";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import {
  isPermissionPresent,
  multiValuecomparator,
} from "utilities/generalUtility";
import { tooltipTagRenderer } from "features/billingCommissions/submitToBilling/tableColumns";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;
const searchFilterPrefix = "global_clients";
const getSearchKey = "global_clients_search";

export default function Clients() {
  const gridRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const userSlice = useSelector(selectUser);
  const clientListData = useSelector(selectClient).listing;
  const [setter, getter] = useSearchAndFilter();

  const searchRef = useRef(null);

  const changeFormat = (params) => convertIntoDashUSFormat(params.value, false);
  const toolTipChangeFormat = (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.value, false)}
    </div>
  );

  const tagRenderer = (params) =>
    params.value.map((item) => <Tag color="#0070C0">{item.name}</Tag>);
  const clientLeaderTag = (params) => {
    return <Tag color="#0070C0">{params.value.name}</Tag>;
  };
  const searchTagRenderer = (params) => params.value.name;
  const multiValueSearchTagRenderer = (params) =>
    params.value.map((item) => item.name);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
    },
    {
      headerName: "Corporate Address",
      field: "address",
      sortable: true,
      tooltipField: "address",
    },

    {
      headerName: "Client Service Leader",
      field: "clientLeader",
      sortable: true,
      tooltipField: "clientLeader",
      getQuickFilterText: searchTagRenderer,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      tooltipComponent: (params) => tooltipTagRenderer(params),
      cellRenderer: clientLeaderTag,
    },
    {
      headerName: "Account Manager",
      field: "accountManagers",
      sortable: true,
      tooltipField: "accountManagers",
      getQuickFilterText: multiValueSearchTagRenderer,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        multiValuecomparator(a, b, "name"),
      tooltipComponent: (params) =>
        tooltipTagRenderer(params, "accountManagers"),
      cellRenderer: tagRenderer,
    },
    {
      headerName: "ATS",
      field: "ats.name",
      sortable: true,
      tooltipField: "ats.name",
    },
    {
      headerName: "Stage",
      field: "stage.name",
      sortable: true,
      tooltipField: "stage.name",
    },
    {
      headerName: "Original Contract Date",
      field: "originalContractDate",
      tooltipField: "originalContractDate",
      sortable: true,
      tooltipComponent: toolTipChangeFormat,
      cellRenderer: changeFormat,
    },

    /* {
      headerName: "Actions",
      field: "action",
      // filter: true,
      width: 250,
      resizable: false,
      headerClass: "text-center",
      cellRendererFramework: () => (
        <>
          <Button type="link" className={LayoutCss.appListingCardActions}>
            Add Job
          </Button>
          <Button type="link" className={LayoutCss.appListingCardActions}>
            Edit
          </Button>
        </>
      ),
    }, */
  ]);

  const rowClickedHandler = (event) => {
    history.push(`/clients/${event.data.id}?tab=overview`);
  };

  const onChange = useCallback(
    (event) => {
      console.log("event", event.target.value);
      onFilterTextBoxChanged(event.target.value, gridRef);
      setter({ search: event.target.value }, searchFilterPrefix);
    },
    [searchRef.current],
  );

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.ClientVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.ClientVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (["accountManagers"].includes(item.field))
          return {
            ...item,
            cellRenderer: tagRenderer,
            getQuickFilterText: multiValueSearchTagRenderer,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              multiValuecomparator(a, b, "name"),
            tooltipComponent: (params) =>
              tooltipTagRenderer(params, "accountManagers"),
          };
        else if (item.field === "clientLeader")
          return {
            ...item,
            cellRenderer: clientLeaderTag,
            getQuickFilterText: searchTagRenderer,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            tooltipComponent: (params) => tooltipTagRenderer(params),
          };
        else if (["originalContractDate"].includes(item.field))
          return {
            ...item,
            cellRenderer: changeFormat,
            tooltipComponent: toolTipChangeFormat,
          };
        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          getColumnsConfigrations(),
          dispatch(Client.getAll()),
        ]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Clients"
        extra={
          isPermissionPresent(K.Permissions.CreateClient, userSlice.roles) && (
            <Link to="/clients/create">
              <Button type="primary" className={LayoutCss.addClientBtn}>
                <i className={"icon-plus " + LayoutCss.plusIcon}></i>
                Client
              </Button>
            </Link>
          )
        }
        className={`${LayoutCss.appPageHeader} ${LayoutCss.clientsPageHeader} ${LayoutCss.PageHeader}`}
      />
      <Card
        className={"appCard " + LayoutCss.appCard}
        bordered={false}
        title="Client List"
        extra={
          <Search
            allowClear
            value={getter(getSearchKey)}
            placeholder="Search"
            onChange={onChange}
            className={LayoutCss.appListingCardSearchBar1}
          />
        }
      >
        <div className="ag-theme-alpine s2-theme-style" style={{ height: 735 }}>
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={clientListData}
            columnDefs={columnDefs}
            ref={gridRef}
            onRowClicked={rowClickedHandler}
            onColumnPinned={onColumnVisible}
            onColumnVisible={onColumnVisible}
            defaultColDef={K.AgGridTable.DefaultColDef}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>
    </>
  );
}
