import { Form, Modal, Select, message } from "antd";

import Invoice from "redux/models/invoice";

import K from "utilities/constants";

export default function BulkUpdateInvoice({
  isOpen,
  setIsModalVisible,
  modalData,
}) {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const body = {
        status: values.status,
        invoiceIds: modalData.rowIds,
      };

      await Invoice.bulkUpdate(body);

      setIsModalVisible((prev) => ({
        ...prev,
        isModalVisible: false,
        shouldUpdate: !prev.shouldUpdate,
        rowIds: [],
        rowData: [],
        isDisabled: true,
      }));

      message.success("Invoices Status Changed Successfully");
    } catch (error) {
      setIsModalVisible((prev) => ({ ...prev, isModalVisible: false }));
      console.log(error);
    }
  };

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalVisible((prev) => ({
      ...prev,
      isModalVisible: false,
    }));

    form.resetFields();
  };

  return (
    <Modal
      centered
      width={462}
      open={isOpen}
      okText="Submit"
      onOk={handleOk}
      onCancel={handleCancel}
      title="Bulk Update"
      className="s2-theme-style "
      closeIcon={<i className="icon-closeable"></i>}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="status" label="Invoice Status">
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            placeholder="Status"
            options={Object.keys(K.Invoice.Status)
              .filter(
                (status) => !["Draft", "Billed in QuickBooks"].includes(status),
              )
              .map((item, i) => ({
                value: K.Invoice.Status[item],
                label: item,
              }))}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
