import { Button, Card, message, Tooltip, Typography } from "antd";
import { useRef, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import PieChart from "./components/pieChart";
import HireChart from "./components/hireChart";
import InterviewDeclineChart from "./components/interviewDeclineChart";
import InterviewNoShowChart from "./components/interviewNoShowChart";
import PostOfferFallOutChart from "./components/postOfferFallOutChart";
import SubmittalDeclineChart from "./components/submittalDeclineChart";
import K from "utilities/constants";
import { titleCase } from "utilities/generalUtility";
import User from "redux/models/user";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";
import layouts from "config/gridLayoutConfig";
import {
  changeLayoutOrder,
  changeSMLayoutOrder,
  changeXSLayoutOrder,
} from "./components/layoutChangeHandlers";
import TTMChart from "./components/ttmChart";

const { Title } = Typography;
const {
  AgGridTable: { Keys },
} = K;
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function DashboardChart({
  pageData,
  selectedCharts,
  isInitialRender,
  setPageData,
  historicalPerformanceData,
}) {
  const gridLayoutRef = useRef(layouts);
  // const dragLayoutRef = useRef(null);
  const isDragRef = useRef(false);
  const currentBreakPoint = useRef("lg");
  const userDetails = useSelector(selectUser).details;

  const layoutChangeHandler = (current) => {
    if (isInitialRender.current) return;
    switch (currentBreakPoint.current) {
      case "lg":
        if (pageData.isPanelSetting) {
          gridLayoutRef.current = {
            lg: changeLayoutOrder(current),
            md: changeLayoutOrder(current),
            sm: changeSMLayoutOrder(current),
            xs: changeXSLayoutOrder(current),
          };
          setPageData((prev) => ({
            ...prev,
            isPanelSetting: false,
          }));
          saveLayout();
        } else if (isDragRef.current) {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            lg: current,
          };
          saveLayout();
          // setPageData((prev) => ({ ...prev, isDragChart: false }));
          isDragRef.current = false;
        } else {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            lg: current,
          };
        }
        break;

      case "md":
        if (pageData.isPanelSetting) {
          gridLayoutRef.current = {
            lg: changeLayoutOrder(current),
            md: changeLayoutOrder(current),
            sm: changeSMLayoutOrder(current),
            xs: changeXSLayoutOrder(current),
          };
          setPageData((prev) => ({
            ...prev,
            isPanelSetting: false,
          }));
          saveLayout();
        } else if (isDragRef.current) {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            md: current,
          };
          saveLayout();
          // setPageData((prev) => ({ ...prev, isDragChart: false }));
          isDragRef.current = false;
        } else {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            md: current,
          };
        }
        break;

      case "sm":
        if (pageData.isPanelSetting) {
          gridLayoutRef.current = {
            lg: changeLayoutOrder(current),
            md: changeLayoutOrder(current),
            sm: changeSMLayoutOrder(current),
            xs: changeXSLayoutOrder(current),
          };
          setPageData((prev) => ({
            ...prev,
            isPanelSetting: false,
          }));
          saveLayout();
        } else if (isDragRef.current) {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            sm: current,
          };
          saveLayout();
          // setPageData((prev) => ({ ...prev, isDragChart: false }));
          isDragRef.current = false;
        } else {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            sm: current,
          };
        }
        break;

      case "xs":
        if (pageData.isPanelSetting) {
          gridLayoutRef.current = {
            lg: changeLayoutOrder(current),
            md: changeLayoutOrder(current),
            sm: changeSMLayoutOrder(current),
            xs: changeXSLayoutOrder(current),
          };
          setPageData((prev) => ({
            ...prev,
            isPanelSetting: false,
          }));
          saveLayout();
        } else if (isDragRef.current) {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            xs: current,
          };
          saveLayout();
          // setPageData((prev) => ({ ...prev, isDragChart: false }));
          isDragRef.current = false;
        } else {
          gridLayoutRef.current = {
            ...gridLayoutRef.current,
            xs: changeXSLayoutOrder(current),
          };
        }
        break;
      default:
    }
  };

  const onDragStop = (layout, oldItem, newItem) => {
    isInitialRender.current = false;
    isDragRef.current = true;

    // if (
    //   !(
    //     Math.abs(newItem.x - oldItem.x) === 6 ||
    //     Math.abs(newItem.x - oldItem.x) === 0
    //   )
    // ) {
    //   dragLayoutRef.current = gridLayoutRef.current;
    // } else {
    //   dragLayoutRef.current = layout;
    // }
  };

  const saveLayout = async () => {
    if (isInitialRender.current) return;
    try {
      await User.saveDashboardVisibleCharts({
        usersId: userDetails.id,
        tableName: Keys.GridLayoutOrder,
        configJson: JSON.stringify(gridLayoutRef.current),
      });
      message.success("Configration Saved");
    } catch (err) {
      console.error(err);
      message.error("Configration Not Saved");
    }
  };

  const getLayoutConfigration = async () => {
    try {
      const res = await User.getConfigrations(Keys.GridLayoutOrder);
      if (res) {
        const parsed = JSON.parse(res.config);
        gridLayoutRef.current = {
          lg: parsed.lg,
          md: parsed.md,
          sm: parsed.sm,
          xs: parsed.xs,
        };
        setPageData((prev) => ({
          ...prev,
          selectedCharts: parsed[currentBreakPoint.current].map(
            (item) => item.i,
          ),
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLayoutConfigration();
  }, []);

  return (
    <ResponsiveReactGridLayout
      isResizable={false}
      layouts={gridLayoutRef.current}
      compactType="horizontal"
      isBounded={true}
      isDroppable={true}
      draggableHandle=".icon-arrows"
      cols={{ lg: 12, md: 12, sm: 6, xs: 4 }}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
      rowHeight={500}
      onDragStop={onDragStop}
      onLayoutChange={layoutChangeHandler}
      onBreakpointChange={(newBreakpoint, newCols) => {
        currentBreakPoint.current = newBreakpoint;
      }}
    >
      {selectedCharts.includes("Hire Chart") && (
        <div key="Hire Chart">
          <Card className="cardHeader">
            <div className="CardTitle">
              <Title level={4}>
                Hires by{" "}
                {historicalPerformanceData.type &&
                  titleCase(historicalPerformanceData.type)}
                <Button type="link">
                  <Tooltip
                    title="Candidates that started work over a period"
                    color={K.TooltipColor}
                    placement="bottom"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                </Button>
              </Title>
              <i className="icon-arrows"></i>
            </div>
            <HireChart historicalPerformanceData={historicalPerformanceData} />
          </Card>
        </div>
      )}
      {selectedCharts.includes("Submittal Decline Rate") && (
        <div key="Submittal Decline Rate">
          <Card className="cardHeader">
            <div className="CardTitle">
              <Title level={4}>
                Submittal Decline Rate by{" "}
                {historicalPerformanceData.type &&
                  titleCase(historicalPerformanceData.type)}
                <Button type="link">
                  <Tooltip
                    title="Percentage of submitted candidates declined by hiring manager"
                    color={K.TooltipColor}
                    placement="bottom"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                </Button>
              </Title>
              <i className="icon-arrows"></i>
            </div>
            <SubmittalDeclineChart
              historicalPerformanceData={historicalPerformanceData}
            />
          </Card>
        </div>
      )}
      {selectedCharts.includes("Interview Decline Rate") && (
        <div key="Interview Decline Rate">
          <Card className="cardHeader">
            <div className="CardTitle">
              <Title level={4}>
                Interview Decline Rate by{" "}
                {historicalPerformanceData.type &&
                  titleCase(historicalPerformanceData.type)}
                <Button type="link">
                  <Tooltip
                    title="Percentage of candidates declined by hiring manager during an interview"
                    color={K.TooltipColor}
                    placement="bottom"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                </Button>
              </Title>
              <i className="icon-arrows"></i>
            </div>
            <InterviewDeclineChart
              historicalPerformanceData={historicalPerformanceData}
            />
          </Card>
        </div>
      )}
      {selectedCharts.includes("Interview No Show Rate") && (
        <div key="Interview No Show Rate">
          <Card className="cardHeader">
            <div className="CardTitle">
              <Title level={4}>
                Interview No Show Rate by{" "}
                {historicalPerformanceData.type &&
                  titleCase(historicalPerformanceData.type)}
                <Button type="link">
                  <Tooltip
                    title="Percentage of candidates that no show to their interview"
                    color={K.TooltipColor}
                    placement="bottom"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                </Button>
              </Title>
              <i className="icon-arrows"></i>
            </div>
            <InterviewNoShowChart
              historicalPerformanceData={historicalPerformanceData}
            />
          </Card>
        </div>
      )}
      {selectedCharts.includes("Post Offer Fall Out Rates") && (
        <div key="Post Offer Fall Out Rates">
          <Card className="cardHeader">
            <div className="CardTitle">
              <Title level={4}>
                Post Offer Fall Out Rates by{" "}
                {historicalPerformanceData.type &&
                  titleCase(historicalPerformanceData.type)}
                <Button type="link">
                  <Tooltip
                    title="Percentage of candidates that received an offer, but did not start work"
                    color={K.TooltipColor}
                    placement="bottom"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                </Button>
              </Title>
              <i className="icon-arrows"></i>
            </div>
            <PostOfferFallOutChart
              historicalPerformanceData={historicalPerformanceData}
            />
          </Card>
        </div>
      )}
      {selectedCharts.includes("Post Offer Fall Out Reasons") && (
        <div key="Post Offer Fall Out Reasons">
          <Card className="cardHeader">
            <div className="CardTitle">
              <Title level={4}>
                Post Offer Fall Out Reasons
                <Button type="link">
                  <Tooltip
                    title="Distribution of reasons for post offer fall out"
                    color={K.TooltipColor}
                    placement="bottom"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                </Button>
              </Title>
              <i className="icon-arrows"></i>
            </div>
            <PieChart historicalPerformanceData={historicalPerformanceData} />
          </Card>
        </div>
      )}
      ,
      {selectedCharts.includes("TTM Chart") && (
        <div key="TTM Chart">
          <Card className="cardHeader">
            <div className="CardTitle">
              <Title level={4}>
                Time to Metrics by{" "}
                {historicalPerformanceData.type &&
                  titleCase(historicalPerformanceData.type)}
                <Button type="link">
                  <Tooltip
                    title="Average time to metrics of candidates"
                    color={K.TooltipColor}
                    placement="bottom"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                </Button>
              </Title>
              <i className="icon-arrows"></i>
            </div>
            <TTMChart historicalPerformanceData={historicalPerformanceData} />
          </Card>
        </div>
      )}
    </ResponsiveReactGridLayout>
  );
}
