import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import K from "utilities/constants";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";
import styles from "./recursiveComponent.module.scss";

const { Text } = Typography;
const { RecurringType } = K;

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const options = Array.from({ length: 28 }).map((_, i) => ({
  label: i + 1,
  value: i + 1,
}));

export default function ClientLevelRecursive({ fieldObj, currencyList }) {
  const shouldUpdate = (prevValues, currValues, index) =>
    prevValues[fieldObj.id][index].recurringType !==
    currValues[fieldObj.id][index]?.recurringType;

  const WeekComponent = ({ index }) => (
    <>
      <Form.Item
        name={[index, "selectedDays"]}
        rules={[
          {
            required: true,
            message: "Please select week day!",
          },
        ]}
      >
        <Checkbox.Group
          className={`recurringCheckboxGroup ${styles.recurringCheckboxGroup}`}
        >
          {days.map((checkbox, i) => (
            <Checkbox className="recurringCheckbox" key={i} value={i}>
              {checkbox}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  );

  const MonthComponent = ({ index }) => (
    <Row gutter={[12, 0]}>
      <Col xs={24} xl={16}>
        <Form.Item
          className={styles.formItemDate}
          label="On this date"
          name={[index, "repetitiveMonthDay"]}
          rules={[
            {
              required: true,
              message: "Please select month day!",
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            className={styles.recurringDateSelect}
            placeholder="Select"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            options={options}
          />
        </Form.Item>
      </Col>
    </Row>
  );

  const recurringComponent = {
    [RecurringType.Week]: WeekComponent,
    [RecurringType.Month]: MonthComponent,
  };

  return (
    <>
      <Form.List name={fieldObj.id} initialValue={[{ alias: "" }]}>
        {(fields, { add, remove }) => (
          <div className="mb-0 oneTimeComponentWrap">
            <div className="oneTimeHeader">
              <Text className="oneTimeTitle">{fieldObj.name}</Text>
              <Button size="small" className="addBtn" onClick={add}>
                <i className="icon-plus"></i>
              </Button>
            </div>
            <Divider className="headerDvider" />
            {fields.map((field, index) => (
              <div key={index} className="oneTimeData">
                {index > 0 && (
                  <Popconfirm
                    title="Are you sure ?"
                    onConfirm={() => {
                      remove(field.name);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className="text-right">
                      <Button danger icon={<i className="icon-delet"></i>} />
                    </div>
                  </Popconfirm>
                )}
                <Row gutter={[12, 0]}>
                  <Col xs={24}>
                    <Form.Item
                      label="Description"
                      name={[index, "alias"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter description!",
                        },
                        noTrailingSpaceAllowedRule(),
                      ]}
                    >
                      <Input className="w-100" placeholder="Description" />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    md={16}
                    lg={16}
                    className={styles.recursiveAmount}
                  >
                    <Form.Item
                      className={styles.formItemRepeat}
                      name={[index, "amount"]}
                      label="Amount"
                      rules={[
                        { required: true, message: "Please enter amount!" },
                      ]}
                    >
                      <InputNumber placeholder="Amount" min={0} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <Form.Item
                      label=" "
                      name={[index, "currency"]}
                      initialValue="USD"
                    >
                      <Select
                        showSearch={false}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Currency"
                        options={currencyList.map(({ code }) => ({
                          value: code,
                          label: code,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} md={12}>
                    <Form.Item
                      label="Referral Fee"
                      name={[index, "referralFee"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter referral fee!",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Refferal Fee" min={0} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Rehired Fee"
                      name={[index, "reHiredFee"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter rehired fee!",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Rehired Fee" min={0} />
                    </Form.Item>
                  </Col> */}
                  <Col
                    xs={24}
                    md={12}
                    lg={12}
                    className={styles.recursiveAmount}
                  >
                    <Form.Item
                      className={styles.formItemRepeat}
                      name={[index, "repetitiveQuantity"]}
                      label="Repeat every"
                      rules={[
                        {
                          required: true,
                          message: "Enter repeat every value!",
                        },
                        {
                          pattern: new RegExp("^[0-9]*$", "g"),
                          message: "Decimal values are not allowed!",
                        },
                      ]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    lg={12}
                    className={styles.recursiveAmount}
                  >
                    <Form.Item
                      label=" "
                      name={[index, "recurringType"]}
                      initialValue={RecurringType.Week}
                    >
                      <Select
                        showSearch
                        placeholder="Week"
                        optionFilterProp="label"
                        className={styles.repeatSelector}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={[
                          { value: RecurringType.Week, label: "Week" },
                          { value: RecurringType.Month, label: "Month" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  className="mb-0"
                  shouldUpdate={(prevValues, currValues) =>
                    shouldUpdate(prevValues, currValues, index)
                  }
                >
                  {({ getFieldValue }) => {
                    const Component =
                      recurringComponent[
                        getFieldValue(fieldObj.id)[index].recurringType
                      ];
                    return <Component index={index} />;
                  }}
                </Form.Item>

                <Form.Item
                  label="Ends On:"
                  name={[index, "isInfinite"]}
                  initialValue={1}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value={1}>Never</Radio>
                      <Radio value={0}>Repeat Occurrences</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return (
                      prevValues[fieldObj.id][index]?.isInfinite !==
                      curValues[fieldObj.id][index]?.isInfinite
                    );
                  }}
                >
                  {({ getFieldValue }) =>
                    !getFieldValue(fieldObj.id)[index].isInfinite && (
                      <Form.Item
                        label="Occurrences"
                        name={[index, "endAfterOccurances"]}
                        rules={[
                          {
                            required: true,
                            message: "Occurrences is required.",
                          },
                        ]}
                      >
                        <InputNumber placeholder="Occurrences" min={1} />
                      </Form.Item>
                    )
                  }
                </Form.Item>
                <Divider dashed className="oneTimeDvider" />
              </div>
            ))}
          </div>
        )}
      </Form.List>
    </>
  );
}
