import { Divider, Typography } from "antd";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import { Scrollbars } from "react-custom-scrollbars-2";
import { formatActivityTimestamp } from "utilities/dateUtility";
import styles from "./candidateActivities.module.scss";
import { sortObjectsArrayDescByKey } from "../../utilities/tableUtility";


const { Title, Paragraph } = Typography;

export const CandidateActivities = ({ data }) => {
  if (!data.length) {
    return <EmptyPlaceholder />
  }

  const sortedData = sortObjectsArrayDescByKey([...data], "createdAt")

  return (
    <div>
      {sortedData.map(({ message, createdAt, userName }, i) => (
        <div key={i}>
          <div className={styles.activityItem}>
            <Title className={styles.activityHeader} level={5}>{userName}</Title>
            <Paragraph className={styles.activityMessage}>{message}</Paragraph>
            <div className={styles.activityTimestamp}>
              <i className={`${styles.activityIcon} icon-appCalendar`}>
                    <span className={styles.dateInfo}>
                      {formatActivityTimestamp(createdAt)}
                    </span>
              </i>
            </div>
          </div>
          <Divider className={styles.divider} />
        </div>
      ))}
    </div>
  );
}
