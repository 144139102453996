import { Button, Typography, Col } from "antd";

import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  convertIntoDashUSFormat,
  formatDisplayTime,
} from "utilities/dateUtility";
import { isPermissionPresent } from "utilities/generalUtility";

const { Paragraph, Title } = Typography;

export default function UDFDisplayEditableDate({
  entityId,
  styles,
  index,
  item,
  dateString,
  setUdfEdit,
  candidateActivity,
}) {
  const showEditField = () => {
    setUdfEdit((prev) => ({ ...prev, [item.id]: true }));
  };
  const userRoles = useSelector(selectUser).roles;
  const value =
    item.fieldType.type === K.UDF.Fields[4].type
      ? formatDisplayTime(item.value)
      : item.value;
  return (
    // <div className="d-flex align-items-center">
    <>
      <Title level={5} className={styles.viewActivityLabel}>
        {`${item.label}:`}
      </Title>

      <Paragraph className={styles.viewActivityValue}>
        {value !== null && value !== "" ? value : "N/A"}

        {isPermissionPresent(
          K.Permissions.EditCandidateStausDates,
          userRoles,
        ) && (
          <Button
            disabled={candidateActivity.isConsiderable === 0}
            onClick={showEditField}
            type="link"
            icon={<i className="icon-edit"></i>}
          />
        )}
      </Paragraph>

      {(index + 1) % 2 === 0 && (
        <div
          style={{
            width: "100%",
            clear: "both",
            paddingBottom: 20,
          }}
        ></div>
      )}

      {/* {isPermissionPresent(
        K.Permissions.EditCandidateStausDates,
        userRoles,
      ) && (
        <Button
          onClick={showEditField}
          type="link"
          icon={<i className="icon-edit"></i>}
        />
      )} */}
    </>
    // </div>
  );
}
