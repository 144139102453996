import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import Candidate from "redux/models/candidate";
import { formatCommentTime } from "utilities/dateUtility";
import { Scrollbars } from "react-custom-scrollbars-2";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

export default function CandidateComments({
  styles,
  userData,
  candidateActivity,
}) {
  const [comments, setComments] = useState([]);
  const [userInput, setUserInput] = useState("");

  const onChange = (event) => {
    setUserInput(event.target.value);
  };

  const addCommentsOnCandidate = async () => {
    try {
      const res = await Candidate.addComment({
        userId: userData.id,
        candidateId: candidateActivity.id,
        comment: userInput,
      });
      setComments([...res]);
      setUserInput("");
      message.success("Comment added successfully!");
    } catch (err) {
      message.error("Failed to add comment!");
      console.error(err);
    }
  };
  const getComments = async () => {
    try {
      const res = await Candidate.getComments(candidateActivity.id);
      setComments(res);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getComments();
  }, [candidateActivity]);

  return (
    <>
      {!comments.length ? (
        <>
          <div className={styles.userCommentSkeleton}>
            <div className={styles.commentSkeletonWrap}>
              <Space className={styles.commentSkeleton}>
                <Skeleton.Avatar />
                <Skeleton.Input size="small" block />
                <Skeleton.Input size="small" block />
              </Space>
            </div>
            <div
              className={`${styles.commentSkeletonWrap} ${styles.secondComment}`}
            >
              <Space
                className={`${styles.commentSkeleton} ${styles.secondCommentSkeleton}`}
              >
                <Skeleton.Avatar />
                <Skeleton.Input size="small" block />
                <Skeleton.Input size="small" block />
              </Space>
            </div>
          </div>
          <Title level={5} className={styles.noComments}>
            No comments yet
          </Title>
        </>
      ) : (
        <>
          <Scrollbars style={{ height: 300 }}>
            {comments.map((item) => (
              <div key={item.id}>
                <div className={styles.clientCommentDetail}>
                  <div className={styles.clientInfo}>
                    <Avatar icon={<UserOutlined />} />
                    <Title level={5}>{item.recrutor.name}</Title>
                  </div>
                </div>
                <Paragraph className={styles.clientComment}>
                  {item.comment}
                </Paragraph>
                <span className={styles.commentDate}>
                  {formatCommentTime(item.createdAt)}
                </span>
              </div>
            ))}
          </Scrollbars>
        </>
      )}

      <Form.Item
        className={styles.commentSection}
        extra={
          <Button
            type="link"
            disabled={!userInput.length}
            className={styles.sendButton}
            onClick={addCommentsOnCandidate}
          >
            <i className={"icon-send " + styles.sendIcon}></i>
          </Button>
        }
      >
        <TextArea
          value={userInput}
          onChange={onChange}
          disabled={candidateActivity.isConsiderable === 0}
          className={styles.addComments}
          placeholder="Add Comments"
          autoSize={{ minRows: 3, maxRows: 4 }}
          showCount
          maxLength={500}
        />
      </Form.Item>
    </>
  );
}
