import { Form, Input } from "antd";
export default function UDFTextArea({
  title = "Title",
  isFormItem = true,
  value,
  className,
  placeholder = "",
  formItemName = "",
  required = true,
  isNumber = false,
  params,
}) {
  const { TextArea } = Input;
  return (
    <>
      {isFormItem ? (
        <Form.Item
          className={className}
          label={title}
          name={formItemName}
          initialValue={value}
          rules={required && [{ required: true, message: `Enter  ${title}` }]}
        >
          <TextArea {...params} placeholder={placeholder} />
        </Form.Item>
      ) : (
        <TextArea {...params} placeholder={placeholder} />
      )}
    </>
  );
}
