import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import { isPermissionPresentWithClientAssigned } from "utilities/generalUtility";

export default function RouteWithSubRoutes(route) {
  const userSlice = useSelector(selectUser);
  return (
    <Route
      path={route.path}
      render={(props) => {
        // Check authentication
        if (
          !route.authenticated ||
          (route.authenticated && User.isTokenAvailable())
        ) {
          if (
            ["/login", "/forgot-password", "/set-password"].includes(
              route.path,
            ) &&
            User.isTokenAvailable()
          )
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            );
          // Check permissions
          const hasPermission = isPermissionPresentWithClientAssigned(
            route.permission,
            userSlice.roles,
            route.isClientRequired,
            userSlice.clients.length > 0,
            userSlice.details?.isSuperAdmin,
          );
          if (hasPermission) {
            const component = (
              <route.component {...props} route={route}></route.component>
            );
            return route.layout ? (
              <route.layout>{component}</route.layout>
            ) : (
              component
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/unauthorized",
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}
