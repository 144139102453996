import { useEffect, useState } from "react";
import { Divider, PageHeader, Tabs } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Client from "redux/models/client";
import { selectClient } from "redux/slices/clientSlice";
import K from "utilities/constants";
import ClientBillingRules from "./clientBillingRules/clientBillingRules";
import "./details.scss";
import Invoices from "./invoices/invoices";
import JobBillingRules from "./jobBillingRules/jobBillingRules";
import Jobs from "./jobs/jobs";
import Overview from "./Overview/overview";
import detailsConfig from "config/detailConfig";
import { isPermissionPresent } from "utilities/generalUtility";
import { selectUser } from "redux/slices/userSlice";

import ClientLookupTableWidget from "./clientLookupTables/clientLookupTableWidget";
import HiringManger from "./hiringManager/hiringManager";
import UserDefinedFields from "./userDefinedFields/userDefinedFields";
import UDF from "redux/models/udf";
import { setClientUdf } from "redux/slices/udfSlice";
import Attachments from "./attachments/attachments";
import Classes from "./classes/class";

export default function ClientDetails() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const userSlice = useSelector(selectUser);

  const clientSlice = useSelector(selectClient);
  const [shouldLoad, setShouldLoad] = useState(false);
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const currentKey = params.get("tab");
    if (!(currentKey in detailsConfig)) {
      history.push({ search: "?tab=overview" });
    }

    (async () => {
      try {
        await dispatch(Client.getById(id));
        await getClientEditUdfs();
        setShouldLoad(true);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const getClientEditUdfs = async () => {
    const clientUdfs = await UDF.getEditUdfFields({
      clientId: id,
      entityId: K.UDF.Entities.Client.id,
      entityKey: K.UDF.Entities.Client.key,
      entityKeyId: id,
      isVisible: 1,
    });
    dispatch(setClientUdf(clientUdfs));
  };

  const tabComponents = {
    overview: <Overview clientData={clientSlice[K.Redux.SelectedClient]} />,
    jobs: <Jobs />,

    "client-billing-rules": <ClientBillingRules />,
    "client-invoices": <Invoices type={K.Invoice.Type.Client} />,
    "job-invoices": <Invoices type={K.Invoice.Type.Job} />,
    "job-billing-rules": <JobBillingRules />,
    lookup: <ClientLookupTableWidget clientId={id} />,
    "hiring-manager": <HiringManger />,
    "user-defineds": <UserDefinedFields />,
    attachments: <Attachments />,
    class: <Classes />,
  };

  const onChange = (key) => {
    params.set("tab", key);
    params.delete("table");
    history.push({ search: params.toString() });
  };

  return (
    <>
      <PageHeader
        ghost={false}
        className={LayoutCss.appPageHeader}
        onBack={() => history.replace("/clients")}
        title="Client Details"
        footer={
          <>
            <Divider className={LayoutCss.appPageHeaderDivider} />
            <Tabs
              activeKey={params.get("tab")}
              className={LayoutCss.appPageHeaderTabs}
              items={Object.keys(detailsConfig)
                .map((item) => ({
                  key: item,
                  label: detailsConfig[item].name,
                  permission: detailsConfig[item].permission,
                }))
                .filter(({ permission }) =>
                  isPermissionPresent(permission, userSlice.roles),
                )}
              onChange={onChange}
            />
          </>
        }
      />

      {shouldLoad &&
        isPermissionPresent(
          detailsConfig[params.get("tab")].permission,
          userSlice.roles,
        ) &&
        tabComponents[params.get("tab")]}
    </>
  );
}
