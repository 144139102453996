import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import UserSelectOption from "common/components/userSelect/userSelect";

import { useEffect, useState } from "react";
import Client from "redux/models/client";
import User from "redux/models/user";
// import LookupTable from "redux/models/lookupTable";
import { useParams } from "react-router-dom";

import K from "utilities/constants";
import {
  customUserHandleSearch,
  noTrailingSpaceAllowedRule,
} from "utilities/generalUtility";
import Class from "redux/models/class";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";

const { TextArea } = Input;
const { Option } = Select;
const { Lookup } = K.Network.URL;

export default function CreateClass({
  form,
  clientId,
  setModalState,
  editData = null,
  getClientClassList,
}) {
  const { id } = useParams();
  const lookupTables = useSelector(selectConfigration).lookup;

  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    hiringManagers: [],
    internalManagers: [],
    editData: {},
  });
  const editClass = async (values) => {
    setModalState((prev) => {
      return { ...prev, isLoadingClassModal: true };
    });

    const body = {
      ...values,
      classSize: editData.classSize,
      id: editData.id,
      clientId: clientId,
    };
    try {
      await Client.editClientClass(body);
      getClientClassList();

      setModalState((prev) => {
        return {
          ...prev,
          isLoadingClassModal: false,
          isCreateClassModalVisible: false,
        };
      });
      message.success("Class Updated Successfully");
    } catch (err) {
      console.log(err);
      setModalState((prev) => {
        return {
          ...prev,
          isLoadingClassModal: false,
          isCreateClassModalVisible: false,
        };
      });
      console.error(err);
    }
  };

  const addClass = async (values) => {
    setModalState((prev) => {
      return { ...prev, isLoadingClassModal: true };
    });
    values.clientId = +id;

    try {
      await Client.addClientClass(values);

      setModalState((prev) => {
        return {
          ...prev,
          isLoadingClassModal: false,
          isCreateClassModalVisible: false,
        };
      });

      message.success("Class Created Successfully");
      await getClientClassList();
    } catch (err) {
      setModalState((prev) => {
        return { ...prev, isLoadingClassModal: false };
      });
      console.error(err);
    }
  };

  const onFinish = async (values) => {
    if (editData) {
      editClass(values);
    } else {
      addClass(values);
    }
  };

  const getClientHiringManager = async () => {
    try {
      //*id = clientID
      const body = { clientIds: [id] };

      const hiringManagerData = await User.getClientHiringManager(body, false);

      setPageData((prev) => ({
        ...prev,
        hiringManagers: [...hiringManagerData],
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const getClientClassManager = async () => {
    try {
      //*id = clientID
      const body = { clientIds: [+id] };

      const managerData = await User.getClientClassManager(body, false);

      setPageData((prev) => ({
        ...prev,
        internalManagers: [...managerData],
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const getClassDetails = async () => {
    try {
      console.log("Inside");
      const res = await Class.getClassDetailById(editData.id);
      setPageData((prev) => ({
        ...prev,
        editData: {
          ...res,
        },
      }));
      populateData(res);
    } catch (err) {
      console.error(err);
    }
  };

  const populateData = async (data) => {
    try {
      form.setFieldsValue({
        title: data.title,
        dateClassReceived: moment(data.dateClassReceived),
        classStartDate: moment(data.classStartDate),
        regionalDirectorId: data.regionalDirectorId,
        humanResourceId: data.humanResourceId,
        internalManagerId: data.internalManagerId,
        classSize: data.totalOpenings,
        classStatusId: data.classStatusId,
        note: data.notes,
      });
    } catch (err) {
      console.error(err);
    }
  };
  const getClassStatus = async () => {
    try {
      await dispatch(
        LookupTable.getData(K.Redux.ClassStatus, Lookup.ClassStatus),
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        getClientHiringManager(),
        getClientClassManager(),
        getClassStatus(),
      ]);
    })();
  }, []);

  useEffect(() => {
    if (editData) {
      populateData(editData);
    }
  }, [editData]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        isWorkFromHome: false,
      }}
    >
      <Row gutter={28}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Please enter class title!" },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Enter Class Title" className="modalInput" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Date Class Received"
            name="dateClassReceived"
            rules={[
              {
                required: true,
                message: "Please select date class received",
              },
            ]}
          >
            <DatePicker
              // getPopupContainer={(triggerNode) => triggerNode.parentNode}
              format={K.DateFormat.DashUSFormat}
              // disabledDate={disableFutureDate}
              className="datePickerModal"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Class Start Date"
            name="classStartDate"
            rules={[
              {
                required: true,
                message: "Please select class start date",
              },
            ]}
          >
            <DatePicker
              // getPopupContainer={(triggerNode) => triggerNode.parentNode}
              format={K.DateFormat.DashUSFormat}
              // disabledDate={disableFutureDate}
              className="datePickerModal"
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12}>
          <Form.Item
            className="formItems"
            name="regionalDirectorId"
            label={"Regional Director"}
          >
            <Select
              showSearch
              allowClear
              optionLabelProp="title"
              optionFilterProp="children"
              placeholder="Manager"
              filterOption={customUserHandleSearch}
              // options={pageData.hiringManagers.map((item) => ({
              //   value: item.id,
              //   label: item.name,
              // }))}
            >
              {pageData.hiringManagers.map(({ id, name, email, clients }) => (
                <Option title={name} key={id} value={id}>
                  <UserSelectOption
                    key={id}
                    userEmail={email}
                    clients={clients}
                  >
                    {name}
                  </UserSelectOption>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12}>
          <Form.Item
            className="formItems"
            name="humanResourceId"
            label={"Human Resource"}
          >
            <Select
              showSearch
              allowClear
              optionLabelProp="title"
              optionFilterProp="children"
              placeholder="Manager"
              filterOption={customUserHandleSearch}
              // options={pageData.hiringManagers.map((item) => ({
              //   value: item.id,
              //   label: item.name,
              // }))}
            >
              {pageData.hiringManagers.map(({ id, name, email, clients }) => (
                <Option title={name} key={id} value={id}>
                  <UserSelectOption
                    key={id}
                    userEmail={email}
                    clients={clients}
                  >
                    {name}
                  </UserSelectOption>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12}>
          <Form.Item
            className="formItems"
            name="internalManagerId"
            label={"Internal Manager"}
          >
            <Select
              showSearch
              allowClear
              optionLabelProp="title"
              optionFilterProp="children"
              placeholder="Manager"
              filterOption={customUserHandleSearch}
              // options={pageData.hiringManagers.map((item) => ({
              //   value: item.id,
              //   label: item.name,
              // }))}
            >
              {pageData.internalManagers.map(({ id, name, email, clients }) => (
                <Option title={name} key={id} value={id}>
                  <UserSelectOption
                    key={id}
                    userEmail={email}
                    clients={clients}
                  >
                    {name}
                  </UserSelectOption>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {!editData && (
          <Col xs={24} sm={12}>
            <Form.Item
              label="Class Size"
              name="classSize"
              rules={[
                {
                  required: true,
                  message: "Please enter class size!",
                },
              ]}
            >
              <InputNumber
                min={1}
                disabled={editData}
                className="modalInput w-100"
                placeholder="Enter Openings"
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={12} md={12}>
          <Form.Item
            label="Class Status"
            name="classStatusId"
            className="formItems"
            rules={[
              {
                required: true,
                message: "Please select class status!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Class Status"
              optionFilterProp="label"
              options={lookupTables?.classStatus.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label="Note" name="note">
            <TextArea />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
