import { useEffect, useState } from "react";
import { CandidateActivities } from "../candidates/candidateActivities";
import CandidateActivity from "../../redux/models/candidateActivity";

export const ActivityTab = ({ candidateId }) => {
  const [activities, setActivities] = useState([]);

  const fetchActivities = async () => {
    try {
      const { data } = await CandidateActivity.getAll(candidateId);
      setActivities(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect (() => {
    fetchActivities();
  }, [])

  return (
    <CandidateActivities data={activities}/>
  );
};
