import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, TimePicker, message } from "antd";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Candidate from "redux/models/candidate";
import Job from "redux/models/job";
import Status from "redux/models/status";
import { selectCandidates, setShouldUpdate } from "redux/slices/candidateSlice";
import K from "utilities/constants";
import {
  convertDateStringToMoment,
  convertTimeStringToMoment,
} from "utilities/dateUtility";

export default function EditDate({
  dateKey,
  dateString,
  setEditDates,
  isTime = false,
  isOtherItem = false, //This check creates and difference between the date field and other components
  itemType = K.EditComponentType.boolean,
  candidateJobEntryId,
  setCandidateActivity,
  jobId,
}) {
  const dateRef = useRef(null); //this reference is used for both component date component and other component;

  const hideEditField = () => {
    setEditDates((prev) => ({ ...prev, [dateKey]: false }));
  };

  const onChange = (dateObj) => {
    dateRef.current = dateObj
      ? isTime
        ? dateObj.format(K.TimeFomat.Response)
        : dateObj.format(K.DateFormat.Response)
      : null;
  };

  const checkboxOnChange = (obj) => {
    dateRef.current = obj.target.checked;
  };

  const changeStatusDate = async () => {
    const payload = { candidateJobEntryId, [dateKey]: dateRef.current };
    try {
      const res = await Status.changeCandidateStatusDate(payload);
      setCandidateActivity((prev) => ({
        ...res,
        candidateJobRecruiters: prev.candidateJobRecruiters,
      }));
      //fetchCandidateDetails(candidateJobEntryId);
      Job.syncCandidateTTM(+jobId);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {/* <div className="d-flex align-items-center"> */}
      {isOtherItem ? (
        <>
          {itemType === K.EditComponentType.CheckBox && (
            <div>
              <Checkbox
                value={dateString}
                // style={{ lineHeight: "32px" }}
                onChange={checkboxOnChange}
              ></Checkbox>
            </div>
          )}
        </>
      ) : !isTime ? (
        <DatePicker
          onChange={onChange}
          format={K.DateFormat.DashUSFormat}
          defaultValue={convertDateStringToMoment(dateString)}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
      ) : (
        <TimePicker
          use12Hours
          onChange={onChange}
          format={K.TimeFomat.Display}
          defaultValue={convertTimeStringToMoment(dateString)}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
      )}
      <Button
        type="link"
        onClick={hideEditField}
        icon={<CloseCircleOutlined className="closeIcon" />}
      />
      <Button
        type="link"
        onClick={changeStatusDate}
        icon={<CheckCircleOutlined className="checkIcon" />}
      />

      {/* </div> */}
    </>
  );
}
