import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectSearchAndFilter,
  setFilter,
  setSearchShouldLoad,
} from "redux/slices/search&FilterSlice";
import { convertDateStringToMoment } from "utilities/dateUtility";

const useSearchAndFilter = (initialValues, prefix = "", getKey) => {
  const dispatch = useDispatch();

  const filterSlice = useSelector(selectSearchAndFilter);

  const setter = (newValues, prefix = "") => {
    let updatedData = {};
    Object.keys(newValues).forEach((key) => {
      let valueToUpdate = newValues[key];

      // Check if the value is an array
      if (Array.isArray(valueToUpdate)) {
        // Handle arrays of Moment.js objects
        console.log("Value to Update", valueToUpdate);
        valueToUpdate = valueToUpdate.map((momentObj) =>
          moment.isMoment(momentObj)
            ? momentObj.format("YYYY-MM-DD")
            : momentObj,
        );
      } else if (moment.isMoment(valueToUpdate)) {
        // Handle single Moment.js object
        valueToUpdate = valueToUpdate.format("YYYY-MM-DD");
      }
      console.log("Value", valueToUpdate);

      updatedData = { ...updatedData, [`${prefix}_${key}`]: valueToUpdate };
    });

    dispatch(setFilter(updatedData)); // Dispatch the action with updated data
  };

  const getter = (getKey) => {
    const data = moment.isMoment(filterSlice[getKey])
      ? convertDateStringToMoment(filterSlice[getKey])
      : filterSlice.filter[getKey];

    return data;
  };

  return [setter, getter];
};

export default useSearchAndFilter;
