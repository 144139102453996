import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Switch,
  Typography,
} from "antd";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import BillingSchedule from "redux/models/billingSchedule";
import K from "utilities/constants";
import {
  convertDateStringToMoment,
  epochTimeToUTCDashUSFormat,
} from "utilities/dateUtility";
import moment from "moment";
import { displayDollar } from "utilities/tableUtility";

const { Title } = Typography;

export default function ScheduledBilling({ candidateActivity, styles }) {
  const [form] = Form.useForm();
  const [rowData, setRowData] = useState([]);
  const [editData, setEditData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsModalVisible] = useState(false);

  const afterModalClose = () => {
    form.resetFields();
    setEditData({});
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onOk = () => {
    form.submit();
  };

  const getScheduledbilling = async () => {
    try {
      const res = await BillingSchedule.getScheduledBillingByCandidateId(
        candidateActivity.id,
      );
      const updateResponse = res.map((item) => {
        return {
          ...item,
          isActive: item.isActive === 0 ? "False" : "True",
          isCommissionable: item.isCommissionable === 0 ? "False" : "True",
        };
      });
      setRowData(updateResponse);
    } catch (err) {
      console.error(err);
    }
  };

  const editRecord = (data) => {
    setIsModalVisible(true);
    setEditData(data);
  };

  const onFinish = async (item) => {
    setIsLoading(true);
    const body = {
      dueDate: convertDateStringToMoment(item.dueDate).format(
        K.DateFormat.Response,
      ),
      amount: item.amount,
      isActive: item.isActive === false ? 0 : 1,
    };
    try {
      const res = await BillingSchedule.updateJobScheduledbilling(
        body,
        editData.id,
      );
      const updateResponse = rowData.map((item) =>
        item.id === res.id
          ? {
              ...res,
              isCommissionable: res.isCommissionable === 0 ? "False" : "True",
              isActive: res.isActive === 0 ? "False" : "True",
            }
          : item,
      );
      setIsLoading(false);
      setIsModalVisible(false);
      setRowData(updateResponse);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (editData) {
      console.log("edit data", editData);
      form.setFieldsValue({
        amount: editData?.amount,
        dueDate: moment.unix(editData?.dueDate),
        isActive: editData?.isActive === "False" ? false : true,
      });
    }
  }, [form, editData]);

  useEffect(() => {
    getScheduledbilling();
  }, [candidateActivity]);

  return (
    <>
      <Scrollbars style={{ height: 400 }}>
        {rowData.length ? (
          rowData.map((item, index) => (
            <div className={styles.invoiceWrap} key={index}>
              <Button
                className={styles.editBtn}
                type="link"
                onClick={() => editRecord(item)}
                icon={<i className="icon-edit"></i>}
              />
              <div className={styles.invoiceDetail}>
                <div className={styles.invoiceDate}>
                  <Title level={5}>
                    Due Date:{" "}
                    <span>{epochTimeToUTCDashUSFormat(item.dueDate)} </span>
                  </Title>
                  <Title level={5}>
                    Currency: <span>{item.currency}</span>
                  </Title>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title title={item.billingTypeName} level={5}>
                    Billing Type: <span>{item.billingTypeName}</span>
                  </Title>
                  <Title level={5}>
                    Amount: <span>{displayDollar(item.amount.toFixed(2))}</span>
                  </Title>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title title={item.candidateName} level={5}>
                    Candidate Name: <span>{item.candidateName}</span>
                  </Title>
                  <Title title={item.jobTitle} level={5}>
                    Job Title: <span> {item.jobTitle}</span>
                  </Title>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title
                    title={item.isCommissionable === "False" ? "No" : "Yes"}
                    level={5}
                  >
                    Commissionable?{" "}
                    <span>
                      {" "}
                      {item.isCommissionable === "False" ? "No" : "Yes"}
                    </span>
                  </Title>
                  <Title
                    title={item.isActive === "False" ? "No" : "Yes"}
                    level={5}
                  >
                    Active?{" "}
                    <span>{item.isActive === "False" ? "No" : "Yes"} </span>
                  </Title>
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyPlaceholder />
        )}
      </Scrollbars>

      <Modal
        forceRender
        centered
        open={isVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okText="Update"
        className="s2-theme-style"
        title="Edit Scheduled Billing"
        wrapClassName="vertical-center-modal"
        okButtonProps={{ loading: isLoading }}
        onOk={onOk}
        onCancel={handleCancel}
        afterClose={afterModalClose}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            rules={[{ required: true, message: "Due Date is required." }]}
          >
            <DatePicker
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              format={K.DateFormat.DashUSFormat}
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Amount is required." }]}
          >
            <Input placeholder="Enter amount" />
          </Form.Item>
          <Form.Item label="Active?" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
