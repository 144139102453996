import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { CreateClientDetails } from "features/clients/create/createClientDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Client from "redux/models/client";
import K from "utilities/constants";
import ClientOverview from "./clientOverview";
import { selectUdf, setClientUdf } from "redux/slices/udfSlice";
import UDF from "redux/models/udf";

export default function Overview({ clientData }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [createClientForm] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(0);
  const udfSlice = useSelector(selectUdf);

  const getClientEditUdfs = async () => {
    const clientUdfs = await UDF.getEditUdfFields({
      clientId: id,
      entityId: K.UDF.Entities.Client.id,
      entityKey: K.UDF.Entities.Client.key,
      entityKeyId: id,
      isVisible: 1,
    });
    dispatch(setClientUdf(clientUdfs));
  };

  const editClientInformation = async (values) => {
    const { originalContractDate, recruitmentStartedDate, ...details } = values;

    const body = {
      ...details,
      departments: [],
      originalContractDate: originalContractDate.format(K.DateFormat.Response),
      recruitmentStartedDate:
        recruitmentStartedDate?.format(K.DateFormat.Response) ?? null,
      clientId: id,
    };
    try {
      await dispatch(Client.editClientInformation(body));
      await getClientEditUdfs();

      setCurrentPage(0);
      message.success("Client Updated Successfully.");
    } catch (error) {
      message.error("Failed to Update");
      console.error(error);
    }
  };

  useEffect(() => {
    getClientEditUdfs();
  }, [currentPage]);
  const overviewComponents = {
    0: (
      <ClientOverview
        clientData={clientData}
        setCurrentPage={setCurrentPage}
        udfs={udfSlice[K.Redux.ClientUdf]}
      />
    ),
    1: (
      <CreateClientDetails
        form={createClientForm}
        setCurrentPage={setCurrentPage}
        editData={clientData}
        editClientInformation={editClientInformation}
        udfs={udfSlice[K.Redux.ClientUdf]}
      />
    ),
  };

  return <>{overviewComponents[currentPage]}</>;
}
