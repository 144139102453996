import { Divider, PageHeader, Tabs } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ClientScheduledList from "./clientScheduledList";
import JobScheduledList from "./jobScheduledList";

const scheduleList = {
  clientScheduled: { name: "Client Scheduled" },
  jobScheduled: { name: "Job Scheduled" },
};

export default function Schedule() {
  const history = useHistory();
  const [shouldLoad, setShouldLoad] = useState(false);
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const currentKey = params.get("tab");
    if (!(currentKey in scheduleList)) {
      history.push({ search: "?tab=clientScheduled" });
      setShouldLoad(true);
    }
    setShouldLoad(true);
  }, [history.location.search]);

  const tabHandler = (key) => {
    params.set("tab", key);
    history.push({ search: params.toString() });
  };

  const tabComponents = {
    jobScheduled: <JobScheduledList params={params} />,
    clientScheduled: <ClientScheduledList params={params} />,
  };

  return (
    <>
      <PageHeader
        className={LayoutCss.appPageHeader}
        ghost={false}
        title="Scheduled Billings"
        footer={
          <>
            <Divider className={LayoutCss.appPageHeaderDivider} />
            <Tabs
              activeKey={params.get("tab")}
              className={LayoutCss.appPageHeaderTabs}
              items={Object.keys(scheduleList).map((item) => ({
                key: item,
                label: scheduleList[item].name,
              }))}
              onChange={tabHandler}
            />
          </>
        }
      />

      {shouldLoad && tabComponents[params.get("tab")]}
    </>
  );
}
