import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import {
  getIsVisibleLookups,
  noTrailingSpaceAllowedRule,
} from "utilities/generalUtility";
import styles from "../../create/jobBilling.module.scss";

export default function EditTierComponent({
  editForm,
  tierData,
  onFinishEdit,
  clientId,
}) {
  const configrationSlice = useSelector(selectConfigration);
  const [pageState, setPageState] = useState({
    isDisabled: true,
    titleListing: [],
    initialLength: tierData.jobTierTitle.length,
  });

  const getTitleListing = (disabled = true) => {
    let list = editForm
      .getFieldValue("titleAssociated")
      ?.map((item) => (item ? item.jobTitleId : [undefined]));

    if (!disabled) list.pop();

    return getIsVisibleLookups(
      configrationSlice[K.Redux.Lookup][K.Redux.JobTitle].filter(
        ({ id }) => !list.includes(id),
      ),
    );
  };

  const setTitleListing = (index) => {
    const titles = editForm.getFieldValue("titleAssociated");
    setPageState((prev) => ({
      ...prev,
      titleListing: getTitleListing(),
      initialLength: prev.initialLength + 1,
      isDisabled: !titles[index + 1],
    }));
  };

  const toggleDisabled = (index) => {
    const titles = editForm.getFieldValue("titleAssociated");
    setPageState((prev) => ({ ...prev, isDisabled: !titles[index] }));
  };

  useEffect(() => {
    if (tierData) {
      const tierTitleAssociated = tierData?.jobTierTitle.map((item) => ({
        jobTitleId: item.jobTitlesId,
        tierName: item.jobTitle.name,
      }));
      editForm.setFieldsValue({
        tierName: tierData.name,
        titleAssociated: tierTitleAssociated.length
          ? [...tierTitleAssociated, undefined]
          : [undefined],
      });
      setPageState((prev) => ({ ...prev, titleListing: getTitleListing() }));
    }
  }, [editForm, tierData]);

  return (
    <Form layout="vertical" form={editForm} onFinish={onFinishEdit}>
      <Form.Item
        label="Tier Name"
        name="tierName"
        rules={[
          { required: true, message: "Tier name is required" },
          { min: 3, message: "Name must be at least 3 characters" },
          {
            max: 150,
            message: "Name cannot be longer than 150 characters",
          },
          noTrailingSpaceAllowedRule(),
        ]}
      >
        <Input placeholder="Tier Name" />
      </Form.Item>
      <p className="form-label">Titles Associated </p>
      <Form.List name="titleAssociated" initialValue={[undefined]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row align="middle" key={index} className="mb-4">
                <Col flex="auto">
                  <Form.Item
                    className="mb-0 add-title-plus-btn"
                    name={[index, "jobTitleId"]}
                    rules={
                      index > 0
                        ? []
                        : [{ required: true, message: "Select titles" }]
                    }
                  >
                    <Select
                      showSearch
                      allowClear
                      disabled={index !== fields.length - 1}
                      placeholder="Select"
                      optionFilterProp="label"
                      onChange={() => toggleDisabled(index)}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      options={
                        pageState.initialLength - 1 < index
                          ? pageState.titleListing
                              .filter(
                                (lookup) => +lookup.clientId === +clientId,
                              )
                              .map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))
                          : configrationSlice[K.Redux.Lookup][K.Redux.JobTitle]
                              .filter(
                                (lookup) => +lookup.clientId === +clientId,
                              )
                              .map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col flex="25px" className="reasonLabel">
                  {index !== fields.length - 1 ? (
                    <Button
                      type="link"
                      icon={
                        <i className={`icon-delet ${styles.deletIcon}`}></i>
                      }
                      onClick={() => {
                        remove(field.name);
                        setPageState((prev) => ({
                          ...prev,
                          initialLength: prev.initialLength - 1,
                          titleListing: getTitleListing(pageState.isDisabled),
                        }));
                      }}
                    />
                  ) : (
                    <Button
                      disabled={pageState.isDisabled}
                      className="plus-icon"
                      onClick={() => {
                        add();
                        setTitleListing(index);
                      }}
                      icon={<PlusOutlined />}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  );
}
