import { Card, Checkbox, Divider, Form, Input, Modal, Typography } from "antd";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import Candidate from "redux/models/candidate";
import {
  convertIntoDashUSFormat,
  convertIntoUTCDashUSFormat,
} from "utilities/dateUtility";
import { displayDollar } from "utilities/tableUtility";
import modalStyle from "./billingListModal.module.scss";

const { Title } = Typography;

export default function BillingListModal({
  candidateActivity,
  billingListModal,
  setBillingListModal,
  viewActivityForm,
  styles,
}) {
  const handleCancel = () => {
    setBillingListModal((prev) => ({
      ...prev,
      showModal: false,
    }));
  };

  const getCandidateBillingList = async () => {
    setBillingListModal((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      const res = await Candidate.getCandidateBilling(
        candidateActivity.id,
        false,
      );

      setBillingListModal((prev) => ({
        ...prev,
        billingList: res,
        isLoading: false,
      }));
    } catch (err) {
      console.error(err);
      setBillingListModal((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handleSubmit = () => {
    viewActivityForm.submit();
  };

  useEffect(() => {
    if (billingListModal.showModal) getCandidateBillingList();
  }, [billingListModal.showModal]);

  return (
    <Modal
      open={billingListModal.showModal}
      centered
      okText="Submit"
      title={"Candidate Issue Credits"}
      className="s2-theme-style modal-overflow-auto"
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      {billingListModal.isLoading ? (
        <Card loading={true} />
      ) : (
        <div className={styles.issueCredits + " issue-credit-head"}>
          <Form.Item
            name="isCreditBilling"
            className={styles.jobFieldLabel + " reason-field"}
            rules={[
              { required: true, message: "Enter reason to issue credit." },
            ]}
            initialValue={true}
            valuePropName="checked"
          >
            <Checkbox>Submit Credit Billing</Checkbox>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={true}>
            {({ getFieldValue }) =>
              getFieldValue("isCreditBilling") && (
                <Form.Item
                  name="issueCreditReason"
                  className={styles.jobFieldLabel + " reason-field"}
                  label={"Reason for credit"}
                  rules={[
                    {
                      required: true,
                      message: "Reason for credit is required.",
                    },
                  ]}
                  initialValue={"Terminated Within Guarantee"}
                >
                  <Input
                    size="large"
                    placeholder={"Enter reason for credit"}
                    className={styles.jobInputFields}
                  />
                </Form.Item>
              )
            }
          </Form.Item>
          <Divider />
          <Title level={5}>Billings</Title>
          <Scrollbars style={{ height: 400 }}>
            {billingListModal.billingList.length ? (
              billingListModal.billingList.map((item) => (
                <div className={modalStyle.invoiceWrap}>
                  <div className={modalStyle.invoiceDetail + " mb-10"}>
                    <div className={modalStyle.invoiceDate}>
                      <Title level={5}>
                        Created at:{" "}
                        <span>
                          {item.isManual
                            ? convertIntoDashUSFormat(item.createdAt, false)
                            : convertIntoUTCDashUSFormat(item.createdAt, false)}
                        </span>
                      </Title>
                      <Title level={5} className={modalStyle.invoiceId}>
                        ID: <span>{item.id}</span>
                      </Title>
                    </div>
                    <Divider className={modalStyle.invoiceDivider} />
                    <div className={modalStyle.invoiceDate}>
                      <Title level={5}>
                        Billing Type: <span>{item.billingTypeName}</span>
                      </Title>
                      <Title level={5}>
                        Amount: {displayDollar(item.dublicateAmount)}
                      </Title>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <EmptyPlaceholder />
            )}
          </Scrollbars>
        </div>
      )}
    </Modal>
  );
}
