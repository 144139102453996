import { AgGridReact } from "ag-grid-react";
import { message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import User from "redux/models/user";

import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { filterColumnListing } from "utilities/tableUtility";

export default function LookupTable({
  rowData,
  gridRef,
  columnDefs,
  setColumnDefs,
  actionColumnRenderer,
  setSorting,
  actionRef,
  isVisibleTooltipRender,
  isVisibleRender,
  userNameRender,
  tooltipUserNameRender,
  updatedAtRender,
  tooltipUpdatedAtRender,
  getSearch,
}) {
  const userDetails = useSelector(selectUser).details;

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.LookupVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.LookupVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions")
          return { ...item, cellRenderer: actionColumnRenderer };
        else if (item.field === "isVisible")
          return {
            ...item,
            cellRenderer: isVisibleRender,
            tooltipFieldComponent: isVisibleTooltipRender,
          };
        else if (item.field === "isVisible")
          return {
            ...item,
            cellRenderer: isVisibleRender,
            tooltipFieldComponent: isVisibleTooltipRender,
          };
        else if (item.field === "userName")
          return {
            ...item,
            cellRenderer: userNameRender,
            tooltipComponent: tooltipUserNameRender,
          };
        else if (item.field === "updatedAt")
          return {
            ...item,
            cellRenderer: updatedAtRender,
            tooltipComponent: tooltipUpdatedAtRender,
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getColumnsConfigrations();
  }, []);

  return (
    <div
      className="ag-theme-alpine s2-theme-style"
      style={{
        height: 735,
      }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={K.AgGridTable.DefaultColDef}
        ref={gridRef}
        onColumnVisible={onColumnVisible}
        onColumnPinned={onColumnVisible}
        onSortChanged={(e) => {
          if (e.source === "uiColumnSorted") {
            setSorting((prev) => {
              return { ...prev, isSortChange: true };
            });
            actionRef.current = { isEditable: true };
          }
        }}
        onRowDragEnd={(e) => {
          setSorting((prev) => {
            return { ...prev, isSortChange: true };
          });
          actionRef.current = { isEditable: true };
        }}
        // {...K.AgGridTable.DefaultProps}
        rowDragManaged={true}
        animateRows={true}
        quickFilterText={getSearch}
      />
    </div>
  );
}
