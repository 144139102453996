import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  camelCaseKeysRecursively,
  camelCaseKeys,
  downloadFile,
} from "utilities/generalUtility";

export default class Class {
  static async getClassDetailById(classId) {
    const res = await NetworkCall.fetch(Request.getClassDetailById(classId));
    return camelCaseKeysRecursively(res.data);
  }

  static async addClientClass(clientId) {
    const res = await NetworkCall.fetch(Request.addClientClass(clientId));
    return camelCaseKeysRecursively(res.data);
  }

  static async getClientClass(clientId) {
    const res = await NetworkCall.fetch(Request.getClientClassList(clientId));
    return camelCaseKeysRecursively(res.data);
  }

  static async updateVacancies(jobId, openings) {
    await NetworkCall.fetch(Request.updateJobVacancies(jobId, openings));
  }

  static async getSettings(classId) {
    const res = await NetworkCall.fetch(Request.getClassSettings(classId));
    return camelCaseKeysRecursively(res.data);
  }

  static async updateSettings(values) {
    const res = await NetworkCall.fetch(Request.updateClassSettings(values));
    return camelCaseKeysRecursively(res.data);
  }

  static async cancelById(values) {
    await NetworkCall.fetch(Request.cancelJobById(values), false);
  }

  static async CustomCancelBillings(values) {
    await NetworkCall.fetch(Request.CustomCancelBilling(values), false);
  }

  static async getByClientIds(ids, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getJobsByClientIds(ids),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }
  static async getOnlyJobsByClientIds(ids, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getOnlyJobsByClientIds(ids),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getStatistics(id) {
    const res = await NetworkCall.fetch(Request.getClassStatistics(id));
    return camelCaseKeys(res.data);
  }

  static async exportJobDetail(payload) {
    const res = await NetworkCall.fetch(Request.exportJobDetail(payload));
    downloadFile(res, "job_data_export");
  }

  static async exportJobCandidates(payload) {
    const res = await NetworkCall.fetch(Request.exportJobCandidates(payload));
    downloadFile(res, "candidate_data_export");
  }

  static async updateClassStatus(body) {
    await NetworkCall.fetch(Request.updateClassStatusById(body));
  }
  static async deleteJob(jobId) {
    const res = await NetworkCall.fetch(Request.deleteJob(jobId));
    return res;
  }

  static async getSubStatusBreakdown(classId) {
    const res = await NetworkCall.fetch(
      Request.getClassSubStatusBreakdown(classId),
    );
    return camelCaseKeysRecursively(res.data);
  }
}
