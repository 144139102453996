import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Input, Card, Button, Tabs, Form, Spin } from "antd";

import { useHistory, useParams } from "react-router-dom";
import LayoutCss from "layout/layout.module.scss";
import "./jobBillingList.scss";
import Client from "redux/models/client";
import K from "utilities/constants";
import { onFilterTextBoxChanged } from "utilities/tableUtility";
import VBDRuleDetail from "./tierDiscount/vbdRule";
import moment from "moment";

const { Search } = Input;

const billingRuleTabs = {
  Tiers: { name: "Tiers", label: "Tiers" },
  VBD: { name: "Discounts", label: "Discounts" },
};

export default function JobBillingList({ setCurrentPage, tierId }) {
  const gridRef = useRef();
  const { id } = useParams();
  const history = useHistory();
  const [rowData, setRowData] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const [vbdEdit, setVbdEdit] = useState(true);
  const [vbdForm] = Form.useForm();
  const [pageStates, setPageStates] = useState({
    rowData: {
      Tiers: [],
      VBD: [],
    },
    activeTab: params.has("billingRuleTab")
      ? params.get("billingRuleTab")
      : "Tiers",
    isModalVisible: false,
  });

  const [columnDefs] = useState([
    {
      headerName: "Tier ID",
      field: "id",
      sortable: true,
    },
    {
      headerName: "Tier Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
    },
    {
      headerName: "Title Associated",
      field: "titleAssociated",
      sortable: true,
      tooltipField: "titleAssociated",
    },
  ]);
  const onTabChange = (key) => {
    params.set("billingRuleTab", key);
    history.push({ search: params.toString() });
    setPageStates((prev) => ({
      ...prev,
      activeTab: key,
    }));
  };

  // useEffect(() => {
  //   const currentKey = params.get("table");
  //   console.log("currentkey", currentKey);
  //   if (!(currentKey in clientTabsConfig)) {
  //     history.push({
  //       search: `?tab=${params.get("tab")}&table=${
  //         K.LookupTabKeys.JobLocations
  //       }`,
  //     });
  //   }
  //   currentKey && getLookupData(clientTabsConfig[currentKey]);
  // }, [params.get("table")]);

  useEffect(() => {
    const currentKey = params.get("billingRuleTab");
    if (!(currentKey in billingRuleTabs)) {
      console.log("current key", currentKey);
      history.push({
        search: `?tab=${params.get("tab")}&billingRuleTab=${
          billingRuleTabs.Tiers.name
        }`,
      });

      setPageStates((prev) => ({
        ...prev,
        activeTab: params.get("billingRuleTab"),
      }));
    }
  }, [history.location.search]);

  // useEffect(() => {
  //   if (params.has("tab")) {
  //     getInvoices();
  //   }
  // }, [globalSelector.selectedClients, pageStates.activeTab]);

  useEffect(() => {
    (async () => {
      try {
        const res = await Client.getJobBillingRules(id);
        const change = res.map((item) => {
          return {
            id: item.id,
            name: item.name,
            titleAssociated: item.jobTierTitle.map((obj) => obj.jobTitle.name),
          };
        });

        setRowData(change);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const addNewHandler = () => {
    setCurrentPage(1);
  };
  const onRowClicked = (params) => {
    tierId.current = params.data.id;
    setCurrentPage(2);
  };

  const onChange = useCallback(
    (event) => onFilterTextBoxChanged(event.target.value, gridRef),
    [],
  );
  if (!rowData.length) return <Spin className="global-spinner" size="large" />;
  return (
    <>
      {/* <PageHeader
        className={LayoutCss.appPageHeader}
        ghost={false}
        footer={
          <>
            
          </>
        }
      /> */}
      <Tabs
        activeKey={params.get("billingRuleTab")}
        onChange={onTabChange}
        className="billing-rule-tab"
        // tabBarExtraContent={
        //   <>
        //     {vbdEdit ? (
        //       <Button type="primary" onClick={() => setVbdEdit(false)}>
        //         Edit
        //       </Button>
        //     ) : (
        //       <Button
        //         type="primary"
        //         onClick={() => vbdForm.submit()}
        //         style={{ color: "white" }}
        //       >
        //         Save & Update
        //       </Button>
        //     )}
        //   </>
        // }
        items={Object.keys(billingRuleTabs).map((item, index) => ({
          key: item,
          label: billingRuleTabs[item].name,
        }))}
      />
      {params.get("billingRuleTab") === "Tiers" && (
        <Card
          title="Tiers"
          className={"appCard " + LayoutCss.appCard}
          extra={
            <div className={LayoutCss.appListingCardJobTable}>
              <Search
                allowClear
                placeholder="Search"
                onChange={onChange}
                className={LayoutCss.appListingCardSearchBar}
              />

              <Button
                key="1"
                type="primary"
                className={`${LayoutCss.appListingCardLookUpButton}  ${LayoutCss.jobBillingListBtn}`}
                onClick={addNewHandler}
              >
                Add New
              </Button>
            </div>
          }
          style={{ marginTop: "20px" }}
        >
          <div
            className="ag-theme-alpine s2-theme-style"
            style={{
              height: 540,
            }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={K.AgGridTable.DefaultColDef}
              onRowClicked={onRowClicked}
              ref={gridRef}
              pagination
              paginationPageSize={10}
            />
          </div>
        </Card>
      )}
      {params.get("billingRuleTab") === "VBD" && (
        <VBDRuleDetail
          key={moment().unix()}
          vbdEdit={vbdEdit}
          setVbdEdit={setVbdEdit}
          form={vbdForm}
          tierListing={rowData}
        />
      )}
    </>
  );
}
