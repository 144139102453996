import {
  Tooltip,
  Row,
  Col,
  Card,
  Descriptions,
  Typography,
  Divider,
} from "antd";
import K from "utilities/constants";
import { titleCase } from "utilities/generalUtility";
import { displayDollar, getCalendarDay } from "utilities/tableUtility";

const { Title } = Typography;

export default function ClientAndJobBillingComponent({ currentRule }) {
  const displayAmountType = (item) => {
    if (item.amountType === K.Format.Percentage) {
      return "%";
    } else if (item.currency === "CAD") {
      return K.CurrencyType.CAD;
    } else {
      return K.CurrencyType.Dollar;
    }
  };
  const getWeekBillingRule = (item) => {
    return (
      <Card className="feeCard">
        <Descriptions
          className="feeCardDescription"
          title={
            item.amountType === K.Format.Percentage ? (
              <>
                {displayDollar(item.amount, false)}
                <sup>{displayAmountType(item)}</sup>
              </>
            ) : (
              <>
                <sup>{displayAmountType(item)}</sup>
                {displayDollar(item.amount, false)}
              </>
            )
          }
          extra={item.currency}
        ></Descriptions>
        <Divider />
        <Descriptions
          column={{ xs: 1, sm: 1, md: 1 }}
          colon={false}
          className="feeCardDescription jobFeeDescription"
        >
          <Descriptions.Item label="Referral Fee">
            {item.amountType === K.Format.Percentage ? (
              <>
                {displayDollar(item.referral, false)}
                {displayAmountType(item)}
              </>
            ) : (
              <>
                {displayAmountType(item)}
                {displayDollar(item.referral, false)}
              </>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Rehire Fee">
            {item.amountType === K.Format.Percentage ? (
              <>
                {displayDollar(item.reHired, false)}
                {displayAmountType(item)}
              </>
            ) : (
              <>
                {displayAmountType(item)}
                {displayDollar(item.reHired, false)}
              </>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Repeat Every">
            {item.calendarEntry.repetitiveQuantity}
          </Descriptions.Item>
          <Descriptions.Item label="Repeat Type">
            {titleCase(item.calendarEntry.repeatType)}
          </Descriptions.Item>

          <Descriptions.Item className="daysLabel" label="Days">
            {getCalendarDay(item.calendarEntry).length
              ? getCalendarDay(item.calendarEntry)
              : K.NullPlaceholder}
          </Descriptions.Item>
          <Descriptions.Item label="Occurrences">
            {item.calendarEntry.endAfterOccurances !== null
              ? item.calendarEntry.isInfinite === 1
                ? "Ongoing"
                : item.calendarEntry.endAfterOccurances
              : K.NullPlaceholder}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    );
  };

  const getMonthBillingRule = (item) => {
    return (
      <Card className="feeCard">
        <Descriptions
          className="feeCardDescription"
          title={
            item.amountType === K.Format.Percentage ? (
              <>
                {displayDollar(item.amount, false)}
                <sup>{displayAmountType(item)}</sup>
              </>
            ) : (
              <>
                <sup>{displayAmountType(item)}</sup>
                {displayDollar(item.amount, false)}
              </>
            )
          }
          extra={item.currency}
        ></Descriptions>
        <Divider />
        <Descriptions
          column={{ xs: 1, sm: 1, md: 1 }}
          colon={false}
          className="feeCardDescription jobFeeDescription"
        >
          <Descriptions.Item label="Referral Fee">
            {item.amountType === K.Format.Percentage ? (
              <>
                {displayDollar(item.referral, false)}
                {displayAmountType(item)}
              </>
            ) : (
              <>
                {displayAmountType(item)}
                {displayDollar(item.referral, false)}
              </>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Rehire Fee">
            {item.amountType === K.Format.Percentage ? (
              <>
                {displayDollar(item.reHired, false)}
                {displayAmountType(item)}
              </>
            ) : (
              <>
                {displayAmountType(item)}
                {displayDollar(item.reHired, false)}
              </>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Repeat Every">
            {item.calendarEntry.repetitiveQuantity}
          </Descriptions.Item>
          <Descriptions.Item label="Repeat Type">
            {titleCase(item.calendarEntry.repeatType)}
          </Descriptions.Item>

          <Descriptions.Item label="On this date">
            {item.calendarEntry.repetitiveMonthDay ?? K.NullPlaceholder}
          </Descriptions.Item>
          <Descriptions.Item label="Occurrences">
            {item.calendarEntry.endAfterOccurances !== null
              ? item.calendarEntry.isInfinite === 1
                ? "Ongoing"
                : item.calendarEntry.endAfterOccurances
              : K.NullPlaceholder}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    );
  };

  const getDataSource = (item) => {
    if (item.calendarEntry && item.calendarEntry.repeatType === "week") {
      return getWeekBillingRule(item);
    } else if (
      item.calendarEntry &&
      item.calendarEntry.repeatType === "month"
    ) {
      return getMonthBillingRule(item);
    } else if (item.jobBillingType.type === "TIME_BASED") {
      return (
        <Card className="feeCard">
          <Descriptions
            className="feeCardDescription"
            title={
              item.amountType === K.Format.Percentage ? (
                <>
                  {displayDollar(item.amount, false)}
                  <sup>{displayAmountType(item)}</sup>
                </>
              ) : (
                <>
                  <sup>{displayAmountType(item)}</sup>
                  {displayDollar(item.amount, false)}
                </>
              )
            }
            extra={item.currency}
          ></Descriptions>
          <Divider />
          <Descriptions
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
            className="feeCardDescription jobFeeDescription"
          >
            <Descriptions.Item label="Referral Fee">
              {item.amountType === K.Format.Percentage ? (
                <>
                  {displayDollar(item.referral, false)}
                  {displayAmountType(item)}
                </>
              ) : (
                <>
                  {displayAmountType(item)}
                  {displayDollar(item.referral, false)}
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Rehire Fee">
              {item.amountType === K.Format.Percentage ? (
                <>
                  {displayDollar(item.reHired, false)}
                  {displayAmountType(item)}
                </>
              ) : (
                <>
                  {displayAmountType(item)}
                  {displayDollar(item.reHired, false)}
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Days">
              <>{item.days ?? "N/A"}</>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      );
    } else {
      return (
        <Card className="feeCard">
          <Descriptions
            className="feeCardDescription"
            title={
              item.amountType === K.Format.Percentage ? (
                <>
                  {displayDollar(item.amount, false)}
                  <sup>{displayAmountType(item)}</sup>
                </>
              ) : (
                <>
                  <sup>{displayAmountType(item)}</sup>
                  {displayDollar(item.amount, false)}
                </>
              )
            }
            extra={item.currency}
          ></Descriptions>
          <Divider />
          <Descriptions
            column={{ xs: 1, sm: 1, md: 1 }}
            colon={false}
            className="feeCardDescription jobFeeDescription"
          >
            <Descriptions.Item label="Referral Fee">
              {item.amountType === K.Format.Percentage ? (
                <>
                  {displayDollar(item.referral, false)}
                  {displayAmountType(item)}
                </>
              ) : (
                <>
                  {displayAmountType(item)}
                  {displayDollar(item.referral, false)}
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Rehire Fee">
              {item.amountType === K.Format.Percentage ? (
                <>
                  {displayDollar(item.reHired, false)}
                  {displayAmountType(item)}
                </>
              ) : (
                <>
                  {displayAmountType(item)}
                  {displayDollar(item.reHired, false)}
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      );
    }
  };
  return (
    <>
      <Row className="job-billing-wrap" gutter={[0, 30]}>
        {currentRule?.billingRuleEntries.map((item, i) =>
          !item.retentionEntries.length ? (
            <Col md={12} lg={12} xl={8}>
              <Card
                className="job-billing-card"
                title={
                  <div className="d-flex justify-content-center">
                    {item.isCommissionable ? (
                      <Tooltip title="Is Commissionable">
                        &nbsp;
                        <i
                          className="icon-commission1"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </Tooltip>
                    ) : null}
                    <Tooltip title={item.jobBillingType.name}>
                      <span className="title-ellipse">
                        {item.jobBillingType.name}
                      </span>
                    </Tooltip>
                  </div>
                }
              >
                {getDataSource(item)}
              </Card>
            </Col>
          ) : null,
        )}
      </Row>

      {currentRule?.billingRuleEntries.map((item, i) =>
        item.retentionEntries.length ? (
          <Card
            className="job-billing-card retention-card"
            title={
              <>
                {item.isCommissionable ? (
                  <Tooltip title="Is Commissionable">
                    &nbsp;
                    <i
                      className="icon-commission1"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </Tooltip>
                ) : null}
                <Tooltip title={item.jobBillingType.name}>
                  {item.jobBillingType.name}
                </Tooltip>
              </>
            }
          >
            <Row gutter={[30, 30]}>
              {item.retentionEntries.map((entry, j) => (
                <Col md={12} lg={8}>
                  <Card className="feeCard">
                    <Title level={5}>
                      {item.retentionType === K.RecurringType.Day
                        ? `${entry.monthOrder} Days`
                        : `Month ${entry.monthOrder}`}
                    </Title>
                    <Descriptions
                      className="feeCardDescription"
                      title={
                        item.amountType === K.Format.Percentage ? (
                          <>
                            {displayDollar(entry.amount, false)}
                            <sup>{displayAmountType(item)}</sup>
                          </>
                        ) : (
                          <>
                            <sup>{displayAmountType(item)}</sup>
                            {displayDollar(entry.amount, false)}
                          </>
                        )
                      }
                      extra={item.currency}
                    ></Descriptions>
                    <Divider />
                    <Descriptions
                      column={{ xs: 1, sm: 1, md: 1 }}
                      colon={false}
                      className="feeCardDescription jobFeeDescription"
                    >
                      <Descriptions.Item label="Referral Fee">
                        {item.amountType === K.Format.Percentage ? (
                          <>
                            {displayDollar(entry.referral, false)}
                            {displayAmountType(item)}
                          </>
                        ) : (
                          <>
                            {displayAmountType(item)}
                            {displayDollar(entry.referral, false)}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Rehire Fee">
                        {item.amountType === K.Format.Percentage ? (
                          <>
                            {displayDollar(entry.reHired, false)}
                            {displayAmountType(item)}
                          </>
                        ) : (
                          <>
                            {displayAmountType(item)}
                            {displayDollar(entry.reHired, false)}
                          </>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        ) : null,
      )}
    </>
  );
}
