import { useState, useEffect } from "react";
import { Button, Form, PageHeader, Steps, Grid } from "antd";
import { useHistory } from "react-router-dom";
import LayoutCss from "layout/layout.module.scss";
import styles from "./create.module.scss";
import JobBilling from "./jobBilling";
import CreateBillingUnit from "./createBillingUnit";
import { ClientBillingRule } from "./clientBillingRules";
import { CreateClientDetails } from "./createClientDetails";
import { selectClient } from "redux/slices/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import BillingJob from "redux/models/billingJobs";

const { useBreakpoint } = Grid;

export default function CreateClient() {
  const history = useHistory();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [createClientForm] = Form.useForm();
  const [billingUnitForm] = Form.useForm();
  const [jobBillingForm] = Form.useForm();
  const [clientBillingForm] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const isTestPassed = useSelector(selectClient).isTestPassed;

  const submitForm = {
    0: createClientForm,
    1: billingUnitForm,
    2: clientBillingForm,
    3: jobBillingForm,
  };

  const next = () => {
    submitForm[current]
      .validateFields()
      .then((values) => {
        submitForm[current].submit();
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        console.error(errorInfo);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Client Details",
      content: <CreateClientDetails form={createClientForm} />,
    },
    {
      title: "Billing Unit",
      content: <CreateBillingUnit form={billingUnitForm} />,
    },
    {
      title: "Client Billing Rules",
      content: <ClientBillingRule form={clientBillingForm} />,
    },
    {
      title: "Job  Billing Rules",
      content: <JobBilling form={jobBillingForm} />,
    },
  ];

  const save = () => {
    submitForm[current]
      .validateFields()
      .then((values) => {
        submitForm[current].submit();
      })
      .catch((errorInfo) => {
        console.error(errorInfo);
      });
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([dispatch(BillingJob.getAll())]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      <div className={styles.wrapPageHeader}>
        <PageHeader
          className={`${LayoutCss.appPageHeader}  ${LayoutCss.pageHeader}`}
          ghost={false}
          onBack={history.goBack}
          title="Create New Client"
          extra={
            <div className="steps-action">
              {current > 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  onClick={prev}
                  className={`${styles.clientButton} ${styles.backButton} ${LayoutCss.appHeaderClientBtn}`}
                >
                  Back
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={next}
                  className={`${styles.clientButton} ${styles.nextButton}  ${LayoutCss.appHeaderNextBtn}`}
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  onClick={save}
                  type="primary"
                  disabled={!isTestPassed}
                  className={`${styles.clientButton} ${styles.saveButton} ${LayoutCss.appHeaderClientBtn}`}
                >
                  Save
                </Button>
              )}
            </div>
          }
        />
      </div>

      <div className={styles.clientSteps}>
        <Steps
          className={styles.stepsName}
          current={current}
          progressDot
          direction={screens["md"] ? "horizontal" : "vertical"}
          items={steps}
        />
      </div>
      <div className="steps-content">{steps[current].content}</div>
    </>
  );
}
