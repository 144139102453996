import { Form, Button } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import DisplayUDF from "features/clients/details/userDefinedFields/displayUdf";
import UDF from "redux/models/udf";

import {
  generateUdfSetFieldValues,
  generateUdfValuesObject,
} from "utilities/generalUtility";
import { useEffect } from "react";

export default function UDFEditComponent({
  candidateJobEntryId,
  styles,
  item,
  setUdfEdit,
  setUdfShouldLoad,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    const udfValues = generateUdfSetFieldValues([item]);
    form.setFieldsValue(udfValues);
  }, []);

  const onFinish = async (values) => {
    try {
      let value = null;
      let generatedValues = generateUdfValuesObject([item], values);
      if (item.udfMultiValueFieldTypeVal.length > 0) {
        value = generatedValues.length > 0 ? generatedValues[0].optionIds : [];
      } else {
        value = generatedValues.length > 0 ? generatedValues[0].value : "";
      }

      const payload = {
        candidateJobEntryId: candidateJobEntryId,
        multiValues: item.udfMultiValueFieldTypeVal.length > 0,
        value: value,
        userDefinedFieldId: Object.keys(values).at(0),
      };

      const res = await UDF.editUdfFieldsValue(payload);
      // dispatch(setShouldUpdate());
      // getJobDetails();
      // setShouldDetailLoad((prev) => !prev);
      setUdfShouldLoad((prev) => !prev);
      hideEditField();
    } catch (error) {
      console.log(error);
    }
  };
  const hideEditField = () => {
    setUdfEdit((prev) => ({ ...prev, [item.id]: false }));
  };

  return (
    <Form
      layout="vertical"
      initialValues={{ interviewConfirmation: false }}
      className={styles.addCandidateFrom + " display-udf-form"}
      onFinish={onFinish}
      form={form}
    >
      <>
        <DisplayUDF
          isFormItem={true}
          title={item.label}
          form={form}
          fieldTypeId={item.fieldTypeId}
          udfId={item.id}
          isRequired={item.isRequired}
          formItemName={item.label}
          options={
            item.udfMultiValueFieldTypeVal.length
              ? item.udfMultiValueFieldTypeVal
              : []
          }
        />

        <Button
          type="link"
          onClick={hideEditField}
          icon={<CloseCircleOutlined className="closeIcon" />}
        />
        <Button
          type="link"
          onClick={() => form.submit()}
          icon={<CheckCircleOutlined className="checkIcon" />}
        />
      </>
    </Form>
  );
}
