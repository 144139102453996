import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
} from "antd";
import LayoutCss from "layout/layout.module.scss";

import { useCallback, useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  isPermissionPresent,
  noTrailingSpaceAllowedRule,
} from "utilities/generalUtility";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
} from "utilities/tableUtility";

import UDF from "redux/models/udf";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;

// const defaultCheckedList = ["Apple", "Orange"];

export default function UdfField({ entityId }) {
  const [form] = Form.useForm();
  //*Client Id
  const { id } = useParams();
  const gridRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [udfs, setUdfs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userSlice = useSelector(selectUser);

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const searchFilterPrefix = `client_${id}_udf_${entityId}`;
  const getSearchKey = `client_${id}_udf_${entityId}_search`;

  const [setter, getter] = useSearchAndFilter();

  const searchRef = useRef(null);

  const isExportableRenderer = (params) => {
    return params.data.isExportable === 1 ? "Yes" : "No";
  };
  const isVisibleRenderer = (params) =>
    params.data.isVisible === 1 ? "Yes" : "No";

  const isRequiredRenderer = (params) =>
    params.data.isRequired === 1 ? "Yes" : "No";

  const actionColumnRenderer = (params) => {
    return (
      <>
        {isPermissionPresent(K.Permissions.UDFEdit, userSlice.roles) && (
          <>
            <Button
              type="link"
              onClick={() => {
                editRecord(params.data);
              }}
              className={LayoutCss.appListingCardActions}
            >
              Edit
            </Button>
          </>
        )}
      </>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Label",
      field: "label",
      sortable: true,
      tooltipField: "label",
    },
    {
      headerName: "Type",
      field: "fieldType.type",
      sortable: true,
      tooltipField: "fieldType.type",
    },
    {
      headerName: "Required",
      field: "isRequired",
      sortable: true,
      tooltipField: "isRequired",
      cellRenderer: isRequiredRenderer,
    },
    {
      headerName: "Exportable",
      field: "isExportable",
      sortable: true,
      tooltipField: "isExportable",
      cellRenderer: isExportableRenderer,
    },
    {
      headerName: "Visible",
      field: "isVisible",
      sortable: true,
      tooltipField: "isVisible",
      cellRenderer: isVisibleRenderer,
    },
    ...(isPermissionPresent(K.Permissions.UDFEdit, userSlice.roles)
      ? [
          {
            headerName: "Actions",
            field: "actions",
            sortable: true,
            resizable: false,
            headerClass: "text-center",
            tooltipField: "actions",
            cellStyle: { textAlign: "center" },
            cellRenderer: actionColumnRenderer,
          },
        ]
      : []),
  ]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const editRecord = (record) => {
    const options = record?.udfMultiValueFieldTypeVal.map((item) => ({
      value: item.value,
      id: item.id,
    }));

    const data = {
      ...record,
      options: options,
      isVisible: record.isVisible === 1 ? true : false,
      isExportable: record.isExportable === 1 ? true : false,
    };

    // form.setFieldsValue(data);
    setEditData(data);
    showModal();
  };
  const afterModalClose = () => {
    setEditData({});
  };

  const onFinish = async (values) => {
    const { options, isVisible, isExportable, isRequired, label, ...rest } =
      values;
    let payload = {
      ...rest,
      label: label,
      entityId: entityId,
      isVisible: isVisible ? 1 : 0,
      isExportable: isExportable ? 1 : 0,
      isRequired: isRequired ? 1 : 0,
      multiFieldValues:
        values?.options?.filter((item) => item.value != null) ?? [],
      clientId: id,
    };
    setIsLoading(true);
    if (Object.keys(editData).length) {
      try {
        values = { ...payload, udfId: editData.id };
        await UDF.updateUdf(values);
        getAllUDFBYEntityID();
        setIsLoading(false);
        setIsModalVisible(false);
        form.resetFields();
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
      setEditData({});
    } else {
      try {
        await UDF.createUdf({
          udfs: [
            {
              ...payload,
            },
          ],
        });
        getAllUDFBYEntityID();
        setIsLoading(false);
        setIsModalVisible(false);
        form.resetFields();
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }
  };

  const getAllUDFBYEntityID = async () => {
    try {
      //*id = clientID
      const body = { clientId: id, entityId: entityId };
      const res = await UDF.getUdfList(body);
      setUdfs(res);
    } catch (err) {
      console.error(err);
    }
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.UserDefinedsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.UserDefinedsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions")
          return {
            ...item,
            cellRenderer: actionColumnRenderer,
          };
        else if (item.field === "isExportable")
          return {
            ...item,
            cellRenderer: isExportableRenderer,
          };
        else if (item.field === "isVisible")
          return {
            ...item,
            cellRenderer: isVisibleRenderer,
          };
        else if (item.field === "isRequired")
          return {
            ...item,
            cellRenderer: isRequiredRenderer,
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };
  //?save cloumn Sorting
  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userSlice.details?.id,
          tableName: K.AgGridTable.Keys.UserDefinedsVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onChange = useCallback(
    (event) => {
      onFilterTextBoxChanged(event.target.value, gridRef);
      setter({ search: event.target.value }, searchFilterPrefix);
    },
    [searchRef.current],
  );

  useEffect(() => {
    form.resetFields();
  }, [editData]);

  useEffect(() => {
    (async () => {
      await Promise.all([getAllUDFBYEntityID(), getColumnsConfigrations()]);
    })();
  }, []);
  return (
    <>
      <Card
        title="List of fields"
        className={"appCard " + LayoutCss.appCard}
        extra={
          <div className="hiring-manager">
            <Search
              allowClear
              placeholder="Search"
              value={getter(getSearchKey)}
              onChange={onChange}
              className={LayoutCss.appListingCardSearchBar1}
            />
            {isPermissionPresent(K.Permissions.UDFCreate, userSlice.roles) && (
              <Button type="primary" className="create-btn" onClick={showModal}>
                Add New Field
              </Button>
            )}
          </div>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={udfs}
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            ref={gridRef}
            onColumnMoved={updateColumns}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
          />
        </div>
      </Card>
      <Modal
        forceRender
        className="s2-theme-style"
        title={`${Object.keys(editData).length ? "Edit" : "Create"} UDF`}
        open={isModalVisible}
        centered
        closeIcon={<i className="icon-closeable"></i>}
        okText={`${Object.keys(editData).length ? "Update" : "Add"}`}
        wrapClassName="vertical-center-modal"
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
        afterClose={afterModalClose}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={editData}
        >
          <Form.Item
            label="Label"
            name="label"
            rules={[
              { required: true, message: "Name is required." },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item
            label="Select Field type "
            name="fieldTypeId"
            rules={[
              { required: true, message: "Field Type is required." },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showArrow
              disabled={Object.keys(editData).length}
              showSearch={true}
              mode="single"
              optionFilterProp="label"
              placeholder="Select Type"
              className="billingTypeTitle"
              options={Object.keys(K.UDF.Fields).map((fieldKey) => ({
                value: +fieldKey,
                label: K.UDF.Fields[fieldKey].displayName,
              }))}
            />
          </Form.Item>

          <Form.Item shouldUpdate={true} className="mb-0">
            {({ getFieldValue }) => {
              const Component =
                K.UDF.Fields[getFieldValue("fieldTypeId")]?.component;
              const componentProps =
                K.UDF.Fields[getFieldValue("fieldTypeId")]?.props;

              return (
                Component && (
                  <Component
                    props={componentProps}
                    isFormItem={true}
                    disabled={Object.keys(editData).length}
                    form={form}
                    title={getFieldValue("label")}
                    placeholder={getFieldValue("label")}
                    formItemName={`${getFieldValue(
                      "label",
                    )}.replace(/\s/g, '') `}
                  />
                )
              );
            }}
          </Form.Item>

          <Row>
            <Col xs={24} md={8} xl={8}>
              <Form.Item name={"isVisible"} valuePropName="checked">
                <Checkbox>Is Visible</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
              <Form.Item name={"isExportable"} valuePropName="checked">
                <Checkbox>Is Exportable</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
              <Form.Item name={"isRequired"} valuePropName="checked">
                <Checkbox>Is Required</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal className="s2-theme-style" title="Edit field">
        <Form>
          <Form.Item label="Label">
            <Input placeholder="Name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
