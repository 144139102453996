import { Form, Input, InputNumber } from "antd";
export default function UDFInput({
  title = "Title",
  isFormItem = true,
  value,
  className,
  placeholder = "",
  formItemName = "",
  required = true,
  isNumber = false,
  params,
}) {
  return (
    <>
      {isFormItem ? (
        <Form.Item
          className={className}
          label={title}
          name={formItemName}
          initialValue={null}
          rules={required && [{ required: true, message: `Enter  ${title}` }]}
        >
          {params?.isNumber ? (
            <InputNumber {...params} placeholder={placeholder} />
          ) : (
            <Input {...params} placeholder={placeholder} />
          )}
        </Form.Item>
      ) : (
        <Input placeholder={placeholder} />
      )}
    </>
  );
}
