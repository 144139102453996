import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import SampleCSV from "assets/Source2_Cadidate_upload_Rich_File.xlsx";
import DragUpload from "common/components/uploadComponent/dragger";

import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Client from "redux/models/client";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { onFilterTextBoxChanged } from "utilities/tableUtility";
import ExportToCSV from "./exportToCSV";
import styles from "./jobList.module.scss";
import UDF from "redux/models/udf";
import { setJobSourceUdf } from "redux/slices/udfSlice";
import { selectCandidates } from "redux/slices/candidateSlice";
import CreateClass from "./createClass";
import ClassTable from "common/tableComponents/classTable";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;
const { Link } = Typography;

export default function Classes() {
  let status = undefined;
  const gridRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [csvFile, setCSVFile] = useState(null);
  const [clientClass, setClientClass] = useState([]);
  const [modalState, setModalState] = useState({
    isCreateClassModalVisible: false,
    isUploadModalVisible: false,
    isLoadingClassModal: false,
    isLoadingUploadModal: false,
    uploadCSVErrors: null,
  });
  const [exportData, setExportData] = useState({
    jobIds: [],
    isDisabled: true,
    isModalVisible: false,
  });

  const userSlice = useSelector(selectUser);

  const lookupTables = useSelector(selectConfigration).lookup;

  const searchFilterPrefix = `client_${id}_class`;
  const getSearchKey = `client_${id}_class_search`;

  const [setter, getter] = useSearchAndFilter();

  const searchRef = useRef(null);

  const getClientClassList = async () => {
    try {
      const res = await Client.getClientClassList(id);

      const tableData = res.map((el) => {
        return {
          id: el.id,
          title: el.title,
          classSize: el.classVacancies[0]?.currentOpenings ?? 0,
          classDateReceived: convertIntoDashUSFormat(el.dateClassReceived),
          classStartDate: convertIntoDashUSFormat(el.classStartDate),
          internalManagerId: el.internalManagerId,
          regionalDirectorId: el.regionalDirectorId,
          humanResourceId: el.humanResourceId,
          humanResource: el.humanResource,
          regionalDirector: el.regionalDirector,
          internalManager: el.internalManager,
          notes: el.notes,
          classStatusId: el.classStatusId,
          classStatus: el.classStatus?.name,
        };
      });

      setClientClass(tableData);
    } catch (error) {
      console.error(error);
    }
  };

  const getJobUdfs = async (entityKeyId = null) => {
    const jobUdfs = await UDF.getEditUdfFields({
      clientId: id,
      entityId: K.UDF.Entities.JobSource.id,
      entityKey: K.UDF.Entities.JobSource.key,
      entityKeyId: entityKeyId,
      isVisible: 1,
    });

    dispatch(setJobSourceUdf(jobUdfs));
  };

  const showModal = () => {
    setModalState((prev) => {
      return { ...prev, isCreateClassModalVisible: true };
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setModalState((prev) => {
      return { ...prev, isCreateClassModalVisible: false };
    });
  };

  const rowClickedHandler = (event) => {
    console.log("Class hanlde");
    history.push(`/clients/${id}/class/${event.data.id}`);
  };
  const onChange = useCallback(
    (event) => {
      onFilterTextBoxChanged(event.target.value, gridRef);
      setter({ search: event.target.value }, searchFilterPrefix);
    },
    [searchRef.current],
  );

  useEffect(() => {
    // (async () => {
    //   try {
    //     await Promise.all(
    //       lookupTablesForJobs.map(async (item) => {
    //         let apiEndPoint = item.isClient
    //           ? item.apiEndPoint + `/${id}`
    //           : item.apiEndPoint;

    //         await dispatch(LookupTable.getData(item.reduxKey, apiEndPoint));
    //       }),
    //     );
    //   } catch (error) {
    //     console.error(error);
    //   }
    // })();
    getClientClassList();
    // getJobUdfs();
  }, []);

  const onOk = async () => {
    setModalState((prev) => {
      return { ...prev, isLoadingUploadModal: true };
    });
    try {
      // await Client.uploadCSV(candidateSlice.uploadedCandidate);
      setCSVFile(null);
      setModalState((prev) => {
        return {
          ...prev,
          isUploadModalVisible: false,
          isLoadingUploadModal: false,
        };
      });
      message.success("Candidate uploaded successfully ");
    } catch (err) {
      const {
        error: { data },
      } = err;
      message.error("Failed to upload CSV");
      console.info(data);
      setModalState((prev) => {
        return {
          ...prev,
          isLoadingUploadModal: false,
          uploadCSVErrors: data.errors,
        };
      });
    }
    return false;
  };

  const handleUploadCancel = () => {
    onRemove(csvFile);
    setModalState((prev) => {
      return {
        ...prev,
        isUploadModalVisible: false,
        uploadCSVErrors: null,
      };
    });
  };

  const onRemove = (file) => {
    setCSVFile(null);
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  return (
    <>
      <Card
        title="Class List"
        className={`appCard ${styles.jobListHeader}  ${LayoutCss.appCard}`}
        extra={
          <div
            className={`${LayoutCss.appListingCardJobTable} ${styles.jobListHeaderTable}`}
          >
            <Search
              allowClear
              placeholder="Search"
              value={getter(getSearchKey)}
              onChange={onChange}
              className={`${LayoutCss.appListingCardSearchBar} ${styles.jobListHeaderSearchBar}`}
            />

            {/* <Select
              showArrow
              showSearch={true}
              allowClear
              optionFilterProp="label"
              placeholder="Select Class Status"
              className={`commision-select ${LayoutCss.appListingCardStatusSelect1}`}
              options={lookupTables.jobStatus.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
              onChange={externalFilterChanged}
            /> */}

            {isPermissionPresent(
              K.Permissions.CreateClass,
              userSlice.roles,
            ) && (
              <Button
                type="primary"
                onClick={showModal}
                className={styles.jobListHeaderBtn}
              >
                Create Class
              </Button>
            )}

            {/* {isPermissionPresent(
              K.Permissions.UploadJobCSV,
              userSlice.roles,
            ) && (
              <Button
                type="primary"
                disabled={!clientClass.length}
                onClick={showUploadModalVisible}
                className={styles.jobListHeaderBtn}
              >
                Upload CSV File
              </Button>
            )} */}
          </div>
        }
      >
        <ClassTable
          rowData={clientClass}
          setClientClass={setClientClass}
          gridRef={gridRef}
          setExportData={setExportData}
          onRowClicked={rowClickedHandler}
          getClientClassList={getClientClassList}
          getterSearch={getter(getSearchKey)}
          // getJobUdfs={getJobUdfs}
        />
      </Card>
      <Modal
        centered
        width={632}
        open={modalState.isCreateClassModalVisible}
        okText="Submit"
        onOk={form.submit}
        okButtonProps={{ loading: modalState.isLoadingClassModal }}
        onCancel={handleCancel}
        closeIcon={<i className="icon-closeable"></i>}
        className="s2-theme-style jobsList modal-overflow-auto"
        title="Create Class"
        afterClose={afterModalClose}
      >
        <CreateClass
          form={form}
          clientId={id}
          lookupTables={lookupTables}
          setClientJobs={setClientClass}
          setModalState={setModalState}
          getClientClassList={getClientClassList}
          getJobUdfs={getJobUdfs}
        />
      </Modal>
      <Modal
        title="Upload Excel File"
        centered
        className="s2-theme-style"
        onOk={onOk}
        onCancel={handleUploadCancel}
        okButtonProps={{
          loading: modalState.isLoadingUploadModal,
          disabled: !csvFile,
        }}
        destroyOnClose
        open={modalState.isUploadModalVisible}
      >
        <DragUpload
          setCSVFile={setCSVFile}
          onRemove={onRemove}
          isCandidateCsv={true}
          clientId={id}
        />
        <div className="d-flex mt-3 justify-content-center">
          <Link
            href={SampleCSV}
            download="Source2_Cadidate_upload_Rich_File.xlsx"
          >
            Click here to download sample excel file
          </Link>
        </div>
        <Divider />
        {modalState.uploadCSVErrors && (
          <>
            <Typography.Title level={5} style={{ color: "red" }}>
              Errors in CSV:
            </Typography.Title>
            <ol>
              {Object.keys(modalState.uploadCSVErrors).map((key) => [
                <li key={key} style={{ color: "red", fontWeight: "bold" }}>
                  Errors in Row {key}:
                </li>,
                Object.values(modalState.uploadCSVErrors[key]).map((item) => (
                  <ul>
                    <li key={key} style={{ color: "red" }}>
                      {item}
                    </li>
                  </ul>
                )),
              ])}
            </ol>
          </>
        )}
      </Modal>
      {/* <ExportToCSV
        exportData={exportData}
        setExportData={setExportData}
        gridRef={gridRef}
        clientId={id}
      /> */}
    </>
  );
}
