import NetworkCall from "network/networkCall";
import Request from "network/request";

import {
  camelCaseKeysRecursively,
  downloadFile,
} from "utilities/generalUtility";

export default class CommissionPlan {
  static async getAll() {
    const res = await NetworkCall.fetch(Request.getAllCommission());
    return camelCaseKeysRecursively(res.data);
  }

  static async create(values) {
    const res = await NetworkCall.fetch(Request.createCommissionPlan(values));
    return res.data;
  }

  static async getCommissionPlans(planId) {
    const res = await NetworkCall.fetch(Request.getCommissionPlansById(planId));
    return camelCaseKeysRecursively(res.data);
  }
  static async addNewCommissionPlan(body) {
    const res = await NetworkCall.fetch(Request.addNewCommissionPlan(body));
    return res.data;
  }

  static async deteteUpcommingPlan(commissionId) {
    const res = await NetworkCall.fetch(
      Request.deleteUpcommingPlan(commissionId),
    );
    return res;
  }

  static async getCommissionApproval(recruiterIds, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getCommissionApprovals(recruiterIds),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async editCommissionApproval(payload) {
    const res = await NetworkCall.fetch(
      Request.editCommissionApproval(payload),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getCommissionStatements(recruiterIds) {
    const res = await NetworkCall.fetch(
      Request.getCommissionStatements(recruiterIds),
    );
    return camelCaseKeysRecursively(res.data);
  }
  static async exportCommissionStatements(body) {
    const res = await NetworkCall.fetch(
      Request.exportCommissionStatements(body),
    );
    downloadFile(res, "commission_statment");
  }

  static async createCommissionStatement(payload) {
    const res = await NetworkCall.fetch(
      Request.createCommissionStatement(payload),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getCommissionStatementDetail(id) {
    const res = await NetworkCall.fetch(
      Request.getCommissionStatementDetails(id),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async deleteCommissionStatment(id) {
    const res = await NetworkCall.fetch(Request.deleteCommissionStatements(id));
    return camelCaseKeysRecursively(res.data);
  }
}
