import { Form, Select } from "antd";
export default function UDFSelect({
  options,
  mode = "single",
  title = "Title",
  isFormItem = true,
  value,
  className,
  placeholder = "",
  formItemName = "",
  required = false,
  params = {},
}) {
  const { Option } = Select;
  return (
    <>
      {isFormItem ? (
        <Form.Item
          className={className}
          label={title}
          name={formItemName}
          rules={required && [{ required: true, message: `Select ${title}` }]}
        >
          <Select
            showSearch
            {...params}
            allowClear
            optionFilterProp="label"
            placeholder={placeholder}
            className={className}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {options?.map((item) => {
              return <Option value={item.id}>{item.value}</Option>;
            })}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          mode={mode}
          {...params}
          allowClear
          optionFilterProp="label"
          placeholder={placeholder}
          className={className}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {options?.map((item) => {
            return <Option value={item.value}>{item.label}</Option>;
          })}
        </Select>
      )}
    </>
  );
}
