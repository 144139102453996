import { Alert, Divider, Form, Typography } from "antd";
import K from "utilities/constants";

import "../../create/jobCard.scss";

const { Text } = Typography;

export default function DeleteJob({ form, record, modalState, deleteJob }) {
  const onFinish = async (values) => {
    deleteJob();
  };

  const getJobStatusColor = (status) => {
    status = status?.toLowerCase();
    const statusColor = ["open", "closed", "on hold"].includes(status)
      ? status
      : "custom";

    return statusColor;
  };
  return (
    <Form form={form} onFinish={onFinish}>
      <Divider style={{ margin: 0, marginBottom: 10 }} />
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong>
            <font style={{ fontSize: 26, color: "#278dc0" }}>
              {record.title}
            </font>
          </strong>
          <Text
            className={K.JobStatus[getJobStatusColor(record?.jobStatus?.name)]}
          >
            <span className="dot"></span>
            {record?.jobStatus?.name}
          </Text>
        </div>
        <strong>Location: </strong>
        {record?.jobLocation?.name}{" "}
      </div>
      <div>
        <strong>Current Openings: </strong>
        {record?.totalOpenings}
      </div>
      <div>
        <strong>Cancelled?: </strong>
        {record?.isCancel === 0 ? "No" : "Yes"}
      </div>
      {modalState.deleteResponse && (
        <>
          <Divider style={{ margin: 0, marginTop: 30 }} />
          <div style={{ marginTop: 20 }}>
            <Alert
              style={{ whiteSpace: "break-spaces" }}
              showIcon
              type="error"
              message={
                <>
                  <strong>Error :&nbsp; </strong>
                  <Text>{modalState.deleteResponse}</Text>
                </>
              }
            />
          </div>
        </>
      )}
    </Form>
  );
}
