import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Card,
  Divider,
  Input,
  PageHeader,
  Tabs,
  Select,
  message,
} from "antd";
import SubmitOneTimeBilling from "common/submitOneTimeBilling/submitOneTimeBilling";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Invoice from "redux/models/invoice";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat, getMonthName } from "utilities/dateUtility";
import {
  camelToSnakeCase,
  isPermissionPresent,
  removeUnderScore,
  toolTipRemoveUnderScore,
} from "utilities/generalUtility";
import { displayDollar, onFilterTextBoxChanged } from "utilities/tableUtility";
import BulkUpdateInvoice from "./bulkUpdateInvoice";
import {
  toolTipInvoiceAmount,
  toolTipInvoiceDueDate,
  toolTipInvoiceMonth,
} from "../submitToBilling/tableColumns";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import { debounce } from "lodash";

const { Search } = Input;
const invoiceTabs = {
  Client: { name: "Client", label: "Clients" },
  Job: { name: "Job", label: "Jobs" },
};
const sortDict = {
  asc: "ASC",
  desc: "DESC",
};

export default function Invoices() {
  const gridRef = useRef(null);
  const bulkUpdateRef = useRef(null);

  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [pageStates, setPageStates] = useState({
    rowData: {
      Job: [],
      Client: [],
    },
    activeTab: params.has("tab") ? params.get("tab") : "Client",
    isModalVisible: false,
  });

  const [selectedRows, setSelectedRows] = useState({
    rowIds: [],
    rowData: [],
    isDisabled: true,
    isModalVisible: false,
    shouldUpdate: false,
  });

  // const [query, setQuery] = useState({
  //   search: params.get("query"),
  //   filter: params.get("filter"),
  // });
  const globalSelector = useSelector(selectGlobalSelector);
  const userRoles = useSelector(selectUser).roles;
  // let status = params.get("filter") === null ? undefined : params.get("filter");
  let status = undefined;
  let searchFilterPrefix = `${params.get("tab")}_invoice`;
  let getSearchKey = `${params.get("tab")}_invoice_search`;
  let getFilterKey = `${params.get("tab")}_invoice_status`;
  const [setter, getter] = useSearchAndFilter();
  const [gridApi, setGridApi] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const statusRef = useRef(getter(getFilterKey));
  const searchRef = useRef(null);

  const [columnDefs] = useState({
    Job: [
      {
        headerName: "Client Name",
        field: "client.name",
        sortable: true,
        tooltipField: "client.name",
        suppressColumnsToolPanel: true,
        checkboxSelection: true,
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
      },
      {
        headerName: "Invoice Number",
        field: "invoiceNumber",
        sortable: true,
        tooltipField: "invoiceNumber",

        cellRenderer: (params) =>
          params.value ? params.value : K.NullPlaceholder,
      },
      {
        headerName: "Invoice Status",
        field: "status",
        sortable: true,
        tooltipField: "status",
        tooltipComponent: (params) => toolTipRemoveUnderScore(params.value),
        cellRenderer: (params) => removeUnderScore(params.value),
      },
      {
        headerName: "Invoice Date",
        field: "dueDate",
        sortable: true,
        tooltipField: "dueDate",
        tooltipComponent: toolTipInvoiceDueDate,
        cellRenderer: (params) => convertIntoDashUSFormat(params.value, false),
      },
      {
        headerName: "Invoice Month",
        field: "dueDate",
        sortable: true,
        tooltipField: "dueDate",
        getQuickFilterText: (params) => getMonthName(params.value),
        tooltipComponent: toolTipInvoiceMonth,
        cellRenderer: (params) => getMonthName(params.value),
      },

      {
        headerName: "Invoice Amount",
        field: "total",
        sortable: true,
        tooltipField: "total",
        tooltipComponent: toolTipInvoiceAmount,
        cellRenderer: (params) => <>{displayDollar(params.value)}</>,
      },
      {
        headerName: "Reason",
        field: "submittedJobBills",
        sortable: true,
        tooltipField: "reason",
        tooltipComponent: (params) => {
          const reasons = [];
          params.value.forEach(({ issueCreditReason }) => {
            if (issueCreditReason) reasons.push(issueCreditReason);
          });
          return reasons.length ? reasons.join(", ") : K.NullPlaceholder;
        },
        getQuickFilterText: (params) => {
          const reasons = [];
          params.value.forEach(({ issueCreditReason }) => {
            if (issueCreditReason) reasons.push(issueCreditReason);
          });
          return reasons.length ? reasons.join(", ") : K.NullPlaceholder;
        },
        cellRenderer: (params) => {
          const reasons = [];
          params.value.forEach(({ issueCreditReason }) => {
            if (issueCreditReason) reasons.push(issueCreditReason);
          });
          return reasons.length ? reasons.join(", ") : K.NullPlaceholder;
        },
      },
    ],
    Client: [
      {
        headerName: "Client Name",
        field: "client.name",
        sortable: true,
        tooltipField: "client.name",
        suppressColumnsToolPanel: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      {
        headerName: "Invoice Number",
        field: "invoiceNumber",
        sortable: true,
        tooltipField: "invoiceNumber",
        cellRenderer: (params) =>
          params.value ? params.value : K.NullPlaceholder,
      },
      {
        headerName: "Invoice Status",
        field: "status",
        sortable: true,
        tooltipField: "status",
        tooltipComponent: (params) => toolTipRemoveUnderScore(params.value),
        cellRenderer: (params) => removeUnderScore(params.value),
      },
      {
        headerName: "Invoice Date",
        field: "dueDate",
        sortable: true,
        tooltipField: "dueDate",
        tooltipComponent: toolTipInvoiceDueDate,
        cellRenderer: (params) => convertIntoDashUSFormat(params.value, false),
      },
      {
        headerName: "Invoice Month",
        field: "dueDate",
        sortable: true,
        getQuickFilterText: (params) => getMonthName(params.value),
        tooltipField: "dueDate",
        tooltipComponent: toolTipInvoiceMonth,
        cellRenderer: (params) => getMonthName(params.value),
      },

      {
        headerName: "Invoice Amount",
        field: "total",
        sortable: true,
        tooltipField: "total",
        tooltipComponent: toolTipInvoiceAmount,
        cellRenderer: (params) => <>{displayDollar(params.value)}</>,
      },
    ],
  });

  const rowClickedHandler = (params) => {
    history.push(`/billings/invoices/detail/${params.data.id}`);
  };

  const onChange = (event) => {
    //onFilterTextBoxChanged(event.target.search, gridRef);
    setter({ search: event.target.value }, searchFilterPrefix);
    searchRef.current = event.target.value;
    debounceOnChange();
  };

  const externalFilterChanged = (newValue) => {
    statusRef.current = newValue;
    gridRef.current.api.onFilterChanged();
    setter({ status: newValue }, searchFilterPrefix);
  };

  const show = () => {
    setPageStates((prev) => ({
      ...prev,
      isModalVisible: true,
    }));
  };
  const showBulkUpdate = () => {
    setSelectedRows((prev) => ({
      ...prev,
      isModalVisible: true,
    }));
  };

  const onTabChange = (key) => {
    params.set("tab", key);
    history.push({ search: params.toString() });
    setPageStates((prev) => ({
      ...prev,
      activeTab: key,
    }));
  };

  const getInvoices = async () => {
    const currentTab = params.get("tab");
    try {
      const res = await Invoice.getAll({
        clientIds: globalSelector.selectedClients,
        type: K.Invoice.Type[currentTab],
      });
      setPageStates((prev) => ({
        ...prev,
        rowData: { ...prev.rowData, [currentTab]: res },
      }));
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const isExternalFilterPresent = useCallback(() => {
    return statusRef.current !== undefined;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        return node.data.status === statusRef.current;
      }
      return true;
    },
    [statusRef.current],
  );

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const isBulkUpdate = selectedRows.every((obj, index, array) => {
      return obj.status === K.Invoice.Status["Billed in QuickBooks"];
    });

    const isSameStatus = selectedRows.every((obj, index, array) => {
      return obj.status === array[0].status;
    });

    bulkUpdateRef.current = isBulkUpdate && selectedRows.length;

    if (!isSameStatus) {
      message.warning(
        "You have selected invoices of different statuses for bulk update.",
      );
    }

    setSelectedRows((prev) => ({
      ...prev,
      isDisabled: selectedRows.length === 0,
      rowData: selectedRows,
      rowIds: selectedRows.map(({ id }) => id),
    }));
  };

  const datasource = useCallback(
    {
      async getRows(dataParams) {
        const { startRow, endRow } = dataParams.request;
        const currentTab = params.get("tab");
        console.log("Sort Model", dataParams.request);
        const pageSize = endRow - startRow;
        const page = startRow / pageSize + 1;

        const body = {
          page,
          pageSize,
          clientIds: globalSelector.selectedClients,
          type: K.Invoice.Type[currentTab],
          search: getter(getSearchKey),
          status: statusRef.current ? statusRef.current : null,
          sortBy: camelToSnakeCase(
            dataParams.request.sortModel[0]?.colId ?? "id",
          ),
          sortOrder:
            dataParams.request.sortModel.length > 0
              ? sortDict[dataParams.request.sortModel[0]["sort"]]
              : "asc",
        };
        try {
          const res = await Invoice.getAll(body);

          dataParams.success({
            rowData: res.listing,
            rowCount: res.total,
          });
        } catch (err) {
          dataParams.fail();
        }
      },
    },
    [
      refreshTable,
      globalSelector.selectedClients,
      pageStates.activeTab,
      selectedRows.shouldUpdate,
    ],
  );
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    const currentKey = params.get("tab");
    if (!(currentKey in invoiceTabs)) {
      history.push({ search: "?tab=Client" });
      setPageStates((prev) => ({
        ...prev,
        activeTab: params.get("tab"),
      }));
    }
  }, [history.location.search]);

  // useEffect(() => {
  //   if (params.has("tab")) {
  //     getInvoices();
  //   }
  // }, [
  //   globalSelector.selectedClients,
  //   pageStates.activeTab,
  //   selectedRows.shouldUpdate,
  // ]);

  // useEffect(() => {
  //   if (gridRef) {
  //     onFilterTextBoxChanged(query.search, gridRef);
  //     gridRef?.current?.api?.onFilterChanged();
  //   }
  // }, [gridRef.current, query.search, query.filter]);

  const debounceOnChange = debounce(() => {
    // Handle search logic here (e.g., fetch data)
    setRefreshTable((prev) => !prev);
    console.log("Search value:");
  }, 500);

  return (
    <>
      <PageHeader
        className={LayoutCss.appPageHeader}
        ghost={false}
        title="Invoices"
        footer={
          <>
            <Divider className={LayoutCss.appPageHeaderDivider} />
            <Tabs
              activeKey={params.get("tab")}
              onChange={onTabChange}
              className={LayoutCss.appPageHeaderTabs}
              items={Object.keys(invoiceTabs).map((item) => ({
                key: item,
                label: invoiceTabs[item].name,
              }))}
            />
          </>
        }
      />
      <div className="d-flex  justify-content-end">
        {isPermissionPresent(K.Permissions.EditInvoice, userRoles) && (
          <div className={`mr-1 ${LayoutCss.invoicesButton}`}>
            <Button
              type="primary"
              onClick={showBulkUpdate}
              disabled={!bulkUpdateRef.current}
            >
              Bulk Update
            </Button>
          </div>
        )}
        {isPermissionPresent(K.Permissions.CreateOneTimeBilling, userRoles) &&
          params.get("tab") !== "Job" && (
            <div className={`mb-4 ${LayoutCss.invoicesButton}`}>
              <Button type="primary" onClick={show}>
                Submit One Time Billing
              </Button>
            </div>
          )}
      </div>
      <Card
        className={"appCard " + LayoutCss.appCard}
        title={`${params.get("tab")} Invoices`}
        extra={
          <div className={LayoutCss.appListingCardJobTable}>
            <Search
              allowClear
              value={getter(getSearchKey)}
              onChange={onChange}
              placeholder="Search"
              className={`${LayoutCss.appListingCardSearchBar1} mr-2`}
            />

            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              placeholder="Status"
              value={getter(getFilterKey)}
              suffixIcon={<i className="icon-Vector" />}
              className={LayoutCss.appListingCardStatusSelect1}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={Object.keys(K.Invoice.Status).map((item) => ({
                value: K.Invoice.Status[item],
                label: item,
              }))}
              onChange={externalFilterChanged}
            />
          </div>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: params.get("tab") === "Client" ? 620 : 675,
          }}
        >
          <AgGridReact
            ref={gridRef}
            {...K.AgGridTable.DefaultProps}
            suppressRowClickSelection
            rowSelection="multiple"
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            serverSideDatasource={datasource}
            // rowData={pageStates.rowData[params.get("tab")]}
            columnDefs={columnDefs[params.get("tab")]}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onRowClicked={rowClickedHandler}
            onSelectionChanged={onSelectionChanged}
            quickFilterText={getter(getSearchKey)}
            loadingCellRenderer={false}
          />
        </div>
      </Card>
      <SubmitOneTimeBilling
        isOpen={pageStates.isModalVisible}
        setIsModalVisible={setPageStates}
      />
      <BulkUpdateInvoice
        isOpen={selectedRows.isModalVisible}
        setIsModalVisible={setSelectedRows}
        modalData={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
}
