import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import UserSelectOption from "common/components/userSelect/userSelect";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Client from "redux/models/client";
import Job from "redux/models/job";
import LookupTable from "redux/models/lookupTable";
import User from "redux/models/user";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import {
  getFirstDayCurrMonth,
  getLastDayCurrMonth,
} from "utilities/dateUtility";
import {
  customUserHandleSearch,
  lowerCaseAllExceptFirstLetter,
} from "utilities/generalUtility";
import DraggableContainer from "../draggableContainer";
import RecruitmentPiplineCard from "./recruitmentPipelineCard";
import { selectSearchAndFilter } from "redux/slices/search&FilterSlice";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { RangePicker } = DatePicker;
const { Title } = Typography;

const searchFilterPrefix = "historical_performance";

const panelSettingsOptions = [
  "Hire Chart",
  "Submittal Decline Rate",
  "Interview Decline Rate",
  "Interview No Show Rate",
  "Post Offer Fall Out Reasons",
  "Post Offer Fall Out Rates",
  "TTM Chart",
];

export default function HistoricalPerformance({
  setPrintData,
  globalSelector,
  userDetails,
}) {
  return <Row justify="center" align="middle" style={{ marginTop: '100px' }}>
    <Col>
      <div style={{ fontSize: '20px', textAlign: 'center' }}>
        Page temporarily unavailable, maintenance in progress
      </div>
    </Col>
  </Row>

  // const { Option } = Select;
  // const [form] = Form.useForm();
  // const isInitialRender = useRef(true);
  // const dispatch = useDispatch();
  // const lookupData = useSelector(selectConfigration).lookup;
  // const filterSlice = useSelector(selectSearchAndFilter);
  // const [setter, getter] = useSearchAndFilter();
  //
  // const [pageData, setPageData] = useState({
  //   jobs: [],
  //   jobDepartmentListing: [],
  //   jobLocationsListing: [],
  //   departments: [],
  //   hiringManagers: [],
  //   recruiters: [],
  //   selectedRecruiter: [],
  //   recruitmentPipelineData: null,
  //   selectedCharts: panelSettingsOptions,
  //   isPanelSetting: false,
  //   isDragChart: false,
  // });
  // const [isLoading, setIsLoading] = useState({
  //   filterLoading: false,
  // });
  // const [historicalPerformanceData, setHistoricalPerformance] = useState({
  //   type: "MONTH",
  //   range: [
  //     moment(getFirstDayCurrMonth()).format(K.DateFormat.Response),
  //     moment(getLastDayCurrMonth()).format(K.DateFormat.Response),
  //   ],
  //   filter: "",
  //   title: "",
  //   clientIds: globalSelector.selectedClients,
  //   managerId: null,
  //   recruiterId: null,
  // });
  //
  // // const checkBoxHandler = async (checkedValues) => {
  // //   try {
  // //     await User.saveDashboardVisibleCharts({
  // //       usersId: userDetails.id,
  // //       tableName: K.DashboardKeys.VisibleCharts,
  // //       configJson: JSON.stringify(checkedValues),
  // //     });
  // //     setPageData((prev) => ({ ...prev, selectedCharts: checkedValues }));
  // //     message.success("Charts configration saved");
  // //   } catch (err) {
  // //     console.error(err);
  // //   }
  // // };
  // const checkBoxHandler = async (checkedValues) => {
  //   isInitialRender.current = false;
  //   setPageData((prev) => ({
  //     ...prev,
  //     selectedCharts: checkedValues,
  //     isPanelSetting: true,
  //   }));
  // };
  //
  // const getChartsConfigrations = async () => {
  //   try {
  //     const res = await User.getConfigrations(K.DashboardKeys.VisibleCharts);
  //     setPageData((prev) => ({
  //       ...prev,
  //       selectedCharts: JSON.parse(res.config),
  //     }));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  //
  // const items = [
  //   {
  //     key: 1,
  //     label: (
  //       <Checkbox.Group
  //         onChange={checkBoxHandler}
  //         defaultValue={pageData.selectedCharts}
  //       >
  //         <Space direction="vertical">
  //           {panelSettingsOptions.map((checkbox, i) => (
  //             <Checkbox key={i} value={checkbox}>
  //               {checkbox}
  //             </Checkbox>
  //           ))}
  //         </Space>
  //       </Checkbox.Group>
  //     ),
  //   },
  // ];
  //
  // const getJobsByClientIds = async () => {
  //   setIsLoading((prev) => {
  //     return { ...prev, filterLoading: true };
  //   });
  //   try {
  //     const res = await Job.getByClientIds(
  //       globalSelector.selectedClients,
  //       false,
  //     );
  //     setPageData((prev) => ({ ...prev, jobs: res }));
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   setIsLoading((prev) => {
  //     return { ...prev, filterLoading: false };
  //   });
  // };
  //
  // const getJobLocations = async () => {
  //   try {
  //     const body = {
  //       client_ids: globalSelector.selectedClients ?? [],
  //     };
  //     const res = await LookupTable.getJobLocationByMultipleClient(body, false);
  //     setPageData((prev) => {
  //       return { ...prev, jobLocationsListing: res };
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  //
  // const getClientDepartments = async () => {
  //   try {
  //     const body = {
  //       client_ids: globalSelector.selectedClients ?? [],
  //     };
  //     const res = await LookupTable.getJobDepartmentByMultipleClient(
  //       body,
  //       false,
  //     );
  //     setPageData((prev) => ({ ...prev, jobDepartmentListing: res }));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  //
  // // const getUserDesignations = async () => {
  // //   try {
  // //     const userDesignation = await LookupTable.getDesignationData();
  // //     const hiringManager = userDesignation.find(
  // //       (item) => item.name === "Hiring Manager"
  // //     );
  // //     const data = await User.getbyDesignation(hiringManager.id);
  // //     setPageData((prev) => ({ ...prev, hiringManagers: data.users }));
  // //   } catch (err) {
  // //     console.error(err);
  // //   }
  // // };
  // const getClientRecruiter = async () => {
  //   try {
  //     const body = {
  //       client_ids: globalSelector.selectedClients ?? [],
  //     };
  //     const recruiter = await Client.getRecruiterByClientsId(body, false);
  //
  //     setPageData((prev) => ({ ...prev, recruiters: recruiter }));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  //
  // const onFinish = (values) => {
  //   const data = { ...values };
  //   //retain search and filter
  //
  //   Object.keys(data).forEach(
  //     (key) =>
  //       (data[key] === undefined ||
  //         data[key] === "" ||
  //         key === "date" ||
  //         key === "managerId" ||
  //         key === "recruiterId" ||
  //         key === "type") &&
  //       delete data[key],
  //   );
  //   console.log({ data, values });
  //   setter(values, searchFilterPrefix);
  //   const filter = Object.keys(data)
  //     .map((k) => {
  //       if (k === "ext_ats_id") {
  //         return k + "='" + data[k] + "'";
  //       } else {
  //         return k + "=" + data[k];
  //       }
  //     })
  //     .join(" AND ");
  //
  //   const body = {
  //     filter,
  //     managerId: values.managerId ?? null,
  //     type: values.type ?? null,
  //     clientIds: globalSelector.selectedClients,
  //     recruiterId: values.recruiterId ?? null,
  //     range: [
  //       moment(values.date[0]).format(K.DateFormat.Response),
  //       moment(values.date[1]).format(K.DateFormat.Response),
  //     ],
  //   };
  //
  //   setPrintData((prev) => ({
  //     ...prev,
  //     historicalPerformance: {
  //       ...prev.historicalPerformance,
  //       requisition: values.ext_ats_id,
  //       type: lowerCaseAllExceptFirstLetter(values.type),
  //       range: `${moment(values.date[0]).format(
  //         K.DateFormat.USFormat,
  //       )} to ${moment(values.date[1]).format(K.DateFormat.USFormat)}`,
  //     },
  //   }));
  //   setHistoricalPerformance(body);
  // };
  // const getClientHiringManager = async () => {
  //   try {
  //     //*id = clientID
  //     const body = { clientIds: globalSelector.selectedClients };
  //
  //     const hiringManagerData = await User.getClientHiringManager(body, false);
  //
  //     setPageData((prev) => ({
  //       ...prev,
  //       hiringManagers: [...hiringManagerData],
  //     }));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  //
  // useEffect(() => {
  //   getJobsByClientIds();
  // }, [globalSelector.selectedClients]);
  //
  // useEffect(() => {
  //   (async () => {
  //     setIsLoading((prev) => {
  //       return { ...prev, filterLoading: true };
  //     });
  //     await Promise.all([
  //       getJobLocations(),
  //
  //       // getUserDesignations(),
  //       getClientRecruiter(),
  //       getClientDepartments(),
  //       getClientHiringManager(),
  //     ]);
  //     setIsLoading((prev) => {
  //       return { ...prev, filterLoading: false };
  //     });
  //   })();
  // }, [globalSelector.selectedClients]);
  //
  // return (
  //   <>
  //     <Card
  //       className="historicalPerformanceCard"
  //       loading={isLoading.filterLoading}
  //     >
  //       <div className="historicalPerformance">
  //         <Title className="hpTitle" level={5}>
  //           Historical Performance
  //         </Title>
  //         <Form form={form} className="selectMenu" onFinish={onFinish}>
  //           <Row gutter={[20, 20]}>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="date"
  //                 initialValue={[
  //                   moment(getFirstDayCurrMonth()),
  //                   moment(getLastDayCurrMonth()),
  //                 ]}
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: "Please select date range",
  //                   },
  //                 ]}
  //               >
  //                 <RangePicker format={K.DateFormat.DashUSFormat} />
  //               </Form.Item>
  //             </Col>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="type"
  //                 initialValue={getter(`${searchFilterPrefix}_type`) ?? "WEEK"}
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: "Please select type",
  //                   },
  //                 ]}
  //               >
  //                 <Select
  //                   showSearch
  //                   allowClear
  //                   optionFilterProp="label"
  //                   options={[
  //                     { value: "WEEK", label: "Group By Week" },
  //                     { value: "MONTH", label: "Group By Month" },
  //                   ]}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="id"
  //                 initialValue={getter(`${searchFilterPrefix}_id`)}
  //               >
  //                 <Select
  //                   showSearch
  //                   allowClear
  //                   optionFilterProp="label"
  //                   placeholder="Select Job"
  //                   options={pageData.jobs.map((item) => ({
  //                     value: item.id,
  //                     label: item.title,
  //                   }))}
  //                   onChange={(_, option) => {
  //                     setPrintData((prev) => ({
  //                       ...prev,
  //                       historicalPerformance: {
  //                         ...prev.historicalPerformance,
  //                         job: option?.label ?? K.NullPlaceholder,
  //                       },
  //                     }));
  //                   }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="job_locations_id"
  //                 initialValue={getter(
  //                   `${searchFilterPrefix}_job_locations_id`,
  //                 )}
  //               >
  //                 <Select
  //                   showSearch
  //                   allowClear
  //                   optionFilterProp="label"
  //                   placeholder="Job Location"
  //                   options={pageData.jobLocationsListing.map(
  //                     ({ id, name }) => ({
  //                       value: id,
  //                       label: name,
  //                     }),
  //                   )}
  //                   onChange={(_, option) => {
  //                     setPrintData((prev) => ({
  //                       ...prev,
  //                       historicalPerformance: {
  //                         ...prev.historicalPerformance,
  //                         location: option?.label ?? K.NullPlaceholder,
  //                       },
  //                     }));
  //                   }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="job_departments_id"
  //                 initialValue={getter(
  //                   `${searchFilterPrefix}_job_departments_id`,
  //                 )}
  //               >
  //                 <Select
  //                   showSearch
  //                   allowClear
  //                   optionFilterProp="label"
  //                   placeholder="Job Department"
  //                   options={pageData.jobDepartmentListing.map((item) => ({
  //                     value: item.id,
  //                     label: item.name,
  //                   }))}
  //                   onChange={(_, option) => {
  //                     setPrintData((prev) => ({
  //                       ...prev,
  //                       historicalPerformance: {
  //                         ...prev.historicalPerformance,
  //                         department: option?.label ?? K.NullPlaceholder,
  //                       },
  //                     }));
  //                   }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="managerId"
  //                 initialValue={getter(`${searchFilterPrefix}_managerId`)}
  //               >
  //                 <Select
  //                   showSearch
  //                   allowClear
  //                   optionLabelProp="title"
  //                   optionFilterProp="children"
  //                   placeholder="Manager"
  //                   filterOption={customUserHandleSearch}
  //                   // options={pageData.hiringManagers.map((item) => ({
  //                   //   value: item.id,
  //                   //   label: item.name,
  //                   // }))}
  //                   onChange={(_, option) => {
  //                     setPrintData((prev) => ({
  //                       ...prev,
  //                       historicalPerformance: {
  //                         ...prev.historicalPerformance,
  //                         manager: option?.label ?? K.NullPlaceholder,
  //                       },
  //                     }));
  //                   }}
  //                 >
  //                   {pageData.hiringManagers.map(
  //                     ({ id, name, email, clients }) => (
  //                       <Option title={name} key={id} value={id}>
  //                         <UserSelectOption
  //                           key={id}
  //                           userEmail={email}
  //                           clients={clients}
  //                         >
  //                           {name}
  //                         </UserSelectOption>
  //                       </Option>
  //                     ),
  //                   )}
  //                 </Select>
  //               </Form.Item>
  //             </Col>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="ext_ats_id"
  //                 initialValue={getter(`${searchFilterPrefix}_ext_ats_id`)}
  //               >
  //                 <Input placeholder="Requisition ID" />
  //               </Form.Item>
  //             </Col>
  //             <Col xs={24} sm={12} md={6}>
  //               <Form.Item
  //                 className="formItems"
  //                 name="recruiterId"
  //                 initialValue={getter(`${searchFilterPrefix}_recruiterId`)}
  //               >
  //                 <Select
  //                   showSearch
  //                   allowClear
  //                   optionLabelProp="title"
  //                   optionFilterProp="children"
  //                   placeholder="Recruiters"
  //                   filterOption={customUserHandleSearch}
  //                   onChange={(_, option) => {
  //                     setPrintData((prev) => ({
  //                       ...prev,
  //                       historicalPerformance: {
  //                         ...prev.historicalPerformance,
  //                         manager: option?.label ?? K.NullPlaceholder,
  //                       },
  //                     }));
  //                   }}
  //                 >
  //                   {pageData?.recruiters?.map(
  //                     ({ id, name, email, clients }) => (
  //                       <Option title={name} key={id} value={id}>
  //                         <UserSelectOption
  //                           key={id}
  //                           userEmail={email}
  //                           clients={clients}
  //                         >
  //                           {name}
  //                         </UserSelectOption>
  //                       </Option>
  //                     ),
  //                   )}
  //                 </Select>
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           <Form.Item className="formSubmitBtn">
  //             <Button type="primary" htmlType="submit">
  //               Submit
  //             </Button>
  //           </Form.Item>
  //         </Form>
  //       </div>
  //     </Card>
  //
  //     <RecruitmentPiplineCard
  //       pageData={pageData}
  //       historicalPerformanceData={historicalPerformanceData}
  //       setPageData={setPageData}
  //     />
  //
  //     <div className="graphHeaderTitle">
  //       <div className="graphHeading">
  //         <Title level={4}>Performance Summary</Title>
  //       </div>
  //       <Dropdown trigger="click" menu={{ items }}>
  //         <Space>
  //           Panel Settings
  //           <i className="icon-Vector"></i>
  //         </Space>
  //       </Dropdown>
  //     </div>
  //     <div className="graphHeaderTabs">
  //       <DraggableContainer
  //         setPageData={setPageData}
  //         pageData={pageData}
  //         isInitialRender={isInitialRender}
  //         selectedCharts={pageData.selectedCharts}
  //         historicalPerformanceData={historicalPerformanceData}
  //       />
  //     </div>
  //   </>
  // );
}
