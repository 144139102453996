import NetworkCall from "../../network/networkCall";
import Request from "../../network/request";
import { camelCaseKeysRecursively } from "../../utilities/generalUtility";

export default class CandidateActivity {
  static async getAll(candidateId) {
    const res = await NetworkCall.fetch(Request.v2.getCandidateActivities(candidateId));
    return { ...res, data: camelCaseKeysRecursively(res.data) };
  }
}
