import { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import K from "utilities/constants";
import {
  breakdownDefaultResponsiveSettings,
  breakdownSliderSettings,
  statisticsSliderSettings,
} from "config/sliderConfig";
import { useParams, useHistory } from "react-router-dom";
import Candidate from "redux/models/candidate";
import { setCandidateList } from "redux/slices/candidateSlice";
import { useDispatch } from "react-redux";
import {
  checkStatusExist,
  convertIntoSnakeCase,
  removeSpacesAndPascalSentenceCase,
} from "utilities/generalUtility";

const { Title, Paragraph } = Typography;

export default function JobStatistics({ styles, jobData, setCurrentPage }) {
  const { statistics, status, subStatus, jobDetails } = jobData;

  const {
    clientId,
    jobId,
    statusesId,
    statusName,
    subStatusesId,
    subStatusName,
  } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const [viewMore, setViewMore] = useState(false);

  const [pageState, setPageState] = useState({
    statusId: null,
    selectedStatusName: "",
    subStatusId: null,
    selectedSubStatusName: "",
  });

  const [breakdownResponsive, setBreakdownResponsive] = useState(
    breakdownDefaultResponsiveSettings,
  );

  const selectStatus = (
    statusName = "",
    id,
    subStatusName = "",
    subStatusId = null,
  ) => {
    let path = `/clients/${clientId}/job/${jobId}`;
    if (id) {
      path = path + `/${id}/${convertIntoSnakeCase(statusName)}`;
    }
    if (subStatusId) {
      path = path + `/${subStatusId}/${convertIntoSnakeCase(subStatusName)}`;
    }

    setPageState((prev) => {
      return {
        statusId: id,
        selectedStatusName: id
          ? removeSpacesAndPascalSentenceCase(statusName)
          : "",
        selectedSubStatusName: subStatusId ? subStatusName : "",
        subStatusId: subStatusId ? subStatusId : prev.subStatusId,
      };
    });

    if (id !== statusesId && subStatusId !== subStatusesId) {
      history.push(path);
    }
  };
  const selectSubStatus = (subStatusName = "", id) => {
    let path = `/clients/${clientId}/job/${jobId}/${
      pageState.statusId
    }/${convertIntoSnakeCase(
      pageState.selectedStatusName,
    )}/${id}/${convertIntoSnakeCase(subStatusName)}`;

    history.push(path);

    setPageState((prev) => {
      return {
        ...prev,
        selectedSubStatusName: subStatusName,
        subStatusId: id,
      };
    });
  };

  // const getCandidateByJobId = async () => {
  //   setPageState((prev) => {
  //     return {
  //       ...prev,
  //       selectedStatusName: "",
  //       selectedSubStatusName: "",
  //     };
  //   });

  //   try {
  //     const data = await Candidate.getByJobId(jobId);
  //     dispatch(setCandidateList(data));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const sortCandidateByStatus = async (statusId, subStatusId = null) => {
    try {
      const data = await Candidate.sortCandidateByStatus(
        statusesId ? statusesId : statusId,
        jobId,
        subStatusesId ? subStatusesId : subStatusId,
      );
      dispatch(setCandidateList(data));
    } catch (err) {
      console.error(err);
    }
  };

  const hiredCandidates =
    status?.find(({ name }) => name === "Hired")?.total ?? 0;

  useEffect(() => {
    if (statusesId) {
      if (statusesId > 6) {
        setViewMore(true);
      }
      if (subStatusesId && checkStatusExist(statusName, subStatusName)) {
        selectStatus(statusName, statusesId, subStatusName, subStatusesId);
      } else {
        checkStatusExist(statusName, subStatusName) &&
          selectStatus(statusName, statusesId);
      }
    } else {
      selectStatus("", null, "", null);
    }
  }, [statusesId, subStatusesId]);

  useEffect(() => {
    if (Object.keys(subStatus)?.length > 0) {
      if (pageState.selectedStatusName) {
        if (subStatus[pageState.selectedStatusName]?.length < 3) {
          const updated = breakdownDefaultResponsiveSettings.map((item) => {
            if (item.breakpoint === 380) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              };
            } else {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: subStatus[pageState.selectedStatusName]?.length,
                  slidesToScroll:
                    subStatus[pageState.selectedStatusName]?.length,
                },
              };
            }
          });
          setBreakdownResponsive(updated);
        } else {
          const updated = breakdownDefaultResponsiveSettings.map((item) => {
            if (item.breakpoint === 1318) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow:
                    subStatus[pageState.selectedStatusName]?.length < 7
                      ? subStatus[pageState.selectedStatusName]?.length % 7
                      : 7,
                  slidesToScroll:
                    subStatus[pageState.selectedStatusName]?.length < 7
                      ? subStatus[pageState.selectedStatusName]?.length % 7
                      : 7,
                },
              };
            } else if (item.breakpoint === 576) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              };
            } else if (item.breakpoint === 1200) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 6,
                  slidesToScroll: 3,
                },
              };
            } else if (item.breakpoint === 1050) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 4,
                  slidesToScroll: 3,
                },
              };
            } else if (item.breakpoint === 670) {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              };
            } else {
              return {
                breakpoint: item.breakpoint,
                settings: {
                  ...item.settings,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              };
            }
          });
          setBreakdownResponsive(updated);
        }

        // sortCandidateByStatus();
        setCurrentPage((prev) => ({ ...prev, pageNo: 1 }));
      }
    }
  }, [pageState.selectedStatusName, pageState.selectedSubStatusName]);

  return (
    <>
      <Card
        className={"statistics-card statistics-wrap " + styles.statisticsCard}
        title="Statistics"
      >
        <Slider
          {...statisticsSliderSettings}
          className={"statistics-wrap " + styles.statisticsWrap}
        >
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Total Openings
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {jobDetails?.vacancies?.totalOpenings ?? K.NullPlaceholder}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Current Openings
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {jobDetails?.vacancies?.currentOpenings ?? 0}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Canceled
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {jobDetails?.vacancies?.cancel}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Filled
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {hiredCandidates}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Time to Submit
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {isNaN(
                statistics?.timeToSubmitDays /
                  statistics?.timeToSubmitCandidates,
              )
                ? K.NullPlaceholder
                : (
                    statistics?.timeToSubmitDays /
                    statistics?.timeToSubmitCandidates
                  ).toFixed(2)}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Time to Offer
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {isNaN(
                statistics?.timeToOfferDays / statistics?.timeToOfferCandidates,
              )
                ? K.NullPlaceholder
                : (
                    statistics?.timeToOfferDays /
                    statistics?.timeToOfferCandidates
                  ).toFixed(2)}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Time to Accept
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {isNaN(
                statistics?.timeToAcceptDays /
                  statistics?.timeToAcceptCandidates,
              )
                ? K.NullPlaceholder
                : (
                    statistics?.timeToAcceptDays /
                    statistics?.timeToAcceptCandidates
                  ).toFixed(2)}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Offer to Hire
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {isNaN(
                statistics?.offerToHireDays / statistics?.offerToHireCandidates,
              )
                ? K.NullPlaceholder
                : (
                    statistics?.offerToHireDays /
                    statistics?.offerToHireCandidates
                  ).toFixed(2)}
            </span>
          </div>
          <div className={styles.statisticsData + " statistic-data"}>
            <Title level={5} className={styles.statisticsTitle}>
              Time to Start
            </Title>
            <span className={styles.statisticsCount + " statistics-count"}>
              {isNaN(
                statistics?.timeToStartDays / statistics?.timeToStartCandidates,
              )
                ? K.NullPlaceholder
                : (
                    statistics?.timeToStartDays /
                    statistics?.timeToStartCandidates
                  ).toFixed(2)}
            </span>
          </div>
        </Slider>
      </Card>
      <Card
        bordered={false}
        className={
          styles.statusCard +
          " status-card-wrap " +
          `${!pageState.selectedStatusName ? " mb-4" : ""}`
        }
      >
        <div className={styles.statusCardHeader}>
          <Title level={5}>
            Status
            {/* <Divider type="vertical" className={styles.titleDivider} />
            <span>
              &nbsp;Total{" "}
              {status.reduce((prev, curr) => {
                return prev + curr.total;
              }, 0)}
            </span> */}
          </Title>
          <h5 className="total-number">
            &nbsp;Total{" "}
            {status?.reduce((prev, curr) => {
              return prev + curr.total;
            }, 0)}
          </h5>
          {/* <Button
            className={styles.viewMoreBtn}
            type="link"
            onClick={() => setViewMore(!viewMore)}
          >
            {viewMore ? "Show Less" : "View More"}
          </Button> */}
        </div>
        <Row
          gutter={[12, 12]}
          // style={{ height: viewMore ? "auto" : "68px", overflow: "hidden" }}
        >
          {status?.map(({ name, total, id }) => (
            <Col
              key={name}
              xs={24}
              sm={12}
              md={8}
              lg={4}
              className={
                convertIntoSnakeCase(pageState.selectedStatusName) ===
                convertIntoSnakeCase(name)
                  ? "box-active"
                  : ""
              }
              onClick={() => selectStatus(convertIntoSnakeCase(name), id)}
            >
              <div
                className={styles.statusCardInnerBoxes + " status-inner-boxes"}
              >
                <Title level={5}>
                  {total}
                  {convertIntoSnakeCase(pageState.selectedStatusName) ===
                  convertIntoSnakeCase(name) ? (
                    <i className="icon-Vector"></i>
                  ) : (
                    <i className="icon-dropdown-arrow"></i>
                  )}
                </Title>
                <Paragraph
                  className={styles.jobsDescription + " hover-underlined"}
                >
                  {name}
                </Paragraph>
              </div>
            </Col>
          ))}
        </Row>
        {pageState.selectedStatusName && (
          <Card
            title="Status Count Breakdown"
            extra={
              <>
                <Button
                  type="link"
                  className={styles.cardCloseIcon}
                  icon={<i className="icon-closeable"></i>}
                  onClick={() => {
                    history.push(`/clients/${clientId}/job/${jobId}`);
                    // getCandidateByJobId();
                  }}
                />
                {/* <Title level={5}>Declined: 03</Title> */}
              </>
            }
            className={
              "status-count-card count-breakdown-card " + styles.statusCountCard
            }
          >
            <Slider
              className={"status-count-slider " + styles.statusCountSlider}
              slidesToShow={
                subStatus[pageState.selectedStatusName]?.length < 7
                  ? subStatus[pageState.selectedStatusName].length % 7
                  : 7
              }
              {...breakdownSliderSettings}
              responsive={breakdownResponsive}
            >
              {subStatus[pageState.selectedStatusName]?.map(
                ({ id, name, candidatesToSubStatusCount }) => (
                  <div
                    key={name}
                    className={
                      convertIntoSnakeCase(pageState.selectedSubStatusName) ===
                      convertIntoSnakeCase(name)
                        ? "count-breakdown-open active"
                        : "count-breakdown-open"
                    }
                    // className="count-breakdown-open active"
                    onClick={() =>
                      selectSubStatus(convertIntoSnakeCase(name), id)
                    }
                  >
                    <Title level={5}>{candidatesToSubStatusCount}</Title>
                    <Paragraph
                      className={styles.jobsDescription + " hover-underlined"}
                    >
                      {name}
                    </Paragraph>
                  </div>
                ),
              )}
            </Slider>
          </Card>
        )}
      </Card>

      {/* {pageState.selectedStatusName && (
        <Card
          title="Status Count Breakdown"
          extra={
            <Button
              type="link"
              className={styles.cardCloseIcon}
              icon={<i className="icon-closeable"></i>}
              onClick={() => {
                history.push(`/clients/${clientId}/job/${jobId}`);
                // getCandidateByJobId();
              }}
            />
          }
          className={
            "status-count-card count-breakdown-card " + styles.statusCountCard
          }
        >
          <Slider
            className={"status-count-slider " + styles.statusCountSlider}
            slidesToShow={
              subStatus[pageState.selectedStatusName]?.length < 7
                ? subStatus[pageState.selectedStatusName].length % 7
                : 7
            }
            {...breakdownSliderSettings}
            responsive={breakdownResponsive}
          >
            {subStatus[pageState.selectedStatusName]?.map(
              ({ id, name, candidatesToSubStatusCount }) => (
                <div
                  key={name}
                  className={
                    convertIntoSnakeCase(pageState.selectedSubStatusName) ===
                    convertIntoSnakeCase(name)
                      ? `${styles.active} ${styles.StatusCountCardBoxes}`
                      : styles.StatusCountCardBoxes
                  }
                  onClick={() =>
                    selectSubStatus(convertIntoSnakeCase(name), id)
                  }
                >
                  <Title level={5}>{candidatesToSubStatusCount}</Title>
                  <Paragraph
                    className={styles.jobsDescription + " hover-underlined"}
                  >
                    {name}
                  </Paragraph>
                </div>
              ),
            )}
          </Slider>
        </Card>
      )} */}
    </>
  );
}
