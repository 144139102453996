import { Button, Card, List, Typography } from "antd";

const { Text } = Typography;
export default function DashboardJobDetailModal({
  details,
  jumpToJobDetail,
  statusRef,
  isLoading,
}) {
  return isLoading ? (
    <Card loading={true} />
  ) : (
    <List
      className="job-detail-list"
      dataSource={details}
      renderItem={(item, index) =>
        item.candidateEntries.length > 0 && (
          <List.Item key={index}>
            <Card
              className="job-detail-card"
              title={
                <>
                  {/* <div
                    onClick={() =>
                      history.push(`/clients/${item.clientsId}/job/${item.id}`)
                    }
                  > */}
                  <div style={{ cursor: "pointer" }}>
                    <Text
                      strong
                      onClick={() =>
                        jumpToJobDetail(
                          item.clientsId,
                          item.id,
                          statusRef.current?.statusId,
                          statusRef.current.statusName,
                          statusRef.current.subStatusId,
                          statusRef.current.subStatusName,
                        )
                      }
                    >
                      {`${item.title}`}
                      <span className="sub-text">{`- ${item.jobLocation?.name}`}</span>
                    </Text>
                    <br />
                    <Text className="sub-text">{`${item.client?.name} `}</Text>
                  </div>
                  {/* </div> */}
                </>
              }
              extra={
                <Text strong>
                  <i className={"icon-user"}></i> {item.candidateEntries.length}
                </Text>
              }
              bodyStyle={{ padding: "15px 12px" }}
            >
              <div>
                {item.candidateEntries?.map(({ candidate }, index) => (
                  <>
                    <Text
                      key={index}
                    >{` ${candidate?.firstName}  ${candidate?.lastName} - ${candidate?.email}`}</Text>
                    &nbsp;
                    <br />
                  </>
                ))}
              </div>
            </Card>
          </List.Item>
        )
      }
    />
  );
}
