import { Card, Col, Form, Row, Typography } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import BillingClient from "redux/models/billingClients";
import BillingJob from "redux/models/billingJobs";
import { updateCreateData } from "redux/slices/clientSlice";
import K from "utilities/constants";
import styles from "./billingRules.module.scss";
import ClientBillingRuleFields from "./clientBillingRuleFields";

const { Title } = Typography;

export function ClientBillingRule({ form }) {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    const clientLevelBilligRules = values.billingTypes.reduce((prev, curr) => {
      const ruleList = values[curr].map((rule) => ({
        alias: rule.alias,
        amount: rule.amount,
        markup: rule.markup,
        currency: rule.currency,
        amountType: rule.amountType,
        referralFee: rule.referralFee,
        reHiredFee: rule.reHiredFee,
        recurringType: rule.recurringType,
        repetitiveQuantity: rule.repetitiveQuantity,
        selectedDays: rule.selectedDays,
        repetitiveMonthDay: rule.repetitiveMonthDay,
        clientLevelBillingTypeId: curr,
        isInfinite: rule.isInfinite,
        endAfterOccurances: rule.isInfinite === 1 ? 0 : rule.endAfterOccurances,
      }));
      return [...prev, ...ruleList];
    }, []);
    console.log({ clientLevelBilligRules });
    dispatch(
      updateCreateData({
        key: K.Redux.ClientLevelBilligRules,
        values: clientLevelBilligRules,
      }),
    );
  };

  const getAllCurrencies = async () => {
    try {
      await dispatch(BillingJob.getCurrencies());
    } catch (err) {
      console.error(err);
    }
  };

  const getClientBillingTypes = async () => {
    try {
      await dispatch(BillingClient.getAll());
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([getAllCurrencies(), getClientBillingTypes()]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <Card className={`${styles.cardWrapper} ${LayoutCss.appCard}`}>
      <Title level={5}>Client Level Billing Rules</Title>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        className={styles.cardForm}
        onFinish={onFinish}
      >
        <Row>
          <Col xs={24} sm={12}>
            <ClientBillingRuleFields
              styles={styles}
              showEffectiveSince={false}
              form={form}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
