import { AgGridReact } from "ag-grid-react";
import { Button, Card, Form, Input, message, Modal, Popconfirm } from "antd";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  isPermissionPresent,
  noTrailingSpaceAllowedRule,
  validateMobileNumber,
} from "utilities/generalUtility";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
} from "utilities/tableUtility";

const { Search } = Input;

export default function HiringManger() {
  const [form] = Form.useForm();
  //*Client Id
  const { id } = useParams();
  const gridRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [hiringManagers, setHiringManagers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userSlice = useSelector(selectUser);

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const searchFilterPrefix = `clients_${id}_hiring_manager`;
  const getSearchKey = `clients_${id}_hiring_manager_search`;

  const [setter, getter] = useSearchAndFilter();
  const searchRef = useRef(null);

  const phoneExtRenderer = (params) =>
    params.data.phoneExt ?? K.NullPlaceholder;

  const jobTitleRenderer = (params) =>
    params.data.jobTitle ?? K.NullPlaceholder;

  const actionColumnRenderer = (params) => {
    return (
      <>
        {isPermissionPresent(
          K.Permissions.EditHiringManager,
          userSlice.roles,
        ) && (
          <>
            <Button
              type="link"
              onClick={() => {
                editRecord(params.data);
              }}
              className={LayoutCss.appListingCardActions}
            >
              Edit
            </Button>
            {params.data.isActive ? (
              <Popconfirm
                title="Are you sure you'd like to Deactivate this User?"
                onConfirm={() => {
                  //*handleActivate(params.data.id, 1) this 1 represent active :1;
                  handleActivate(params.data.id, 0);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button className={LayoutCss.appListingCardActions} type="link">
                  Deactive
                </Button>
              </Popconfirm>
            ) : (
              //*handleActivate(params.data.id, 1) this 1 represent active :1;
              <Button
                type="link"
                className={LayoutCss.appListingCardActions}
                onClick={() => handleActivate(params.data.id, 1)}
              >
                Active
              </Button>
            )}
          </>
        )}
        {/* {isPermissionPresent(
          K.Permissions.DeleteClientContact,
          userSlice.roles
        ) && (
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => {
              confirm(params.data.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" className={LayoutCss.appListingCardActions}>
              Delete
            </Button>
          </Popconfirm>
        )} */}
      </>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      tooltipField: "email",
    },
    {
      headerName: "Job Title",
      field: "jobTitle",
      sortable: true,
      tooltipField: "jobTitle",
      tooltipComponent: jobTitleRenderer,
      cellRenderer: jobTitleRenderer,
    },
    {
      headerName: "Phone Number",
      field: "phone",
      sortable: true,
      tooltipField: "phone",
    },
    {
      headerName: "Phone Ext",
      field: "phoneExt",
      sortable: true,
      tooltipField: "phoneExt",
      cellRenderer: phoneExtRenderer,
    },
    ...(isPermissionPresent(K.Permissions.EditHiringManager, userSlice.roles)
      ? [
          {
            headerName: "Actions",
            field: "actions",
            sortable: true,
            resizable: false,
            headerClass: "text-center",
            tooltipField: "actions",
            cellStyle: { textAlign: "center" },
            cellRenderer: actionColumnRenderer,
          },
        ]
      : []),
  ]);

  const handleActivate = async (userId, isActive) => {
    try {
      const res = await User.handleUserActive({
        userId: userId,
        isActive: isActive,
      });

      getAllHiringManager();
    } catch (err) {
      console.error(err);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const editRecord = (record) => {
    setEditData(record);
    showModal();
  };
  const afterModalClose = () => {
    setEditData({});
  };

  const onFinish = async (values) => {
    values = {
      ...values,
      isHiringManager: true,
      clientId: [id],
      userDesignationId: [6],
      roleId: 4,
      type: K.UserType.Source2.value,
      isManager: false,
    };
    setIsLoading(true);
    if (Object.keys(editData).length) {
      try {
        values = { ...values, user_id: editData.id };
        const res = await User.updateUser(values, editData.id);
        //
        getAllHiringManager();
        setIsLoading(false);
        setIsModalVisible(false);
        form.resetFields();
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
      setEditData({});
    } else {
      try {
        await User.createUser(values);
        getAllHiringManager();
        setIsLoading(false);
        setIsModalVisible(false);
        form.resetFields();
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }
  };

  const getAllHiringManager = async () => {
    try {
      //*id = clientID
      const body = { clientIds: [id] };
      const res = await User.getClientHiringManager(body);

      setHiringManagers(res);
    } catch (err) {
      console.error(err);
    }
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.HiringManagerVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.HiringManagerVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions")
          return {
            ...item,
            cellRenderer: actionColumnRenderer,
          };
        else if (item.field === "phoneExt")
          return {
            ...item,
            cellRenderer: phoneExtRenderer,
          };
        else if (item.field === "jobTitle")
          return {
            ...item,
            cellRenderer: jobTitleRenderer,
            tooltipComponent: jobTitleRenderer,
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };
  //?save cloumn Sorting
  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userSlice.details?.id,
          tableName: K.AgGridTable.Keys.HiringManagerVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onChange = useCallback(
    (event) => {
      onFilterTextBoxChanged(event.target.value, gridRef);
      setter({ search: event.target.value }, searchFilterPrefix);
    },
    [searchRef.current],
  );

  useEffect(() => {
    form.resetFields();
  }, [editData]);

  useEffect(() => {
    (async () => {
      await Promise.all([getAllHiringManager(), getColumnsConfigrations()]);
    })();
  }, []);

  return (
    <>
      <Card
        title="Hiring Manager List"
        className={"appCard " + LayoutCss.appCard}
        extra={
          <div className="hiring-manager">
            <Search
              allowClear
              placeholder="Search"
              value={getter(getSearchKey)}
              onChange={onChange}
              className={LayoutCss.appListingCardSearchBar1}
            />
            {isPermissionPresent(
              K.Permissions.EditHiringManager,
              userSlice.roles,
            ) && (
              <Button type="primary" className="create-btn" onClick={showModal}>
                Create
              </Button>
            )}
          </div>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={hiringManagers}
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            ref={gridRef}
            onColumnMoved={updateColumns}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>
      <Modal
        forceRender
        className="s2-theme-style"
        title={`${
          Object.keys(editData).length ? "Edit" : "Create"
        } Hiring Manager`}
        open={isModalVisible}
        centered
        closeIcon={<i className="icon-closeable"></i>}
        okText={`${Object.keys(editData).length ? "Update" : "Create"}`}
        wrapClassName="vertical-center-modal"
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
        afterClose={afterModalClose}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={editData}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Name is required." },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required.",
              },
              {
                type: "email",
                message: "Enter a valid email address",
              },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: "Phone Number is required.",
              },
              {
                validator: (_, value) => {
                  if (!value || validateMobileNumber(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please enter correct number");
                  }
                },
              },
            ]}
          >
            <PatternFormat
              placeholder="Enter Phone Number"
              customInput={Input}
              format={K.PhoneFormat.USNational}
            />
          </Form.Item>
          <Form.Item
            label="Phone Ext"
            name="phoneExt"
            rules={[
              // {
              //   required: true,
              //   message: "Phone Ext is required.",
              // },
              {
                pattern: new RegExp("^[0-9]{1,6}$", "g"),
                message: "Maximum of 6 digits is allowed",
              },
            ]}
          >
            <Input placeholder="Enter Phone Ext" />
          </Form.Item>
          <Form.Item
            label="Job Title"
            name="jobTitle"
            // rules={[
            //   {
            //     required: true,
            //     message: "Job title is required.",
            //   },
            // ]}
          >
            <Input placeholder="Enter job title" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
