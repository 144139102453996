import {
  Button,
  Card,
  Grid,
  Modal,
  PageHeader,
  Popconfirm,
  Tabs,
  message,
  Typography,
} from "antd";
import UserDetailCard from "common/userDetailCard/userDetailCard";
import styles from "features/candidateDetails/candidatesDetails.module.scss";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import User from "redux/models/user";
import ProfileTab from "./profileTab";
import RecruiterTab from "./recruiterTab";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";

const { useBreakpoint } = Grid;
const { Title } = Typography;

export default function UserDetails() {
  const { id } = useParams();
  const screens = useBreakpoint();
  const history = useHistory();
  const userSlice = useSelector(selectUser);
  const [userDetail, setUserDetail] = useState({});
  const [plansDetails, setPlansDetails] = useState(null);
  const [commissionPlan, setCommissionPlan] = useState("upcomingPlans");

  const getUserDetailById = async (id) => {
    try {
      const data = await User.getUserDetailById(id);
      setUserDetail(data);
    } catch (err) {
      console.error(err);
    }
  };
  const getUserPlanTimelineById = async (id) => {
    try {
      const data = await User.getUserPlanTimeLine(id);
      setPlansDetails(data);
    } catch (err) {
      console.error(err);
    }
  };

  const activeButtonHandler = async () => {
    try {
      const res = await User.handleUserActive({
        userId: id,
        isActive: !userDetail.isActive,
      });
      setUserDetail((prev) => ({ ...prev, isActive: res.isActive }));
    } catch (err) {
      console.error(err);
    }
  };

  const deleteUser = async () => {
    try {
      const res = await User.deleteUser(id);
      history.goBack();
      success();
    } catch (error) {
      console.log(error);
    }
  };
  const success = (isEdit = false) => {
    Modal.success({
      centered: true,
      className: styles.headerModal,
      okText: "Ok",
      title: (
        <Title className={styles.modalTitle} level={5}>
          {"User deleted successfully !"}
        </Title>
      ),
    });
  };

  useEffect(() => {
    if (id) {
      getUserDetailById(id);
      getUserPlanTimelineById(id);
    }
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        className={LayoutCss.appPageHeader}
        onBack={history.goBack}
        title={userDetail.name}
        extra={
          <>
            {userDetail.isActive ? (
              <Popconfirm
                title="Are you sure you'd like to Deactivate this User?"
                onConfirm={() => {
                  activeButtonHandler();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Deactivate</Button>
              </Popconfirm>
            ) : (
              <Button type="primary" onClick={activeButtonHandler}>
                Active
              </Button>
            )}

            <Button type="primary">
              <Link to={`/configurations/users/edit/${id}`}>Edit</Link>
            </Button>

            {isPermissionPresent(K.Permissions.DeleteUser, userSlice.roles) && (
              <Popconfirm
                placement="bottom"
                title={"Are you sure you want to delete this user?"}
                onConfirm={deleteUser}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger={true}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <div className={styles.candidateDetailsWrapper}>
        <UserDetailCard styles={styles} userDetail={userDetail} />
        <Card
          className={styles.candidateInfoCard}
          bodyStyle={{ padding: "4px 0 1.5rem" }}
        >
          <Tabs
            defaultActiveKey={1}
            className="candidates-info-card"
            tabBarGutter={40}
            items={[
              {
                key: 1,
                label: <span className="cic-tabs-label-style">Profile</span>,
                children: (
                  <ProfileTab
                    screens={screens}
                    styles={styles}
                    userDetail={userDetail}
                    plansDetails={plansDetails}
                    commissionPlan={commissionPlan}
                    setCommissionPlan={setCommissionPlan}
                    setPlanDetails={setPlansDetails}
                  />
                ),
              },
              userDetail?.isManager ?? false
                ? {
                    key: 2,
                    label: (
                      <span className="cic-tabs-label-style">Recruiter</span>
                    ),
                    children: (
                      <RecruiterTab
                        styles={styles}
                        recruiters={userDetail?.recruiters ?? []}
                      />
                    ),
                  }
                : {},
            ]}
          />
        </Card>
      </div>
    </>
  );
}
