import { Card, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dashboard from "redux/models/dashboard";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";

const { Title, Text } = Typography;

export default function RecruitmentPiplineCard({
  pageData,
  historicalPerformanceData,
  setPageData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const globalSelector = useSelector(selectGlobalSelector);

  const getHistoricalPerformance = async () => {
    const payload = {
      ...historicalPerformanceData,
      clientIds: globalSelector.selectedClients,
    };
    try {
      setIsLoading(true);
      const res = await Dashboard.getRecruitmentPipelineData(payload);
      setPageData((prev) => ({ ...prev, recruitmentPipelineData: res }));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    getHistoricalPerformance();
  }, [historicalPerformanceData, globalSelector.selectedClients]);

  return (
    <Card
      loading={isLoading}
      className="recruitmentPipelineCard"
      title="Recruitment Pipeline"
    >
      <Row gutter={[30, 30]} className="recruitmentPipelineRow">
        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec withdrewCol openingCol">
            <Title level={5}>Openings</Title>
            <Text>{pageData.recruitmentPipelineData?.openings}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec withdrewCol">
            <Title level={5}>Prospects</Title>
            <Text>{pageData.recruitmentPipelineData?.prospects}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec">
            <Title level={5}>Candidates</Title>
            <Text>{pageData.recruitmentPipelineData?.candidates}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec">
            <Title level={5}>S2 Interview</Title>
            <Text>{pageData.recruitmentPipelineData?.s2Interview}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec withdrewCol">
            <Title level={5}>S2 Decline</Title>
            <Text>
              {pageData.recruitmentPipelineData?.s2decline}
              <i className="icon-rightarrow"></i>
            </Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec">
            <Title level={5}>Submitted</Title>
            <Text>{pageData.recruitmentPipelineData?.submitted}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec candidateCol openingCol">
            <Title level={5}>Client Interview</Title>
            <Text>{pageData.recruitmentPipelineData?.clientInterview}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec openingCol">
            <Title level={5}>Client Declined</Title>
            <Text>{pageData.recruitmentPipelineData?.clientdeclined}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec candidateCol openingCol">
            <Title level={5}>Offer</Title>
            <Text>{pageData.recruitmentPipelineData?.offer}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec">
            <Title level={5}>Offer Fall Out</Title>
            <Text>{pageData.recruitmentPipelineData?.offerfallout}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec withdrewCol openingCol">
            <Title level={5}>Hired</Title>
            <Text>{pageData.recruitmentPipelineData?.hire}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec">
            <Title level={5}>Withdrew</Title>
            <Text>{pageData.recruitmentPipelineData?.withdrew}</Text>
          </div>
        </Col>

        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec openingCol">
            <Title level={5}>Unresponsive</Title>
            <Text>{pageData.recruitmentPipelineData?.unresponsive}</Text>
          </div>
        </Col>
        <Col xs={12} sm={8} md={6} xl={3}>
          <div className="recruitmentPipelineSec openingCol">
            <Title level={5}>Terminated</Title>
            <Text>{pageData.recruitmentPipelineData?.terminate}</Text>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
