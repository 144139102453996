import { Typography } from "antd";

const { Text, Paragraph } = Typography;

export default function JobSelectOption({
  children,
  title,
  location,
  client,
  extAtsId,
}) {
  return (
    <div className="custom-user-select">
      <Text key={title} className="client-detail">
        {`${children} - `}

        <span className="client-name">
          {extAtsId !== "" ? extAtsId : "N/A"}
          {" - "}
          {client?.name !== "" ? client?.name : "N/A"}
        </span>
      </Text>
      <Paragraph>{location !== "" ? location : "N/A"}</Paragraph>
    </div>
  );
}
