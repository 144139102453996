import { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Card, message, PageHeader } from "antd";
import { Link, useHistory } from "react-router-dom";
import LayoutCss from "layout/layout.module.scss";
import CommissionPlan from "redux/models/commissionPlan";
import K from "utilities/constants";
import User from "redux/models/user";
import {
  displayDollar,
  displayPercentage,
  filterColumnListing,
} from "utilities/tableUtility";
import { selectUser } from "redux/slices/userSlice";
import { useSelector } from "react-redux";
import { isPermissionPresent } from "utilities/generalUtility";

const dataUnitObj = {
  defaultBillingThreshold: "defaultBillingThresholdUnit",
  appliedHire: "appliedHireUnit",
  sourceHired: "sourceHiredUnit",
  referral: "referralUnit",
  cancellations: "cancellationsUnit",
  hiringEvent: "hiringEventUnit",
};

export default function CommissionPlans() {
  const history = useHistory();
  const userSlice = useSelector(selectUser);
  const [comissionPlans, setComissionPlans] = useState([]);
  const displayDataUnit = (data, unit) => {
    if (unit === K.Format.Dollar) {
      return displayDollar(data);
    } else {
      return displayPercentage(data);
    }
  };

  const toolTipDisplayDataUnit = (data, unit) => {
    if (unit === K.Format.Dollar) {
      return <div className="client-list-tooltip">{displayDollar(data)} </div>;
    } else {
      return (
        <div className="client-list-tooltip">{displayPercentage(data)}</div>
      );
    }
  };

  const displayThresholdDataUnit = (data, type) => {
    if (type === K.CommissionThresholdType.Amount) {
      return displayDollar(data);
    } else {
      return `${data} hires `;
    }
  };

  const tooltipDisplayThresholdDataUnit = (data, type) => {
    if (type === K.CommissionThresholdType.Amount) {
      return <div className="client-list-tooltip">{displayDollar(data)}</div>;
    } else {
      return <div className="client-list-tooltip">{`${data} hires `}</div>;
    }
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Plan Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
      cellClass: "cursor-pointer",
    },
    {
      headerName: "Default  Threshold",
      field: "defaultBillingThreshold",
      cellClass: "cursor-pointer",
      tooltipField: "defaultBillingThreshold",
      tooltipComponent: (params) =>
        tooltipDisplayThresholdDataUnit(
          params.data.defaultBillingThreshold,
          params.data.thresholdType,
        ),
      sortable: true,
      cellRenderer: (params) =>
        displayThresholdDataUnit(
          params.data.defaultBillingThreshold,
          params.data.thresholdType,
        ),
    },
    {
      headerName: "Applied Hires",
      field: "appliedHire",
      sortable: true,
      cellClass: "cursor-pointer",
      tooltipField: "appliedHire",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(
          params.data.appliedHire,
          params.data.appliedHireUnit,
        ),
      cellRenderer: (params) =>
        displayDataUnit(params.data.appliedHire, params.data.appliedHireUnit),
    },

    {
      headerName: "Sourced Hires",
      field: "sourceHired",
      sortable: true,
      cellClass: "cursor-pointer",
      tooltipField: "sourceHired",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(
          params.data.sourceHired,
          params.data.sourceHiredUnit,
        ),
      cellRenderer: (params) =>
        displayDataUnit(params.data.sourceHired, params.data.sourceHiredUnit),
    },
    {
      headerName: "Referrals",
      field: "referral",
      sortable: true,
      cellClass: "cursor-pointer",
      tooltipField: "referral",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(params.data.referral, params.data.referralUnit),
      cellRenderer: (params) =>
        displayDataUnit(params.data.referral, params.data.referralUnit),
    },
    {
      headerName: "Cancellations",
      field: "cancellations",
      sortable: true,
      cellClass: "cursor-pointer",
      tooltipField: "cancellations",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(
          params.data.cancellations,
          params.data.cancellationsUnit,
        ),
      cellRenderer: (params) =>
        displayDataUnit(
          params.data.cancellations,
          params.data.cancellationsUnit,
        ),
    },
    {
      headerName: "Hiring Events",
      field: "hiringEvent",
      sortable: true,
      cellClass: "cursor-pointer",
      tooltipField: "hiringEvent",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(
          params.data.hiringEvent,
          params.data.hiringEventUnit,
        ),
      cellRenderer: (params) =>
        displayDataUnit(params.data.hiringEvent, params.data.hiringEventUnit),
    },
    {
      headerName: "Rehire Applied",
      field: "reHireApplied",
      sortable: true,
      cellClass: "cursor-pointer",
      tooltipField: "reHireApplied",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(
          params.data.reHireApplied,
          params.data.reHireAppliedUnit,
        ),
      cellRenderer: (params) =>
        displayDataUnit(
          params.data.reHireApplied,
          params.data.reHireAppliedUnit,
        ),
    },
    {
      headerName: "Rehire Referral",
      field: "reHireReferral",
      sortable: true,
      cellClass: "cursor-pointer",
      tooltipField: "reHireReferral",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(
          params.data.reHireReferral,
          params.data.reHireReferralUnit,
        ),
      cellRenderer: (params) =>
        displayDataUnit(
          params.data.reHireReferral,
          params.data.reHireReferralUnit,
        ),
    },

    {
      headerName: "Rehire Referral",
      field: "reHireSourced",
      cellClass: "cursor-pointer",
      tooltipField: "reHireSourced",
      tooltipComponent: (params) =>
        toolTipDisplayDataUnit(
          params.data.reHireSourced,
          params.data.reHireSourcedUnit,
        ),
      sortable: true,
      cellRenderer: (params) =>
        displayDataUnit(
          params.data.reHireSourced,
          params.data.reHireSourcedUnit,
        ),
    },
  ]);

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.CommissionPlansVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.CommissionPlansVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (
          [
            "appliedHire",
            "sourceHired",
            "referral",
            "cancellations",
            "hiringEvent",
            "rehireApplied",
            "rehireRefferal",
            "rehireSourced",
          ].includes(item.field)
        ) {
          return {
            ...item,
            cellRenderer: (params) =>
              displayDataUnit(
                params.data[item.field],
                params.data[dataUnitObj[item.field]],
              ),
            tooltipComponent: (params) =>
              toolTipDisplayDataUnit(
                params.data[item.field],
                params.data[dataUnitObj[item.field]],
              ),
          };
        }
        if (item.field === "defaultBillingThreshold") {
          return {
            ...item,
            cellRenderer: (params) =>
              displayThresholdDataUnit(
                params.data.defaultBillingThreshold,
                params.data.defaultBillingThresholdUnit,
                params.data.thersholdType,
              ),
            tooltipComponent: (params) =>
              tooltipDisplayThresholdDataUnit(
                params.data.defaultBillingThreshold,
                params.data.thersholdType,
              ),
          };
        }
        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllCommissionPlans = async () => {
    try {
      const res = await CommissionPlan.getAll();
      setComissionPlans(res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getAllCommissionPlans(), getColumnsConfigrations()]);
    })();
  }, []);

  const rowClickedHandler = (event) => {
    history.push(
      `/configurations/commission-plans/detail/${event.data.commissionId}`,
    );
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Commission Plans"
        extra={
          isPermissionPresent(
            K.Permissions.CreateCommissionPlan,
            userSlice.roles,
          ) && (
            <Link to="/configurations/commission-plans/create">
              <Button type="primary" className={LayoutCss.appHeaderBtn}>
                Create
              </Button>
            </Link>
          )
        }
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
      />

      <Card
        className={LayoutCss.appListingCardCommissionPlansTAble}
        bordered={false}
        style={{ marginTop: "20px" }}
      >
        <div className="ag-theme-alpine s2-theme-style" style={{ height: 735 }}>
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={comissionPlans}
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onRowClicked={rowClickedHandler}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
          />
        </div>
      </Card>
    </>
  );
}
