import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  Row,
  Tag,
  Typography,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import {
  displayUdfFieldValues,
  isPermissionPresent,
  stopPropagationFunction,
} from "utilities/generalUtility";
import OverviewModel from "./overviewModel";

import { selectUdf } from "redux/slices/udfSlice";

const { Panel } = Collapse;
const { Title } = Typography;

export default function ClientOverview({ clientData, setCurrentPage }) {
  const [editData, setEditData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userSlice = useSelector(selectUser);
  const udfSlice = useSelector(selectUdf);

  const [columnDefs] = useState([
    {
      headerName: "Id",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },
    {
      headerName: "Job Title",
      field: "jobTitle",
      sortable: true,
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      sortable: true,
    },
  ]);

  const addRecord = () => {
    setIsModalVisible(true);
  };

  const showModal = (values) => {
    setEditData(values);
    setIsModalVisible(values);
  };

  const showClientEditInfoModal = () => {
    setCurrentPage(1);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24}>
          <Card
            title="Client Information"
            className="clientCard"
            bordered={false}
            extra={
              isPermissionPresent(
                K.Permissions.EditClient,
                userSlice.roles,
              ) && (
                <Button
                  type="link"
                  icon={<i className="icon-edit"></i>}
                  onClick={() => showClientEditInfoModal(clientData)}
                />
              )
            }
          >
            <div className="cardContent">
              <div className="cardContentSecIcon">
                <div className="lockIcon">
                  <i className="icon-lock cardContentIcon"></i>
                </div>
                <div className="lockIconText">
                  <Title level={4}>Total Current Openings</Title>
                  <p>{clientData?.totalOpenings}</p>
                </div>
              </div>
              <div className="cardContentItems">
                <Descriptions column={2}>
                  <Descriptions.Item>Name</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>{clientData?.name}</Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Original Contract Date</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {convertIntoDashUSFormat(
                        clientData?.originalContractDate,
                        false,
                      )}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    Recruitment Started Date
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {convertIntoDashUSFormat(
                        clientData?.recruitmentStartedDate,
                        false,
                      )}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Corporate Address</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.address ?? K.NullPlaceholder}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Website</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.website ?? K.NullPlaceholder}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>ATS</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.ats?.name ?? K.NullPlaceholder}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Ownership</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.ownership?.name ?? K.NullPlaceholder}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Olivia/Paradox</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.paradox ?? K.NullPlaceholder}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Client Service Leader</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.clientLeader ? (
                      <Tag level={5} color={"#0070C0"}>
                        {clientData?.clientLeader?.name}
                      </Tag>
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item>Supporting Director</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.director?.name ? (
                      <Tag level={5} color="#0070C0">
                        {clientData?.director?.name}
                      </Tag>
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item>
                    Supporting Client Service Manager
                  </Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.clientServiceManager?.name ? (
                      <Tag level={5} color="#0070C0">
                        {clientData?.clientServiceManager?.name}
                      </Tag>
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item>Account Manager</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.accountManagers.length ? (
                      clientData?.accountManagers.map((item) => (
                        <Tag color="#0070C0" key={item.id}>
                          {item.name}
                        </Tag>
                      ))
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item>Senior Recruiters</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.seniorRecruiters.length ? (
                      clientData?.seniorRecruiters.map((item) => (
                        <Tag level={5} key={item.id} color="#0070C0">
                          {item.name}
                        </Tag>
                      ))
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item>Client Success Leader</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.clientSuccessLeader?.name ? (
                      <Tag level={5} color="#0070C0">
                        {clientData?.clientSuccessLeader?.name}
                      </Tag>
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item>Exclusivity</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.exclusivity ?? K.NullPlaceholder}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Stage</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>{clientData?.stage?.name}</Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Reporting Schedule</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.reportingSchedule?.name}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Contact Persons</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.contacts.length ? (
                      clientData?.contacts.map(({ id, name }) => (
                        <Tag color="#0070C0" key={id}>
                          {name}
                        </Tag>
                      ))
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item>Turnover</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>{clientData?.turnover ?? "N/A"}</Title>
                  </Descriptions.Item>
                  <Descriptions.Item>
                    Internal Recruiting Team
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.internalRecruitingSystem ? "Yes" : "No"}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>No Of Employees</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>
                      {clientData?.numberOfEmployees ?? "N/A"}
                    </Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Net Terms</Descriptions.Item>
                  <Descriptions.Item>
                    <Title level={5}>{clientData?.netTerms ?? "N/A"}</Title>
                  </Descriptions.Item>
                  <Descriptions.Item>Recruiting Team</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.recruitersClients?.length ? (
                      clientData?.recruitersClients?.map(({ id, name }) => (
                        <Tag color="#0070C0" key={id}>
                          {name}
                        </Tag>
                      ))
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item>

                  {/* <Descriptions.Item>Departments</Descriptions.Item>
                  <Descriptions.Item>
                    {clientData?.departments.length ? (
                      clientData?.departments.map(({ id, name }) => (
                        <Title key={id} level={5}>
                          {name}
                        </Title>
                      ))
                    ) : (
                      <Title level={5}>{K.NullPlaceholder}</Title>
                    )}
                  </Descriptions.Item> */}
                  {displayUdfFieldValues(udfSlice[K.Redux.ClientUdf]).map(
                    (item) => {
                      return (
                        <>
                          <Descriptions.Item>{item.label}</Descriptions.Item>
                          <Descriptions.Item>
                            <Title level={5}>
                              {item.value !== null && item.value !== ""
                                ? item.value
                                : "N/A"}
                            </Title>
                          </Descriptions.Item>
                        </>
                      );
                    },
                  )}
                </Descriptions>
              </div>
            </div>
          </Card>
        </Col>
        {/* <Col xs={24} md={8}>
          <Card
            className="billingCard"
            title="Billing Information"
            bordered={false}
            extra={
              <a href="#">
                <i className="icon-edit1"></i>
                Edit
              </a>
            }
          >
            <div className="billingInformationContent">
              <Title level={4}>Europe Street beat</Title>
              <p className="sec-insta">www.instagram.com</p>
            </div>

            <div className="cardContent2ndCard">
              <div>
                <Paragraph className="sec-para">
                  Mark client as active
                </Paragraph>
              </div>
              <div>
                <Switch size="small" defaultChecked className="btn-switch" />
              </div>
            </div>
          </Card>
        </Col> */}
      </Row>
      <Row>
        <Col xs={24}>
          <div className="overviewAddNewButton">
            <Title level={4} className="detailsBillingUnit">
              Billing Unit
            </Title>
            {isPermissionPresent(K.Permissions.EditClient, userSlice.roles) && (
              <Button key="1" type="primary" onClick={addRecord}>
                Add New
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {clientData?.clientBillingUnit?.map((item, i) => (
        <Row key={item.id} gutter={[16, 16]}>
          <Col xs={24}>
            <Collapse
              defaultActiveKey={["0"]}
              expandIconPosition="end"
              bordered={false}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <i className="icon-up-arrow" />
                ) : (
                  <i className="icon-down-arrow" />
                )
              }
            >
              <Panel
                key={i}
                header={item.name}
                extra={
                  isPermissionPresent(
                    K.Permissions.EditClient,
                    userSlice.roles,
                  ) && (
                    <Button
                      type="link"
                      onClick={(event) => {
                        stopPropagationFunction(event);
                        showModal(item);
                      }}
                    >
                      <i className="icon-edit"></i>
                    </Button>
                  )
                }
                className={
                  "collapseContainer " + LayoutCss.appListingCardContainer
                }
              >
                <Card bordered={false} className={LayoutCss.appCard}>
                  <div
                    className="ag-theme-alpine s2-theme-style "
                    style={{
                      height: 220,
                    }}
                  >
                    <AgGridReact
                      rowData={item.contacts}
                      columnDefs={columnDefs}
                      defaultColDef={K.AgGridTable.DefaultColDef}
                      className={`commissionTable ${LayoutCss.appListingCardCommissionTable}`}
                      scroll={{ x: 400 }}
                    />
                  </div>
                </Card>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      ))}

      <OverviewModel
        isModalVisible={isModalVisible}
        editData={editData}
        setEditData={setEditData}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
}
