import { Col, Row, Card, Select, Tag, Typography, Divider } from "antd";
import { useHistory } from "react-router-dom";
import LayoutCss from "layout/layout.module.scss";
import K from "utilities/constants";
import UpcomingUserCommissionPlans from "./upcomingUserCommissionPlans";
import HistoryUserCommissionPlans from "./historyUserCommissionPlans";
import CurrentUserCommissionPlans from "./currentUserCommissionPlan";
import { pascalCase } from "utilities/generalUtility";

const { Title, Text } = Typography;

export default function ProfileTab({
  styles,
  screens,
  userDetail,
  plansDetails,
  setPlanDetails,
  commissionPlan,
  setCommissionPlan,
}) {
  const history = useHistory();

  const handleChange = (value) => {
    setCommissionPlan(value);
  };

  const commissionPlansComponents = {
    upcomingPlans: (
      <UpcomingUserCommissionPlans
        plansDetails={plansDetails}
        setPlansDetails={setPlanDetails}
        isEdit={false}
        // getCommissionPlansById={getCommissionPlansById}
      />
    ),
    historyPlans: (
      <HistoryUserCommissionPlans
        plansDetails={plansDetails}
        setPlansDetails={setPlanDetails}
      />
    ),
  };

  return (
    <div className={`${styles.cicTabsContent} ${styles.userInfoCard}`}>
      <Title level={4} className={styles.personalInfoHeading}>
        Personal Info
      </Title>
      <Row gutter={24} className="mb-3">
        <Col xs={24} sm={12} xl={9} className={screens["xs"] ? "mb-3" : ""}>
          <label className={styles.icLabelStyle}>Full Name</label>
          <Text className={styles.icTextStyle}>{userDetail.name}</Text>
        </Col>
        <Col xs={24} sm={12} xl={15}>
          <label className={styles.icLabelStyle}>Designation</label>
          {userDetail.designations && userDetail.designations.length === 0
            ? K.NullPlaceholder
            : userDetail.designations?.map((dg, i) => (
                <Tag key={i} className="viewTags">
                  {dg.name}
                </Tag>
              ))}
        </Col>
      </Row>
      <Row gutter={24} className="mb-3">
        <Col xs={24} sm={12} xl={9} className={screens["xs"] ? "mb-3" : ""}>
          <label className={styles.icLabelStyle}>Clients</label>
          <Text>
            {userDetail.clients && userDetail.clients.length === 0
              ? K.NullPlaceholder
              : userDetail.clients?.map((cl, i) => {
                  return (
                    <Tag
                      key={i}
                      className="viewTags"
                      onClick={() =>
                        history.push(`/clients/${cl.id}?tab=overview`)
                      }
                    >
                      {cl.name}
                    </Tag>
                  );
                })}
          </Text>
        </Col>
        <Col xs={24} sm={12} xl={15}>
          <label className={styles.icLabelStyle}>Roles</label>
          {userDetail.roles && userDetail.roles.length === 0
            ? K.NullPlaceholder
            : userDetail.roles?.map((rl, i) => (
                <Tag key={i} className="viewTags">
                  {rl.displayName}
                </Tag>
              ))}
        </Col>
      </Row>
      <Row gutter={24} className="mb-3">
        {" "}
        <Col xs={24} sm={12} xl={9} className={screens["xs"] ? "mb-3" : ""}>
          <label className={styles.icLabelStyle}>Active</label>
          <Text>{userDetail.isActive === 0 ? "No" : "Yes"}</Text>
        </Col>
        <Col xs={24} sm={12} xl={15}>
          <label className={styles.icLabelStyle}>Source2 Manager</label>
          <Text>{userDetail.isManager === 0 ? "No" : "Yes"}</Text>
        </Col>
      </Row>
      <Row gutter={24} className="mb-3">
        {" "}
        <Col xs={24} sm={12} xl={9} className={screens["xs"] ? "mb-3" : ""}>
          <label className={styles.icLabelStyle}>Type</label>
          <Text>{pascalCase(userDetail?.type ?? K.NullPlaceholder)}</Text>
        </Col>
        <Col xs={24} sm={12} xl={15}>
          <label className={styles.icLabelStyle}>Job Title</label>
          <Text>{userDetail?.jobTitle ?? K.NullPlaceholder}</Text>
        </Col>
      </Row>
      {/* <Row gutter={24} className="mb-3">
        <Col xs={24} sm={12} xl={9} className={screens["xs"] ? "mb-3" : ""}>
          <label className={styles.icLabelStyle}>Commission Plan</label>
          <Text>
            {plansDetails?.currentPlan?.commisions?.name ?? K.NullPlaceholder}
          </Text>
        </Col>
      </Row> */}
      <Divider />
      <Card
        title={
          <>
            <Title level={4} className={styles.personalInfoHeading}>
              Commission Plan
            </Title>
          </>
        }
        className={`${styles.cardWrap} ${LayoutCss.appCard}  `}
        bordered={false}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24}>
            <Card
              title={"Current "}
              bordered={false}
              className={LayoutCss.appListingCard}
              bodyStyle={{ padding: "0" }}
            >
              {plansDetails && (
                <CurrentUserCommissionPlans plansDetails={plansDetails} />
              )}
            </Card>
          </Col>
        </Row>
        {/* <Divider /> */}
        <Row gutter={24}>
          <Col xs={24} sm={24}>
            <Card
              title={commissionPlan == "upcomingPlans" ? "Upcoming" : "History"}
              bordered={false}
              className={LayoutCss.appListingCard}
              bodyStyle={{ padding: "0" }}
              extra={
                <div className={LayoutCss.invoicesButton + " mt-0"}>
                  <Select
                    showSearch
                    optionFilterProp="label"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue="upcomingPlans"
                    onChange={handleChange}
                    className={LayoutCss.invoicesButtonSet}
                    options={[
                      { value: "historyPlans", label: "History" },
                      { value: "upcomingPlans", label: "Upcoming" },
                    ]}
                  />
                </div>
              }
            >
              {plansDetails && commissionPlansComponents[commissionPlan]}
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
