import {
  DatePicker,
  Form,
  Input,
  List,
  message,
  PageHeader,
  Typography,
} from "antd";

import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "./submitToBilling.scss";
import { displayDollar, displayPercentage } from "utilities/tableUtility";
import {
  convertIntoDashUSFormat,
  disabledDatesPriorMonth,
} from "utilities/dateUtility";
import { useEffect, useState } from "react";
import BillingApproval from "redux/models/billingApproval";
import K from "utilities/constants";

import {
  camelCaseKeysRecursively,
  snakeCaseToSentence,
} from "utilities/generalUtility";
const { Title } = Typography;
const {
  AgGridTable,
  Invoice: { Type },
} = K;

const DiscountedColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Candidate Name",
    field: "candidateName",
    sortable: true,
  },
  {
    headerName: "Candidate Type",
    field: "hireType",
    sortable: true,
    cellRenderer: (params) =>
      snakeCaseToSentence(params.data.hireType) ?? "N/A",
  },

  {
    headerName: "Billing Type",
    field: "billingTypeName",
    sortable: true,
    cellRenderer: (params) => params.data.billingTypeName ?? "N/A",
  },
  {
    headerName: "Start Date",
    field: "startDate",
    sortable: true,
    cellRenderer: (params) =>
      convertIntoDashUSFormat(params.data.startDate, false),
  },

  {
    headerName: "Job Title",
    field: "title",
    sortable: true,
    cellRenderer: (params) => params.data.jobTitle ?? "N/A",
  },

  {
    headerName: "Previous Amount",
    field: "duplicateAmount",
    sortable: true,
    cellRenderer: (params) => displayDollar(params.data.duplicateAmount ?? 0),
  },

  {
    headerName: "New Amount",
    field: "discountedAmount",
    sortable: true,
    cellRenderer: (params) => displayDollar(params.data.discountedAmount ?? 0),
  },

  {
    headerName: "Discount Percentage",
    field: "discountPercentage",
    sortable: true,
    cellRenderer: (params) =>
      displayPercentage(params.data.discountPercentage ?? 0),
  },
  {
    headerName: "Discount",
    field: "discount",
    sortable: true,
    cellRenderer: (params) => displayDollar(params.data.discount ?? 0),
  },
  {
    headerName: "Currency",
    field: "currency",
    sortable: true,
    cellRendere: (params) => params.data.currency,
  },
];
const NonDiscountedColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Candidate Name",
    field: "candidateName",
    sortable: true,
    cellRenderer: (params) => params.data.candidateName ?? K.NullPlaceholder,
  },
  {
    headerName: "Candidate Type",
    field: "hireType",
    sortable: true,
    cellRenderer: (params) =>
      snakeCaseToSentence(params.data.hireType) ?? "N/A",
  },
  {
    headerName: "Billing Type",
    field: "billingTypeName",
    sortable: true,
    cellRenderer: (params) => params.data.billingTypeName ?? "N/A",
  },
  {
    headerName: "Job Title",
    field: "jobTitle",
    sortable: true,
    cellRenderer: (params) => params.data.jobTitle ?? "N/A",
  },

  {
    headerName: "Amount",
    field: "duplicateAmount",
    sortable: true,
    cellRenderer: (params) => displayDollar(params.data.duplicateAmount ?? 0),
  },

  {
    headerName: "Currency",
    field: "currency",
    sortable: true,
    cellRendere: (params) => params.data.currency,
  },
];
export default function CreateInvoice({
  form,
  removeBillingEntry,
  invoicePayloadRef,
  pageStates,
  setPageStates,
}) {
  const [dataSource, setDataSource] = useState({
    hireTypeBillings: [],
    otherTypeBillings: [],
    totalDiscount: 0,
  });

  const calculateDiscount = (hires) => {
    const { total } = hires.reduce(
      (prev, curr) => ({
        total: prev.total + curr.discount,
      }),
      { total: 0 },
    );

    return isNaN(total) ? 0 : total;
  };

  const createBillingInvoice = async (values) => {
    const { index, ...payload } = invoicePayloadRef.current;
    payload.dueDate = values.dueDate.format(K.DateFormat.Response);
    payload.hires = dataSource.hireTypeBillings;
    payload.other = dataSource.otherTypeBillings;

    const invoicebody = {
      clientId: payload.clientId,
      type: payload.type,
      dueDate: payload.dueDate,
      hires:
        invoicePayloadRef.current.type === Type.Job
          ? dataSource.hireTypeBillings
          : [],
      others:
        invoicePayloadRef.current.type === Type.Job
          ? dataSource.otherTypeBillings
          : payload.submittalBillIds?.map((id) => {
              return { id: id };
            }),
    };
    try {
      const res = await BillingApproval.generateInvoice(invoicebody);
      message.success(res.message);
      removeBillingEntry(
        payload.clientId,
        payload.type,
        payload.submittalBillIds,
        index,
      );
    } catch (err) {
      console.error(err);
    }
  };

  const generateDiscount = async () => {
    try {
      const { index, ...payload } = invoicePayloadRef.current;
      const hireTypeBillings = [];
      const otherTypeBillings = [];
      payload.submittalJobBillsData?.forEach((item) => {
        if (
          item.billingRuleEntry &&
          item.billingRuleEntry.jobBillingTypesId === 1
        ) {
          hireTypeBillings.push({
            id: item.id,
            candidateName: item.candidateName,
            hireType: item.candidateJobEntry.hireType,
            billingTypeName: item.billingTypeName,
            jobBillingTypeId: item.billingRuleEntry?.jobBillingTypesId ?? null,
            startDate: item.candidateJobEntry.startDate,
            jobTiersId: item.jobSource.jobTiersId,
            jobTitle: item.jobSource.title,
            duplicateAmount: item.dublicateAmount,
            currency: item.convertToCurrency,
          });
        } else {
          otherTypeBillings.push({
            id: item.id,
            candidateName: item.candidateName,
            hireType: item.candidateJobEntry?.hireType,
            billingTypeName: item.billingTypeName,
            jobBillingTypeId: item.billingRuleEntry?.jobBillingTypesId ?? null,
            startDate: item.candidateJobEntry?.startDate ?? null,
            jobTiersId: item.jobSource?.jobTiersId ?? null,
            jobTitle: item.jobSource?.title ?? null,
            duplicateAmount: item.dublicateAmount,
            currency: item.convertToCurrency,
            discount: -1,
          });
        }
      });

      const body = {
        clientId: payload.clientId,
        type: payload.type,
        hireTypeBillings: hireTypeBillings,
        otherTypeBillings: otherTypeBillings,
      };

      const res = await BillingApproval.calculateDiscount(body);

      const flatHires = res.clientVbd
        ? Object.keys(res.hires).reduce((prev, curr) => {
            return [...prev, ...res.hires[curr]];
          }, [])
        : res.hires;

      const totalDiscount = calculateDiscount(flatHires);

      setDataSource((prev) => {
        return {
          ...prev,
          hireTypeBillings: res.clientVbd ? flatHires : [],
          otherTypeBillings: res.clientVbd
            ? res.others
            : [...res.others, ...flatHires],
          totalDiscount: totalDiscount,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (invoicePayloadRef.current.type === K.Invoice.Type.Job) {
      generateDiscount();
    }
  }, []);
  return (
    <Form layout="vertical" form={form} onFinish={createBillingInvoice}>
      <Form.Item
        label="Invoice Draft Date"
        name="dueDate"
        initialValue={moment()}
        rules={[{ required: true, message: "Please select due date" }]}
      >
        <DatePicker
          disabledDate={disabledDatesPriorMonth}
          placeholder="Due Date"
          format={K.DateFormat.DashUSFormat}
        />
      </Form.Item>

      {invoicePayloadRef.current.type === Type.Job && (
        <div className="text-left">
          <Title level={5} className="mb-0">
            Previous total:{" "}
            {displayDollar(
              invoicePayloadRef.current
                ? pageStates.checkedRows[
                    invoicePayloadRef.current.type === Type.Job
                      ? "jobTotal"
                      : "clientTotal"
                  ]
                : 0,
            )}
          </Title>
          <Title level={5} className="mb-0">
            Total Discount: -{displayDollar(dataSource.totalDiscount ?? 0)}
          </Title>
          <div className="text-right">
            <Title level={5} style={{ color: "#278dc0" }} className="mb-0">
              Grand Total:{" "}
              {displayDollar(
                (invoicePayloadRef.current
                  ? pageStates.checkedRows[
                      invoicePayloadRef.current.type === Type.Job
                        ? "jobTotal"
                        : "clientTotal"
                    ]
                  : 0) - dataSource.totalDiscount ?? 0,
              )}
            </Title>
          </div>

          <div style={{ textAlign: "left" }}>
            <Title style={{ color: "#278dc0" }} level={5}>
              Discount Eligibles
            </Title>
          </div>
          <div
            className="ag-theme-alpine s2-theme-style"
            style={{
              height: 400,
            }}
          >
            <AgGridReact
              pagination
              paginationPageSize={5}
              rowData={dataSource.hireTypeBillings}
              columnDefs={DiscountedColumnDefs}
              defaultColDef={K.AgGridTable.DefaultColDef}
            />
          </div>
          <div style={{ textAlign: "left" }}>
            <Title style={{ color: "#278dc0" }} level={5}>
              Others
            </Title>
          </div>

          <div
            className="ag-theme-alpine s2-theme-style"
            style={{
              height: 400,
            }}
          >
            <AgGridReact
              pagination
              paginationPageSize={5}
              rowData={dataSource.otherTypeBillings}
              columnDefs={NonDiscountedColumnDefs}
              defaultColDef={K.AgGridTable.DefaultColDef}
            />
          </div>
        </div>
      )}
    </Form>
  );
}
