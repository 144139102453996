import { Checkbox, Form, Input, Radio, Select, Space, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import BillingJob from "redux/models/billingJobs";
import K from "utilities/constants";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";
import "./addNewBillingJob.scss";

const { Text } = Typography;
const { Option } = Select;

export default function AddNewBillingJob({
  form,
  billingTypes,
  pageState,
  setPageState,
}) {
  const dispatch = useDispatch();

  const addBillingJobs = async (values) => {
    try {
      await dispatch(BillingJob.addNewType(values));
      form.resetFields();
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        isModalVisible: false,
        editData: null,
      }));
    } catch (err) {
      console.error(err);
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        isModalVisible: false,
        editData: null,
      }));
    }
  };

  const editBillingJobs = async (values) => {
    try {
      await dispatch(BillingJob.editData(values, pageState.editData.id));
      setPageState((prev) => ({
        ...prev,
        editData: null,
        isLoading: false,
        isModalVisible: false,
      }));
    } catch (error) {
      console.error(error);
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const onFinish = async (values) => {
    setPageState((prev) => ({ ...prev, isLoading: true }));
    if (pageState.editData) {
      editBillingJobs(values);
    } else {
      addBillingJobs(values);
    }
  };

  useEffect(() => {
    if (pageState.editData) {
      form.setFieldsValue({
        name: pageState.editData?.name,
        isAssignable:
          pageState.editData?.isAssignable === "Assignable" ? true : false,
      });
    }
  }, [form, pageState.editData]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        isRequired: false,
        isAssignable: false,
        isDependentOnSalary: true,
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please enter name!" },
          noTrailingSpaceAllowedRule(),
        ]}
        className="addNewTitle"
      >
        <Input placeholder="Name" />
      </Form.Item>
      {!pageState.editData && (
        <div id="billingJobSelectIdParent">
          <Form.Item
            label="Select Type"
            name="type"
            className="clientJobsField2 selectTypeField"
            rules={[{ required: true, message: "Type is required." }]}
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="label"
              getPopupContainer={() =>
                document.getElementById("billingJobSelectIdParent")
              }
              placeholder="Select Type"
              options={[
                { value: "recursive", label: "Recursive" },
                { value: "oneTime", label: "One Time" },
                { value: "timeBased", label: "After Certain Period" },
              ]}
            />
          </Form.Item>
        </div>
      )}
      <div className="configurationCheckbox">
        <Form.Item
          name="isAssignable"
          valuePropName="checked"
          className="checkbox"
        >
          <Checkbox className="addNewCheckbox">Is Assignable</Checkbox>
        </Form.Item>

        {!pageState.editData && (
          <>
            <Form.Item
              name="isRequired"
              valuePropName="checked"
              className="checkbox"
            >
              <Checkbox>Is Required</Checkbox>
            </Form.Item>
            <Form.Item
              name="isDependent"
              valuePropName="checked"
              className="checkbox"
            >
              <Checkbox checked>Dependent on</Checkbox>
            </Form.Item>
          </>
        )}
      </div>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.type !== curValues.type
        }
      >
        {({ getFieldValue }) =>
          getFieldValue("type") === "oneTime" && (
            <Form.Item
              name="hasMarkup"
              valuePropName="checked"
              className="checkbox"
            >
              <Checkbox>Has Markup</Checkbox>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.isDependent !== curValues.isDependent
        }
      >
        {({ getFieldValue }) =>
          getFieldValue("isDependent") && (
            <>
              <Text className="radioTitle">Dependent On:</Text>
              <Form.Item name="isDependentOnSalary" className="mb-0">
                <Radio.Group className="radioGroup">
                  <Space direction="vertical">
                    <Radio value={true}>Candidate Salary</Radio>
                    <Radio value={false}>Another Billing Type</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.isDependentOnSalary !==
                  curValues.isDependentOnSalary
                }
              >
                {({ getFieldValue }) =>
                  !getFieldValue("isDependentOnSalary") && (
                    <Form.Item
                      label="Billing Type"
                      name="dependentOnBillingType"
                      className="clientJobsField2 selectTypeField"
                      rules={[{ required: true, message: "Type is required." }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        getPopupContainer={() =>
                          document.getElementById("billingJobSelectIdParent")
                        }
                        placeholder="Select Billing Type"
                      >
                        {billingTypes.map(({ id, name, type }) =>
                          type !== K.BillingTypes.Retention ? (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          ) : null,
                        )}
                      </Select>
                    </Form.Item>
                  )
                }
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    </Form>
  );
}
