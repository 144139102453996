import { Divider, Typography } from "antd";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Candidate from "redux/models/candidate";
import { formatActivityTimestamp } from "utilities/dateUtility";
import { sortObjectsArrayDescByKey } from "utilities/tableUtility";
import { CandidateActivities } from "./candidateActivities";

const { Title, Paragraph } = Typography;

export default function CandidateHistory({ styles, candidateActivity }) {
  const [logsListing, setLogsListing] = useState([]);
  const getLogs = async () => {
    try {
      const res = await Candidate.getLogs(candidateActivity.id);
      //==========Deprictated used in cloudwatch
      // const data = res.results.map((item) =>
      //   item.reduce((prev, curr) => {
      //     if (["@message", "@timestamp"].includes(curr.field)) {
      //       return {
      //         ...prev,
      //         [curr.field.substring(1)]:
      //           curr.field === "@message"
      //             ? isJsonValid(curr.value) && JSON.parse(curr.value)
      //             : convertIntoUtcLocalTime(curr.value),
      //       };
      //     } else
      //       return {
      //         ...prev,
      //         [curr.field.substring(1)]: curr.value,
      //       };
      //   }, {})
      // );

      setLogsListing(res);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getLogs();
  }, [candidateActivity]);


  return <Scrollbars style={{ height: 400 }}>
    <CandidateActivities data={logsListing}/>
  </Scrollbars>
}
