import { Form, InputNumber, message } from "antd";

import Job from "redux/models/job";

export default function SubmitCancelBillingForm({
  form,
  jobId,
  styles,
  clientId,
  setIsLoading,
  handleCancelBillingOk,
}) {
  const onFinish = async (values) => {
    setIsLoading(true);
    values.clientId = +clientId;
    values.jobSourcesId = +jobId;

    try {
      await Job.CustomCancelBillings(values);
      setIsLoading(false);
      message.success("Cancellation Fee Generated Successfully");
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      message.error("Something Went Wrong");
    }
    handleCancelBillingOk();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className={styles.cancelJobFrom}
    >
      <Form.Item
        className={styles.jobFieldLabel}
        label="Enter Number of Opening to Bill"
        name="jobCancelledOpenings"
        rules={[
          {
            required: true,
            message: "Number of openings to bill is required",
          },
          {
            pattern: new RegExp("^[0-9]*$", "g"),
            message: "Decimal values are not allowed!",
          },
        ]}
      >
        <InputNumber
          placeholder="0"
          min={0}
          className={styles.jobInputFields}
        />
      </Form.Item>
    </Form>
  );
}
