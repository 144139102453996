import { Checkbox, Form, Input, Tag } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import BillingClient from "redux/models/billingClients";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";

export default function AddNewClientBillingJob({
  form,
  pageState,
  setPageState,
}) {
  const dispatch = useDispatch();
  const addClientJobs = async (values) => {
    try {
      await dispatch(BillingClient.addData({ ...values, type: "recursive" }));
      setPageState((prev) => ({
        ...prev,
        isModalVisible: false,
        isLoading: false,
      }));
    } catch (error) {
      setPageState((prev) => ({
        ...prev,
        isModalVisible: false,
        isLoading: false,
      }));
      console.error(error);
    }
  };

  const editBillingJobs = async (values) => {
    try {
      await dispatch(BillingClient.editData(values, pageState.editData.id));
      setPageState((prev) => ({
        ...prev,
        editData: null,
        isModalVisible: false,
        isLoading: false,
      }));
    } catch (error) {
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      console.error(error);
    }
  };

  const onFinish = async (values) => {
    setPageState((prev) => ({ ...prev, isLoading: true }));
    if (pageState.editData) {
      editBillingJobs(values);
    } else {
      addClientJobs(values);
    }
  };

  useEffect(() => {
    if (pageState.editData) {
      form.setFieldsValue({
        name: pageState.editData?.name,
        isAssignable: pageState.editData?.isAssignable,
      });
    }
  }, [form, pageState.editData]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        hasMarkup: false,
        isAssignable: false,
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Name is required." },
          noTrailingSpaceAllowedRule(),
        ]}
      >
        <Input placeholder="Enter Name" />
      </Form.Item>

      <Form.Item>
        Billing Type: <Tag className="viewTags"> Recursive</Tag>
      </Form.Item>

      <Form.Item
        name="isAssignable"
        valuePropName="checked"
        className="checkbox"
      >
        <Checkbox>Is Assignable</Checkbox>
      </Form.Item>
    </Form>
  );
}
