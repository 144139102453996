import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import moment from "moment";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Client from "redux/models/client";
import CommissionPlan from "redux/models/commissionPlan";
import LookupTable from "redux/models/lookupTable";
import RoleAndPermission from "redux/models/roleAndPermission";
import User from "redux/models/user";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import {
  getIsVisibleLookups,
  noTrailingSpaceAllowedRule,
  validateMobileNumber,
} from "utilities/generalUtility";
import styles from "./createUser.module.scss";
import { disabledDate } from "utilities/dateUtility";
import UpcomingUserCommissionPlans from "./upcomingUserCommissionPlans";
import HistoryUserCommissionPlans from "./historyUserCommissionPlans";
import CurrentUserCommissionPlans from "./currentUserCommissionPlan";

const { Title } = Typography;
const { Option } = Select;

const success = (isEdit = false) => {
  Modal.success({
    centered: true,
    className: styles.headerModal,
    okText: "Ok",
    title: (
      <Title className={styles.modalTitle} level={5}>
        {isEdit ? "User Updated Successfully" : "User Created Cuccessfully"}
      </Title>
    ),
  });
};

export default function CreateUser() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const { Lookup } = K.Network.URL;
  const [dropdownList, setDropDownList] = useState({
    clientsName: [],
    roles: [],
    commissionPlans: [],
  });
  const [toggleCommissionPlan, setToggleCommissionPlan] =
    useState("upcomingPlans");
  const [userEditData, setUserEditData] = useState(null);
  const [userPlansDetails, setUserPlansDetails] = useState(null);
  const [deletePlan, setDeletePlan] = useState([]);
  const lookupTables = useSelector(selectConfigration).lookup;

  const getAllClientName = async () => {
    try {
      const clientsName = await Client.getAllClientName();
      setDropDownList((prev) => {
        return { ...prev, clientsName };
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getAllRoles = async () => {
    try {
      const roles = await RoleAndPermission.getAllRoles();
      setDropDownList((prev) => {
        return { ...prev, roles };
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getAllCommissionPlans = async () => {
    try {
      const commissionPlans = await CommissionPlan.getAll();
      setDropDownList((prev) => {
        return { ...prev, commissionPlans };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const createUser = async (data) => {
    try {
      await User.createUser(data);
      success();
      form.resetFields();
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };
  const getUserDetail = async (userId) => {
    try {
      const basicDetail = await User.getUserDetailById(userId);
      const userPlan = await User.getUserPlanTimeLine(userId);

      populateData(basicDetail, userPlan);
      setUserPlansDetails(userPlan);
      setUserEditData(basicDetail);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async (data) => {
    try {
      data = { ...data, removeIds: deletePlan };
      const res = await User.updateUser(data);
      success(true);
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const getLookUpUserDesignations = async () => {
    const lookupTable = {
      reduxKey: K.Redux.UserDesignation,
      apiEndPoint: Lookup.UserDesignation,
    };
    await dispatch(
      LookupTable.getData(lookupTable.reduxKey, lookupTable.apiEndPoint),
    );
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        getLookUpUserDesignations(),
        getAllClientName(),
        getAllRoles(),
        getAllCommissionPlans(),
      ]);
      if (id) {
        await getUserDetail(id);
      }
    })();
  }, []);

  const onFinish = (values) => {
    if (id) {
      values = {
        ...values,
        userId: id,
        isManager: values.isManager === false ? 0 : 1,
        isHiringManager: 0,
        startDate: values.startDate?.unix() ?? null,
      };
      updateUser(values);
    } else {
      values = {
        ...values,
        isHiringManager: 0,
        startDate: values.startDate?.unix() ?? null,
      };
      createUser(values);
    }
  };
  const handleChange = (value) => {
    setToggleCommissionPlan(value);
  };
  const handleUpcomingPlanDelete = (id, planId) => {
    setUserPlansDetails((prev) => {
      return {
        ...prev,
        upcomingPlans: prev.upcomingPlans?.filter((item) => item.id !== id),
      };
    });
    setDeletePlan((prev) => [...new Set([...prev, id])]);
  };
  const handleCurrentPlanDelete = (id, planId) => {
    setUserPlansDetails((prev) => {
      return {
        ...prev,
        currentPlan: {},
      };
    });

    setDeletePlan((prev) => [...new Set([...prev, id])]);
  };
  const populateData = (userDetail, userPlan) => {
    const roles = userDetail?.roles?.map((rl) => rl.id);
    const roleId = roles.length > 0 ? roles[0] : 0;

    form.setFieldsValue({
      name: userDetail.name,
      phone: userDetail.phone,
      email: userDetail.email,
      jobTitle: userDetail.jobTitle,
      type: userDetail.type,
      roleId,
      clientId: userDetail.clients.map((cl) => cl.id),
      // commissionPlanId: userPlan?.currentPlan?.planId,
      // startDate: userPlan?.currentPlan?.startDate
      //   ? moment.unix(userPlan.currentPlan?.startDate)
      //   : null,
      userDesignationId: userDetail.designations.map((dl) => dl.id),
      isManager: userDetail.isManager === 0 ? false : true,
    });
  };
  const commissionPlansComponents = {
    upcomingPlans: (
      <UpcomingUserCommissionPlans
        plansDetails={userPlansDetails}
        setPlansDetails={setUserPlansDetails}
        handleUpcomingPlanDelete={handleUpcomingPlanDelete}
        isEdit={true}
        // getCommissionPlansById={getCommissionPlansById}
      />
    ),
    historyPlans: (
      <HistoryUserCommissionPlans
        plansDetails={userPlansDetails}
        setPlansDetails={setUserPlansDetails}
      />
    ),
  };

  return (
    <>
      <PageHeader
        ghost={false}
        className={LayoutCss.appPageHeader}
        title={id ? "Edit User" : "Create User"}
        onBack={history.goBack}
        extra={
          <Button onClick={form.submit} type="primary">
            {id ? "Update" : "Save"}
          </Button>
        }
      />
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Card
          title="User Detail"
          className={`${styles.cardWrap} ${LayoutCss.appCard}`}
          bordered={false}
        >
          <Row gutter={[16, 0]}>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                className={styles.userFormItemLabel}
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter user name!" },
                  noTrailingSpaceAllowedRule(),
                ]}
              >
                <Input placeholder="Enter Name" size="medium" />
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter email!" },
                  { type: "email", message: "Please enter valid email!" },
                ]}
              >
                <Input
                  // disabled={id ? true : false}
                  placeholder="Enter Email"
                  size="medium"
                />
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  { required: true, message: "Please enter phone!" },
                  {
                    validator: (_, value) => {
                      if (!value || validateMobileNumber(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Please enter correct number");
                      }
                    },
                  },
                ]}
              >
                <PatternFormat
                  placeholder="Enter Phone Number"
                  customInput={Input}
                  format={K.PhoneFormat.USNational}
                />
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} sm={12} xs={24} i>
              <Form.Item
                label="Phone Ext"
                name="phoneExt"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Phone Ext is required.",
                  // },
                  {
                    pattern: new RegExp("^[0-9]{1,6}$", "g"),
                    message: "Maximum of 6 digits is allowed!",
                  },
                ]}
              >
                <Input placeholder="Enter Phone Number Ext" />
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                label="Role"
                name="roleId"
                rules={[
                  { required: true, message: "Please select user role!" },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  size="medium"
                  placeholder="Select Role"
                >
                  {dropdownList.roles.map(({ id, displayName }) => (
                    <Option key={id} value={id}>
                      {displayName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                label="Designation"
                name="userDesignationId"
                rules={[
                  {
                    required: true,
                    message: "Please enter user designation!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  size="medium"
                  optionFilterProp="children"
                  mode="multiple"
                  placeholder="Select Designations"
                  showArrow
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {/* {lookupTables[K.Redux.UserDesignation]
                    .filter((item) => item.name !== "Hiring Manager")
                    .map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))} */}
                  {getIsVisibleLookups(
                    lookupTables[K.Redux.UserDesignation].filter(
                      (item) => item.name !== "Hiring Manager",
                    ),
                    userEditData
                      ? userEditData.designations.map((dl) => dl.id)
                      : null,
                  ).map(({ id, name, isVisible }) => (
                    <Option
                      key={id}
                      value={id}
                      disabled={isVisible === 1 ? false : true}
                    >
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item label="Job Title" name="jobTitle">
                <Input placeholder="Enter Job Title" size="medium" />
              </Form.Item>
            </Col>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select type!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  size="medium"
                  placeholder="Select user type"
                  showArrow
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {Object.keys(K.UserType).map((key) => (
                    <Option
                      key={K.UserType[key].id}
                      value={K.UserType[key].value}
                    >
                      {K.UserType[key].name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                label="Source2 Manager"
                name="isManager"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          title="Assign Commission Plan"
          className={`${styles.cardWrap} ${LayoutCss.appCard}`}
          bordered={false}
        >
          <Row gutter={[16, 0]}>
            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item label="Commission" name="commissionPlanId">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  size="medium"
                  placeholder="Select Commission Plan"
                >
                  {dropdownList.commissionPlans.map(
                    ({ commissionId, name }) => (
                      <Option key={commissionId} value={commissionId}>
                        {name}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col xl={6} lg={8} sm={12} xs={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.commissionPlanId !== curValues.commissionPlanId
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("commissionPlanId") && (
                    <Form.Item
                      label="Effective Since"
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: "Please select effective since date!",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={disabledDate}
                        placeholder="Effective Since Date"
                        format={K.DateFormat.DashUSFormat}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          {/* <Divider className="mt-1" /> */}
          {id && (
            <>
              <Card
                title={"Current"}
                bordered={false}
                className={LayoutCss.appListingCard}
                bodyStyle={{ padding: "0" }}
              >
                {userPlansDetails && (
                  <CurrentUserCommissionPlans
                    plansDetails={userPlansDetails}
                    isEdit={true}
                    handleCurentPlanDelete={handleCurrentPlanDelete}
                  />
                )}
              </Card>

              <Card
                title={
                  toggleCommissionPlan == "upcomingPlans"
                    ? "Upcoming"
                    : "History"
                }
                bordered={false}
                className={LayoutCss.appListingCard}
                bodyStyle={{ padding: "0" }}
                extra={
                  <div className={LayoutCss.invoicesButton + " mt-0"}>
                    <Select
                      showSearch={false}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      defaultValue="upcomingPlans"
                      onChange={handleChange}
                      className={LayoutCss.invoicesButtonSet}
                      options={[
                        { value: "historyPlans", label: "History" },
                        { value: "upcomingPlans", label: "Upcoming" },
                      ]}
                    />
                  </div>
                }
              >
                {userPlansDetails &&
                  commissionPlansComponents[toggleCommissionPlan]}
              </Card>
            </>
          )}
        </Card>
        <Card
          title="Assign Clients"
          className={`${styles.cardWrap} ${LayoutCss.appCard}`}
          bordered={false}
        >
          <Row>
            <Col xs={24} lg={8} md={12}>
              <Form.Item label="Select Clients" name="clientId">
                <Select
                  size="medium"
                  mode="multiple"
                  optionFilterProp="children"
                  showArrow
                  showSearch={true}
                  allowClear
                  placeholder="Select Clients"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {dropdownList.clientsName.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
}
