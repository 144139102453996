import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import ContactPersonDetails from "common/contactPersonDetails/contactPersonDetails";
import LayoutCss from "layout/layout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import LookupTable from "redux/models/lookupTable";
import User from "redux/models/user";
import { updateCreateData } from "redux/slices/clientSlice";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import { convertDateStringToMoment } from "utilities/dateUtility";
import styles from "./create.module.scss";
import { lookupTablesForClient } from "config/lookupTables";
import ClientContact from "redux/models/clientContacts";
import {
  customUserHandleSearch,
  generateUdfSetFieldValues,
  generateUdfValuesObject,
  getIsVisibleLookups,
  isValidHttpUrl,
  noTrailingSpaceAllowedRule,
  removeUdfKeysFromObject,
} from "utilities/generalUtility";
import UserSelectOption from "common/components/userSelect/userSelect";

import DisplayUDF from "../details/userDefinedFields/displayUdf";

import { selectUdf, setClientUdf } from "redux/slices/udfSlice";

const { Option } = Select;
const { Lookup } = K.Network.URL;

export const CreateClientDetails = ({
  form,
  setCurrentPage,
  editData,
  editClientInformation,
  udfs,
}) => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const clientDetailsData = useSelector(selectConfigration).lookup;
  const udfSlice = useSelector(selectUdf);
  const [contactList, setContactList] = useState([]);
  const [userDesignation, setUserDesignation] = useState({});

  const getClientContacts = async () => {
    try {
      const list = await ClientContact.getAll();
      console.log({ list });
      setContactList(list);
    } catch (err) {
      console.error(err);
    }
  };

  const populateFormValues = async () => {
    const udfEditData = generateUdfSetFieldValues(udfSlice[K.Redux.ClientUdf]);

    form.setFieldsValue({
      name: editData.name,
      address: editData.address,
      url: editData.website,
      atsId: editData.atsId,
      ownershipId: editData.ownershipId,
      paradox: editData.paradox,
      clientLeaderId: editData.clientLeaderId,
      directorId: editData.directorId,
      clientServiceManagerId: editData.clientServiceManagerId,
      accountManagerId: editData.accountManagers.map(({ id }) => id),
      seniorRecruitorId: editData.seniorRecruiters.map(({ id }) => id),
      recuiterId: editData.recuiterId,
      clientSuccessLeaderId: editData.clientSuccessLeaderId,
      exclusivity: editData.exclusivity,
      stageId: editData.stageId,
      reportingScheduleId: editData.reportingScheduleId,
      originalContractDate: convertDateStringToMoment(
        editData.originalContractDate,
      ),
      recruitmentStartedDate: convertDateStringToMoment(
        editData.recruitmentStartedDate,
      ),
      recruiterTeamIds: editData.recruitersClients?.map(({ id }) => id),
      turnover: editData?.turnover,
      numberOfEmployees: editData?.numberOfEmployees,
      internalRecruitingSystem: editData?.internalRecruitingSystem ?? "YES",
      netTerms: editData?.netTerms,
      ...udfEditData,
    });
  };
  useEffect(() => {
    if (editData && params.has("tab")) {
      populateFormValues();
    } else {
      dispatch(setClientUdf([]));
    }
  }, [form, editData]);

  useEffect(() => {
    (async () => {
      await Promise.all([
        getLookUpData(),
        getClientContacts(),
        getUserDesignations(),
        dispatch(
          LookupTable.getData(K.Redux.ContactTypes, Lookup.ContactTypes),
        ),
      ]);
    })();
  }, []);

  const onFinish = (values) => {
    const { originalContractDate, recruitmentStartedDate, ...details } = values;

    if (params.has("tab")) {
      const udfValues = generateUdfValuesObject(
        udfSlice[K.Redux.ClientUdf],
        details,
      );
      const updatedValues = removeUdfKeysFromObject(
        values,
        udfSlice[K.Redux.ClientUdf],
      );
      editClientInformation({ ...updatedValues, udfValues: udfValues });
    } else {
      dispatch(
        updateCreateData({
          key: K.Redux.ClientDetails,
          values: {
            ...details,
            originalContractDate: originalContractDate.format(
              K.DateFormat.Response,
            ),
            recruitmentStartedDate:
              recruitmentStartedDate?.format(K.DateFormat.Response) ?? null,
          },
        }),
      );
    }
  };

  const getUserDesignations = async () => {
    try {
      const userDesignation = await LookupTable.getDesignationData();
      await Promise.all(
        userDesignation.map(async (user) => {
          const data = await User.getbyDesignation(user.id);
          setUserDesignation((prev) => ({ ...prev, [user.name]: data }));
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };
  const getLookUpData = async () => {
    try {
      await Promise.all(
        lookupTablesForClient.map(async (item) => {
          // const lookupSelected = Array.isArray(editData[item.reduxKey])
          //   ? editData[item.reduxKey]
          //   : [editData[item.reduxKey]];

          await dispatch(LookupTable.getData(item.reduxKey, item.apiEndPoint));
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const backHandler = () => {
    setCurrentPage((pre) => (pre -= 1));
  };

  return (
    <>
      <Card
        className={`${LayoutCss.appCard} ${styles.editClientCard}`}
        title={
          params.get("tab") === "overview"
            ? "Edit Client Details"
            : "Client Details"
        }
        extra={
          params.get("tab") === "overview" && (
            <Button
              type="link"
              icon={<i className="icon-back-arrow"></i>}
              className="backButton"
              onClick={backHandler}
            >
              Back
            </Button>
          )
        }
      >
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          className={styles.editClientDetailFrom}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter client name!",
                  },
                  { min: 3, message: "Name must be at least 3 characters" },
                  {
                    max: 150,
                    message: "Name cannot be longer than 150 characters",
                  },
                  noTrailingSpaceAllowedRule(),
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Original Contract Date"
                name="originalContractDate"
                rules={[
                  {
                    required: true,
                    message: "Please enter original contract date!",
                  },
                ]}
              >
                <DatePicker
                  allowClear={true}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="formDatepicker"
                  format={K.DateFormat.DashUSFormat}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Recruitment Started Date"
                name="recruitmentStartedDate"
              >
                <DatePicker
                  allowClear={true}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  className="formDatepicker"
                  format={K.DateFormat.DashUSFormat}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Corporate Address"
                name="address"
                rules={[
                  {
                    min: 3,
                    message: "Corporate Address must be at least 3 characters",
                  },
                  {
                    max: 150,
                    message:
                      "Corporate Address cannot be longer than 150 characters",
                  },
                  noTrailingSpaceAllowedRule(),
                ]}
              >
                <Input placeholder="Corporate Address" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Website"
                name="url"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || isValidHttpUrl(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please enter valid URL with http or https",
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Website" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="ATS"
                name="atsId"
                rules={[
                  {
                    required: true,
                    message: "Please select ats!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="ATS"
                  optionFilterProp="label"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={getIsVisibleLookups(
                    clientDetailsData.ats,
                    editData?.atsId,
                  ).map(({ id, name, isVisible }) => ({
                    value: id,
                    label: name,
                    disabled: isVisible === 1 ? false : true,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Ownership" name="ownershipId">
                <Select
                  showSearch
                  placeholder="Ownership"
                  allowClear={true}
                  optionFilterProp="label"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={getIsVisibleLookups(
                    clientDetailsData.ownership,
                    editData?.ownershipId,
                  ).map(({ id, name, isVisible }) => ({
                    value: id,
                    label: name,
                    disabled: isVisible === 1 ? false : true,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Olivia/Paradox" name="paradox">
                <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Olivia/Paradox"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                allowClear={true}
                label="Client Service Leader"
                name="clientLeaderId"
                rules={[
                  {
                    required: true,
                    message: "Please select client Service leader!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  optionLabelProp="title"
                  placeholder="Client Service Leader"
                  filterOption={customUserHandleSearch}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {userDesignation["Client Service Leader"]?.users.map(
                    ({ id, name, email, clients }) => (
                      <Option title={name} key={id} value={id}>
                        <UserSelectOption
                          key={id}
                          userEmail={email}
                          clients={clients}
                        >
                          {name}
                        </UserSelectOption>
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Supporting Director" name="directorId">
                <Select
                  showSearch
                  allowClear={true}
                  optionLabelProp="title"
                  optionFilterProp="children"
                  filterOption={customUserHandleSearch}
                  placeholder="Supporting Director"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {userDesignation["Director"]?.users.map(
                    ({ id, name, email, clients }) => (
                      <Option key={id} value={id} title={name}>
                        <UserSelectOption
                          key={id}
                          userEmail={email}
                          clients={clients}
                        >
                          {name}
                        </UserSelectOption>
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Supporting Client Service Manager"
                name="clientServiceManagerId"
              >
                <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  optionLabelProp="title"
                  filterOption={customUserHandleSearch}
                  placeholder="Supporting Client Service Manager"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {userDesignation["Client Service Manager"]?.users.map(
                    ({ id, name, email, clients }) => (
                      <Option key={id} value={id} title={name}>
                        <UserSelectOption
                          key={id}
                          userEmail={email}
                          clients={clients}
                        >
                          {name}
                        </UserSelectOption>
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Account Manager"
                name="accountManagerId"
                rules={[
                  {
                    required: true,
                    message: "Please select account manager!",
                  },
                ]}
              >
                <Select
                  allowClear={true}
                  mode="multiple"
                  showArrow
                  showSearch={true}
                  optionFilterProp="children"
                  optionLabelProp="title"
                  filterOption={customUserHandleSearch}
                  placeholder="Account Manager"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {userDesignation["Account Manager"]?.users.map(
                    ({ id, name, email, clients }) => (
                      <Option key={id} value={id} title={name}>
                        <UserSelectOption
                          key={id}
                          userEmail={email}
                          clients={clients}
                        >
                          {name}
                        </UserSelectOption>
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Sr Recruiter" name="seniorRecruitorId">
                <Select
                  allowClear={true}
                  showArrow
                  showSearch={true}
                  optionFilterProp="children"
                  optionLabelProp="title"
                  mode="multiple"
                  filterOption={customUserHandleSearch}
                  placeholder="Sr Recruiter"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {userDesignation["Senior Recruiter"]?.users.map(
                    ({ id, name, email, clients }) => (
                      <Option key={id} value={id} title={name}>
                        <UserSelectOption
                          key={id}
                          userEmail={email}
                          clients={clients}
                        >
                          {name}
                        </UserSelectOption>
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Client Success Leader"
                name="clientSuccessLeaderId"
              >
                <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  optionLabelProp="title"
                  filterOption={customUserHandleSearch}
                  placeholder="Client Success Leader"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {userDesignation["Client Success Leader"]?.users.map(
                    ({ id, name, email, clients }) => (
                      <Option key={id} value={id} title={name}>
                        <UserSelectOption
                          key={id}
                          userEmail={email}
                          clients={clients}
                        >
                          {name}
                        </UserSelectOption>
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Exclusivity" name="exclusivity">
                <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Exclusivity"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Stage"
                name="stageId"
                rules={[
                  {
                    required: true,
                    message: "Please select Stage!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear={true}
                  placeholder="Stage"
                  optionFilterProp="children"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {getIsVisibleLookups(
                    clientDetailsData.stage,
                    editData?.stageId,
                  ).map(({ id, name, isVisible }) => (
                    <Option
                      key={id}
                      value={id}
                      disabled={isVisible === 1 ? false : true}
                    >
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Reporting Schedule"
                name="reportingScheduleId"
                rules={[
                  {
                    required: true,
                    message: "Please select reporting schedule!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder="Reporting Schedule"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {getIsVisibleLookups(
                    clientDetailsData.reportingSchedule,
                    editData?.reportingScheduleId,
                  ).map(({ id, name, isVisible }) => (
                    <Option
                      key={id}
                      value={id}
                      disabled={isVisible === 1 ? false : true}
                    >
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Internal Recruiting Team"
                name="internalRecruitingSystem"
                initialValue={"YES"}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder="Internal Recruiting Team"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={[
                    { value: "YES", label: "Yes" },
                    { value: "NO", label: "No" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Turnover " name="turnover">
                <InputNumber allowClear={true} placeholder="Enter Turn Over" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Net Terms " name="netTerms">
                <Input allowClear={true} placeholder="Enter Net Terms" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Number of Employees"
                name="numberOfEmployees"
                rules={[
                  {
                    pattern: new RegExp("^[0-9]*$", "g"),
                    message: "Only Positive And Decimal Number Allowed",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  allowClear
                  placeholder="Enter Number of Employees"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Recruiting Team " name="recruiterTeamIds">
                <Select
                  showSearch
                  allowClear={true}
                  mode="multiple"
                  showArrow
                  optionFilterProp="children"
                  optionLabelProp="title"
                  filterOption={customUserHandleSearch}
                  placeholder="Recruiters"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {userDesignation["Recruiter"]?.users.map(
                    ({ id, name, email, clients }) => (
                      <Option key={id} value={id} title={name}>
                        <UserSelectOption
                          key={id}
                          userEmail={email}
                          clients={clients}
                        >
                          {name}
                        </UserSelectOption>
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            {udfSlice[K.Redux.ClientUdf]?.map((item) => {
              return (
                <Col xs={24} sm={12} lg={8}>
                  <DisplayUDF
                    isFormItem={true}
                    title={item.label}
                    fieldTypeId={item.fieldTypeId}
                    udfId={item.id}
                    isRequired={item.isRequired}
                    form={form}
                    formItemName={item.label}
                    options={
                      item.udfMultiValueFieldTypeVal.length
                        ? item.udfMultiValueFieldTypeVal
                        : []
                    }
                  />
                </Col>
              );
            })}
          </Row>
          <div className={styles.ContactPersonDepartment}>
            <ContactPersonDetails
              form={form}
              editData={editData}
              contactList={contactList}
              keyName="contactType"
            />
            {/* <Deparments form={form} editData={editData} /> */}
          </div>
          {params.get("tab") === "overview" && (
            <Button
              onClick={form.submit}
              type="primary"
              className={`${styles.clientSaveButton} ${styles.saveButton}`}
            >
              Save
            </Button>
          )}
        </Form>
      </Card>
    </>
  );
};
