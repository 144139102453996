// CustomPaginationComponent.js
import React from "react";

const CustomPaginationComponent = ({
  totalPages,
  totalCount,
  currentCount,
  pageSize,
  currentPage,
  onPageChange,
}) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  return (
    <div className="ag-paging-panel ag-unselectable">
      <span className="ag-paging-row-summary-panel" role="status">
        <span className="ag-paging-row-summary-panel-number">
          {currentPage === 1 && totalCount > 0
            ? 1
            : (currentPage - 1) * pageSize}
        </span>
        <span id="ag-17-to"> to </span>
        <span className="ag-paging-row-summary-panel-number">
          {/* {currentPage === 1 ? pageSize : currentCount * currentPage} */}
          {currentPage * pageSize > totalCount
            ? totalCount
            : currentPage * pageSize}
        </span>
        <span id="ag-17-of"> of </span>
        <span className="ag-paging-row-summary-panel-number">{totalCount}</span>
      </span>
      <span className="ag-paging-page-summary-panel" role="presentation">
        <div
          className={`ag-button ag-paging-button ${
            currentPage === 1 ? "ag-disabled" : ""
          }`}
          role="button"
          aria-label="First Page"
          tabIndex="0"
          aria-disabled={currentPage === 1}
          onClick={() => handlePageChange(1)}
        >
          <span
            className="ag-icon ag-icon-first"
            unselectable="on"
            role="presentation"
          ></span>
        </div>
        <div
          className={`ag-button ag-paging-button ${
            currentPage === 1 ? "ag-disabled" : ""
          }`}
          role="button"
          aria-label="Previous Page"
          tabIndex="0"
          aria-disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <span
            className="ag-icon ag-icon-previous"
            unselectable="on"
            role="presentation"
          ></span>
        </div>
        <span className="ag-paging-description" role="status">
          <span id="ag-17-start-page">Page </span>
          <span className="ag-paging-number">{currentPage}</span>
          <span id="ag-17-of-page"> of </span>
          <span className="ag-paging-number">{totalPages}</span>
        </span>
        <div
          className={`ag-button ag-paging-button ${
            currentPage === totalPages ? "ag-disabled" : ""
          }`}
          role="button"
          aria-label="Next Page"
          tabIndex="0"
          aria-disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <span
            className="ag-icon ag-icon-next"
            unselectable="on"
            role="presentation"
          ></span>
        </div>
        <div
          className={`ag-button ag-paging-button ${
            currentPage === totalPages ? "ag-disabled" : ""
          }`}
          role="button"
          aria-label="Last Page"
          tabIndex="0"
          aria-disabled={currentPage === totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          <span
            className="ag-icon ag-icon-last"
            unselectable="on"
            role="presentation"
          ></span>
        </div>
      </span>
    </div>
  );
};

export default CustomPaginationComponent;
