import moment from "moment";
import K from "./constants";

// * Can not select days before today and today
export const disabledDate = (current) =>
  current && current < moment().startOf("day");

export const disabledDatesPriorMonth = (current) =>
  current && current < moment().subtract(1, "month").startOf("month");

export const isValidMomentDate = (dateObj) => moment.isMoment(dateObj);

export const convertDateStringToMoment = (dateString) =>
  dateString ? moment(dateString) : null;

export const convertTimeStringToMoment = (timeString) =>
  timeString ? moment(timeString, K.TimeFomat.Display) : null;

export const formatDisplayDate = (date, time = false) => {
  const format = time ? K.DateFormat.LongDisplay : K.DateFormat.Display;
  return date ? moment(date).format(format) : K.NullPlaceholder;
};

export const formatDisplayTime = (time) => {
  if (time) {
    const convertToMoment = convertTimeStringToMoment(time);
    return moment(convertToMoment).format(K.TimeFomat.Display);
  }
  return K.NullPlaceholder;
};

export const formatCommentTime = (date) => {
  return date
    ? moment(date).format(K.DateFormat.DashUSFormatTimeStamp)
    : K.NullPlaceholder;
};

export const customFormatSettingTime = (date, isLocal = true) => {
  return date
    ? isLocal
      ? moment(date).local().format(K.DateFormat.DashUSFormatTimeStamp)
      : moment(date).format(K.DateFormat.DashUSFormatTimeStamp)
    : K.NullPlaceholder;
};

export const getFirstDayPrevMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
};
export const getFirstDayCurrMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};
export const getLastDayPrevMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 0);
};
export const getLastDayCurrMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getMonthName = (date, isUtc = false) => {
  return date
    ? isUtc
      ? moment(date).utc().format(K.DateFormat.LongMonth)
      : moment(date).format(K.DateFormat.LongMonth)
    : K.NullPlaceholder;
};

export const formatTimeStamp = (timeStamp, showTime = true) => {
  const format = showTime
    ? K.DateFormat.TimeStampLongFormat
    : K.DateFormat.TimeStampShortFormat;
  return timeStamp ? moment(timeStamp).format(format) : K.NullPlaceholder;
};

export const convertIntoDashUSFormat = (timeStamp, showTime = true) => {
  const format = showTime
    ? K.DateFormat.DashUSFormatTimeStamp
    : K.DateFormat.DashUSFormat;

  return timeStamp ? moment(timeStamp).format(format) : K.NullPlaceholder;
};

export const convertIntoUTCDashUSFormat = (timeStamp, showTime = true) => {
  const format = showTime
    ? K.DateFormat.DashUSFormatTimeStamp
    : K.DateFormat.DashUSFormat;
  return timeStamp ? moment(timeStamp).utc().format(format) : K.NullPlaceholder;
};

export const epochTimeToDashUSFormat = (date) => {
  return date
    ? formatUnixToMoment(date).format(K.DateFormat.DashUSFormatTimeStamp)
    : K.NullPlaceholder;
};
export const epochTimeToUTCDashUSFormat = (date) => {
  return date
    ? formatUnixToMoment(date).utc().format(K.DateFormat.DashUSFormat)
    : K.NullPlaceholder;
};

export const convertIntoUtcLocalTime = (timeStamp) => {
  return timeStamp
    ? moment.utc(timeStamp).local().format(K.DateFormat.DashUSFormatTimeStamp)
    : K.NullPlaceholder;
};

export const convertIntoUSFormat = (date) =>
  date ? moment(date).format(K.DateFormat.USFormat) : K.NullPlaceholder;

export const calculateDaysDifference = (date) => {
  if (!date) return K.NullPlaceholder;
  const given = moment(date, K.DateFormat.Response);
  const current = moment().startOf("day");
  return given.diff(current, "days");
};

export const formatUnixToMoment = (unix) => moment.unix(unix);

export const getCurrentDateTime = () => {
  return moment().format(K.DateFormat.FileCreatedTime);
};

export const formatActivityTimestamp = (timestamp) => {
  const timestampMs = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp
  return convertIntoDashUSFormat(timestampMs);
}
