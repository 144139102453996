import { SyncOutlined } from "@ant-design/icons";
import { Form, Input, Typography } from "antd";
import { PatternFormat } from "react-number-format";
import K from "utilities/constants";
import {
  noTrailingSpaceAllowedRule,
  validateMobileNumber,
} from "utilities/generalUtility";
import editFormStyles from "./editForm.module.scss";
import { PALETTE } from "../../utilities/palette";

const { Text } = Typography;

function CustomLabel({ label, required, isChanged }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </div>
      <div>
        {isChanged && (
          <div>
            <SyncOutlined
              style={{ color: "orange", marginRight: "5px", fontSize: "16px" }}
            />
            <Text style={{ color: PALETTE.WARNING }}>Changed</Text>
          </div>
        )}
      </div>
    </div>
  );
}

export default function EditForm(props) {
  const {
    styles,
    onFinish,
    onValuesChange,
    form,
    isChanged,
    onEmailChange,
    isMerge,
  } = props;

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        className={editFormStyles.customFormItem}
        label={
          <CustomLabel
            label="Email"
            required
            isChanged={isChanged("email", "email")}
          />
        }
        required={false}
        name="email"
        rules={[
          {
            type: "email",
            message: "Enter a valid email format!",
          },
          { required: true, message: "Email is required." },
          noTrailingSpaceAllowedRule(),
        ]}
      >
        <Input onChange={onEmailChange} placeholder="Enter Email" />
      </Form.Item>

      {isMerge && (
        <div style={{ color: "red", marginTop: "-10px", marginBottom: "10px" }}>
          Email already exists. Selecting 'Merge' will combine data from the
          original and current candidate profiles. Please review both candidate
          profiles before proceeding.
        </div>
      )}

      <Form.Item
        className={editFormStyles.customFormItem}
        label={
          <CustomLabel
            label="First Name"
            required
            isChanged={isChanged("firstName", "firstName")}
          />
        }
        required={false}
        name="firstName"
        rules={[
          { required: true, message: "First Name is required." },
          noTrailingSpaceAllowedRule(),
        ]}
      >
        <Input placeholder="Enter first name" />
      </Form.Item>

      <Form.Item
        className={editFormStyles.customFormItem}
        label={
          <CustomLabel
            label="Last Name"
            required
            isChanged={isChanged("lastName", "lastName")}
          />
        }
        required={false}
        name="lastName"
        rules={[
          { required: true, message: "Last Name is required." },
          noTrailingSpaceAllowedRule(),
        ]}
      >
        <Input placeholder="Enter last name" />
      </Form.Item>

      <Form.Item
        className={editFormStyles.customFormItem}
        label={
          <CustomLabel
            label="Mobile Number"
            required={false}
            isChanged={isChanged("mobileNumber", "mobileNumber")}
          />
        }
        name="mobileNumber"
        rules={[
          {
            validator: (_, value) => {
              if (value === "") {
                Promise.resolve();
              }
              if (!value || validateMobileNumber(value)) {
                return Promise.resolve();
              }
              return Promise.reject("Please enter correct number");
            },
          },
        ]}
      >
        <PatternFormat
          placeholder="Mobile Number"
          customInput={Input}
          format={K.PhoneFormat.USNational}
        />
      </Form.Item>

      <Form.Item
        className={editFormStyles.customFormItem}
        label={
          <CustomLabel
            label="Additional Phone"
            required={false}
            isChanged={isChanged("phoneNumber", "phone")}
          />
        }
        name="phoneNumber"
        rules={[
          {
            validator: (_, value) => {
              if (!value || validateMobileNumber(value)) {
                return Promise.resolve();
              }
              return Promise.reject("Please enter correct number");
            },
          },
        ]}
      >
        <PatternFormat
          placeholder="Additional Phone"
          customInput={Input}
          format={K.PhoneFormat.USNational}
        />
      </Form.Item>
      <Form.Item
        className={editFormStyles.customFormItem}
        label={
          <CustomLabel
            label="Home Address"
            required={false}
            isChanged={isChanged("location", "location")}
          />
        }
        name="location"
      >
        <Input placeholder="Enter Home Address" />
      </Form.Item>

      <Form.Item
        className={editFormStyles.customFormItem}
        label={
          <CustomLabel
            label="Current Title"
            required={false}
            isChanged={isChanged("currentTitle", "currentTitle")}
          />
        }
        name="currentTitle"
        rules={[noTrailingSpaceAllowedRule()]}
      >
        <Input
          placeholder="Enter Current Title"
          className={styles.addCandidateInputFields}
        />
      </Form.Item>
    </Form>
  );
}
