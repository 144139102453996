import { createSlice } from "@reduxjs/toolkit";
import K from "utilities/constants";

const { Redux } = K;

const initialState = {
  [Redux.Lookup]: {
    [Redux.Source]: [],
    [Redux.AdSource]: [],
    [Redux.ReportingSchedule]: [],
    [Redux.Stage]: [],
    [Redux.AtsName]: [],
    [Redux.CandidateStatus]: [],
    [Redux.JobStatus]: [],
    [Redux.JobLocation]: [],
    [Redux.JobDepartment]: [],
    [Redux.JobGroup]: [],
    [Redux.JobGroup]: [],
    [Redux.JobTitle]: [],
    [Redux.Ownership]: [],
    [Redux.ContactTypes]: [],
    [Redux.BillingTypes]: [],
    [Redux.UserDesignation]: [],
    [Redux.ClassStatus]: [],
  },
  [Redux.BillingClients]: [],
  [Redux.BillingJobs]: [],
  [Redux.Currencies]: [],
};

export const configrationsSlice = createSlice({
  name: "Configrations",
  initialState,
  reducers: {
    addLookupData: (state, action) => {
      state[Redux.Lookup][action.payload.table] = [
        ...state[Redux.Lookup][action.payload.table],
        action.payload.data,
      ];
    },
    editLookupData: (state, action) => {
      state[Redux.Lookup][action.payload.table] = state[Redux.Lookup][
        action.payload.table
      ].map((item) => {
        if (action.payload.data.id === item.id) return action.payload.data;
        return item;
      });
    },
    getLookupData: (state, action) => {
      state[Redux.Lookup][action.payload.table] = action.payload.data;
    },
    deleteLookupData: (state, action) => {
      state[Redux.Lookup][action.payload.table] = state[Redux.Lookup][
        action.payload.table
      ].filter((item) => item.id !== action.payload.id);
    },
    setBillingClients: (state, action) => {
      state[Redux.BillingClients] = action.payload;
    },
    addBillingClients: (state, action) => {
      state[Redux.BillingClients] = [
        ...state[Redux.BillingClients],
        action.payload.data,
      ];
    },
    editBillingClients: (state, action) => {
      state[Redux.BillingClients] = state[Redux.BillingClients].map((item) => {
        if (action.payload.data.id === item.id) return action.payload.data;
        return item;
      });
    },
    addBillingJobs: (state, action) => {
      state[Redux.BillingJobs] = [
        ...state[Redux.BillingJobs],
        action.payload.data,
      ];
    },
    setBillingJobs: (state, action) => {
      state[Redux.BillingJobs] = action.payload;
    },
    editBillingJobs: (state, action) => {
      state[Redux.BillingJobs] = state[Redux.BillingJobs].map((item) => {
        if (action.payload.data.id === item.id) return action.payload.data;
        return item;
      });
    },
    setCurrencies: (state, action) => {
      state[Redux.Currencies] = action.payload;
    },
  },
});

export const selectConfigration = (state) => state.configration;
export const {
  getLookupData,
  addLookupData,
  editLookupData,
  deleteLookupData,
  setBillingClients,
  addBillingClients,
  addBillingJobs,
  setBillingJobs,
  editBillingJobs,
  editBillingClients,
  setCurrencies,
} = configrationsSlice.actions;

export default configrationsSlice.reducer;
