import NetworkCall from "network/networkCall";
import Request from "network/request";
import { camelCaseKeys } from "utilities/generalUtility";

export default class ClientContact {
  static async getAll() {
    const res = await NetworkCall.fetch(Request.getClientContact());
    return res.data.map((obj) => camelCaseKeys(obj));
  }

  static async create(values) {
    const res = await NetworkCall.fetch(
      Request.createClientContact(values),
      false,
    );
    return camelCaseKeys(res.data);
  }

  static async update(values, id) {
    const res = await NetworkCall.fetch(
      Request.updateClientContact(values, id),
      false,
    );
    return camelCaseKeys({ id, ...res.data });
  }

  static async delete(id) {
    await NetworkCall.fetch(Request.deleteClientContact(id));
  }
  static async deleteClientContact(contactId) {
    const res = await NetworkCall.fetch(Request.deleteClientContact(contactId));
    return res;
  }
}
