import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Typography,
} from "antd";

import LayoutCss from "layout/layout.module.scss";
import { groupBy, map } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { selectCandidates } from "redux/slices/candidateSlice";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { onFilterTextBoxChanged } from "utilities/tableUtility";

import styles from "./clientsJob.module.scss";
import "./jobCard.scss";

import { selectUdf, setCandidateUdf } from "redux/slices/udfSlice";
import UDF from "redux/models/udf";
import ClassStatistics from "./classStatistics";
import Class from "redux/models/class";
import ClassCandidateListing from "features/candidates/classCandidateListing";
import ClassSettingModal from "../details/classes/classSettingsModal";
import ClassViewActivity from "features/candidates/classViewActivity";
import AssignCandidate from "../details/classes/assignCandidateClass/assignCandidateClass";
import AddClassCandidate from "features/candidates/addClassCandidate";
import CancelClassForm from "../details/classes/cancelClassForm";
import LookupTable from "redux/models/lookupTable";

const { Search } = Input;
const { Title, Text } = Typography;
const { Lookup } = K.Network.URL;

export default function ClientClassDetail() {
  const gridRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId, classId } = useParams();

  const [settingsForm] = Form.useForm();
  const [candidateForm] = Form.useForm();
  const [cancelClassForm] = Form.useForm();
  const initialSettingsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const candidatesSlice = useSelector(selectCandidates);

  const userSlice = useSelector(selectUser);

  const [candidateActivity, setCandidateActivity] = useState(null);
  const [modalVisible, setModalVisible] = useState({
    editCandidateId: null,
    editCandidateEntryId: null,
    isSettingModalVisible: false,
    isClassCancelModalVisible: false,
    isCandidateModalVisible: false,
    isCancelBillingModalVisible: false,
  });
  const [classData, setClassData] = useState({
    classDetails: null,
    statistics: null,
    status: [],
    subStatus: {},
    settings: null,
    shouldUpdate: false,
    shouldUpdateListing: false,
    moveCandidates: false,
  });

  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);

  const classStatus = {
    open: "open-flag",
    closed: "closed-flag",
    "on hold": "onhold-flag",
    custom: "blue-flag",
  };

  const lookupTables = useSelector(selectConfigration).lookup;

  const showModalAddCandidate = () => {
    candidateForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isCandidateModalVisible: true, editCandidateId: null };
    });
  };
  const showSettingsModal = () => {
    settingsForm.setFieldsValue(initialSettingsRef.current);
    setModalVisible((prev) => {
      return { ...prev, isSettingModalVisible: true };
    });
  };

  const showAssigCandidateModal = () => {
    setIsAssignModalVisible(true);
  };

  const handleOkCancelClass = () => {
    cancelClassForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isClassCancelModalVisible: false };
    });
  };
  const addCandidateOk = () => {
    candidateForm.submit();
  };
  const handleOkSetting = () => {
    settingsForm.submit();
  };

  const handleCancelClassCancel = () => {
    cancelClassForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isClassCancelModalVisible: false };
    });
  };

  const addCandidateCancel = () => {
    setModalVisible((prev) => {
      return { ...prev, isCandidateModalVisible: false };
    });
  };
  const handleCancelSetting = () => {
    settingsForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isSettingModalVisible: false };
    });
  };

  const getStatusBreakdown = async () => {
    try {
      const res = await Class.getSubStatusBreakdown(classId);
      const subStatus = groupBy(res, (item) => item.status.name);
      const status = map(subStatus, (item) => {
        const total = item.reduce(
          (prev, curr) => curr.candidatesToSubStatusCount + prev,
          0,
        );
        return { id: item[0].status.id, name: item[0].status.name, total };
      });

      setClassData((prev) => ({ ...prev, status, subStatus }));
    } catch (err) {
      console.error(err);
    }
  };
  const getClassStatistics = async () => {
    try {
      const res = await Class.getStatistics(classId);
      setClassData((prev) => ({ ...prev, statistics: res }));
    } catch (err) {
      console.error(err);
    }
  };

  const getClassSettings = async () => {
    try {
      const res = await Class.getSettings(classId);
      setClassData((prev) => ({ ...prev, settings: res }));
    } catch (err) {
      console.error(err);
    }
  };

  const getClassDetails = async () => {
    try {
      const res = await Class.getClassDetailById(classId);

      setClassData((prev) => ({
        ...prev,
        classDetails: {
          ...res,
          vacancies: res.classVacancies.at(-1),
        },
      }));
    } catch (err) {
      console.error(err);
    }
  };
  const onChange = useCallback(
    (event) => onFilterTextBoxChanged(event.target.value, gridRef),
    [],
  );

  const onStatusChange = async (statusId) => {
    if (statusId === undefined) {
      return null;
    }
    const body = {
      classId: classId,
      classStatusId: statusId,
    };
    setClassData((prev) => ({
      ...prev,
      classDetails: { ...prev.classDetails, classStatusId: statusId },
    }));

    try {
      await Class.updateClassStatus(body);
      message.success("Class Status Updated Successfully.");
    } catch (err) {
      console.error(err);
      message.error("Failed to Updated Class Status.");
    }
  };

  const getCandidateUdfs = async (entityKeyId = null) => {
    const candidateUdfs = await UDF.getEditUdfFields({
      clientId: clientId,
      entityId: K.UDF.Entities.Candidate.id,
      entityKey: K.UDF.Entities.Candidate.key,
      entityKeyId: entityKeyId,
      isVisible: 1,
    });

    dispatch(setCandidateUdf(candidateUdfs));
    return candidateUdfs;
  };

  const getClassStatus = async () => {
    try {
      await dispatch(
        LookupTable.getData(K.Redux.ClassStatus, Lookup.ClassStatus),
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getClassStatus();
  }, [clientId, classId]);

  useEffect(() => {
    (async () => {
      await Promise.all([
        getClassDetails(),
        getClassSettings(),
        getClassStatistics(),
        getStatusBreakdown(),
        getCandidateUdfs(),
      ]);
    })();
  }, [
    classId,
    clientId,
    classData.shouldUpdate,
    classData.shouldUpdateListing,
    candidatesSlice.shouldUpdate,
  ]);

  const getClassStatusName = (classStatusId) => {
    return (
      lookupTables.classStatus?.find((item) => item.id === classStatusId)
        ?.name ?? K.NullPlaceholder
    );
  };
  const getClassStatusColor = (classStatusId) => {
    const status = getClassStatusName(classStatusId).toLowerCase();
    const statusColor = ["open", "closed", "on hold"].includes(status)
      ? status
      : "custom";

    return statusColor;
  };

  return (
    <>
      <div className={styles.wrapPageHeader}>
        <PageHeader
          className={[
            LayoutCss.appPageHeader,
            LayoutCss.clientsJobPageHeader +
              " client-page-head" +
              " client-details",
          ]}
          ghost={false}
          onBack={history.goBack}
          title={
            <>
              <span className="icon-view-wrap">
                <i className="icon-view-detail"></i>
              </span>
              <Text>{classData.classDetails?.title}</Text>
              <Text
                className={
                  classStatus[
                    getClassStatusColor(classData?.classDetails?.classStatusId)
                  ]
                }
              >
                <span className="dot"></span>
                {lookupTables.classStatus
                  ?.find(
                    (item) =>
                      item.id === classData?.classDetails?.classStatusId,
                  )
                  ?.name.toUpperCase()}
              </Text>
            </>
          }
          subTitle={classData.classDetails?.name}
        />
      </div>
      <ClassStatistics styles={styles} classData={classData} />
      <Row gutter={[16, 16]} className={"job-card-wrap " + styles.jobCardWrap}>
        <Col xs={24} xl={candidateActivity ? 14 : 24}>
          <Card bordered={false} className={styles.bottomCard}>
            <div
              className={`${styles.bottomCardInfo} ${
                "bottom-card-info-header " + styles.bottomCardInfoHeader
              }`}
            >
              <div className={styles.jobSearch}>
                <Search allowClear placeholder="Search" onChange={onChange} />
              </div>

              <div className={styles.jobCardHeader}>
                <div className={styles.jobInfo}>
                  <Select
                    showSearch
                    placeholder="Class Status"
                    optionFilterProp="label"
                    value={classData?.classDetails?.classStatusId}
                    className={styles.jobStatusField}
                    options={lookupTables?.classStatus.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    onChange={onStatusChange}
                  />

                  {isPermissionPresent(
                    K.Permissions.AddClassJobCandidate,
                    userSlice.roles,
                  ) && (
                    <div className={styles.addCandidateModal}>
                      <Button
                        type="primary"
                        onClick={showModalAddCandidate}
                        className={styles.addCandidate}
                        disabled={
                          !classData.classDetails?.vacancies?.isActive ?? true
                        }
                      >
                        Create Candidate
                      </Button>
                    </div>
                  )}
                  {isPermissionPresent(
                    K.Permissions.ClassAssignCandidate,
                    userSlice.roles,
                  ) && (
                    <div className={styles.addCandidateModal}>
                      <Button
                        type="primary"
                        onClick={showAssigCandidateModal}
                        className={styles.addCandidate}
                        disabled={
                          !classData.classDetails?.vacancies?.isActive ?? true
                        }
                      >
                        Assign Candidate
                      </Button>
                    </div>
                  )}
                </div>
                <div className={styles.iconsModals}>
                  <div className={styles.settingButton}>
                    <Button
                      type="primary"
                      onClick={showSettingsModal}
                      className={styles.bottomCardIcon}
                    >
                      <i className={"icon-setting " + styles.settingIcon}></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <ClassCandidateListing
              gridRef={gridRef}
              setModalVisible={setModalVisible}
              candidateActivity={candidateActivity}
              setCandidateActivity={setCandidateActivity}
              shouldUpdateListing={classData.shouldUpdateListing}
              setJobData={setClassData}
            />
          </Card>
        </Col>

        {candidateActivity && (
          <Col xs={24} xl={10}>
            <ClassViewActivity
              jobId={candidateActivity.jobsourceId}
              styles={styles}
              candidateActivity={candidateActivity}
              setCandidateActivity={setCandidateActivity}
              setModalVisible={setModalVisible}
              candidateForm={candidateForm}
              classDetails={classData.classDetails}
              setClassData={setClassData}
              getCandidateUdfs={getCandidateUdfs}
            />
          </Col>
        )}
      </Row>

      <Modal
        title={`${modalVisible.editCandidateId ? "Edit" : "Add New"} Candidate`}
        className={styles.addNewCandidateModal + " modal-overflow-auto"}
        open={modalVisible.isCandidateModalVisible}
        width={632}
        centered
        destroyOnClose
        onCancel={addCandidateCancel}
        closeIcon={<i className={"icon-closeable " + styles.closeAble}></i>}
        footer={
          <>
            <Button
              className={styles.candidateCancelButton}
              onClick={addCandidateCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={addCandidateOk}
              loading={isLoading}
              className={styles.candidateSubmitButton}
            >
              Submit
            </Button>
          </>
        }
      >
        <AddClassCandidate
          styles={styles}
          form={candidateForm}
          setIsLoading={setIsLoading}
          addCandidateCancel={addCandidateCancel}
          editCandidateId={modalVisible.editCandidateId}
          editCandidateEntryId={modalVisible.editCandidateEntryId}
          setCandidateActivity={setCandidateActivity}
          candidateActivity={candidateActivity}
          getCandidateUdf={getCandidateUdfs}
          classId={classId}
        />
      </Modal>
      <Modal
        centered
        open={modalVisible.isSettingModalVisible}
        onCancel={handleCancelSetting}
        width={572}
        destroyOnClose
        closeIcon={<i className="icon-closeable"></i>}
        title="Class Settings"
        className="s2-theme-style modal-overflow-auto"
        footer={
          <>
            <Button
              className={styles.jobCancelButton}
              onClick={handleCancelSetting}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={handleOkSetting}
              className={styles.jobConfirmButton}
              disabled={!classData.classDetails?.vacancies?.isActive ?? true}
            >
              Save
            </Button>
          </>
        }
      >
        <ClassSettingModal
          classId={classId}
          classData={classData}
          form={settingsForm}
          setClassData={setClassData}
          classDetails={classData.classDetails}
          classStats={classData.settings}
          setModalVisible={setModalVisible}
        />
      </Modal>

      <AssignCandidate
        isModalVisible={isAssignModalVisible}
        setIsModalVisible={setIsAssignModalVisible}
        clientId={clientId}
        classId={classId}
        setClassData={setClassData}
        classCandidates={candidatesSlice.listing.map((item) => item.id)}
      />
      {/* 
      <Modal
        centered
        width={462}
        title="Cancel Class"
        className={styles.jobCancelModal}
        open={modalVisible.isClassCancelModalVisible}
        onCancel={handleCancelClassCancel}
        closeIcon={<i className={"icon-closeable " + styles.closeAble}></i>}
        footer={
          <>
            <Button
              className={styles.jobCancelButton}
              onClick={handleCancelClassCancel}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={cancelClassForm.submit}
              className={styles.jobConfirmButton}
              disabled={!classData.moveCandidates}
            >
              Confirm
            </Button>
          </>
        }
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {" "}
            <Title level={5} className={styles.jobCancelSubTitle}>
              <i
                className={"icon-vacancies-briefcase " + styles.briefcaseIcon}
              ></i>
              Current Openings:{" "}
              {classData.classDetails?.vacancies?.currentOpenings ?? 0}
            </Title>
          </div>
          <div>
            <Title level={5} className={styles.jobCancelSubTitle}>
              <i className={"icon-user " + styles.briefcaseIcon}></i>
              No. of Submittals: {classData.classDetails?.submittedCount ?? 0}
            </Title>
          </div>
        </div>
        <CancelClassForm
          jobId={classId}
          clientId={clientId}
          form={cancelClassForm}
          styles={styles}
          getClassSettings={getClassSettings}
          classDetails={classData.classDetails}
          setClassData={setClassData}
          setIsLoading={setIsLoading}
          handleOkCancelClass={handleOkCancelClass}
        />
      </Modal> */}
      {/* Submit Cancel Billing  */}
      {/* <Modal
        centered
        width={462}
        title="Submit Cancel Billings"
        className={styles.jobCancelModal + " cancelBilling"}
        open={modalVisible.isCancelBillingModalVisible}
        onCancel={handleCancelBilling}
        closeIcon={<i className={"icon-closeable " + styles.closeAble}></i>}
        footer={
          <>
            <Button
              className={styles.jobCancelButton}
              onClick={handleCancelBilling}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={cancelBillingForm.submit}
            >
              Confirm
            </Button>
          </>
        }
      >
        <Title level={5} className={styles.jobCancelSubTitle}>
          <i className={"icon-user" + styles.briefcaseIcon}></i>
          No. of Submittals: {jobData.jobDetails?.submittedCount ?? 0}
        </Title>

        <SubmitCancelBillingForm
          jobId={jobId}
          clientId={clientId}
          form={cancelBillingForm}
          styles={styles}
          setIsLoading={setIsLoading}
          handleCancelBillingOk={handleCancelBillingOk}
        />
      </Modal> */}
    </>
  );
}
