import { Table } from "antd";
import { displayDollar } from "utilities/tableUtility";

export default function RecruiterTab({ styles, recruiters }) {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
    },
    {
      title: "Threshold",
      dataIndex: "threshold",
      key: "threshold",
      align: "center",
      render: (text) => displayDollar(text),
    },
  ];
  return (
    <div className={styles.cicTabsContent}>
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={recruiters}
      />
    </div>
  );
}
