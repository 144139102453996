import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import styles from "./contactPersonDetails.module.scss";
import { getIsVisibleLookups } from "utilities/generalUtility";

export default function ContactPersonDetails({
  form,
  parentIndex,
  contactList,
  editData,
  keyName,
}) {
  const [contactDetails, setContactDetails] = useState({
    contactId: null,
    contactTypes: [],
  });
  const params = new URLSearchParams(window.location.search);
  const configrationSlice = useSelector(selectConfigration);

  useEffect(() => {
    if (params.has("tab")) {
      const contactPersonDetailsName = [];
      const contacts = editData?.contacts?.map((item) =>
        item[keyName].map((obj) => obj.id),
      );
      editData?.contacts?.map(
        (item, index) =>
          (contactPersonDetailsName[index] = {
            contactId: item.id,
            contactTypes: contacts[index],
          }),
      );
      if (contactPersonDetailsName) {
        form.setFieldsValue({
          contactPersonDetail: [...contactPersonDetailsName],
        });
      }
    }
  }, [form, editData]);

  const onNameChange = (value) => {
    setContactDetails((prev) => {
      return {
        contactId: value,
        contactTypes: prev.contactTypes,
      };
    });
  };
  const onTypeChange = (value) => {
    setContactDetails((prev) => {
      return {
        contactTypes: value,
        contactId: prev.contactId,
      };
    });
  };

  const contactPersonValues = () => {
    if (parentIndex !== undefined) {
      const billingUnits = form.getFieldValue("billingUnits");
      const prevDetails =
        billingUnits[parentIndex].contactPersonDetail.filter(Boolean);

      if (billingUnits.length > 1) {
        const updated = billingUnits.map((item, i) => {
          if (i === parentIndex)
            return prevDetails.length > 0
              ? {
                  name: billingUnits[parentIndex].name,
                  contactPersonDetail: [...prevDetails, contactDetails],
                }
              : {
                  name: billingUnits[parentIndex].name,
                  contactPersonDetail: [contactDetails],
                };
          return item;
        });
        form.setFieldsValue({
          billingUnits: updated,
        });
      } else {
        if (prevDetails.length > 0) {
          form.setFieldsValue({
            billingUnits: [
              {
                name: billingUnits[parentIndex].name,
                contactPersonDetail: [...prevDetails, contactDetails],
              },
            ],
          });
        } else {
          form.setFieldsValue({
            billingUnits: [
              {
                name: billingUnits[parentIndex].name,
                contactPersonDetail: [contactDetails],
              },
            ],
          });
        }
      }
    } else {
      const prevDetails = form
        .getFieldValue("contactPersonDetail")
        .filter(Boolean);
      if (prevDetails.length > 0) {
        form.setFieldsValue({
          contactPersonDetail: [...prevDetails, contactDetails],
        });
      } else {
        form.setFieldsValue({
          contactPersonDetail: [contactDetails],
        });
      }
    }
    setContactDetails({
      contactId: null,
      contactTypes: [],
    });
  };

  const getContactListing = () => {
    let list;
    if (parentIndex !== undefined) {
      const billingUnits = form.getFieldValue("billingUnits");
      list =
        billingUnits[parentIndex].contactPersonDetail?.map(
          ({ contactId }) => contactId,
        ) ?? [];
    } else {
      list =
        form
          .getFieldValue("contactPersonDetail")
          ?.map(({ contactId }) => contactId) ?? [];
    }
    return contactList.filter(({ id }) => !list.includes(id));
  };
  return (
    <fieldset className={styles.fieldSet}>
      <legend className={styles.fieldTitle}>Contact Person Details</legend>
      <Form.List
        name={
          parentIndex !== undefined
            ? [parentIndex, "contactPersonDetail"]
            : "contactPersonDetail"
        }
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <Row
                key={key}
                className="mb-3"
                gutter={[16, 16]}
                id={`${index}CPDpopConfirm`}
              >
                <Col xs={24} sm={11} id={`${index}selectContactIdPatent`}>
                  <Form.Item
                    className="mb-0"
                    label="Name"
                    name={[index, "contactId"]}
                  >
                    <Select
                      showSearch
                      allowClear
                      className={styles.selectField}
                      placeholder="Select Contact"
                      optionFilterProp="label"
                      disabled
                      getPopupContainer={() =>
                        document.getElementById(`${index}selectContactIdPatent`)
                      }
                      options={contactList.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={11} id={`${index}selectTypeIdPatent`}>
                  <Form.Item
                    className="mb-0"
                    label="Type"
                    name={[index, "contactTypes"]}
                  >
                    <Select
                      showSearch
                      allowClear
                      className={styles.selectField}
                      placeholder="Select Type"
                      mode="multiple"
                      optionFilterProp="label"
                      disabled
                      getPopupContainer={() =>
                        document.getElementById(`${index}selectTypeIdPatent`)
                      }
                      options={configrationSlice[K.Redux.Lookup][
                        K.Redux.ContactTypes
                      ].map((item) => ({ value: item.id, label: item.name }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={2} className={styles.formIcon}>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                      remove(name);
                    }}
                    okText="Yes"
                    cancelText="No"
                    getPopupContainer={() =>
                      document.getElementById(`${index}CPDpopConfirm`)
                    }
                  >
                    <Button
                      type="link"
                      icon={
                        <i className={"icon-delet " + styles.deletIcon}></i>
                      }
                    />
                  </Popconfirm>
                </Col>
              </Row>
            ))}

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={11}>
                <Form.Item label="Name" className="mb-0">
                  <Select
                    showSearch
                    allowClear
                    className={styles.selectField}
                    placeholder="Select"
                    value={contactDetails.contactId}
                    onChange={onNameChange}
                    optionFilterProp="label"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    options={getContactListing().map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={11}>
                <Form.Item label="Type" className="mb-0">
                  <Select
                    className={styles.selectField}
                    placeholder="Select"
                    value={contactDetails.contactTypes}
                    mode="multiple"
                    showArrow
                    allowClear
                    showSearch={true}
                    optionFilterProp="label"
                    onChange={onTypeChange}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    // options={configrationSlice[K.Redux.Lookup][
                    //   K.Redux.ContactTypes
                    // ].map((item) => ({
                    //   value: item.id,
                    //   label: item.name,
                    // }))}
                    options={getIsVisibleLookups(
                      configrationSlice[K.Redux.Lookup][K.Redux.ContactTypes] ??
                        [],
                      null,
                    ).map(({ id, name, isVisible }) => ({
                      value: id,
                      label: name,
                      disabled: isVisible === 1 ? false : true,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={2} className={styles.formIcon}>
                <Button
                  disabled={
                    !!!(
                      contactDetails.contactId &&
                      contactDetails.contactTypes.length
                    )
                  }
                  icon={<PlusOutlined />}
                  onClick={() => {
                    add();
                    contactPersonValues();
                  }}
                  size="middle"
                  type="secondary"
                />
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </fieldset>
  );
}
