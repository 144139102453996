import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  message,
  Modal,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Candidate from "redux/models/candidate";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { debounce, isEmpty, isNil } from "lodash";
import EditForm from "./editForm";
import MergeForm from "./mergeForm";

const { Title, Text } = Typography;

export default function UserDetailCard({
  styles,
  userDetail,
  setCandidateDetail,
  isEditUserInfo,
  onMergeCallback,
}) {
  const userSlice = useSelector(selectUser);

  const [form] = useForm();
  const [, setCurrentState] = useState(userDetail);
  const [isVisible, setIsModalVisible] = useState(false);
  const [isMerge, setIsMerge] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [editData, setEditData] = useState({});
  const [sourceCandidate, setSourceCandidate] = useState(null);
  const [destinationCandidate, setDestinationCandidate] = useState(null);
  const userRoles = useSelector(selectUser).roles;
  const debouncedFetchCandidateData = useRef(null);

  const hasAccessToMerge = isPermissionPresent(
    K.Permissions.CandidateProfileMerge,
    userSlice.roles,
  );

  const afterModalClose = () => {
    form.resetFields();
    setEditData({});
  };

  const editRecord = (data) => {
    setIsModalVisible(true);
    setEditData(data);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onValuesChange = (changedValues, allValues) => {
    setCurrentState(allValues);
  };

  const openMergeView = () => {
    setSourceCandidate(form.getFieldsValue());
    setIsMerge(true);
  };

  const isChanged = (stateField, userField) => {
    const current = form.getFieldsValue();
    const isEmptyCurrentState =
      isNil(current[stateField]) || isEmpty(current[stateField]);
    const isEmptyInitialState =
      isNil(userDetail[userField]) || isEmpty(userDetail[userField]);
    if (isEmptyCurrentState && isEmptyInitialState) {
      return false;
    }
    return current[stateField] !== userDetail[userField];
  };

  const fetchCandidateData = async (email) => {
    if (email === userDetail.email) {
      setDestinationCandidate(null);
      setIsLoading(false);
      return;
    }

    try {
      const {
        data: [candidate],
      } = await Candidate.v2.get({ email }, false);
      if (candidate && !isEmpty(candidate)) {
        setDestinationCandidate(candidate);
      } else {
        setDestinationCandidate(null);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onEmailChange = (event) => {
    if (hasAccessToMerge) {
      setIsDisabled(false);
      setIsLoading(true);
      setDestinationCandidate(null);
      debouncedFetchCandidateData.current(event);
    }
  };

  const onMerge = async (values) => {
    setIsLoading(true);
    try {
      const requestData = {
        sourceEmail: userDetail.email,
        destinationEmail: destinationCandidate.email,
        data: values,
      };
      const response = await Candidate.v2.merge(requestData);
      await onMergeCallback(response.data.id);
      setIsLoading(false);
      setIsModalVisible(false);
      message.success(response.message);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const onEdit = async (values) => {
    setIsLoading(true);
    const body = {
      ...values,
      id: editData.id,
    };
    try {
      const res = await Candidate.editCandidateDetails(body);
      const { firstName, lastName, phoneNumber: phone, ...rest } = res;
      setCandidateDetail((prev) => ({
        ...{
          ...rest,
          firstName,
          lastName,
          phone,
          name: [firstName, lastName].join(" "),
        },
        candidateJobEntries: prev.candidateJobEntries,
      }));
      setIsLoading(false);
      setIsModalVisible(false);
      message.success("Candidate updated successfully");
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        email: editData?.email,
        firstName: editData?.firstName,
        lastName: editData?.lastName,
        mobileNumber: editData?.mobileNumber,
        phoneNumber: editData?.phone,
        phoneExt: editData?.phoneExt,
        location: editData?.location,
        currentTitle: editData?.currentTitle,
      });
      setCurrentState(editData);
      setIsMerge(false);
      setIsLoading(false);
      setIsDisabled(false);
      setDestinationCandidate(null);
      debouncedFetchCandidateData.current = debounce((e) => {
        const email = e.target.value;
        return form
          .validateFields(["email"])
          .then(() => {
            fetchCandidateData(email);
          })
          .catch((errorInfo) => {
            console.error(errorInfo);
          });
      }, 1000);
    }
  }, [form, editData]);

  return (
    <div className={styles.candidateProfileCardWrapper}>
      <Card className={styles.candidateProfileCard}>
        {isPermissionPresent(K.Permissions.EditCandidate, userRoles) &&
          isEditUserInfo && (
            <div className="user-edit-btn">
              <Button
                className={styles.editBtn}
                type="link"
                onClick={() => editRecord(userDetail)}
                icon={<i className="icon-edit" />}
              >
                Edit
              </Button>
            </div>
          )}
        <div className={styles.profileImageAndNameWrapper}>
          <div className={styles.profileImageWrapper}>
            <Avatar
              className={styles.profileImage}
              size={96}
              icon={<UserOutlined />}
            />
          </div>
          <Title level={5} className={styles.profileName}>
            {userDetail.name}
          </Title>
        </div>
        <Divider className={styles.cdDividerStyle} />
        <div className={styles.candidateContact}>
          <Title level={4} className={styles.contactTitle}>
            Contact
          </Title>
          <div className={styles.iconAndContactWrapper}>
            <div className={styles.cpIconWrapper}>
              <i className={`icon-email ${styles.cpIconStyle}`} />
            </div>

            <div className={styles.cpContactWrapper}>
              <label className={styles.cpLabelStyle}>Email</label>
              <Text className={`${styles.cpTextStyle} hover-underlined`}>
                <a href={`mailto:${userDetail.email}`}>{userDetail.email}</a>
              </Text>
            </div>
          </div>
          {isEditUserInfo && (
            <div className={styles.iconAndContactWrapper}>
              <div className={styles.cpIconWrapper}>
                <i className={`icon-phone ${styles.cpIconStyle}`} />
              </div>

              <div className={styles.cpContactWrapper}>
                <label className={styles.cpLabelStyle}>Mobile Number</label>
                <Text className={`${styles.cpTextStyle} hover-underlined `}>
                  <a href={`tel:${userDetail.mobileNumber}`}>
                    {userDetail.mobileNumber}
                  </a>
                </Text>
              </div>
            </div>
          )}

          <div className={styles.iconAndContactWrapper}>
            <div className={styles.cpIconWrapper}>
              <i className={`icon-phone ${styles.cpIconStyle}`} />
            </div>

            <div className={styles.cpContactWrapper}>
              <label className={styles.cpLabelStyle}>
                {isEditUserInfo ? "Additional Phone" : "Phone"}
              </label>
              <Text className={`${styles.cpTextStyle} hover-underlined `}>
                <a
                  href={`tel:${
                    isEditUserInfo ? userDetail.phone : userDetail.phone
                  }`}
                >
                  {isEditUserInfo ? userDetail.phone : userDetail.phone}
                </a>
              </Text>
            </div>
          </div>
          {!isEditUserInfo && (
            <div className={styles.iconAndContactWrapper}>
              <div className={styles.cpIconWrapper}>
                <i className={`icon-phone ${styles.cpIconStyle}`} />
              </div>
              <div className={styles.cpContactWrapper}>
                <label className={styles.cpLabelStyle}>Phone Ext</label>
                <Text className={styles.cpTextStyle}>
                  <a href={`tel:${userDetail.phoneExt ?? K.NullPlaceholder}`}>
                    {userDetail.phoneExt ?? K.NullPlaceholder}
                  </a>
                </Text>
              </div>
            </div>
          )}
        </div>
      </Card>

      <Modal
        forceRender
        centered
        open={isVisible}
        closeIcon={<i className="icon-closeable" />}
        className="s2-theme-style"
        title={isMerge ? "Merge Candidates" : "Edit Candidate Basic Info"}
        wrapClassName="vertical-center-modal"
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
        afterClose={afterModalClose}
        width={isMerge ? 890 : 520}
        footer={[
          <Button key="1" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="2"
            type="primary"
            loading={isLoading}
            onClick={form.submit}
            disabled={isDisabled}
          >
            {!isEmpty(destinationCandidate) && hasAccessToMerge
              ? "Merge"
              : "Update"}
          </Button>,
        ]}
      >
        {!isMerge ? (
          <EditForm
            styles={styles}
            onFinish={
              !isEmpty(destinationCandidate) && hasAccessToMerge
                ? openMergeView
                : onEdit
            }
            onValuesChange={onValuesChange}
            form={form}
            isChanged={isChanged}
            onEmailChange={onEmailChange}
            isMerge={hasAccessToMerge && !isNil(destinationCandidate)}
          />
        ) : (
          <MergeForm
            form={form}
            leftEntity={{
              ...sourceCandidate,
              email: userDetail.email,
              isBlacklist: userDetail.isBlacklist,
            }}
            rightEntity={destinationCandidate}
            onMerge={onMerge}
          />
        )}
      </Modal>
    </div>
  );
}
