import { createSlice } from "@reduxjs/toolkit";
import K from "utilities/constants";

const { Redux } = K;

const initialState = {
  [Redux.Listing]: [],
  [Redux.SelectedClient]: null,
  [Redux.CreateClientData]: {
    [Redux.ClientDetails]: null,
    [Redux.BillingUnit]: [],
    [Redux.ClientLevelBilligRules]: [],
  },
  [Redux.IsTestPassed]: false,
};

export const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClientList: (state, action) => {
      state[Redux.Listing] = action.payload;
    },
    setSelectedClient: (state, action) => {
      state[Redux.SelectedClient] = action.payload;
    },
    editClientData: (state, action) => {
      state[Redux.SelectedClient].clientBillingUnit = state[
        Redux.SelectedClient
      ].clientBillingUnit.map((item) => {
        if (action.payload.clientBillingUnit.id === item.id) {
          return action.payload.clientBillingUnit;
        } else {
          return item;
        }
      });
    },
    editClientDataInfo: (state, action) => {
      if (state[Redux.SelectedClient].id === action.payload.id) {
        state[Redux.SelectedClient] = {
          ...state[Redux.SelectedClient],
          ...action.payload,
        };
      }
    },
    addClientData: (state, action) => {
      state[Redux.SelectedClient].clientBillingUnit = [
        ...state[Redux.SelectedClient].clientBillingUnit,
        action.payload.clientBillingUnit,
      ];
    },
    updateCreateData: (state, action) => {
      state[Redux.CreateClientData][action.payload.key] = action.payload.values;
    },
    setIsTestPassed: (state, action) => {
      state[Redux.IsTestPassed] = action.payload;
    },
  },
});

export const selectClient = (state) => state.clients;
export const {
  setClientList,
  addClientData,
  editClientData,
  setIsTestPassed,
  updateCreateData,
  setSelectedClient,
  editClientDataInfo,
} = clientSlice.actions;

export default clientSlice.reducer;
