import { message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { useDispatch } from "react-redux";
import { setUploadCandidate } from "redux/slices/candidateSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as XLSX from "xlsx";
import K from "utilities/constants";

const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: false,
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

export default function DragUpload({
  setCSVFile,
  onRemove,
  accept = ".csv",
  otherProps = {},
  isCandidateCsv = false,
  clientId = null,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const beforeUpload = (file) => {
    const isExeOrComFile = /\.(exe|com|msi)$/i.test(file.name);

    if (isExeOrComFile) {
      message.error("Uploading .exe.com and .msi files is not allowed.");
      return true;
    } else {
      if (isCandidateCsv) {
        const reader = new FileReader();
        reader.onload = (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, {
            raw: false,
            defval: "",
            blankrows: false,
          });
          const updatedData = [];
          data.forEach((item, index) => {
            if (item["First Name"] !== "") {
              const type =
                K.UploadHireType.find(
                  (i) => i.label === item["Candidate Hire Type"],
                )?.value ?? null;

              const updatedItem = {
                ...item,
                id: index,
                "Candidate Hire Type": type,
              };
              updatedData.push(updatedItem);
            }
          });

          dispatch(setUploadCandidate(updatedData));
          setCSVFile({});
          history.push(`/clients/${clientId}/preview-upload`);
        };
        reader.readAsBinaryString(file);
      } else {
        setCSVFile(file);
        dispatch(setUploadCandidate([]));
      }
    }

    return false;
  };

  return (
    <Dragger
      {...props}
      accept={accept === "all" ? null : accept}
      maxCount={1}
      onRemove={onRemove}
      beforeUpload={beforeUpload}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      {/*  <p className="ant-upload-hint">
        Support for a single upload. Strictly prohibit from uploading
        company data or other band files
      </p> */}
    </Dragger>
  );
}
