import { LockOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Form, Input, message } from "antd";
import md5 from "md5";
import { useHistory, useLocation } from "react-router-dom";
import User from "redux/models/user";

export default function SetPassword() {
  const history = useHistory();
  const location = useLocation();

  const onFinish = async (values) => {
    let token = location.search.substring(7);
    let encryptedPass = md5(values.password);
    try {
      await User.resetPassword(encryptedPass, token, values.remember);
      message.success("Password reset successfully!");
      history.replace("/login");
      // if (user) redirectToUrl("/");
    } catch (error) {}
  };

  return (
    <div className="login-container">
      <div className="lc-logo">
        <img src="images/logo.png" alt="" />
      </div>
      <Card bordered={false} className="login-card">
        <h4>Set Password</h4>
        <Form
          name="set-password"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 8,
                message: "Password must be atleast 8 characters long!",
              },
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined className="site-form-item-icon text-primary" />
              }
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Confirm Password is required" },
              { min: 8, message: "Password must be minimum 8 characters." },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The passwords does not match.");
                },
              }),
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined className="site-form-item-icon text-primary" />
              }
              placeholder="Confirm Password"
              size="large"
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item noStyle>
            <Button block size="large" type="primary" htmlType="submit">
              Done
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
