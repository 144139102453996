import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

import UserSelectOption from "common/components/userSelect/userSelect";
import { useEffect, useRef, useState, useTransition } from "react";

import Client from "redux/models/client";
import Dashboard from "redux/models/dashboard";
import LookupTable from "redux/models/lookupTable";
import User from "redux/models/user";

import K from "utilities/constants";
import {
  convertIntoSnakeCase,
  customUserHandleSearch,
} from "utilities/generalUtility";

import DashboardJobDetailModal from "./dashboardJobDetailModal";

import { useHistory } from "react-router-dom";
import PipelineDashboardReqDetail from "./pipelineDashboardReqDetail";
import PipelineDashboardSummary from "./pipelineDashboardSummary";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Title } = Typography;
const { Option } = Select;
const searchFilterPrefix = "pipeline_dashboard";

export default function PipelineDashboard({ globalSelector, setPrintData }) {
  const [form] = Form.useForm();
  const [isPending, startTransition] = useTransition();
  const history = useHistory();

  const statusRef = useRef(null);

  const [loading, setLoading] = useState({
    filterLoading: false,
    summaryLoading: false,
    reqLoading: false,
    reqDetailLoading: false,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [requisitionDetail, setRequisitionDetail] = useState([]);
  const [pageData, setPageData] = useState({
    hiringManagers: [],
    departments: [],
    recruiters: [],
    jobDepartmentListing: [],
    jobLocationListing: [],
  });
  const [statusDetail, setStatusDetail] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    candidateSummary: null,
  });
  const [setter, getter] = useSearchAndFilter();

  const onFinish = async (values) => {
    const data = { ...values };
    setLoading((prev) => {
      return { ...prev, summaryLoading: true, reqLoading: true };
    });
    console.log("values", values, getter(`${searchFilterPrefix}_recruiterId`));
    setter(values, searchFilterPrefix);

    Object.keys(data).forEach(
      (key) =>
        (data[key] === undefined ||
          data[key] === "" ||
          key === "title" ||
          key === "managerId" ||
          key === "recruiterId" ||
          key === "type") &&
        delete data[key],
    );
    const filter = Object.keys(data)
      .map((k) => {
        if (k === "ext_ats_id") {
          return k + "='" + data[k] + "'";
        } else {
          return k + "=" + data[k];
        }
      })
      .join(" AND ");

    let { managerId, title, recruiterId } = values;
    recruiterId = recruiterId ?? null;
    managerId = managerId ?? null;

    await Promise.all([
      fetchSummary({ managerId, title, recruiterId, filter }),
      startTransition(() =>
        getRequisitionDetail({ managerId, title, recruiterId, filter }),
      ),
    ]);

    setPrintData((prev) => ({
      ...prev,
      pipelineDashboard: {
        ...prev.pipelineDashboard,
        requisition: values.ext_ats_id ? values.ext_ats_id : K.NullPlaceholder,
        title: values.title ? values.title : K.NullPlaceholder,
      },
    }));
  };

  const getJobLocations = async () => {
    try {
      const body = {
        client_ids: globalSelector.selectedClients ?? [],
      };
      const res = await LookupTable.getJobLocationByMultipleClient(body, false);
      setPageData((prev) => {
        return { ...prev, jobLocationListing: res };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getClientDepartments = async () => {
    try {
      const body = {
        client_ids: globalSelector.selectedClients ?? [],
      };
      const res = await LookupTable.getJobDepartmentByMultipleClient(
        body,
        false,
      );
      setPageData((prev) => ({ ...prev, jobDepartmentListing: res }));
    } catch (err) {
      console.error(err);
    }
  };

  const getClientRecruiter = async () => {
    try {
      const body = {
        client_ids: globalSelector.selectedClients ?? [],
      };
      const recruiter = await Client.getRecruiterByClientsId(body, false);

      setPageData((prev) => ({ ...prev, recruiters: recruiter }));
    } catch (err) {
      console.error(err);
    }
  };

  const getRequisitionDetail = async (values) => {
    const body = { clientIds: globalSelector.selectedClients, ...values };
    try {
      const res = await Dashboard.getRequisitionDetail(body);
      startTransition(() => {
        setRequisitionDetail(res);
        setLoading((prev) => {
          return { ...prev, reqLoading: false };
        });
      });
    } catch (err) {
      console.error(err);
      setLoading((prev) => {
        return { ...prev, reqLoading: false };
      });
    }
  };

  const getJobDetailByStatus = async (statusId, jobIds, subStatusId) => {
    setIsModalVisible(true);
    setLoading((prev) => {
      return { ...prev, reqDetailLoading: true };
    });

    try {
      const payload = {
        jobIds: jobIds,
        statusId: subStatusId ? subStatusId : statusId,
      };
      const res = await Dashboard.getJobDetailByCandidateStatus(payload);

      setStatusDetail(res);
      setLoading((prev) => {
        return { ...prev, reqDetailLoading: false };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSummary = async (values) => {
    try {
      const body = { clientIds: globalSelector.selectedClients, ...values };
      const res = await Dashboard.getActiveCandidateSummary(body);
      setDashboardData({ candidateSummary: res });
      setLoading((prev) => {
        return { ...prev, summaryLoading: false };
      });
    } catch (err) {
      setLoading((prev) => {
        return { ...prev, summaryLoading: false };
      });
      console.error(err);
    }
  };

  const getClientHiringManager = async () => {
    try {
      //*id = clientID
      const body = { clientIds: globalSelector.selectedClients };

      const hiringManagerData = await User.getClientHiringManager(body, false);

      setPageData((prev) => ({ ...prev, hiringManagers: hiringManagerData }));
    } catch (err) {
      console.error(err);
    }
  };

  const jumpToJobDetail = (
    clientId,
    jobId,
    statusId,
    statusName,
    subStatusId,
    subStatusName,
  ) => {
    let path = `/clients/${clientId}/job/${jobId}/${statusId}/${convertIntoSnakeCase(
      statusName,
    )}`;
    if (subStatusId) {
      path = path + `/${subStatusId}/${convertIntoSnakeCase(subStatusName)}`;
    }

    history.push(path);
  };

  useEffect(() => {
    (async () => {
      setLoading((prev) => {
        return { ...prev, filterLoading: true };
      });
      await Promise.all([
        getJobLocations(),
        // getUserDesignations(),
        getClientRecruiter(),
        getClientDepartments(),
        getClientHiringManager(),
      ]);
      setLoading((prev) => {
        return { ...prev, filterLoading: false };
      });
    })();
    // form.setFieldsValue({ managerId: null, recruiterId: null });
    form.submit();
  }, [globalSelector.selectedClients]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setStatusDetail([]);
  };

  return (
    <>
      <Card
        loading={loading.filterLoading}
        className="historicalPerformanceCard"
      >
        <div className="historicalPerformance">
          <Title className="hpTitle" level={5}>
            Pipeline Dashboard
          </Title>
          <Form
            form={form}
            initialValues={{
              managerId: getter(`${searchFilterPrefix}_managerId`) ?? null,
              title: getter(`${searchFilterPrefix}_title`) ?? "",
              recruiterId: getter(`${searchFilterPrefix}_recruiterId`) ?? null,
            }}
            className="selectMenu"
            onFinish={onFinish}
          >
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="ext_ats_id"
                  initialValue={getter(`${searchFilterPrefix}_ext_ats_id`)}
                >
                  <Input placeholder="Requisition ID" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="job_departments_id"
                  initialValue={getter(
                    `${searchFilterPrefix}_job_departments_id`,
                  )}
                >
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    placeholder="Job Department"
                    options={pageData.jobDepartmentListing.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        pipelineDashboard: {
                          ...prev.pipelineDashboard,
                          department: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="job_locations_id"
                  initialValue={getter(
                    `${searchFilterPrefix}_job_locations_id`,
                  )}
                >
                  <Select
                    showSearch
                    optionFilterProp="label"
                    allowClear
                    placeholder="Job Location"
                    options={pageData.jobLocationListing.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      }),
                    )}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        pipelineDashboard: {
                          ...prev.pipelineDashboard,
                          location: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="managerId"
                  initialValue={getter(`${searchFilterPrefix}_managerId`)}
                >
                  <Select
                    showSearch
                    optionFilterProp="label"
                    optionLabelProp="title"
                    allowClear
                    placeholder="Manager"
                    // options={pageData.hiringManagers.map(({ id, name }) => ({
                    //   value: id,
                    //   label: name,
                    // }))}
                    filterOption={customUserHandleSearch}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        pipelineDashboard: {
                          ...prev.pipelineDashboard,
                          manager: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                    }}
                  >
                    {pageData.hiringManagers.map(
                      ({ id, name, email, clients }) => (
                        <Option key={id} value={id} title={name}>
                          <UserSelectOption
                            key={id}
                            userEmail={email}
                            clients={clients}
                          >
                            {name}
                          </UserSelectOption>
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="title"
                  initialValue={getter(`${searchFilterPrefix}_title`)}
                >
                  <Input placeholder="Title" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  className="formItems"
                  name="recruiterId"
                  initialValue={getter(`${searchFilterPrefix}_recruiterId`)}
                >
                  <Select
                    showSearch
                    allowClear
                    optionLabelProp="title"
                    optionFilterProp="children"
                    placeholder="Recruiters"
                    filterOption={customUserHandleSearch}
                    onChange={(_, option) => {
                      setPrintData((prev) => ({
                        ...prev,
                        historicalPerformance: {
                          ...prev.historicalPerformance,
                          manager: option?.label ?? K.NullPlaceholder,
                        },
                      }));
                    }}
                  >
                    {pageData?.recruiters?.map(
                      ({ id, name, email, clients }) => (
                        <Option title={name} key={id} value={id}>
                          <UserSelectOption
                            key={id}
                            userEmail={email}
                            clients={clients}
                          >
                            {name}
                          </UserSelectOption>
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="formSubmitBtn">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
      <PipelineDashboardSummary
        requisitionDetail={requisitionDetail}
        dashboardData={dashboardData}
        isLoading={loading.summaryLoading}
        getJobDetailByStatus={getJobDetailByStatus}
        statusRef={statusRef}
      />
      {/* Requisition Detail Section */}
      {/*<Title className="requisitionTitle">Requisition Detail </Title>*/}
      {/*{loading.reqLoading ? (*/}
      {/*  <Card loading={true} />*/}
      {/*) : requisitionDetail.length ? (*/}
      {/*  requisitionDetail.map(*/}
      {/*    (item, index) =>*/}
      {/*      item.vacancies?.length > 0 && (*/}
      {/*        <PipelineDashboardReqDetail*/}
      {/*          key={index}*/}
      {/*          getJobDetailByStatus={getJobDetailByStatus}*/}
      {/*          requisitionDetail={requisitionDetail}*/}
      {/*          statusRef={statusRef}*/}
      {/*          reqLoading={loading.reqLoading}*/}
      {/*          item={item}*/}
      {/*          index={index}*/}
      {/*        />*/}
      {/*      ),*/}
      {/*  )*/}
      {/*) : (*/}
      {/*  <Empty />*/}
      {/*)}*/}

      <Modal
        forceRender
        centered
        title={`${statusRef.current?.displayStatusName ?? "N/A"} | Total:  ${
          statusRef.current?.statusCount ?? "0"
        }`}
        width={600}
        okButtonProps={{ hidden: true }}
        open={isModalVisible}
        closeIcon={<i className="icon-closeable"></i>}
        className="s2-theme-style"
        wrapClassName="vertical-center-modal"
        onCancel={handleCancel}
        destroyOnClose
      >
        {statusRef.current?.statusCount !== 0 ? (
          <DashboardJobDetailModal
            details={statusDetail}
            setStatusDetail={setStatusDetail}
            isModalVisible={isModalVisible}
            statusRef={statusRef}
            jumpToJobDetail={jumpToJobDetail}
            isLoading={loading.reqDetailLoading}
          />
        ) : (
          <Empty />
        )}
      </Modal>
    </>
  );
}
