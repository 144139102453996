import {
  Divider,
  Form,
  InputNumber,
  message,
  Typography,
  Row,
  Col,
  Input,
} from "antd";
import "./jobSettingsModal.scss";
import moment from "moment";
import Job from "redux/models/job";
import Scrollbars from "react-custom-scrollbars-2";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import K from "utilities/constants";
import { customFormatSettingTime } from "utilities/dateUtility";
import Class from "redux/models/class";

const { Paragraph, Title } = Typography;

export default function ClassSettingModal({
  form,
  classId,
  classData,
  classStats,
  setClassData,
  classDetails,
  setModalVisible,
}) {
  const jobHistory = [...classStats.history];

  const onFinish = async (values) => {
    try {
      const res = await Class.updateSettings({
        ...values,
        jobClassId: +classId,
        jobClassOriginalOpenings: classDetails.vacancies.totalOpenings,
      });

      setModalVisible((prev) => {
        return { ...prev, isSettingModalVisible: false };
      });
      setClassData((prev) => ({
        ...prev,
        settings: res,
        shouldUpdate: !prev.shouldUpdate,
      }));
      form.resetFields();
      message.success("Settings Updated.");
    } catch (err) {
      console.error(err);
      message.error("Failed to update settings.");
    }
  };

  const validateOpeningsToBill = (value) => {
    const diff =
      classStats.setting.currentOpenings -
      form.getFieldValue("currentOpenings");
    if (value <= diff) {
      return Promise.resolve();
    }
    return Promise.reject(`Value should be less than or equal to ${diff}`);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Title className="VacancyTitle" level={5}>
        Openings
      </Title>
      <div className="modalVacancyContainer">
        <div className="modalBodyVacancy">
          <Paragraph className="vacancyDate">
            Last updated at{" "}
            {moment(jobHistory[0].createdAt).format(
              K.DateFormat.DashUSFormatTimeStamp,
            )}{" "}
            by {jobHistory[0].user?.name}
          </Paragraph>
          <Paragraph className="vacancyNumber">
            {!classData.classDetails?.vacancies?.isActive
              ? 0
              : classStats.setting?.currentOpenings}
          </Paragraph>
          <Title className="totalVacancy">Current Openings</Title>
        </div>
      </div>

      <Row gutter={16}>
        <Col xs={24} sm={14}>
          <Form.Item
            label="Enter New Current Openings"
            name="jobClassCurrentOpenings"
            rules={[
              {
                required: true,
                message: "Please enter number of current openings.",
              },
            ]}
          >
            <InputNumber
              className="vacancyNumberZero"
              placeholder="0"
              min={0}
              disabled={!classData.classDetails?.vacancies?.isActive ?? true}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={10}>
          <Form.Item
            label="No of Submittals"
            className="no-of-submitText"
            style={{ textAlign: "center" }}
          >
            <Paragraph className="submittalNumber">
              {classDetails.submittedCount}
            </Paragraph>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        <Col xs={24} sm={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.currentOpenings !== curValues.currentOpenings
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("currentOpenings") <
                classStats.setting?.currentOpenings && (
                <Form.Item
                  label="How many orders are eligible for billing?"
                  name="jobCancelledOpenings"
                  initialValue={0}
                  dependencies={["currentOpenings"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter number of eligible orders.",
                    },
                    () => ({
                      validator(_, value) {
                        return validateOpeningsToBill(value);
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    className="vacancyNumberZero"
                    placeholder="0"
                    min={0}
                    disabled={
                      !classData.classDetails?.vacancies?.isActive ?? true
                    }
                  />
                </Form.Item>
              )
            }
          </Form.Item>
        </Col>
      </Row> */}
      <Form.Item
        name="reasons"
        label="Reason"
        rules={[{ required: true, message: "Enter reason!" }]}
      >
        <Input
          className="vacancyNumberZero"
          placeholder="Reason"
          disabled={!classData.classDetails?.vacancies?.isActive ?? true}
        />
      </Form.Item>

      <Title level={5} className="VacancyTitle">
        Opening History
      </Title>
      <div className="settingHistoryWrap">
        <Scrollbars style={{ height: 270 }}>
          {jobHistory.length ? (
            jobHistory.map((item, i, source) => (
              <div key={item.id} className="settingHistoryDetail">
                <div className="settingAmountHistory">
                  <div className="clientName">
                    <Title level={5}>Updated By:</Title>
                    <Paragraph>{item.user?.name}</Paragraph>
                  </div>
                  <div className="clientName">
                    <Title level={5}>Previous Openings:</Title>
                    <Paragraph>{item.previousOpenings}</Paragraph>
                  </div>
                  <div className="clientName">
                    <Title level={5}>Current Openings:</Title>
                    <Paragraph>{item.currentOpenings}</Paragraph>
                  </div>
                </div>
                <Divider className="historyDivider" />
                <div className="clientAmount">
                  <Title level={5}>
                    Updated Date:{" "}
                    {i === source.length - 1
                      ? customFormatSettingTime(item.openDate, false)
                      : customFormatSettingTime(item.createdAt, true)}
                  </Title>
                </div>

                <div className="clientAmount">
                  <Title level={5}>
                    Reason: {item.reason ?? K.NullPlaceholder}
                  </Title>
                </div>
              </div>
            ))
          ) : (
            <EmptyPlaceholder />
          )}
        </Scrollbars>
      </div>
    </Form>
  );
}
