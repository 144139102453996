import navigations from "layout/navigations";
import { isPermissionPresentWithClientAssigned } from "utilities/generalUtility";

const getNextAvailableRoute = (userSlice) => {
  const availableRoutes = [];

  navigations.forEach((navigation) => {
    const hasPermission = isPermissionPresentWithClientAssigned(
      navigation.permission,
      userSlice.roles,
      navigation.isClientRequired,
      userSlice.clients.length > 0,
      userSlice.isSuperAdmin,
    );
    if ((navigation.children?.length ?? 0) > 0) {
      navigation.children.forEach((subNavigation) => {
        const navHasPermission = isPermissionPresentWithClientAssigned(
          subNavigation.permission,
          userSlice.roles,
          navigation.isClientRequired,
          userSlice.clients.length > 0,
          userSlice.isSuperAdmin,
        );
        if (navHasPermission) availableRoutes.push(subNavigation.path);
      });
    }
    if (hasPermission && navigation.path) availableRoutes.push(navigation.path);
  });

  return availableRoutes;
};
export default getNextAvailableRoute;
