import {
  Alert,
  Card,
  Divider,
  Form,
  Modal,
  Tag,
  Typography,
  message,
} from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";

//import styles from "../../clients/create/clientsJob.module.scss";

import styles from "../../create/clientsJob.module.scss";
import Job from "redux/models/job";
import LayoutCss from "layout/layout.module.scss";
import K from "utilities/constants";

///import "../clients/create/jobCard.scss";
import "../../create/jobCard.scss";
import "./detachCandidate.scss";
import Candidate from "redux/models/candidate";
import { displayDollar } from "utilities/tableUtility";
import { checkNullPlaceHolder } from "utilities/generalUtility";

const { Text, Title } = Typography;

export default function DetachCandidate({
  clientId,
  classId,
  candidateActivity,
  detachCandidateModal,
  setDetachCandidateModal,
  setClassData,
  onActivityModalClose,
}) {
  console.log("Candidate Activity", candidateActivity);
  const [form] = Form.useForm();

  const detachCancelHandler = () => {
    form.resetFields();
    setDetachCandidateModal((prev) => {
      return { ...prev, showModal: false, detachResponse: null };
    });
  };

  const afterModalClose = () => {
    form.resetFields();
    setDetachCandidateModal((prev) => {
      return { ...prev, showModal: false, detachResponse: null };
    });
  };

  const detachCandidate = async () => {
    try {
      const payload = {
        classId: classId,
        candidateJobEntryId: candidateActivity.id,
      };
      const res = await Candidate.detachCandidate(payload);

      onActivityModalClose();
      //close delete modal
      setDetachCandidateModal((prev) => {
        return { ...prev, showModal: false, detachResponse: null };
      });
      //update canddiate listing
      setClassData((prev) => {
        return { ...prev, shouldUpdateListing: !prev.shouldUpdateListing };
      });

      message.success("Candidate detach successfully");
    } catch (error) {
      setDetachCandidateModal((prev) => {
        return { ...prev, detachResponse: error.message };
      });
    }
  };

  const onFinish = async (values) => {
    detachCandidate();
  };

  return (
    <Modal
      centered
      width={568}
      okText="Detach Candidate"
      title="Detach Candidate"
      className="s2-theme-style modal-overflow-auto detach-candidate-modal candidates-modals"
      wrapClassName="vertical-center-modal"
      open={detachCandidateModal.showModal}
      closeIcon={<i className="icon-closeable"></i>}
      okButtonProps={{
        loading: detachCandidateModal.isLoading,
      }}
      onOk={() => form.submit()}
      onCancel={detachCancelHandler}
      afterClose={afterModalClose}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish}>
        <p className="instructions">Do you want to detach this candidate?</p>

        <Card className="detach-candidate-info">
          <Title level={1}>
            {[
              candidateActivity.candidate.firstName,
              candidateActivity.candidate.lastName,
            ].join(" ")}
          </Title>
          <p>
            Email :<span> {candidateActivity.candidate.email}</span>{" "}
          </p>
        </Card>

        {/* <div className={`cic-tabs-content ${styles.cicTabsContent}`}> */}
        {/* <Card
            key={candidateActivity.jobSource.id}
            title={"Requistion"}
            className={styles.jobsCardsWrapper}
            // onClick={() =>
            //   history.push(
            //     `/clients/${candidateActivity.jobSource.clientsId}/job/${candidateActivity.jobSource.id}`,
            //   )
            // }
          > */}
        {/* <div className={styles.jobCardContentWrapper}> */}
        {/* <div className={styles.jobWrapper}> */}
        <div className="candidate-address">
          <Title level={5}>Requisition</Title>
          <Title
            title={candidateActivity?.jobSource?.title}
            level={3}
            // className={styles.jobTitle + " hover-underlined"}
          >
            <a
              href={`/clients/${candidateActivity?.jobSource?.clientsId}/job/${candidateActivity?.jobSource?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {candidateActivity?.jobSource?.title}
            </a>
          </Title>

          <p className={styles.locationsList}>
            Requsition : {candidateActivity?.jobSource?.title}
          </p>

          <p className={styles.locationsList}>
            Location: {candidateActivity?.jobSource?.jobLocation?.name}
          </p>
        </div>
        {/* <div className={styles.tagesListWrapper}>
                                <span
                                  className={`${styles.tagesStyle} ${styles.fullTime}`}
                                >
                                  {job.position}
                                </span>
                                <Divider
                                  type={"vertical"}
                                  className={styles.jobTimeDivider}
                                />
                                <span
                                  className={`${styles.tagesStyle} ${styles.marketing}`}
                                >
                                  {displayDollar(job.salary)}
                                </span>
                              </div> */}

        {/* <div className={styles.tagesListWrapper}>
                    <Tag
                      className="tagTextColor"
                      color={tagColors[candidateActivity.status.name]}
                    >
                      {candidateActivity.status.name}
                    </Tag>
                    <Divider type={"vertical"} />
                    <Tag
                      className="tagTextColor"
                      color={getSubStatusColor(candidateActivity)}
                    >
                      {candidateActivity.subStatus.name}
                    </Tag>
                  </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </Card>
        </div> */}

        {detachCandidateModal.detachResponse && (
          <>
            <Divider style={{ margin: 0, marginTop: 30 }} />
            <div style={{ marginTop: 20 }}>
              <Alert
                style={{ whiteSpace: "break-spaces" }}
                showIcon
                type="error"
                message={
                  <>
                    <strong>Error :&nbsp; </strong>
                    <Text>{detachCandidateModal.detachResponse}</Text>
                  </>
                }
              />
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}
