import { useState, useEffect, useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  PageHeader,
  Input,
  Button,
  Typography,
  Form,
  Modal,
  Transfer,
  Card,
  message,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import K from "utilities/constants";
import RoleAndPermission from "redux/models/roleAndPermission";
import {
  isPermissionPresent,
  noTrailingSpaceAllowedRule,
  setFieldErrorsFromServer,
} from "utilities/generalUtility";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
} from "utilities/tableUtility";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import { useSelector } from "react-redux";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Title, Paragraph } = Typography;
const { Search } = Input;
const searchFilterPrefix = "roles_and_permission";
const getSearchKey = "roles_and_permission_search";

export default function RolesPermission() {
  const gridRef = useRef();
  const [form] = Form.useForm();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [listing, setListing] = useState({
    roles: [],
    permissions: [],
  });
  const userSlice = useSelector(selectUser);
  const [setter, getter] = useSearchAndFilter();

  const searchRef = useRef(null);

  const actionColumnRenderer = (params) => (
    <>
      {params.data.isDefault ? (
        <Button
          type="link"
          className={LayoutCss.appListingCardActions}
          disabled
        >
          Default
        </Button>
      ) : (
        isPermissionPresent(K.Permissions.CreateRole, userSlice.roles) && (
          <Button
            type="link"
            className={LayoutCss.appListingCardActions}
            onClick={() => handleEdit(params.data.id)}
          >
            Edit
          </Button>
        )
      )}

      {/* <Button type="link" className={LayoutCss.appListingCardActions}>
      Delete
    </Button> */}
    </>
  );

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Role Name",
      field: "displayName",
      sortable: true,
      tooltipComponent: "displayName",
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      resizable: false,
      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRenderer: actionColumnRenderer,
    },
  ]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setTargetKeys([]);
    setEditId(null);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const getAllRoles = async () => {
    const roles = await RoleAndPermission.getAllRoles();
    setListing((prev) => {
      return { ...prev, roles };
    });
  };

  const getAllPermissions = async () => {
    const permissions = await RoleAndPermission.getAllPermissions();
    setListing((prev) => {
      return { ...prev, permissions };
    });
  };

  const setInitialValues = () => {
    setIsModalVisible(false);
    setTargetKeys([]);
    setEditId(null);
    form.resetFields();
  };
  const createRole = async (data) => {
    try {
      const res = await RoleAndPermission.createRole(data);
      message.success("Role created successfully");
      setListing((prev) => {
        return {
          ...prev,
          roles: [...prev.roles, res],
        };
      });
      setInitialValues();
    } catch (error) {
      setFieldErrorsFromServer(error, form);
      message.error(error.message);
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const data = { ...values, permissions: targetKeys };
    if (editId) await updateRole(editId, data);
    else await createRole(data);
    setIsLoading(false);
  };
  const searchHandler = useCallback(
    (event) => {
      onFilterTextBoxChanged(event.target.value, gridRef);
      setter({ search: event.target.value }, searchFilterPrefix);
    },
    [searchRef.current],
  );
  useEffect(() => {
    (async () => {
      await Promise.all([
        getAllRoles(),
        getAllPermissions(),
        getColumnsConfigrations(),
      ]);
    })();
  }, []);

  const onChange = (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const filterOption = (inputValue, option) =>
    option.displayName.toLowerCase().indexOf(inputValue) > -1;

  const handleEdit = async (roleId) => {
    setEditId(roleId);
    const res = await RoleAndPermission.getRoleById(roleId);
    const permissions = res.permissions.map((pr) => pr.id);
    setTargetKeys([...permissions]);
    showModal();
    form.setFieldsValue({ name: res.displayName });
  };

  const updateRole = async (id, data) => {
    try {
      const res = await RoleAndPermission.updateRole(id, data);
      message.success("Role updated successfully");
      setListing((prev) => {
        return {
          ...prev,
          roles: prev.roles.map((item) => {
            if (item.id === res.id) return res;
            return item;
          }),
        };
      });
      setInitialValues();
    } catch (error) {
      setFieldErrorsFromServer(error, form);
      message.error(error.message);
    }
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.RolePermissionsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.RolePermissionsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions") {
          return { ...item, cellRenderer: actionColumnRenderer };
        }
        return item;
      });

      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Roles & Permissions"
        className={LayoutCss.appPageHeader}
      />
      <Card
        className={[LayoutCss.appCard, LayoutCss.rolesPermissionCard]}
        extra={
          <>
            <div className={LayoutCss.appListingCardRolesTable}>
              <Search
                allowClear
                value={getter(getSearchKey)}
                placeholder="Search"
                onChange={searchHandler}
                className={LayoutCss.appListingCardRolesTableSearch}
              />
              {isPermissionPresent(
                K.Permissions.CreateRole,
                userSlice.roles,
              ) && (
                <Button
                  type="primary"
                  onClick={showModal}
                  className={LayoutCss.appListingCardRolesTableButton}
                >
                  <i className={"icon-plus " + LayoutCss.plusIcon}></i>
                  <span>Add New</span>
                </Button>
              )}
            </div>
          </>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            ref={gridRef}
            rowData={listing.roles}
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onColumnPinned={onColumnVisible}
            onColumnVisible={onColumnVisible}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>

      <Modal
        open={isModalVisible}
        okText={editId ? "Update" : "Save"}
        wrapClassName="vertical-center-modal"
        onCancel={handleCancel}
        centered
        destroyOnClose
        closeIcon={<i className="icon-closeable"></i>}
        width={1050}
        onOk={form.submit}
        okButtonProps={{
          loading: isLoading,
          disabled: targetKeys.length === 0,
        }}
        className="s2-theme-style"
        title={
          <Title className="ant-modal-title">
            {!editId ? "Create New" : "Update"} Roles & Permissions
            <Paragraph className="rolesTableModal">
              Please fill in all the details
            </Paragraph>
          </Title>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label="Role Name"
            name="name"
            rules={[
              { required: true, message: "Please enter role name!" },
              { min: 3, message: "Name must be at least 3 characters" },
              {
                max: 150,
                message: "Name cannot be longer than 150 characters",
              },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Enter" className="rolesTableInput" />
          </Form.Item>
        </Form>
        <Title level={5} className="rolesTableTypography">
          Select Permissions
        </Title>
        <Transfer
          oneWay
          showSearch
          className="rolesPermissionTransfer"
          pagination={{ pageSize: 6 }}
          titles={["Source", "Target"]}
          dataSource={listing.permissions}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          rowKey={(record) => record.id}
          onChange={onChange}
          filterOption={filterOption}
          onSelectChange={onSelectChange}
          render={(item) => item.displayName}
        />
      </Modal>
    </>
  );
}
