import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import SampleCSV from "assets/Source2_Cadidate_upload_Rich_File.xlsx";
import DragUpload from "common/components/uploadComponent/dragger";
import JobsTable from "common/tableComponents/jobTable";
import { lookupTablesForJobs } from "config/lookupTables";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Client from "redux/models/client";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  camelToSnakeCase,
  isPermissionPresent,
} from "utilities/generalUtility";

import CreateJob from "./createJob";
import ExportToCSV from "./exportToCSV";
import styles from "./jobList.module.scss";
import UDF from "redux/models/udf";
import { setJobSourceUdf } from "redux/slices/udfSlice";
import { selectCandidates } from "redux/slices/candidateSlice";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;
const { Link } = Typography;
const sortDict = {
  asc: "ASC",
  desc: "DESC",
};

export default function Jobs() {
  let status = undefined;
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [csvFile, setCSVFile] = useState(null);
  const [clientJobs, setClientJobs] = useState([]);
  const [modalState, setModalState] = useState({
    isCreateJobModalVisible: false,
    isUploadModalVisible: false,
    isLoadingJobModal: false,
    isLoadingUploadModal: false,
    uploadCSVErrors: null,
  });
  const [exportData, setExportData] = useState({
    jobIds: [],
    isDisabled: true,
    isModalVisible: false,
  });
  const userSlice = useSelector(selectUser);

  const lookupTables = useSelector(selectConfigration).lookup;
  const [setter, getter] = useSearchAndFilter();

  const searchFilterPrefix = `client_${id}_jobs`;
  const getSearchKey = `client_${id}_jobs_search`;
  const getFilterKey = `client_${id}_jobs_status`;

  const statusRef = useRef(getter(getFilterKey));
  const searchRef = useRef(getter(getSearchKey));

  const getClientDetailsJobs = async (body) => {
    try {
      const res = await Client.getClientJobsByServerSidePagination(body);
      const tableData = res.jobs.map((el) => {
        let searchRecruiters = "",
          searchHiringManagers = "";
        const jobRecruiters = el.jobRecruiters.map((obj) => {
          searchRecruiters += obj.recruiter.name;
          return obj.recruiter;
        });
        const jobHiringManagers = el.jobHiringManagers.map((obj) => {
          searchHiringManagers += obj.hiringManager.name;
          return obj.hiringManager;
        });
        return {
          id: el.id,
          title: el.title,
          totalOpenings: el.vacancies[0]?.currentOpenings ?? 0,
          jobLocation: el.jobLocation,
          recruitingStartDate: el.recruitingStartDate,
          jobRecruiters,
          atsLookUp: el.atsLookUp,
          atsLookUpSecondary: el.atsLookUpSecondary,
          jobStatus: el.jobStatus,
          jobDepartment: el.jobDepartment,
          jobGroup: el.jobGroup,
          extAtsId: el.extAtsId,
          jobHiringManagers,
          jobTier: el.jobTier,
          jobTiersTitlesId: el.jobTiersTitlesId,
          extSecondaryAtsId: el.extSecondaryAtsId,
          minPay: el.minPay,
          maxPay: el.maxPay,
          isCancel: el.isCancel,
          isWorkFromHome: el.isWorkFromHome,
          searchRecruiters,
          searchHiringManagers,
        };
      });
      setClientJobs(tableData);
      return { jobs: tableData, totalCount: res.totalCount };
    } catch (error) {
      console.error(error);
    }
  };

  const datasource = useCallback(
    {
      async getRows(params) {
        const { startRow, endRow } = params.request;

        const pageSize = endRow - startRow;
        const page = startRow / pageSize + 1;

        const body = {
          page,
          pageSize,
          clientId: id,
          search: getter(getSearchKey),
          filter: {
            jobStatusId:
              statusRef.current === undefined ? null : statusRef.current,
          },
          sortBy: camelToSnakeCase(params.request.sortModel[0]?.colId ?? "id"),
          sortOrder:
            params.request.sortModel.length > 0
              ? sortDict[params.request.sortModel[0]["sort"]]
              : "asc",
        };
        try {
          const data = await getClientDetailsJobs(body);

          params.success({
            rowData: data.jobs,
            rowCount: data.totalCount,
          });
        } catch (err) {
          params.fail();
        }
      },
    },
    [refreshTable],
  );
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.setServerSideDatasource(datasource);
  };

  const getJobUdfs = async (entityKeyId = null) => {
    const jobUdfs = await UDF.getEditUdfFields({
      clientId: id,
      entityId: K.UDF.Entities.JobSource.id,
      entityKey: K.UDF.Entities.JobSource.key,
      entityKeyId: entityKeyId,
      isVisible: 1,
    });

    dispatch(setJobSourceUdf(jobUdfs));
  };

  const showModal = () => {
    setModalState((prev) => {
      return { ...prev, isCreateJobModalVisible: true };
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setModalState((prev) => {
      return { ...prev, isCreateJobModalVisible: false };
    });
  };

  const rowClickedHandler = (event) => {
    history.push(`/clients/${id}/job/${event.data.id}`);
  };

  const onChange = (event) => {
    searchRef.current = event.target.value;
    setter({ search: event.target.value }, searchFilterPrefix);
    setRefreshTable((prev) => !prev);
  };

  const onStatusChange = (newValue) => {
    statusRef.current = newValue;
    setter({ status: newValue }, searchFilterPrefix);

    setRefreshTable((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all(
          lookupTablesForJobs.map(async (item) => {
            let apiEndPoint = item.isClient
              ? item.apiEndPoint + `/${id}`
              : item.apiEndPoint;

            await dispatch(LookupTable.getData(item.reduxKey, apiEndPoint));
          }),
        );
      } catch (error) {
        console.error(error);
      }
    })();

    getJobUdfs();
  }, []);

  const showUploadModalVisible = () => {
    setModalState((prev) => {
      return { ...prev, isUploadModalVisible: true };
    });
  };

  const onOk = async () => {
    setModalState((prev) => {
      return { ...prev, isLoadingUploadModal: true };
    });
    try {
      // await Client.uploadCSV(candidateSlice.uploadedCandidate);
      setCSVFile(null);
      setModalState((prev) => {
        return {
          ...prev,
          isUploadModalVisible: false,
          isLoadingUploadModal: false,
        };
      });
      message.success("Candidate uploaded successfully ");
    } catch (err) {
      const {
        error: { data },
      } = err;
      message.error("Failed to upload CSV");
      console.info(data);
      setModalState((prev) => {
        return {
          ...prev,
          isLoadingUploadModal: false,
          uploadCSVErrors: data.errors,
        };
      });
    }
    return false;
  };

  const handleUploadCancel = () => {
    onRemove(csvFile);
    setModalState((prev) => {
      return {
        ...prev,
        isUploadModalVisible: false,
        uploadCSVErrors: null,
      };
    });
  };

  const onRemove = (file) => {
    setCSVFile(null);
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  const exportDetails = () => {
    setExportData((prev) => ({ ...prev, isModalVisible: true }));
  };

  const externalFilterChanged = useCallback((newValue) => {
    status = newValue;
    gridRef.current.api.onFilterChanged();
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    return status !== undefined;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        return node.data.jobStatus.name === status;
      }
      return true;
    },
    [status],
  );

  return (
    <>
      <Card
        title="Job List"
        className={
          `appCard ${styles.jobListHeader}  ${LayoutCss.appCard}` +
          " client-job-list"
        }
        extra={
          <div
            className={
              `${LayoutCss.appListingCardJobTable} ${styles.jobListHeaderTable}` +
              " right-side-inner"
            }
          >
            <Search
              allowClear
              value={getter(getSearchKey)}
              placeholder="Search"
              onChange={onChange}
              className={`${LayoutCss.appListingCardSearchBar} ${styles.jobListHeaderSearchBar}`}
            />

            <Select
              showArrow
              showSearch={true}
              value={getter(getFilterKey)}
              allowClear
              optionFilterProp="label"
              placeholder="Select Job Status"
              className={`commision-select ${LayoutCss.appListingCardStatusSelect1}`}
              options={lookupTables.jobStatus.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={onStatusChange}
            />

            <Button
              type="primary"
              disabled={exportData.isDisabled}
              onClick={exportDetails}
            >
              Export
            </Button>
            {isPermissionPresent(K.Permissions.CreateJob, userSlice.roles) && (
              <Button
                type="primary"
                onClick={showModal}
                className={styles.jobListHeaderBtn}
              >
                Create Job
              </Button>
            )}
            {isPermissionPresent(
              K.Permissions.UploadJobCSV,
              userSlice.roles,
            ) && (
              <Button
                type="primary"
                disabled={!clientJobs.length}
                onClick={showUploadModalVisible}
                className={styles.jobListHeaderBtn}
              >
                Upload CSV File
              </Button>
            )}
          </div>
        }
      >
        <JobsTable
          rowData={clientJobs}
          setClientJobs={setClientJobs}
          gridRef={gridRef}
          setExportData={setExportData}
          onRowClicked={rowClickedHandler}
          getClientDetailsJobs={getClientDetailsJobs}
          doesExternalFilterPass={doesExternalFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
          getJobUdfs={getJobUdfs}
          onGridReady={onGridReady}
          datasource={datasource}
          setRefreshTable={setRefreshTable}
        />
      </Card>
      <Modal
        centered
        width={632}
        open={modalState.isCreateJobModalVisible}
        okText="Submit"
        onOk={form.submit}
        okButtonProps={{ loading: modalState.isLoadingJobModal }}
        onCancel={handleCancel}
        closeIcon={<i className="icon-closeable"></i>}
        className="s2-theme-style jobsList modal-overflow-auto"
        title="Create Job"
        afterClose={afterModalClose}
      >
        <CreateJob
          form={form}
          clientId={id}
          lookupTables={lookupTables}
          setClientJobs={setClientJobs}
          setModalState={setModalState}
          getClientDetailsJobs={getClientDetailsJobs}
          getJobUdfs={getJobUdfs}
          setRefreshTable={setRefreshTable}
        />
      </Modal>
      <Modal
        title="Upload CSV File"
        centered
        className="s2-theme-style"
        onOk={onOk}
        onCancel={handleUploadCancel}
        okButtonProps={{
          loading: modalState.isLoadingUploadModal,
          disabled: !csvFile,
        }}
        destroyOnClose
        open={modalState.isUploadModalVisible}
      >
        <DragUpload
          setCSVFile={setCSVFile}
          onRemove={onRemove}
          isCandidateCsv={true}
          clientId={id}
          accept=".xlsx"
        />
        <div className="d-flex mt-3 justify-content-center">
          <Link
            href={SampleCSV}
            download="Source2_Cadidate_upload_Rich_File.xlsx"
          >
            Click here to download sample excel file
          </Link>
        </div>
        <Divider />
        {modalState.uploadCSVErrors && (
          <>
            <Typography.Title level={5} style={{ color: "red" }}>
              Errors in CSV:
            </Typography.Title>
            <ol>
              {Object.keys(modalState.uploadCSVErrors).map((key) => [
                <li key={key} style={{ color: "red", fontWeight: "bold" }}>
                  Errors in Row {key}:
                </li>,
                Object.values(modalState.uploadCSVErrors[key]).map((item) => (
                  <ul>
                    <li key={key} style={{ color: "red" }}>
                      {item}
                    </li>
                  </ul>
                )),
              ])}
            </ol>
          </>
        )}
      </Modal>
      <ExportToCSV
        exportData={exportData}
        setExportData={setExportData}
        gridRef={gridRef}
        clientId={id}
        statusRef={statusRef}
      />
    </>
  );
}
