import { Tabs } from "antd";
import UdfField from "./udfField";
import K from "utilities/constants";

const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "Client",
    children: <UdfField entityId={K.UDF.Entities.Client.id} />,
  },

  {
    key: "2",
    label: "Job",
    children: <UdfField entityId={K.UDF.Entities.JobSource.id} />,
  },
  {
    key: "3",
    label: "Candidate",
    children: <UdfField entityId={K.UDF.Entities.Candidate.id} />,
  },
];

export default function UserDefinedFields() {
  return (
    <>
      <Tabs
        className="billing-rule-tab"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </>
  );
}
