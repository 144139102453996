import { AgGridReact } from "ag-grid-react";
import { Button, Card, Form, Input, message, Modal, Popconfirm } from "antd";
import DragUpload from "common/components/uploadComponent/dragger";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Client from "redux/models/client";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import {
  isPermissionPresent,
  noTrailingSpaceAllowedRule,
} from "utilities/generalUtility";
import {
  filterColumnListing,
  onFilterTextBoxChanged,
} from "utilities/tableUtility";

const { Search } = Input;

export default function Attachments() {
  const [form] = Form.useForm();
  //*Client Id
  const { id } = useParams();
  const gridRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState({});

  const [attachments, setAttachments] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState(null);

  const userSlice = useSelector(selectUser);

  const [shouldUpdate, setShouldUpdate] = useState(false);

  const searchFilterPrefix = `client_${id}_attachment`;
  const getSearchKey = `client_${id}_attachment_search`;

  const [setter, getter] = useSearchAndFilter();

  const searchRef = useRef(null);

  const createdAtRenderer = (params) =>
    params.data.createdAt
      ? convertIntoDashUSFormat(params.data.createdAt)
      : K.NullPlaceholder;

  const downloadFile = async (url) => {
    const downloadUrl = await Client.getPreSignedUrl(url);
    window.open(downloadUrl);
  };

  const deleteAttachment = async (record) => {
    try {
      await Client.deleteFileFromS3(record.attachment);
      await Client.deleteAttachement(record.id);
      getAllAttachments();
      message.success("Attachment Deleted Successfully");
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };
  const actionColumnRenderer = (params) => {
    return (
      <>
        <>
          <Button
            type="link"
            onClick={() => {
              downloadFile(params.data.attachment);
            }}
            className={LayoutCss.appListingCardActions}
          >
            Download
          </Button>
          {isPermissionPresent(
            K.Permissions.DeleteAttachment,
            userSlice.roles,
          ) && (
            <Popconfirm
              title="Are you sure you'd like to delete this document?"
              onConfirm={(event, record) => {
                //*handleActivate(params.data.id, 1) this 1 represent active :1;
                deleteAttachment(params.data);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button className={LayoutCss.appListingCardActions} type="link">
                Delete
              </Button>
            </Popconfirm>
          )}
        </>

        {/* {isPermissionPresent(
          K.Permissions.DeleteClientContact,
          userSlice.roles
        ) && (
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => {
              confirm(params.data.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" className={LayoutCss.appListingCardActions}>
              Delete
            </Button>
          </Popconfirm>
        )} */}
      </>
    );
  };
  const onRemove = (file) => {
    setFile(null);
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Title",
      field: "title",
      sortable: true,
      tooltipField: "title",
    },
    {
      headerName: "Uploaded By",
      field: "userName",
      sortable: true,
      tooltipField: "userName",
    },
    {
      headerName: "Uploaded At",
      field: "createdAt",
      sortable: true,
      tooltipField: "createdAt",
      tooltipComponent: createdAtRenderer,
      cellRenderer: createdAtRenderer,
    },

    ...(isPermissionPresent(K.Permissions.ViewAttachment, userSlice.roles)
      ? [
          {
            headerName: "Actions",
            field: "actions",
            sortable: true,
            resizable: false,
            headerClass: "text-center",
            tooltipField: "actions",
            cellStyle: { textAlign: "center" },
            cellRenderer: actionColumnRenderer,
          },
        ]
      : []),
  ]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const afterModalClose = () => {
    setEditData({});
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const res = await Client.uploadAttachement(
        file,
        K.S3Folders.ClientAttachments + id,
      );

      const payload = {
        entityId: id, //Client Id
        type: "CLIENT",
        title: values.title,
        attachment: res.filepath,
        userName: userSlice.details.name,
        userId: userSlice.details.id,
      };

      await Client.createAttachment(payload);
      getAllAttachments();
      setIsLoading(false);
      setIsModalVisible(false);
      form.resetFields();
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const getAllAttachments = async () => {
    try {
      //*id = clientID
      const body = { entityId: id, type: "CLIENT" };
      const res = await Client.getAttachment(body);
      setAttachments(res);
    } catch (err) {
      console.error(err);
    }
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.AttachmentVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.AttachmentVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions")
          return {
            ...item,
            cellRenderer: actionColumnRenderer,
          };
        else if (item.field === "createdAt")
          return {
            ...item,
            cellRenderer: createdAtRenderer,
            tooltipComponent: createdAtRenderer,
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };
  //?save cloumn Sorting
  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userSlice.details?.id,
          tableName: K.AgGridTable.Keys.HiringManagerVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onChange = useCallback(
    (event) => {
      onFilterTextBoxChanged(event.target.value, gridRef);
      setter({ search: event.target.value }, searchFilterPrefix);
    },
    [searchRef.current],
  );

  useEffect(() => {
    form.resetFields();
  }, [editData]);

  useEffect(() => {
    (async () => {
      await Promise.all([getAllAttachments(), getColumnsConfigrations()]);
    })();
  }, []);

  return (
    <>
      <Card
        title="Attached Document List"
        className={"appCard " + LayoutCss.appCard}
        extra={
          <div className="hiring-manager">
            <Search
              allowClear
              placeholder="Search"
              value={getter(getSearchKey)}
              onChange={onChange}
              className={LayoutCss.appListingCardSearchBar1}
            />
            {isPermissionPresent(
              K.Permissions.AddAttchment,
              userSlice.roles,
            ) && (
              <Button type="primary" className="create-btn" onClick={showModal}>
                Create
              </Button>
            )}
          </div>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={attachments}
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            ref={gridRef}
            onColumnMoved={updateColumns}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
            quickFilterText={getter(getSearchKey)}
          />
        </div>
      </Card>
      <Modal
        forceRender
        className="s2-theme-style"
        title={`Add Documents`}
        open={isModalVisible}
        centered
        closeIcon={<i className="icon-closeable"></i>}
        okText={`Add`}
        wrapClassName="vertical-center-modal"
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
        afterClose={afterModalClose}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={editData}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Title is required." },
              noTrailingSpaceAllowedRule(),
            ]}
          >
            <Input placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            label="Document"
            name="document"
            rules={[
              {
                required: true,
                validator: (_, obj) => {
                  if (file) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please upload documents!");
                  }
                },
              },
            ]}
          >
            <DragUpload setCSVFile={setFile} onRemove={onRemove} accept="all" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
