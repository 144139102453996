import { Typography } from "antd";

const { Text, Paragraph } = Typography;

export default function UserSelectOption({
  children,
  userName,
  userEmail,
  clients = [],
}) {
  return (
    <div className="custom-user-select">
      <Text key={userEmail} className="client-detail">
        {`${children} - `}
        {clients.length
          ? clients?.map((item, index) => (
              <span className="client-name">
                {`${item.name} ${clients.length - 1 !== index ? "," : ""} `}
              </span>
            ))
          : "N/A"}
        {/* <span className="client-name">
          {clientName !== "" ? clientName : "N/A"}
        </span> */}
      </Text>
      <Paragraph>{userEmail}</Paragraph>
    </div>
  );
}
