import { Checkbox, Modal } from "antd";
import Job from "redux/models/job";

export default function LastOpeningFilledModal({
  isLastOpeningModalVisible,
  setIsLastOpeningModalVisible,
  statusForm,
}) {
  const handleCancel = () => {
    setIsLastOpeningModalVisible((prev) => ({
      ...prev,
      showModal: false,
      movingCandidate: false,
    }));
  };

  const handleOk = () => {
    //new Api call
    statusForm.submit();
  };

  return (
    <Modal
      open={isLastOpeningModalVisible.showModal}
      centered
      okText="Submit"
      title={"Final Opening Filled"}
      className="s2-theme-style modal-overflow-auto"
      onCancel={handleCancel}
      onOk={handleOk}
      // okButtonProps={{ disabled: !isLastOpeningModalVisible.movingCandidate }}
    >
      <Checkbox
        onChange={(e) => {
          setIsLastOpeningModalVisible((prev) => ({
            ...prev,
            movingCandidate: e.target.checked,
          }));
        }}
        checked={isLastOpeningModalVisible.movingCandidate}
      >
        Move all active candidates to Declined - Requisitions Closed
      </Checkbox>
    </Modal>
  );
}
