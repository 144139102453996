import { Col, Form, InputNumber, Row, Select } from "antd";
import LayoutCss from "layout/layout.module.scss";
import K from "utilities/constants";

export default function ClientException({
  form,
  parentIndex,
  clientListing,
  setIsDisabled,
}) {
  const isDisabledOption = (itemId) => {
    const list =
      form.getFieldValue("clientExceptions")?.map(({ clientId }) => clientId) ??
      [];
    if (list.includes(itemId)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row gutter={[16, 0]}>
      <Col sm={12} xs={24}>
        <Form.Item
          label="Select Client "
          name={[parentIndex, "clientId"]}
          rules={[{ required: true, message: "Client is required." }]}
        >
          <Select
            showSearch
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="Select"
            optionFilterProp="label"
            className={LayoutCss.appListingCardStatusSelect}
            allowClear
            onChange={() => {
              setIsDisabled((prevData) => !prevData);
            }}
            options={clientListing.map((item) => ({
              value: item.id,
              label: item.name,
              disabled: isDisabledOption(item.id),
            }))}
          />
        </Form.Item>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Applied Hires"
              name={[parentIndex, "appliedHire", "value"]}
              rules={[
                { required: true, message: "Applied Hires is required." },
              ]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              initialValue={K.Format.Dollar}
              name={[parentIndex, "appliedHire", "unit"]}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Sourced Hires"
              name={[parentIndex, "sourceHired", "value"]}
              rules={[{ required: true, message: "Source Hired is required." }]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              name={[parentIndex, "sourceHired", "unit"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Referrals"
              name={[parentIndex, "referral", "value"]}
              rules={[{ required: true, message: "Referral is required." }]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              name={[parentIndex, "referral", "unit"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Cancellations"
              name={[parentIndex, "cancellations", "value"]}
              rules={[
                { required: true, message: "Cancellations is required." },
              ]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              name={[parentIndex, "cancellations", "unit"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Hiring Events"
              name={[parentIndex, "hiringEvent", "value"]}
              rules={[{ required: true, message: "Hiring Event is required." }]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              name={[parentIndex, "hiringEvent", "unit"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Rehire Referral"
              name={[parentIndex, "reHireReferral", "value"]}
              rules={[
                { required: true, message: "Rehire Referral is required." },
              ]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              name={[parentIndex, "reHireReferral", "unit"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Rehire Applied"
              name={[parentIndex, "reHireApplied", "value"]}
              rules={[
                { required: true, message: "Rehire Applied is required." },
              ]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              name={[parentIndex, "reHireApplied", "unit"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xs={24}>
        <Row gutter={16} align="bottom">
          <Col sm={16} xs={24}>
            <Form.Item
              label="Rehire Sourced"
              name={[parentIndex, "reHireSourced", "value"]}
              rules={[
                { required: true, message: "Rehire Sourced is required." },
              ]}
            >
              <InputNumber placeholder="Enter" size="medium" min={0} />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item
              name={[parentIndex, "reHireSourced", "unit"]}
              initialValue={K.Format.Dollar}
            >
              <Select
                showSearch={false}
                className={LayoutCss.appListingCardStatusSelect}
                options={[
                  { label: "$", value: K.Format.Dollar },
                  { label: "%", value: K.Format.Percentage },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
