import { DatePicker, Form, Modal, Typography } from "antd";
import CommissionPlan from "redux/models/commissionPlan";

import K from "utilities/constants";

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

export default function CommissionStatmentExport({
  exportData,
  setExportData,
  globalSelectedRecruiter,
}) {
  const [form] = Form.useForm();

  const handleSuccess = () => {
    setExportData((prev) => ({ ...prev, isModalVisible: false }));
  };

  const handleExportCancel = () => {
    setExportData((prev) => ({ ...prev, isModalVisible: false }));
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  const onFinish = async (values) => {
    console.log("values", values, globalSelectedRecruiter);

    const payload = {
      recruiterIds: globalSelectedRecruiter,
      range: values.range
        ? [
            values.range[0].format(K.DateFormat.Response),
            values.range[1].format(K.DateFormat.Response),
          ]
        : undefined,
    };

    try {
      CommissionPlan.exportCommissionStatements(payload);
      handleSuccess();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      centered
      className="s2-theme-style"
      title="Export Commission Statments"
      open={exportData.isModalVisible}
      onOk={form.submit}
      onCancel={handleExportCancel}
      afterClose={afterModalClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          exportType: "job",
          recruiterIds: globalSelectedRecruiter ?? [],
        }}
      >
        <Paragraph>
          Kindly input a date range to export all commission statements within
          the specified range as a single CSV file.
        </Paragraph>
        <Form.Item
          label="Due Date"
          name="range"
          rules={[{ required: true, message: "Due Date is required" }]}
        >
          <RangePicker format={K.DateFormat.DashUSFormat} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
