import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import { displayDollar } from "utilities/tableUtility";

export const commissionEarnedAmountRenderer = (params) =>
  [displayDollar(+params.value), `(USD)`].join(" ");
export const toolTipCommissionEarnedAmountRenderer = (params) => (
  <div className="client-list-tooltip">
    {[displayDollar(+params.value), `(USD)`].join(" ")}
  </div>
);

export const commissionAmountRenderer = (params) =>
  [
    displayDollar(+params.value),
    `(${params.data.submittedJobBill?.convertToCurrency})`,
  ].join(" ");
export const toolTipCommissionAmountRenderer = (params) => (
  <div className="client-list-tooltip">
    {[
      displayDollar(+params.value),
      `(${params.data.submittedJobBill?.convertToCurrency})`,
    ].join(" ")}
  </div>
);

export const createdAtRenderer = (params) =>
  convertIntoDashUSFormat(params.value);

export const tooltipCreatedAtRenderer = (params) => (
  <div className="client-list-tooltip">
    {convertIntoDashUSFormat(params.value)}
  </div>
);

export const candidateName = (params) => params.value ?? K.NullPlaceholder;

export const tooltipCandidateName = (params) => (
  <div className="client-list-tooltip">{params.value ?? K.NullPlaceholder}</div>
);

export const candidateStartDate = (params) => {
  return convertIntoDashUSFormat(params.value?.startDate, false);
};

export const tooltipCandidateStartDate = (params) => (
  <div className="client-list-tooltip">
    {params.startDate ?? K.NullPlaceholder}
  </div>
);
