import K from "utilities/constants";
export default function DisplayUDF({
  fieldTypeId,
  form,
  udfId,
  title = "Title",
  formItemName,
  isFormItem,
  isRequired = false,
  options = [],
}) {
  const Component = K.UDF.Fields[fieldTypeId]?.displayComponent;
  const componentProps = K.UDF.Fields[fieldTypeId]?.props;

  return (
    Component && (
      <Component
        params={componentProps}
        isFormItem={isFormItem}
        form={form}
        required={isRequired}
        title={title}
        placeholder={title}
        formItemName={udfId}
        options={options}
      />
    )
  );
}
