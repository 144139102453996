import { Card, Collapse, Typography, Divider, Row, Col } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import LayoutCss from "layout/layout.module.scss";
import {
  convertIntoUtcLocalTime,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";
import ClientAndJobBillingComponent from "./clientAndJobBillingComponent";

const { Panel } = Collapse;
const { Text } = Typography;

export default function JobHistoryRule({ tierData }) {
  return (
    <>
      {tierData?.histroyRules.length === 0 ? (
        <Card
          title="History"
          bordered={false}
          className={LayoutCss.appListingCard}
          style={{ marginTop: "20px" }}
        >
          <EmptyPlaceholder />
        </Card>
      ) : (
        <Card
          title="History"
          bordered={false}
          className={`${styles.collapseCard}`}
          style={{ marginTop: "20px" }}
          bodyStyle={{ paddingTop: 0 }}
        >
          {tierData?.histroyRules.map(
            (item) =>
              item.billingRuleEntries.length !== 0 && (
                <div key={item.id}>
                  <Collapse
                    expandIconPosition="end"
                    bordered={false}
                    className={
                      LayoutCss.appListingCardPanel + " appListingCardPanel"
                    }
                  >
                    <Panel
                      header={
                        <Row>
                          <Col xs={24} sm={4}>
                            <Text className="upcoming-date">
                              <span>Created Date:</span>
                              {convertIntoUtcLocalTime(item.createdAt)}
                            </Text>
                          </Col>
                          <Divider type="vertical" />
                          <Col xs={24} sm={4}>
                            <Text className="upcoming-date">
                              <span>Effective Date:</span>
                              {epochTimeToDashUSFormat(item.startDate)}
                            </Text>
                          </Col>
                          <Divider type="vertical" />
                          <Col xs={24} sm={3}>
                            <Text className="upcoming-date">
                              <span>Guarantee Days:</span>{" "}
                              {item.guaranteeDays ?? 0}
                            </Text>
                          </Col>
                          <Divider type="vertical" />
                          <Col xs={24} sm={3}>
                            <Text className="upcoming-date">
                              <span>Working Days:</span>
                              {item.isWorkingDays ? "Yes" : "No"}
                            </Text>
                          </Col>
                          <Divider type="vertical" />
                          <Col xs={24} sm={4}>
                            <Text className="upcoming-date">
                              <span>Created By:</span>{" "}
                              {item.user?.name ?? "N/A"}
                            </Text>
                          </Col>
                        </Row>
                      }
                      className={LayoutCss.appListingCardPanelBorder}
                    >
                      <Card className={styles.clientBillingRulesCard}>
                        <ClientAndJobBillingComponent currentRule={item} />
                      </Card>
                    </Panel>
                  </Collapse>
                </div>
              ),
          )}
        </Card>
      )}
    </>
  );
}
