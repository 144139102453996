import { Card, Divider, Typography } from "antd";

import { useHistory } from "react-router-dom";

import K from "utilities/constants";

const { Title, Paragraph } = Typography;

export default function PipelineDashboardReqDetail({
  getJobDetailByStatus,
  isLoading,
  statusRef,
  item,
  index,
}) {
  const history = useHistory();
  const DisplayReqDetailStatusCount = ({
    statusName,
    statusCount,
    statusId,
    subStatusId,
    subStatusName,
    displayStatusName,
    jobId,
    item,
  }) => {
    return (
      <div
        className="gridCol"
        onClick={() => {
          statusRef.current = {
            statusId: statusId,
            statusCount: statusCount ?? 0,
            statusName: statusName ?? "",
            subStatusName: subStatusName ?? "",
            subStatusId: subStatusId,
            displayStatusName: displayStatusName,
          };

          getJobDetailByStatus(statusId, [jobId], subStatusId);
        }}
        style={{ cursor: "pointer" }}
      >
        <Title className="gridTitle">{statusCount}</Title>
        <Paragraph className="gridParagraph hover-underlined">
          {displayStatusName}
        </Paragraph>
      </div>
    );
  };

  return (
    <>
      <Card key={index} className="requisitionCard" loading={isLoading}>
        <div className="cardHeaderRequisition">
          <div
            //href={`/clients/${item.clientsId}/job/${item.id}`}
            className="containHeader"
            onClick={() =>
              history.push(`/clients/${item.clientsId}/job/${item.id}`)
            }
          >
            <Title className="headerTitle">Job Title:</Title>
            <Paragraph className="headerID hover-underlined">
              {item?.title ?? K.NullPlaceholder}
            </Paragraph>
          </div>

          <div className="containHeader">
            <Title className="headerTitle">Requisition ID:</Title>
            <Paragraph className="headerID">
              {item.extAtsId ?? K.NullPlaceholder}
            </Paragraph>
          </div>
        </div>
        <div className="cardInnerDetails ">
          <div className="cardInnerDesc">
            <Title className="cardInnerIdTitle">Recruiters:</Title>
            <Paragraph className="cardInnerIdDesc">
              {item.jobRecruiters
                .map((obj, index) => `${obj["recruiter"]?.name} `)
                .join(", ")}
            </Paragraph>
          </div>
        </div>
        <div className="cardInnerDetails">
          <div className="cardInnerDesc">
            <Title className="cardInnerIdTitle">Openings:</Title>
            <Paragraph className="cardInnerIdDesc">
              {item.vacancies[0]?.currentOpenings ?? K.NullPlaceholder}
            </Paragraph>
          </div>

          <div className="cardInnerDesc">
            <Title className="cardInnerIdTitle">Location:</Title>
            <Paragraph className="cardInnerIdDesc">
              {item.jobLocation?.name}
            </Paragraph>
          </div>

          <div className="cardInnerDesc">
            <Title className="cardInnerIdTitle">Department:</Title>
            <Paragraph className="cardInnerIdDesc">
              {item?.jobDepartment?.name ?? K.NullPlaceholder}
            </Paragraph>
          </div>

          <div className="cardInnerDesc">
            <Title className="cardInnerIdTitle">Hiring Manager:</Title>
            {item.jobHiringManagers.map((obj) => (
              <Paragraph className="cardInnerIdDesc">
                {obj["hiringManager"]?.name}
              </Paragraph>
            ))}
          </div>
        </div>
        <div className="cardGrid">
          <div className="rowGrid">
            <div className="gridCol">
              <Title className="gridTitle">{item?.activecandidates}</Title>
              <Paragraph className="gridParagraph">Active Candidates</Paragraph>
            </div>
            <Divider type="vertical" />
            {/* <div className="gridCol">
                        <Title className="gridTitle">{item?.underreview}</Title>
                        <Paragraph className="gridParagraph">
                          Under Review
                        </Paragraph>
                      </div> */}
            <DisplayReqDetailStatusCount
              statusCount={item?.underreview}
              statusName={K.Status.Candidates.name}
              displayStatusName={"Under Review"}
              statusId={K.Status.Candidates.id}
              jobId={item.id}
              item={item}
            />

            <Divider type="vertical" />
            {/* <div className="gridCol">
                        <Title className="gridTitle">{item?.s2interview}</Title>
                        <Paragraph className="gridParagraph">
                          S2 Interview
                        </Paragraph>
                      </div> */}
            <DisplayReqDetailStatusCount
              statusCount={item?.s2interview}
              statusName={K.Status.S2Interview.name}
              statusId={K.Status.S2Interview.id}
              displayStatusName={K.Status.S2Interview.name}
              jobId={item.id}
              item={item}
            />
            <Divider type="vertical" />
            {/* <div className="gridCol">
                        <Title className="gridTitle">{item?.submittal}</Title>
                        <Paragraph className="gridParagraph">Submitted</Paragraph>
                      </div> */}
            <DisplayReqDetailStatusCount
              statusCount={item?.submittal}
              statusName={K.Status.Submitted.name}
              statusId={K.Status.Submitted.id}
              displayStatusName={K.Status.Submitted.name}
              jobId={item.id}
              item={item}
            />

            <Divider type="vertical" />
            {/* <div className="gridCol">
                        <Title className="gridTitle">
                          {item?.clientinterview}
                        </Title>
                        <Paragraph className="gridParagraph">
                          Client Interview{" "}
                        </Paragraph>
                      </div>{" "} */}
            <DisplayReqDetailStatusCount
              statusCount={item?.clientinterview}
              statusName={K.Status.ClientInterview.name}
              statusId={K.Status.ClientInterview.id}
              displayStatusName={K.Status.ClientInterview.name}
              jobId={item.id}
              item={item}
            />
            <Divider type="vertical" />
            {/* <div className="gridCol">
                        <Title className="gridTitle">{item?.offerextended}</Title>
                        <Paragraph className="gridParagraph">
                          Offer Extended
                        </Paragraph>
                      </div> */}
            <DisplayReqDetailStatusCount
              statusCount={item?.offerextended}
              statusName={K.Status.Offer.name}
              statusId={K.Status.Offer.id}
              subStatusId={K.Status.Offer.subStatus.Extended.id}
              subStatusName={K.Status.Offer.subStatus.Extended.name}
              displayStatusName={K.Status.Offer.subStatus.Extended.name}
              jobId={item.id}
              item={item}
            />
            <Divider type="vertical" />
            {/* <div className="gridCol">
                        <Title className="gridTitle">{item?.offeraccepted}</Title>
                        <Paragraph className="gridParagraph">
                          Offer Accepted
                        </Paragraph>
                      </div> */}
            <DisplayReqDetailStatusCount
              statusCount={item?.offeraccepted}
              statusName={K.Status.Offer.name}
              statusId={K.Status.Offer.id}
              subStatusId={K.Status.Offer.subStatus.Accepted.id}
              subStatusName={K.Status.Offer.subStatus.Accepted.name}
              displayStatusName={K.Status.Offer.subStatus.Accepted.name}
              jobId={item.id}
              item={item}
            />
            <Divider type="vertical" />
            {/* <div className="gridCol">
                        <Title className="gridTitle">{item?.hired}</Title>
                        <Paragraph className="gridParagraph">Hired</Paragraph>
                      </div> */}
            <DisplayReqDetailStatusCount
              statusCount={item?.hired}
              statusName={K.Status.Hired.name}
              statusId={K.Status.Hired.id}
              displayStatusName={K.Status.Hired.name}
              jobId={item.id}
              item={item}
            />
          </div>
        </div>
      </Card>
    </>
  );
}
