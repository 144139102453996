import { createSlice } from "@reduxjs/toolkit";
import K from "utilities/constants";

const { Redux } = K;

const initialState = {
  [Redux.Listing]: [],
  [Redux.ShouldUpdate]: false,
  [Redux.ShouldReinitialize]: false,
  uploadedCandidate: [],
};

export const candidateSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setCandidateList: (state, action) => {
      state[Redux.Listing] = action.payload;
    },
    updateCandidateList: (state, action) => {
      state[Redux.ShouldUpdate] = !state[Redux.ShouldUpdate];
      state[Redux.Listing] = [...state[Redux.Listing], action.payload];
    },
    editCandidateList: (state, action) => {
      state[Redux.ShouldUpdate] = !state[Redux.ShouldUpdate];
      state[Redux.Listing] = state[Redux.Listing].map((item) => {
        if (item.candidatesId === action.payload.candidatesId) {
          return {
            ...item,
            status: action.payload.status,
            subStatus: action.payload.subStatus,
          };
        } else {
          return item;
        }
      });
    },
    setShouldReinitialize: (state) => {
      state[Redux.ShouldReinitialize] = !state[Redux.ShouldReinitialize];
    },
    setShouldUpdate: (state) => {
      state[Redux.ShouldUpdate] = !state[Redux.ShouldUpdate];
    },
    setUploadCandidate: (state, action) => {
      state.uploadedCandidate = action.payload;
    },
  },
});

export const selectCandidates = (state) => state.candidates;
export const {
  setCandidateList,
  editCandidateList,
  updateCandidateList,
  setShouldReinitialize,
  setShouldUpdate,
  setUploadCandidate,
} = candidateSlice.actions;

export default candidateSlice.reducer;
