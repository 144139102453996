import { Card, Collapse, Typography, Divider, Row, Col } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import LayoutCss from "layout/layout.module.scss";
import {
  convertIntoUtcLocalTime,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";
import ClientBillingRuleComponent from "./clientBillingRuleComponent";

const { Panel } = Collapse;
const { Text } = Typography;

export default function HistoryBillingRule({ clientRulesData }) {
  return (
    <>
      {clientRulesData.length === 0 ||
      clientRulesData?.histroyRules.length === 0 ? (
        <Card
          title="History"
          bordered={false}
          className={LayoutCss.appListingCard}
          style={{ marginTop: "20px" }}
        >
          <EmptyPlaceholder />
        </Card>
      ) : (
        <Card
          title="History"
          bordered={false}
          className={`${styles.collapseCard} appListingCard`}
          style={{ marginTop: "20px" }}
          bodyStyle={{ paddingTop: 0 }}
        >
          {clientRulesData.length !== 0 &&
            clientRulesData?.histroyRules.map(
              (item) =>
                item.clientRuleEntries.length !== 0 && (
                  <Collapse
                    key={item.id}
                    defaultActiveKey={["0"]}
                    expandIconPosition="end"
                    bordered={false}
                    className={
                      LayoutCss.appListingCardPanel + " appListingCardPanel"
                    }
                  >
                    <Panel
                      header={
                        <Row>
                          <Col xs={24} sm={6}>
                            <Text className="upcoming-date">
                              <span>Created Date:</span>
                              {convertIntoUtcLocalTime(item.createdAt)}
                            </Text>
                          </Col>
                          <Divider type="vertical" />
                          <Col xs={24} sm={6}>
                            <Text className="upcoming-date">
                              <span>Effective Date:</span>
                              {epochTimeToDashUSFormat(item.startDate)}
                            </Text>
                          </Col>
                          <Divider type="vertical" />
                          <Col xs={24} sm={6}>
                            <Text className="upcoming-date">
                              <span>Updated By:</span>
                              {item.user?.name ?? "N/A"}
                            </Text>
                          </Col>
                        </Row>
                      }
                      key="1"
                      className={LayoutCss.appListingCardPanelBorder}
                    >
                      <Card className={styles.clientBillingRulesCard}>
                        <ClientBillingRuleComponent
                          styles={styles}
                          currentRule={item}
                        />
                      </Card>
                    </Panel>
                  </Collapse>
                ),
            )}
        </Card>
      )}
    </>
  );
}
