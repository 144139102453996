import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Radio,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import styles from "./commissionPlans.module.scss";
import K from "utilities/constants";
import { disabledDate } from "utilities/dateUtility";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";

export default function CreateCommissionPlanComponent({ form, planId }) {
  return (
    <Card
      title={planId ? "Add New Commission Plan" : "Create Commission Plans"}
      className={`${styles.cardWrap} ${LayoutCss.appCard}`}
    >
      <Form layout="vertical" className={styles.commissionForm} form={form}>
        <Row gutter={[16, 0]}>
          <Col sm={12} xs={24}>
            <Form.Item
              label="Commission Threshold Type"
              name="thresholdType"
              initialValue={"AMOUNT"}
              rules={[
                { required: true, message: "Threshold Type is required." },
              ]}
            >
              <Radio.Group>
                <Radio value={"AMOUNT"}>Billing Amount</Radio>
                <Radio value={"TOTAL_HIRE"}>Total Hires</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          {!planId && (
            <Col sm={12} xs={24}>
              <Form.Item
                label="Plan Name"
                name="planName"
                rules={[
                  { required: true, message: "Plan Name is required." },
                  noTrailingSpaceAllowedRule(),
                ]}
              >
                <Input placeholder="Enter" size="medium" />
              </Form.Item>
            </Col>
          )}
          {planId && (
            <Col sm={12} xs={24} id="effectiveDateIdParent">
              <Form.Item
                className={styles.brFormItems}
                label="Select Start Date"
                name="startDate"
                rules={[{ required: true, message: "Start Date is required." }]}
              >
                <DatePicker
                  getPopupContainer={() =>
                    document.getElementById("effectiveDateIdParent")
                  }
                  disabledDate={disabledDate}
                  className={styles.datePicker}
                  format={K.DateFormat.DashUSFormat}
                />
              </Form.Item>
            </Col>
          )}
          <Col sm={12} xs={24}>
            <Form.Item shouldUpdate={true}>
              {({ getFieldValue }) => (
                <Form.Item
                  label={
                    getFieldValue("thresholdType") ===
                    K.CommissionThresholdType.Amount
                      ? "Default Billing Threshold"
                      : "Default Hires Threshold"
                  }
                  name="defaultBillingThreshold"
                  rules={[
                    { required: true, message: "Default Billing is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Applied Hires"
                  name={["appliedHire", "value"]}
                  rules={[
                    { required: true, message: "Applied Hires is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="appliedHiresSelectIdParent">
                <Form.Item
                  name={["appliedHire", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("appliedHiresSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Sourced Hires"
                  name={["sourceHired", "value"]}
                  rules={[
                    { required: true, message: "Source Hired is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="sourceHiredSelectIdParent">
                <Form.Item
                  name={["sourceHired", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("sourceHiredSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Referrals"
                  name={["referral", "value"]}
                  rules={[{ required: true, message: "Referral is required." }]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="refferalSelectIdParent">
                <Form.Item
                  name={["referral", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("refferalSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Cancellations"
                  name={["cancellations", "value"]}
                  rules={[
                    { required: true, message: "Cancellations is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="cancellationsSelectIdParent">
                <Form.Item
                  name={["cancellations", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("cancellationsSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Hiring Events"
                  name={["hiringEvent", "value"]}
                  rules={[
                    { required: true, message: "Hiring Event is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="hiringSelectIdParent">
                <Form.Item
                  name={["hiringEvent", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("hiringSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Rehire Referral"
                  name={["reHireReferral", "value"]}
                  rules={[
                    { required: true, message: "Rehire Referral is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="rehireReferralSelectIdParent">
                <Form.Item
                  name={["reHireReferral", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("rehireReferralSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Rehire Applied"
                  name={["reHireApplied", "value"]}
                  rules={[
                    { required: true, message: "Rehire Applied is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="reHireAppliedSelectIdParent">
                <Form.Item
                  name={["reHireApplied", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("reHireAppliedSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={16} align="bottom">
              <Col sm={16} xs={24}>
                <Form.Item
                  label="Rehire Sourced"
                  name={["reHireSourced", "value"]}
                  rules={[
                    { required: true, message: "Rehire Sourced is required." },
                  ]}
                >
                  <InputNumber placeholder="Enter" size="medium" min={0} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24} id="rehireSourcedSelectIdParent">
                <Form.Item
                  name={["reHireSourced", "unit"]}
                  initialValue={K.Format.Dollar}
                >
                  <Select
                    showSearch={false}
                    className={LayoutCss.appListingCardStatusSelect}
                    getPopupContainer={() =>
                      document.getElementById("rehireSourcedSelectIdParent")
                    }
                    options={[
                      { label: "$", value: K.Format.Dollar },
                      { label: "%", value: K.Format.Percentage },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
