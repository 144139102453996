import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  addBillingClients,
  editBillingClients,
  setBillingClients,
} from "redux/slices/configrationSlice";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
} from "utilities/generalUtility";

export default class BillingClient {
  static getAll() {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.getBillingClient());
      dispatch(setBillingClients(camelCaseKeysRecursively(res.data)));
    };
  }

  static addData(values) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.addBillingClient(values),
        false,
      );
      const payload = {
        data: camelCaseKeys(res.data),
      };
      dispatch(addBillingClients(payload));
    };
  }

  static editData(values, id) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.editBillingClient({ ...values, clientLevelBillingTypeId: id }),
        false,
      );
      const payload = {
        data: camelCaseKeys(res.data),
      };
      dispatch(editBillingClients(payload));
    };
  }

  static async addNewRule(values) {
    const res = await NetworkCall.fetch(
      Request.addNewBillingClientRule(values),
    );
    return res;
  }

  static async getOneTimeEntries(id, current) {
    const res = await NetworkCall.fetch(
      Request.getOneTimeBillingClientEntries(id, current),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async submitOneTimeBilling(values) {
    const res = await NetworkCall.fetch(
      Request.submitOneTimeClientBilling(values),
    );
    return res;
  }
}
