import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import ClientBillingRuleFields from "features/clients/create/clientBillingRuleFields";
import LayoutCss from "layout/layout.module.scss";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Client from "redux/models/client";
import {
  convertIntoUtcLocalTime,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";
import {
  formatClientRulesEntries,
  stopPropagationFunction,
} from "utilities/generalUtility";
import ClientBillingRuleComponent from "./clientBillingRuleComponent";

const { Panel } = Collapse;
const { Text } = Typography;

export default function UpcomingBillingRule({
  sourceData,
  clientRulesData,
  setClientRulesData,
  getClientBillingRulesDetails,
}) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const selectedRuleId = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showEditModal = (index, ruleId) => {
    selectedRuleId.current = ruleId;
    populateFormValues(index);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  const populateFormValues = (index) => {
    const formValues = formatClientRulesEntries(
      sourceData.upcomingRules[index],
    );
    form.setFieldsValue(formValues);
  };

  const confirm = async (clientBillingRuleId) => {
    try {
      const data = await Client.abortClientBillingRule(clientBillingRuleId);
      if (data.message) {
        setClientRulesData((prevRule) => {
          const { source, modified } = prevRule;
          const upcomingRules = modified.upcomingRules.filter(
            (item) => item.id !== clientBillingRuleId,
          );
          return {
            source,
            modified: { ...prevRule.modified, upcomingRules: upcomingRules },
          };
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onFinish = async (values) => {
    const clientLevelBilligRules = values.billingTypes.reduce((prev, curr) => {
      const ruleList = values[curr].map((rule) => ({
        alias: rule.alias,
        amount: rule.amount,
        currency: rule.currency,
        repetitiveQuantity: rule.repetitiveQuantity,
        recurringType: rule.recurringType,
        selectedDays: rule.selectedDays,
        repetitiveMonthDay: rule.repetitiveMonthDay,
        clientLevelBillingTypeId: curr,
        isInfinite: rule.isInfinite,
        endAfterOccurances: rule.isInfinite === 1 ? 0 : rule.endAfterOccurances,
      }));
      return [...prev, ...ruleList];
    }, []);
    const body = {
      clientId: id,
      clientBillingRuleId: selectedRuleId.current,
      effectiveSince: values["effectiveSince"].unix(),
      clientLevelBilligRules: [...clientLevelBilligRules],
    };
    setIsLoading(true);
    try {
      await Client.editUpcomingClientBillingRule(body);
      setIsLoading(false);
      setIsModalVisible(false);
      getClientBillingRulesDetails();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      {clientRulesData.length === 0 ||
      clientRulesData?.upcomingRules.length === 0 ? (
        <Card
          title="Upcoming"
          bordered={false}
          className={`${styles.collapseCard + " appListingCard"} `}
          style={{ marginTop: "20px" }}
        >
          <EmptyPlaceholder />
        </Card>
      ) : (
        <Card
          title="Upcoming"
          bordered={false}
          className="appListingCard"
          style={{ marginTop: "20px" }}
          bodyStyle={{ paddingTop: 0 }}
        >
          {clientRulesData.length !== 0 &&
            clientRulesData?.upcomingRules.map(
              (item, i) =>
                item.clientRuleEntries.length !== 0 && (
                  <Collapse
                    key={item.id}
                    defaultActiveKey={["0"]}
                    expandIconPosition="end"
                    bordered={false}
                    className={
                      LayoutCss.appListingCardPanel + " appListingCardPanel"
                    }
                  >
                    <Panel
                      header={
                        <Row>
                          <Col xs={24} sm={6}>
                            <Text className="upcoming-date">
                              <span>Created Date:</span>
                              {convertIntoUtcLocalTime(item.createdAt)}
                            </Text>
                          </Col>
                          <Divider type="vertical" />
                          <Col xs={24} sm={6}>
                            <Text className="upcoming-date">
                              <span>Effective Date:</span>
                              {epochTimeToDashUSFormat(item.startDate)}
                            </Text>
                          </Col>

                          <Divider type="vertical" />
                          <Col xs={24} sm={6}>
                            <Text className="upcoming-date">
                              <span>Updated By:</span>
                              {item.user?.name ?? "N/A"}
                            </Text>
                          </Col>
                        </Row>
                      }
                      key="1"
                      className={LayoutCss.appListingCardPanelBorder}
                      extra={
                        <div onClick={stopPropagationFunction}>
                          <Button
                            type="link"
                            icon={<i className="icon-edit"></i>}
                            onClick={() => showEditModal(i, item.id)}
                          />
                          <Popconfirm
                            title="Are you sure to delete this record?"
                            onConfirm={() => {
                              confirm(item.id);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="link"
                              icon={
                                <i
                                  className={"icon-delet " + styles.deletIcon}
                                ></i>
                              }
                            />
                          </Popconfirm>
                        </div>
                      }
                    >
                      <Card className={styles.clientBillingRulesCard}>
                        <ClientBillingRuleComponent
                          styles={styles}
                          currentRule={item}
                        />
                      </Card>
                    </Panel>
                  </Collapse>
                ),
            )}
        </Card>
      )}

      <Modal
        width={568}
        centered
        className={styles.addButtonModule + " modal-overflow-auto"}
        open={isModalVisible}
        closable={false}
        afterClose={afterModalClose}
        onCancel={handleCancel}
        footer={
          <>
            <Button
              className={styles.cancelBillingRules}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              className={styles.saveBillingRules}
              onClick={form.submit}
            >
              Save
            </Button>
          </>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <fieldset className={styles.fieldSet}>
            <legend className={styles.fieldTitle}>Billing Rules</legend>
            <ClientBillingRuleFields
              styles={styles}
              showEffectiveSince={true}
            />
          </fieldset>
        </Form>
      </Modal>
    </>
  );
}
