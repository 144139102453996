import NetworkCall from "network/networkCall";
import Request from "network/request";
import { camelCaseKeysRecursively } from "utilities/generalUtility";

export default class Status {
  static async getAll() {
    const res = await NetworkCall.fetch(Request.getAllStatus());
    return camelCaseKeysRecursively(res.data);
  }

  static async getSubStatusBreakdown(jobId) {
    const res = await NetworkCall.fetch(Request.getSubStatusBreakdown(jobId));
    return camelCaseKeysRecursively(res.data);
  }

  static async undoCandidateStatus(candidateId) {
    const res = await NetworkCall.fetch(
      Request.undoCandidateStatus(candidateId),
    );
    const { data, message } = res;
    return { data: camelCaseKeysRecursively(data), message };
  }

  static async changeCandidateStatusDate(payload) {
    const res = await NetworkCall.fetch(
      Request.changeCandidateStatusDate(payload),
    );
    return camelCaseKeysRecursively(res.data);
  }
}
