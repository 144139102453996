import { AgGridReact } from "ag-grid-react";
import { Button, Card, Form, message, Modal, PageHeader } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingClient from "redux/models/billingClients";
import User from "redux/models/user";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  isPermissionPresent,
  removeUnderScore,
  toolTipRemoveUnderScore,
} from "utilities/generalUtility";
import { filterColumnListing } from "utilities/tableUtility";
import AddNewClientBillingJob from "./addNewClientBillingJob";

export default function BillingClients() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pageState, setPageState] = useState({
    editData: null,
    isLoading: false,
    isModalVisible: false,
  });

  const userSlice = useSelector(selectUser);
  const billingClientData = useSelector(selectConfigration).billingClients;

  const showModal = () => {
    setPageState((prev) => ({ ...prev, isModalVisible: true }));
  };

  const editHandler = (record) => {
    setPageState((prev) => {
      return {
        ...prev,
        isModalVisible: true,
        editData: record,
      };
    });
  };

  const actionColumnRenderer = (params) => (
    <>
      {isPermissionPresent(
        K.Permissions.EditClientBilling,
        userSlice.roles,
      ) && (
        <Button
          type="link"
          className={LayoutCss.appListingCardActions + " hover-underlined"}
          onClick={() => editHandler(params.data)}
        >
          Edit
        </Button>
      )}
    </>
  );

  const tooltipIsAssignable = (params) => (
    <div className="client-list-tooltip">
      {params.data.isAssignable ? "Assignable" : "UnAssignable"}
    </div>
  );

  const isAssignable = (params) =>
    params.data.isAssignable ? "Assignable" : "UnAssignable";
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      tooltipField: "type",
      tooltipComponent: (params) => toolTipRemoveUnderScore(params.value),
      cellRenderer: (params) => removeUnderScore(params.value),
    },
    {
      headerName: "Status",
      field: "isAssignable",
      sortable: true,
      tooltipField: "isAssignable",
      tooltipComponent: tooltipIsAssignable,
      cellRenderer: isAssignable,
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      resizable: false,

      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRenderer: actionColumnRenderer,
    },
  ]);

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userSlice.details.id,
        tableName: K.AgGridTable.Keys.BillingClientsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setPageState((prev) => ({
      ...prev,
      editData: null,
      isModalVisible: false,
    }));
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  const getAllBillings = async () => {
    try {
      await dispatch(BillingClient.getAll());
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.BillingClientsVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "actions") {
          return { ...item, cellRenderer: actionColumnRenderer };
        } else if (item.field === "type") {
          return {
            ...item,
            cellRenderer: (params) => removeUnderScore(params.value),
            tooltipComponent: (params) => toolTipRemoveUnderScore(params.value),
          };
        } else if (item.field === "isAssignable") {
          return {
            ...item,
            cellRenderer: isAssignable,
            tooltipComponent: tooltipIsAssignable,
          };
        }

        return item;
      });

      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getAllBillings(), getColumnsConfigrations()]);
    })();
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Client Level Configuration"
        extra={
          isPermissionPresent(
            K.Permissions.CreateClientBilling,
            userSlice.roles,
          ) && (
            <Button
              type="primary"
              onClick={showModal}
              className={LayoutCss.appHeaderBtn}
            >
              Add New
            </Button>
          )
        }
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
      />
      <Card className={LayoutCss.appCard} bordered={false} title="Client Level">
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            rowData={billingClientData}
            columnDefs={columnDefs}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnVisible}
            defaultColDef={K.AgGridTable.DefaultColDef}
          />
        </div>
      </Card>

      <Modal
        open={pageState.isModalVisible}
        okText={pageState.editData ? "Edit" : "Add"}
        wrapClassName="vertical-center-modal"
        centered
        closeIcon={<i className="icon-closeable"></i>}
        className="s2-theme-style billingClient"
        title={
          pageState.editData
            ? "Edit Configuration"
            : "Clients New Configuration"
        }
        okButtonProps={{ loading: pageState.isLoading }}
        width={462}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={afterModalClose}
        destroyOnClose
      >
        <AddNewClientBillingJob
          form={form}
          pageState={pageState}
          setPageState={setPageState}
        />
      </Modal>
    </>
  );
}
