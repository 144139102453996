import { CaretRightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Form,
  InputNumber,
  Table,
  Typography,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Client from "redux/models/client";
import { setIsTestPassed } from "redux/slices/clientSlice";
import { removeUnderScore } from "utilities/generalUtility";
import { formateDollar } from "utilities/tableUtility";
import "./testBillingRules.scss";
import K from "utilities/constants";

const { Title } = Typography;

export default function TestBillingRule({ form, parentIndex }) {
  const dispatch = useDispatch();
  const [testResults, setTestResults] = useState([]);

  const generateDataSource = (item) => {
    const {
      data: { result },
    } = item;

    if (result.retentionEntries.length) {
      console.log(item);
      const dataSource = result.retentionEntries.map((entry) => [
        {
          title:
            entry.retentionType === K.RecurringType.Month ? (
              <b>Month {entry.monthOrder}</b>
            ) : (
              <b>{entry.monthOrder} Days </b>
            ),
        },
        {
          title: "Amount",
          value: ` ${formateDollar(+entry["amount"])} (${item.currency})`,
          message: item.data.message,
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Referral Fee",
          value: ` ${formateDollar(+entry["referral"])} (${item.currency})`,
          message: item.data.message,
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Rehire Fee",
          value: ` ${formateDollar(+entry["reHired"])} (${item.currency})`,
          message: item.data.message,
          icon: <CheckCircleOutlined />,
        },
      ]);
      return dataSource.flat();
    } else {
      return [
        {
          title: "Amount",
          value: ` ${formateDollar(+result["amount"])} (${item.currency})`,
          message: item.data.message,
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Referral Fee",
          value: ` ${formateDollar(+result["referral"])}  (${item.currency})`,
          message: item.data.message,
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Rehire Fee",
          value: ` ${formateDollar(+result["reHired"])}  (${item.currency})`,
          message: item.data.message,
          icon: <CheckCircleOutlined />,
        },
      ];
    }
  };

  const runTest = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log({ values });

        const currentRules =
          parentIndex !== undefined
            ? values.jobLevelBillingRules[parentIndex]
            : values;
        const jobLevelBilligRulesValues = currentRules.billingTypes.reduce(
          (prev, curr) => {
            const ruleObj = currentRules[curr];
            ruleObj.jobBillingTypesId = curr;
            if ("retentionEntries" in ruleObj) {
              ruleObj.retentionEntries.forEach((v) => {
                v.retentionType = ruleObj.retentionType;
              });
            }
            return [
              ...prev,
              {
                ...ruleObj,
                endAfterOccurances:
                  ruleObj.isInfinite === 1 ? 0 : ruleObj.endAfterOccurances,
              },
            ];
          },
          [],
        );
        try {
          const res = await Client.testBillingRules({
            salary: currentRules.estimatedSalary,
            entries: jobLevelBilligRulesValues,
          });
          setTestResults(res);
          dispatch(setIsTestPassed(res.every((item) => "data" in item)));
        } catch (err) {
          console.error(err);
        }
      })
      .catch((errorInfo) => {
        console.error(errorInfo);
      });
  };
  return (
    <div className="testBillingWrap">
      <Title level={5}>Test Billing Rules</Title>
      <div className="textInput">
        <Form.Item
          className="textBillingFormItem"
          label="Candidate Salary"
          name={
            parentIndex !== undefined
              ? [parentIndex, "estimatedSalary"]
              : "estimatedSalary"
          }
          rules={[
            {
              required: true,
              message: "Salary is required.",
            },
          ]}
        >
          <InputNumber placeholder="Salary" min={0} />
        </Form.Item>
        <Button type="primary" onClick={runTest} icon={<CaretRightOutlined />}>
          Run Test
        </Button>
      </div>
      {testResults.map((item, i) =>
        item.data ? (
          <>
            <Table
              key={i}
              className="textBillingTable"
              size="small"
              columns={[
                {
                  title: item.name + ` (${removeUnderScore(item.billingType)})`,
                  dataIndex: "title",
                  width: 200,
                },
                { dataIndex: "value", width: 200 },
                { dataIndex: "message" },
                { dataIndex: "icon" },
              ]}
              dataSource={generateDataSource(item)}
              pagination={false}
            />
            <Divider />
          </>
        ) : (
          <div key={i} className="testBillingAlertWrap">
            <Title level={5}>{item.name}</Title>
            <div className="testBillingAlert">
              <Alert message="Failed" type="error" showIcon />
              <p>
                Reason: <span>{item.error.message}</span>
              </p>
            </div>
            <Divider />
          </div>
        ),
      )}
    </div>
  );
}
