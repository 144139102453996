import K from "utilities/constants";

export const subStatusesToDisplay = {
  [K.CandidateStatus.Declined]: {
    [K.CandidateStatus.Prospects]: [
      "Communication",
      "Experience",
      "Location",
      "Requirements",
      "Schedule",
      "Requisition Closed",
    ],
    [K.CandidateStatus.Candidates]: [
      "Communication",
      "Experience",
      "Location",
      "Pay",
      "Professionalism",
      "Requirements",
      "Schedule",
      "Requisition Closed",
    ],
    [K.CandidateStatus.S2Interview]: [
      "Communication",
      "Cultural Fit",
      "Experience",
      "Location",
      "Pay",
      "Professionalism",
      "Requirements",
      "Schedule",
      "No Show to Interview",
      "Requisition Closed",

      "Assessment",
    ],
    [K.CandidateStatus.Submitted]: [
      "Communication",
      "Experience",
      "Location",
      "Pay",
      "Professionalism",
      "Requirements",
      "Schedule",
      "Requisition Closed",

      "Assessment",
    ],
    [K.CandidateStatus.ClientInterview]: [
      "Communication",
      "Cultural Fit",
      "Experience",
      "Location",
      "Pay",
      "Professionalism",
      "Requirements",
      "Schedule",
      "No Show to Interview",
      "Requisition Closed",
      "Assessment",
    ],
    [K.CandidateStatus.Offer]: [
      "Assessment",
      "Background",
      "Drug Screen",
      "MVR",
      "Physical",
      "Offer Rescinded",
    ],
  },
  [K.CandidateStatus.Withdrew]: {
    [K.CandidateStatus.Prospects]: [
      "Benefits",
      "Culture",
      "Did Not Disclose",
      "Location",
      "No Longer Interested",
      "Pay",
      "Requirements",
      "Schedule",
      "Unresponsive",
    ],
    [K.CandidateStatus.Candidates]: [
      "Benefits",
      "Culture",
      "Did Not Disclose",
      "Location",
      "No Longer Interested",
      "Pay",
      "Requirements",
      "Schedule",
      "Unresponsive",
    ],
    [K.CandidateStatus.S2Interview]: [
      "Benefits",
      "Culture",
      "Did Not Disclose",
      "Location",
      "No Longer Interested",
      "Pay",
      "Requirements",
      "Schedule",
      "Unresponsive",
    ],
    [K.CandidateStatus.Submitted]: [
      "Benefits",
      "Culture",
      "Did Not Disclose",
      "Location",
      "No Longer Interested",
      "Pay",
      "Requirements",
      "Schedule",
      "Unresponsive",
    ],
    [K.CandidateStatus.ClientInterview]: [
      "Benefits",
      "Culture",
      "Did Not Disclose",
      "Location",
      "No Longer Interested",
      "Pay",
      "Requirements",
      "Schedule",
      "Unresponsive",
    ],
    [K.CandidateStatus.Offer]: [
      "Benefits",
      "Culture",
      "Did Not Disclose",
      "Location",
      "No Longer Interested",
      "Pay",
      "Requirements",
      "Schedule",
      "Unresponsive",
    ],
  },
};

export const activeStatusDateRules = [
  {
    status: K.CandidateStatus.Prospects,
    subStatus: "Sourced",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "sourcedDate",
        placeholder: "Sourced Date",
        required: true,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Prospects,
    subStatus: "First Contacted",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "firstContactedDate",
        placeholder: "First Contact Date",
        required: true,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Prospects,
    subStatus: "Second Contacted",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "secondContactedDate",
        placeholder: "Second Contact Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Prospects,
    subStatus: "Third Contacted",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "thirdContactedDate",
        placeholder: "Third Contact Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Prospects,
    subStatus: "Not Interested",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "notIntrestedDate",
        placeholder: "Not Interested Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Prospects,
    subStatus: "Interested",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "intrestedDate",
        placeholder: "Interested Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Candidates,
    subStatus: "Needs Review",
    isDateNotRequired: false,
    enteringDate: [
      {
        formItemName: "applicationDate",
        placeholder: "Applied Date",
        required: true,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Candidates,
    subStatus: "Review Complete",
    isDateNotRequired: false,
    enteringDate: [
      {
        formItemName: "reviewCompleteDate",
        placeholder: "Review Complete Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.S2Interview,
    subStatus: "Contacted",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "s2InterviewContactedDate",
        placeholder: "S2 Interview Contacted Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.S2Interview,
    subStatus: "Scheduled",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "s2InterviewScheduledDate",
        placeholder: "S2 Interview Scheduled Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.S2Interview,
    subStatus: "Complete",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "phoneScreenCompleteDate",
        placeholder: "Date Phone Screen Complete",
        required: true,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Submitted,
    subStatus: "Awaiting Feedback",
    isDateNotRequired: false,
    enteringDate: [
      {
        formItemName: "dateSubmitted",
        placeholder: "Date Submitted",
        required: true,
        isTimeField: false,
      },
    ],
    leavingDate: [
      {
        formItemName: "dateSubmittedFeedbackReceived",
        placeholder: "Date Submittal Feedback Received",
        required: true,
      },
    ],
  },
  {
    status: K.CandidateStatus.Submitted,
    subStatus: "Scheduling Interview",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "schedulingDate",
        placeholder: "Scheduling Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.ClientInterview,
    subStatus: "Awaiting Feedback",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "interviewDate",
        placeholder: "Interview Date",
        required: true,
        isTimeField: false,
      },
      {
        formItemName: "interviewTime",
        placeholder: "Interview Time",
        required: false,
        isTimeField: true,
      },
    ],
    leavingDate: [
      {
        formItemName: "interviewFeedbackReceived",
        placeholder: "Date Interview Feedback Received",
        required: true,
      },
    ],
  },
  {
    status: K.CandidateStatus.ClientInterview,
    subStatus: "Second Interview",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "secondInterviewDate",
        placeholder: "Second Interview Date ",
        required: false,
        isTimeField: false,
      },
      {
        formItemName: "secondInterviewTime",
        placeholder: "Second Interview Time ",
        required: false,
        isTimeField: true,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.ClientInterview,
    subStatus: "Third Interview",
    isDateNotRequired: true,
    enteringDate: [
      {
        formItemName: "thirdInterviewDate",
        placeholder: "Third Interview Date ",
        required: false,
        isTimeField: false,
      },
      {
        formItemName: "thirdInterviewTime",
        placeholder: "Third Interview Time ",
        required: false,
        isTimeField: true,
      },
    ],
    leavingDate: [],
  },

  {
    status: K.CandidateStatus.Offer,
    subStatus: "Approval",
    isDateNotRequired: false,
    enteringDate: [
      {
        formItemName: "offerApprovalDate",
        placeholder: "Date Offer Approval",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Offer,
    subStatus: "Extended",
    isDateNotRequired: false,
    enteringDate: [
      {
        formItemName: "offerExtendedDate",
        placeholder: "Date Offer Extended",
        required: true,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Offer,
    subStatus: "Accepted",
    isDateNotRequired: false,
    enteringDate: [
      {
        formItemName: "offerAcceptedDate",
        placeholder: "Date Offer Accepted",
        required: true,
        isTimeField: false,
      },
      {
        formItemName: "pendingStartDate",
        placeholder: "Pending Start Date",
        required: false,
        isTimeField: false,
      },
    ],
    leavingDate: [],
  },
  /*
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "Benefits",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "Culture",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "Did Not Disclose",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "Location",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "No Longer Interested",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "Pay",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "Requirements",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Withdrew,
    subStatus: "Schedule",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Communication",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },

  {
    status: K.CandidateStatus.Declined,
    subStatus: "Cultural Fit",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Experience",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Location",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Pay",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Professionalism",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Requirements",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Schedule",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "No Show to Interview",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Requisition Closed",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Unresponsive",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Assessment",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Background",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Drug Screen",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "MVR",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Declined,
    subStatus: "Physical",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Hired,
    subStatus: "Started Work",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Terminated,
    subStatus: "No Show to Day 1",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Terminated,
    subStatus: "Within Guarantee",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  {
    status: K.CandidateStatus.Terminated,
    subStatus: "Within Retention Period",
    isDateNotRequired: false,
    enteringDate: [],
    leavingDate: [],
  },
  */
];

export const finalStatusDateRules = {
  [K.CandidateStatus.Withdrew]: {
    Unresponsive: [
      {
        formItemName: "declinedUnresponsiveDate",
        placeholder: "Withdrew Unresponsive Date",
        required: true,
        isTimeField: false,
      },
    ],
  },
  [K.CandidateStatus.Declined]: {
    //deprecated
    // Unresponsive: [
    //   {
    //     formItemName: "declinedUnresponsiveDate",
    //     placeholder: "Declined Unresponsive Date",
    //     required: true,
    //     isTimeField: false,
    //   },
    // ],

    ["Offer Rescinded"]: [
      {
        formItemName: "declinedOfferRescindedDate",
        placeholder: "Offer Rescinded Date",
        required: true,
        isTimeField: false,
      },
    ],
  },
  [K.CandidateStatus.Hired]: {},
  [K.CandidateStatus.Terminated]: {
    ["Outside Retention Period"]: [
      {
        formItemName: "outsideRetentionPeriodDate",
        placeholder: "Outside Retention Period",
        required: true,
        isTimeField: false,
      },
    ],
  },
};

export const finalDestinationDates = {
  [K.CandidateStatus.Hired]: [
    {
      formItemName: "hireDate",
      placeholder: "Hired Date",
      required: true,
      isTimeField: false,
    },
    {
      formItemName: "startDate",
      placeholder: "Start Date",
      required: true,
      isTimeField: false,
    },
  ],
  [K.CandidateStatus.Withdrew]: [
    {
      formItemName: "withdrewDate",
      placeholder: "Withdrew Date",
      required: true,
      isTimeField: false,
    },
  ],
  [K.CandidateStatus.Declined]: [
    {
      formItemName: "declinedDate",
      placeholder: "Declined Date",
      required: true,
      isTimeField: false,
    },
  ],
  [K.CandidateStatus.Terminated]: [
    {
      formItemName: "termDate",
      placeholder: "Term Date",
      required: true,
      isTimeField: false,
    },
  ],
};
