import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input, Modal } from "antd";
import Client from "redux/models/client";
import ContactPersonDetails from "common/contactPersonDetails/contactPersonDetails";
import LookupTable from "redux/models/lookupTable";
import ClientContact from "redux/models/clientContacts";
import K from "utilities/constants";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";

const { Lookup } = K.Network.URL;

export default function OverviewModel({
  isModalVisible,
  editData,
  setEditData,
  setIsModalVisible,
}) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    form.setFieldsValue({ name: editData?.name });
  }, [form, editData]);

  const editClientBillingUnit = async (body) => {
    setIsLoading(true);
    try {
      await dispatch(Client.editBillingUnit(body));
      setIsLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  const addClientBillingUnit = async (body) => {
    setIsLoading(true);
    try {
      await dispatch(Client.addBillingUnit(body));
      setIsLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onFinish = async (values) => {
    if (editData) {
      const body = { billingUnitId: editData.id, clientId: id, ...values };
      editClientBillingUnit(body);
    } else {
      const body = { clientId: id, ...values };
      addClientBillingUnit(body);
    }
  };

  const afterModalClose = () => {
    form.resetFields();
    setEditData(null);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onOk = () => {
    form.submit();
  };

  const getClientContacts = async () => {
    try {
      const list = await ClientContact.getAll();
      setContactList(list);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          dispatch(
            LookupTable.getData(K.Redux.ContactTypes, Lookup.ContactTypes),
          ),
          getClientContacts(),
        ]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <Modal
      centered
      open={isModalVisible}
      closeIcon={<i className="icon-closeable"></i>}
      okText={editData ? "Edit" : "Add"}
      className="s2-theme-style modal-overflow-auto"
      width={600}
      wrapClassName="vertical-center-modal"
      title={editData ? `Edit Billing Unit` : `Add New Billing Unit`}
      onOk={onOk}
      onCancel={handleCancel}
      afterClose={afterModalClose}
      okButtonProps={{ loading: isLoading }}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={editData}
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
            noTrailingSpaceAllowedRule(),
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <ContactPersonDetails
          keyName="contactsType"
          form={form}
          editData={editData}
          contactList={contactList}
        />
      </Form>
    </Modal>
  );
}
