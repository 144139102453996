import { Form, Radio } from "antd";
export default function UDFRadio({
  title = "",
  isFormItem = true,
  value = true,
  className = "",
  placeholder = "",
  formItemName = "",
  required = true,
  options = [],
  params = {},
}) {
  const radioOptions = options.map((opt) => ({
    label: opt.value,
    value: opt.id,
  }));
  return (
    <>
      {isFormItem ? (
        <Form.Item
          label={title}
          name={formItemName}
          rules={
            required ? [{ required: true, message: `Choose ${title}` }] : null
          }
        >
          <Radio.Group name="radiogroup" options={radioOptions} />
        </Form.Item>
      ) : (
        <>
          <Radio.Group name="radiogroup" options={radioOptions} />
        </>
      )}
    </>
  );
}
