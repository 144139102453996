import NetworkCall from "network/networkCall";
import Request from "network/request";
import { camelCaseKeysRecursively } from "utilities/generalUtility";

export default class UDF {
  static async getFields() {
    const res = await NetworkCall.fetch(Request.getUdfFields());
    return camelCaseKeysRecursively(res.data);
  }

  static async createUdf(body) {
    const res = await NetworkCall.fetch(Request.createUDF(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async updateUdf(body) {
    const res = await NetworkCall.fetch(Request.updateUDF(body));
    return camelCaseKeysRecursively(res.data);
  }
  static async getUdfList(body) {
    const res = await NetworkCall.fetch(Request.GetUDF(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async getEditUdfFields(body) {
    const res = await NetworkCall.fetch(Request.GetEditUdfList(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async editUdfFieldsValue(body) {
    const res = await NetworkCall.fetch(Request.editUdfValue(body));
    return camelCaseKeysRecursively(res.data);
  }
}
