import { createSlice } from "@reduxjs/toolkit";
import K from "utilities/constants";

const { Redux } = K;

const initialState = {
  [Redux.CandidateUdf]: [],
  [Redux.ClientUdf]: [],
  [Redux.JobSourceUdf]: [],
  shouldLoad: false,
};

export const udfSlice = createSlice({
  name: "udfs",
  initialState,
  reducers: {
    setClientUdf: (state, action) => {
      state[Redux.ClientUdf] = action.payload;
    },
    setCandidateUdf: (state, action) => {
      state[Redux.CandidateUdf] = action.payload;
    },
    setJobSourceUdf: (state, action) => {
      state[Redux.JobSourceUdf] = action.payload;
    },

    setUdfShouldLoad: (state, action) => {
      state.shouldLoad = !action.payload;
    },
  },
});

export const selectUdf = (state) => state.udfs;
export const {
  setCandidateUdf,
  setClientUdf,
  setJobSourceUdf,
  setUdfShouldLoad,
} = udfSlice.actions;

export default udfSlice.reducer;
