import { AgGridReact } from "ag-grid-react";
import { Button, message, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Candidate from "redux/models/candidate";
import User from "redux/models/user";
import {
  selectCandidates,
  setCandidateList,
  setShouldReinitialize,
} from "redux/slices/candidateSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  checkNullPlaceHolder,
  checkStatusExist,
  removeSpacesAndPascalCase,
} from "utilities/generalUtility";
import { filterColumnListing, stringSorting } from "utilities/tableUtility";

const tagColors = {
  Prospect: "#D9D9D9",
  Candidates: "#F6A2E6",
  Submitted: "#BDD7EE",
  Offer: "#99FF99",
  Withdrew: "#F9B367",
  Declined: "#FF9999",
  Hired: "#00CC66",
  Terminated: "#FF9999",
  "S2 Interview": "#33CCFF",
  "Client Interview": "#CC99FF",
  "No Show to Day 1": "#FF9999",
  "Within Guarantee": "#FFB66D",
  "Within Retention Period": "#FFB66D",
};

export default function ClassCandidateListing({
  candidateActivity,
  setCandidateActivity,
  setModalVisible,
  gridRef,
  shouldUpdateListing,
  setJobData,
}) {
  const dispatch = useDispatch();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const userDetails = useSelector(selectUser).details;

  const candidateNameToolip = (params) => (
    <div className="client-list-tooltip">
      {params.data.candidate?.firstName + " " + params.data.candidate?.lastName}
    </div>
  );
  const candidateTypeToolip = (params) => (
    <div className="client-list-tooltip">
      {checkNullPlaceHolder(K.CandidateTypes[params.value])}
    </div>
  );
  const statusToolip = (params) => (
    <div className="client-list-tooltip">{params.data.status.name}</div>
  );
  const subStatusToolip = (params) => (
    <div className="client-list-tooltip">{params.data.subStatus?.name}</div>
  );

  const { classId, statusesId, statusName, subStatusesId, subStatusName } =
    useParams();
  const candidates = useSelector(selectCandidates);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Name",
      field: "candidate.firstName",
      sortable: true,
      tooltipField: "candidate.firstName",
      tooltipComponent: candidateNameToolip,
      getQuickFilterText: (params) => {
        return [
          params.data.candidate?.firstName,
          params.data.candidate?.lastName,
        ].join(" ");
      },
      cellRenderer: (params) => (
        <div>
          {[
            params.data.candidate?.firstName,
            params.data.candidate?.lastName,
          ].join(" ")}
        </div>
      ),
    },
    {
      headerName: "Email",
      field: "candidate.email",
      sortable: true,
      tooltipField: "candidate.email",
    },
    {
      headerName: "Mobile Number",
      field: "candidate.mobileNumber",
      sortable: true,
      tooltipField: "candidate.mobileNumber",
      getQuickFilterText: (params) => params.value,
      cellRenderer: (params) => checkNullPlaceHolder(params.value),
    },
    {
      headerName: "Candidate Type",
      field: "hireType",
      sortable: true,
      tooltipField: "hireType",
      getQuickFilterText: (params) => K.CandidateTypes[params.value],
      cellRenderer: (params) =>
        checkNullPlaceHolder(K.CandidateTypes[params.value]),
      tooltipComponent: candidateTypeToolip,
    },
    {
      headerName: "Current Title",
      field: "candidate.currentTitle",
      sortable: true,
      tooltipField: "candidate.currentTitle",
      getQuickFilterText: (params) => params.value,
      cellRenderer: (params) => checkNullPlaceHolder(params.value),
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      tooltipField: "status",
      getQuickFilterText: (params) => params.data.status.name,
      tooltipComponent: statusToolip,
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: (params) => {
        return (
          <Tag
            className="tagTextColor"
            color={tagColors[params.data.status.name]}
          >
            {params.data.status.name}
          </Tag>
        );
      },
    },
    {
      headerName: "Sub Status",
      field: "subStatus",
      sortable: true,
      tooltipField: "subStatus",
      tooltipComponent: subStatusToolip,
      getQuickFilterText: (params) => params.data.subStatus?.name,

      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: (params) => {
        const colorHexValue =
          params.data.status.name === "Terminated"
            ? tagColors[params.data.subStatus?.name]
            : tagColors[params.data.status?.name];
        return (
          <Tag className="tagTextColor" color={colorHexValue}>
            {params.data.subStatus?.name}
          </Tag>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      sortable: true,
      resizable: false,
      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <Button
          type="link"
          className="view-activity-btn"
          onClick={() => {
            params.api.forEachNode((node) => {
              if (node.rowIndex === params.rowIndex) node.setSelected(true);
            });
            fetchCandidateDetails(params.data.id);
          }}
        >
          View Activity
        </Button>
      ),
    },
  ]);

  const showModalViewActivity = () => {
    setModalVisible((prev) => {
      return {
        ...prev,
        isActivityModalVisible: true,
      };
    });
  };

  const fetchCandidateDetails = async (id) => {
    try {
      const res = await Candidate.getActivity(id);
      setCandidateActivity(res);
      dispatch(setShouldReinitialize());
      showModalViewActivity();
    } catch (err) {
      message.error("Failed to fetch candidate!");
      console.error(err);
    }
  };

  const getCandidateByClassId = async () => {
    try {
      const data = await Candidate.getByClassId(classId);
      dispatch(setCandidateList(data.candidateJobEntries));
    } catch (err) {
      console.error(err);
    }
  };
  // const sortCandidateByStatus = async (statusId, subStatusId = null) => {
  //   try {
  //     const data = await Candidate.sortCandidateByStatus(
  //       statusesId ? statusesId : statusId,
  //       jobId,
  //       subStatusesId ? subStatusesId : subStatusId,
  //     );
  //     dispatch(setCandidateList(data));
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.JobDetailCandidateColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "candidate.firstName")
          return {
            ...item,

            tooltipComponent: candidateNameToolip,
            getQuickFilterText: (params) => {
              return [
                params.data.candidate?.firstName,
                params.data.candidate?.lastName,
              ].join(" ");
            },
            cellRenderer: (params) => (
              <div>
                {[
                  params.data.candidate?.firstName,
                  params.data.candidate?.lastName,
                ].join(" ")}
              </div>
            ),
          };
        else if (item.field === "candidate.mobileNumber")
          return {
            ...item,
            getQuickFilterText: (params) => params.value,
            cellRenderer: (params) => checkNullPlaceHolder(params.value),
          };
        else if (item.field === "hireType")
          return {
            ...item,
            getQuickFilterText: (params) => K.CandidateTypes[params.value],
            cellRenderer: (params) =>
              checkNullPlaceHolder(K.CandidateTypes[params.value]),
            tooltipComponent: candidateTypeToolip,
          };
        else if (item.field === "candidate.currentTitle")
          return {
            ...item,
            getQuickFilterText: (params) => params.value,
            cellRenderer: (params) => checkNullPlaceHolder(params.value),
          };
        else if (item.field === "status")
          return {
            ...item,
            getQuickFilterText: (params) => params.data.status.name,
            tooltipComponent: statusToolip,
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            cellRenderer: (params) => {
              return (
                <Tag
                  className="tagTextColor"
                  color={tagColors[params.data.status.name]}
                >
                  {params.data.status.name}
                </Tag>
              );
            },
          };
        else if (item.field === "subStatus")
          return {
            ...item,
            tooltipComponent: subStatusToolip,
            getQuickFilterText: (params) => params.data.subStatus.name,

            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            cellRenderer: (params) => {
              const colorHexValue =
                params.data.status.name === "Terminated"
                  ? tagColors[params.data.subStatus.name]
                  : tagColors[params.data.status.name];
              return (
                <Tag className="tagTextColor" color={colorHexValue}>
                  {params.data.subStatus.name}
                </Tag>
              );
            },
          };
        else if (item.field === "actions")
          return {
            ...item,
            resizable: false,
            headerClass: "text-center",
            cellStyle: { textAlign: "center" },
            cellRenderer: (params) => (
              <Button
                type="link"
                className="view-activity-btn"
                onClick={() => {
                  params.api.forEachNode((node) => {
                    if (node.rowIndex === params.rowIndex)
                      node.setSelected(true);
                  });
                  fetchCandidateDetails(params.data.id);
                }}
              >
                View Activity
              </Button>
            ),
          };

        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  //?save cloumn Sorting
  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userDetails.id,
          tableName: K.AgGridTable.Keys.JobDetailCandidateColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.JobDetailCandidateColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (candidateActivity === null) {
      gridRef.current?.api?.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  }, [candidateActivity]);

  useEffect(() => {
    if (statusesId && checkStatusExist(statusName, subStatusName)) {
      getCandidateByClassId();
    } else {
      // getCandidateByJobId();
      getCandidateByClassId();
    }
  }, [shouldUpdateListing, statusesId, subStatusesId]);

  useEffect(() => {
    getColumnsConfigrations();
  }, []);

  return (
    <div
      className="ag-theme-alpine s2-theme-style"
      style={{
        height: 560,
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={candidates.listing}
        columnDefs={columnDefs}
        onColumnMoved={updateColumns}
        onColumnPinned={onColumnVisible}
        onColumnVisible={onColumnVisible}
        {...K.AgGridTable.DefaultProps}
        defaultColDef={K.AgGridTable.DefaultColDef}
      />
    </div>
  );
}
