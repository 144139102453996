import { useEffect, useState } from "react";
import { Select } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useParams } from "react-router-dom";
import Client from "redux/models/client";
import CurrentBillingRule from "./currentBillingRule";
import HistoryBillingRule from "./historyBillingRule";
import UpcomingBillingRule from "./upcomingBillingRule";
import groupBy from "lodash/groupBy";
import BillingClient from "redux/models/billingClients";
import BillingJob from "redux/models/billingJobs";
import { useDispatch } from "react-redux";

export default function ClientBillingRules() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [billingRule, setBillingRule] = useState("upcoming");
  const [clientRulesData, setClientRulesData] = useState({
    source: null,
    modified: null,
  });
  const handleChange = (value) => {
    setBillingRule(value);
  };

  const groupRuleEntries = (entries) => {
    const source = structuredClone(entries);
    const { currentRule, ...rules } = entries;
    currentRule.clientRuleEntries = groupBy(
      currentRule.clientRuleEntries,
      "clientLevelBillingTypesId",
    );
    for (let key of Object.keys(rules)) {
      const list = rules[key].map((item) => {
        return {
          ...item,
          clientRuleEntries: groupBy(
            item.clientRuleEntries,
            "clientLevelBillingTypesId",
          ),
        };
      });
      rules[key] = list;
    }
    setClientRulesData({
      source,
      modified: { currentRule, ...rules },
    });
  };

  const getClientBillingRulesDetails = async () => {
    try {
      const res = await Client.getClientBillingRules(id);
      groupRuleEntries(res);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllCurrencies = async () => {
    try {
      await dispatch(BillingJob.getCurrencies());
    } catch (err) {
      console.error(err);
    }
  };

  const getClientBillingTypes = async () => {
    try {
      await dispatch(BillingClient.getAll());
    } catch (err) {
      console.error(err);
    }
  };

  const billingRules = {
    upcoming: (
      <UpcomingBillingRule
        clientRulesData={clientRulesData.modified}
        setClientRulesData={setClientRulesData}
        sourceData={clientRulesData.source}
        getClientBillingRulesDetails={getClientBillingRulesDetails}
      />
    ),
    history: <HistoryBillingRule clientRulesData={clientRulesData.modified} />,
    currentBillingRule: (
      <CurrentBillingRule
        clientRulesData={clientRulesData.modified}
        sourceData={clientRulesData.source}
        getClientBillingRulesDetails={getClientBillingRulesDetails}
      />
    ),
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          getAllCurrencies(),
          getClientBillingTypes(),
          getClientBillingRulesDetails(),
        ]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      {clientRulesData.modified && billingRules["currentBillingRule"]}

      <div className={LayoutCss.invoicesButton}>
        <Select
          showSearch={false}
          defaultValue="upcoming"
          onChange={handleChange}
          className={LayoutCss.invoicesButtonSet}
          options={[
            { value: "history", label: "History" },
            { value: "upcoming", label: "Upcoming" },
          ]}
        />
      </div>
      {clientRulesData.modified && billingRules[billingRule]}
    </>
  );
}
