import { AgGridReact } from "ag-grid-react";
import { Button, Card, Input, Select, message } from "antd";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import SubmitOneTimeBilling from "common/submitOneTimeBilling/submitOneTimeBilling";
import BulkUpdateInvoice from "features/billingCommissions/invoices/bulkUpdateInvoice";
import {
  toolTipInvoiceAmount,
  toolTipInvoiceDueDate,
  toolTipInvoiceMonth,
  toolTipInvoiceNumber,
} from "features/billingCommissions/submitToBilling/tableColumns";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BillingJob from "redux/models/billingJobs";
import Invoice from "redux/models/invoice";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat, getMonthName } from "utilities/dateUtility";
import {
  camelToSnakeCase,
  isPermissionPresent,
  removeUnderScore,
  toolTipRemoveUnderScore,
} from "utilities/generalUtility";
import { displayDollar, onFilterTextBoxChanged } from "utilities/tableUtility";

const { Search } = Input;
const sortDict = {
  asc: "ASC",
  desc: "DESC",
};

export default function Invoices({ type }) {
  const gridRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [rowData, setRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userRoles = useSelector(selectUser).roles;

  let searchFilterPrefix = `client_${id}_${type}_invoice`;
  let getSearchKey = `client_${id}_${type}_invoice_search`;
  let getFilterKey = `client_${id}_${type}_invoice_status`;
  const [setter, getter] = useSearchAndFilter();

  const statusRef = useRef(getter(getFilterKey));
  const searchRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const [selectedRows, setSelectedRows] = useState({
    rowIds: [],
    rowData: [],
    isDisabled: true,
    isModalVisible: false,
    shouldUpdate: false,
  });
  const [columnDefs] = useState([
    {
      headerName: "Client Name",
      field: "client.name",
      sortable: true,
      tooltipField: "client.name",
      suppressColumnsToolPanel: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: "Invoice Number",
      field: "invoiceNumber",
      sortable: true,
      tooltipField: "invoiceNumber",
      tooltipComponent: toolTipInvoiceNumber,
      cellRenderer: (params) =>
        params.value ? params.value : K.NullPlaceholder,
    },
    {
      headerName: "Invoice Status",
      field: "status",
      sortable: true,
      tooltipField: "status",
      tooltipComponent: (params) => toolTipRemoveUnderScore(params.value),
      cellRenderer: (params) => removeUnderScore(params.value),
    },
    {
      headerName: "Invoice Date",
      field: "dueDate",
      sortable: true,
      tooltipField: "dueDate",
      tooltipComponent: toolTipInvoiceDueDate,
      cellRenderer: (params) => convertIntoDashUSFormat(params.value, false),
    },
    {
      headerName: "Invoice Month",
      field: "dueDate",
      sortable: true,
      getQuickFilterText: (params) => getMonthName(params.value),
      tooltipField: "dueDate",
      tooltipComponent: toolTipInvoiceMonth,
      cellRenderer: (params) => getMonthName(params.value),
    },
    {
      headerName: "Invoice Amount",
      field: "total",
      sortable: true,
      tooltipField: "total",
      tooltipComponent: toolTipInvoiceAmount,
      cellRenderer: (params) => <>{displayDollar(+params.value.toFixed(2))}</>,
    },
    {
      headerName: "Reason",
      field: "reason",
      sortable: true,
      tooltipField: "reason",
    },
  ]);

  const show = () => {
    setIsModalVisible(true);
  };
  const showBulkUpdate = () => {
    setSelectedRows((prev) => ({
      ...prev,
      isModalVisible: true,
    }));
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await Invoice.getAll({
  //         clientIds: [+id],
  //         type,
  //       });

  //       setRowData(res);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   })();
  // }, [id, type, selectedRows.shouldUpdate]);

  const rowClickedHandler = (params) => {
    history.push(`/billings/invoices/detail/${params.data.id}`);
  };

  const onSearch = (event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);

    setter({ search: event.target.value }, searchFilterPrefix);
  };

  const externalFilterChanged = (newValue) => {
    statusRef.current = newValue;
    gridRef.current.api.onFilterChanged();
    setter({ status: newValue }, searchFilterPrefix);
  };

  const isExternalFilterPresent = useCallback(() => {
    return statusRef.current !== undefined;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        return node.data.status === statusRef.current;
      }
      return true;
    },
    [statusRef.current],
  );

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const isSameStatus = selectedRows.every((obj, index, array) => {
      return obj.status === array[0].status;
    });
    if (!isSameStatus) {
      message.warning(
        "You have selected two invoices with different statuses for bulk update.",
      );
    }

    setSelectedRows((prev) => ({
      ...prev,
      isDisabled: selectedRows.length === 0,
      rowData: selectedRows,
      rowIds: selectedRows.map(({ id }) => id),
    }));
  };

  const datasource = useCallback(
    {
      async getRows(dataParams) {
        const { startRow, endRow } = dataParams.request;
        const currentTab = type;
        console.log("Sort Model", dataParams.request);
        const pageSize = endRow - startRow;
        const page = startRow / pageSize + 1;

        const body = {
          page,
          pageSize,
          clientIds: [+id],
          type: type,
          search: getter(getSearchKey),
          status: statusRef.current ? statusRef.current : null,
          sortBy: camelToSnakeCase(
            dataParams.request.sortModel[0]?.colId ?? "id",
          ),
          sortOrder:
            dataParams.request.sortModel.length > 0
              ? sortDict[dataParams.request.sortModel[0]["sort"]]
              : "asc",
        };
        try {
          const res = await Invoice.getAll(body);

          dataParams.success({
            rowData: res.listing,
            rowCount: res.total,
          });
        } catch (err) {
          dataParams.fail();
        }
      },
    },
    [type, refreshTable, selectedRows.shouldUpdate],
  );
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <>
      <div className="d-flex  justify-content-end">
        {isPermissionPresent(K.Permissions.EditInvoice, userRoles) && (
          <div className={`mr-1 ${LayoutCss.invoicesButton}`}>
            <Button
              type="primary"
              onClick={showBulkUpdate}
              disabled={selectedRows.isDisabled}
            >
              Bulk Update
            </Button>
          </div>
        )}
        {type === K.Invoice.Type.Client && (
          <div className={LayoutCss.invoicesButton}>
            <Button type="primary" onClick={show}>
              Submit One Time Billing
            </Button>
          </div>
        )}
      </div>
      <Card
        title="Invoices"
        className={"appCard " + LayoutCss.appCard}
        extra={
          <div className={LayoutCss.appListingCardJobTable}>
            <Search
              allowClear
              value={getter(getSearchKey)}
              onChange={onSearch}
              placeholder="Search"
              className={LayoutCss.appListingCardSearchBar}
            />

            <Select
              allowClear
              showSearch
              value={getter(getFilterKey)}
              placeholder="Status"
              suffixIcon={<i className="icon-Vector" />}
              className={LayoutCss.appListingCardStatusSelect1}
              optionFilterProp="label"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={Object.keys(K.Invoice.Status).map((item) => ({
                value: K.Invoice.Status[item],
                label: item,
              }))}
              onChange={externalFilterChanged}
            />
          </div>
        }
        style={{ marginTop: "20px" }}
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 675,
          }}
        >
          <AgGridReact
            ref={gridRef}
            // rowData={rowData}
            suppressRowClickSelection
            rowSelection="multiple"
            columnDefs={columnDefs}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onRowClicked={rowClickedHandler}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            {...K.AgGridTable.DefaultProps}
            onSelectionChanged={onSelectionChanged}
            quickFilterText={getter(getSearchKey)}
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            loadingCellRenderer={false}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            serverSideDatasource={datasource}
          />
        </div>
      </Card>
      <SubmitOneTimeBilling
        selectedClientId={id}
        isOpen={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <BulkUpdateInvoice
        isOpen={selectedRows.isModalVisible}
        setIsModalVisible={setSelectedRows}
        modalData={selectedRows}
      />
    </>
  );
}
