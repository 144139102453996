import { Button, Image, Layout } from "antd";
import Logo from "assets/images/logo.svg";
import { Link } from "react-router-dom";
import styles from "./layout.module.scss";
import SiderPopover from "./siderPopover";

export default function Header({ toggle }) {
  const { Header } = Layout;
  return (
    <Header className={styles.responsiveLayoutHeader}>
      <Button
        type="primary"
        icon={<i className="icon-bars-solid"></i>}
        onClick={toggle}
        className="responsiveCollapedButton"
        size="large"
      />
      <Link to="/" className="d-flex align-items-center">
        <Image src={Logo} preview={false} />
      </Link>
      <SiderPopover />
    </Header>
  );
}
