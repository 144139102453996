import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Typography,
} from "antd";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";
import "./oneTimeComponent.scss";

const { Text } = Typography;

export default function ClientLevelOneTime({ fieldObj, index, currencyList }) {
  return (
    <Form.List name={fieldObj.id} initialValue={[{ alias: "" }]}>
      {(fields, { add, remove }) => (
        <Form.Item key={index} className="mb-0 oneTimeComponentWrap">
          <div className="oneTimeHeader">
            <Text className="oneTimeTitle">{fieldObj.name}</Text>
            <Button size="small" className="addBtn" onClick={add}>
              <i className="icon-plus"></i>
            </Button>
          </div>
          <Divider className="headerDvider" />
          {fields.map((field, index) => (
            <div key={index} className="oneTimeData">
              {index > 0 && (
                <Popconfirm
                  title="Are you sure ?"
                  onConfirm={() => {
                    remove(field.name);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="text-right">
                    <Button danger icon={<i className="icon-delet"></i>} />
                  </div>
                </Popconfirm>
              )}
              <Row gutter={[12, 0]}>
                <Col xs={24}>
                  <Form.Item
                    label="Description"
                    name={[index, "alias"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter description!",
                      },
                      noTrailingSpaceAllowedRule(),
                    ]}
                  >
                    <Input className="w-100" placeholder="Description" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={16} lg={16}>
                  <Form.Item
                    label="Amount"
                    name={[index, "amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount!",
                      },
                    ]}
                  >
                    <InputNumber
                      className="w-100"
                      placeholder="Amount"
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} lg={8}>
                  <Form.Item
                    label=" "
                    name={[index, "currency"]}
                    initialValue="USD"
                  >
                    <Select
                      showSearch={false}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Currency"
                      options={currencyList.map(({ code }) => ({
                        value: code,
                        label: code,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider dashed className="oneTimeDvider" />
            </div>
          ))}
        </Form.Item>
      )}
    </Form.List>
  );
}
