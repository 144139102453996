import { Button, Card, Input, PageHeader, Select, message } from "antd";
import LookupTableComponent from "common/tableComponents/lookupTable";
import { clientTabsConfig } from "config/tabsConfig";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { onFilterTextBoxChanged } from "utilities/tableUtility";

import ClientLookupTableModal from "./clientLookupTableModal";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;

export default function ClientLookupTableWidget({ clientId }) {
  const gridRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const lookData = useSelector(selectConfigration).lookup;
  const userSlice = useSelector(selectUser);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [sorting, setSorting] = useState({
    isSortChange: false,
    sortedList: [],
    shouldReinitialize: false,
  });

  let searchPrefix = `clientLookUp_${clientId}`;
  let getSearchKey = `clientLookUp_${clientId}_search`;
  let filterPrefix = `clientLookUp_${clientId}`;
  let getFilterKey = `clientLookUp_${clientId}_status`;

  const [setter, getter] = useSearchAndFilter();

  const statusRef = useRef(getter(getFilterKey));
  const searchRef = useRef(null);

  const actionRef = useRef({ isEditable: false });
  const isVisibleRender = (params) => {
    return params.data.isVisible === 0 ? "Yes" : "No";
  };
  const tooltipIsVisibleRender = (params) => (
    <div className="client-list-tooltip">
      {params.data.isVisible === 0 ? "Yes" : "No"}
    </div>
  );

  const userNameRender = (params) => {
    return params.data.userName ? params.data.userName : "N/A";
  };

  const tooltipUserNameRender = (params) => {
    return (
      <div className="client-list-tooltip">
        {params.data.userName ? params.data.userName : "N/A"}
      </div>
    );
  };

  const updatedAtRender = (params) => {
    return convertIntoDashUSFormat(params.data.updatedAt, true);
  };
  const tooltipUpdatedAtRender = (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.data.updatedAt, true)}
    </div>
  );
  useEffect(() => {
    if (getter(getFilterKey) && !params.get("table")) {
      params.set("table", getter(getFilterKey));
      history.push({ search: params.toString() });
    }

    const currentKey = params.get("table");
    if (!(currentKey in clientTabsConfig)) {
      history.push({
        search: `?tab=${params.get("tab")}&table=${
          K.LookupTabKeys.JobLocations
        }`,
      });
      setter({ status: K.LookupTabKeys.JobLocations }, filterPrefix);
    }

    currentKey && getLookupData(clientTabsConfig[currentKey]);
  }, [params.get("table")]);

  const getLookupData = async (tab) => {
    try {
      await dispatch(
        LookupTable.getData(tab.reduxKey, tab.apiEndPoint + `/${clientId}`),
      );
    } catch (error) {
      console.error(error);
    }
  };

  /* const removeLookupData = async (params) => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentKey = urlParams.get("tab");
    const currentTab = tabsConfig[currentKey];
    try {
      await dispatch(
        LookupTable.deleteAction(
          currentTab.reduxKey,
          currentTab.apiEndPoint,
          params.data.id
        )
      );
    } catch (error) {
      console.error(error);
    }
  }; */

  const addNewRecord = () => {
    setEditData({});
    setIsModalVisible(true);
  };
  const editRecord = (params) => {
    setEditData(params.data);
    setIsModalVisible(true);
  };

  const actionColumnRenderer = (params) => (
    <>
      <Button
        type="link"
        className={LayoutCss.appListingCardActions}
        onClick={() => editRecord(params)}
      >
        Edit
      </Button>

      {/* <Button
        type="link"
        className={LayoutCss.appListingCardActions}
        onClick={() => removeLookupData(params)}
      >
        Delete
      </Button> */}
    </>
  );

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      rowDrag: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
    },
    {
      headerName: "Archived",
      field: "isVisible",
      sortable: true,
      tooltipField: "isVisible",
      tooltipComponent: tooltipIsVisibleRender,
      cellRenderer: isVisibleRender,
    },
    {
      headerName: "Updated By",
      field: "userName",
      sortable: true,
      tooltipField: "userName",
      cellRenderer: userNameRender,
      tooltipComponent: tooltipUserNameRender,
    },

    {
      headerName: "Updated At",
      field: "updatedAt",
      sortable: true,
      tooltipField: "updatedAt",
      cellRenderer: updatedAtRender,
      tooltipComponent: tooltipUpdatedAtRender,
    },
    ...(isPermissionPresent(
      K.Permissions.EditClientLookupTables,
      userSlice.roles,
    )
      ? [
          {
            headerName: "Actions",
            field: "actions",
            sortable: true,
            resizable: false,
            headerClass: "text-center",
            cellStyle: { textAlign: "center" },
            cellRenderer: actionColumnRenderer,
          },
        ]
      : []),
  ]);
  const onChange = (key) => {
    params.set("table", key);
    setSorting((prev) => {
      return { ...prev, isSortChange: false, sortedList: [] };
    });
    setter({ status: key }, filterPrefix);
    history.push({ search: params.toString() });
  };

  const searchHandler = (event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);
    setter({ search: event.target.value }, searchPrefix);
  };

  const saveSort = useCallback(async () => {
    try {
      // const colState = gridRef.current.columnApi.getColumnState();
      const updatedRows = [];
      if (gridRef) {
        gridRef.current.api.forEachNodeAfterFilter((row, index) => {
          const { data, rowIndex } = row;
          updatedRows.push({ id: data.id, orderId: rowIndex });
        });

        let searchParams = new URLSearchParams(window.location.search);

        const payload = {
          table: clientTabsConfig[searchParams.get("table")]?.table,
          rowsToOrder: updatedRows,
        };

        const res = await LookupTable.saveSorting(payload);
        message.success("Sorting Saved Successfully");

        setSorting((prev) => {
          return {
            ...prev,
            isSortChange: false,
            shouldReinitialize: !prev.shouldReinitialize,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const clearFilters = () => {
    if (gridRef) {
      gridRef.current.api.setRowData(
        lookData[clientTabsConfig[params.get("table")]?.reduxKey],
      );
      gridRef.current.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
      setSorting((prev) => {
        return { ...prev, isSortChange: false };
      });
    }
  };

  return (
    <>
      <div className={LayoutCss.invoicesButton + " mb-3"}>
        <Select
          showSearch
          allowClear
          size="middle"
          className={LayoutCss.clientLookupSelect}
          optionFilterProp="label"
          onChange={onChange}
          value={getter(getFilterKey)}
          options={Object.keys(clientTabsConfig).map((key) => ({
            value: key,
            label: clientTabsConfig[key].name,
          }))}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
      </div>

      <Card
        title={clientTabsConfig[params.get("table")]?.name}
        className={"appCard " + LayoutCss.appCard}
        extra={
          <div>
            {" "}
            <div className={LayoutCss.appListingCardJobTable}>
              {sorting.isSortChange && (
                <div style={{ display: "flex", marginRight: 10 }}>
                  <Button
                    type="primary"
                    onClick={saveSort}
                    className={LayoutCss.appListingCardLookUpButton}
                  >
                    Save Sort
                  </Button>
                  <Button onClick={clearFilters} className={"clearSort"}>
                    Clear Sort
                  </Button>
                </div>
              )}
              <Search
                allowClear
                value={getter(getSearchKey)}
                placeholder="Search"
                onChange={searchHandler}
                className={LayoutCss.appListingCardSearchBar}
              />
              {isPermissionPresent(
                K.Permissions.EditClientLookupTables,
                userSlice.roles,
              ) && (
                <Button
                  type="primary"
                  onClick={addNewRecord}
                  className={LayoutCss.appListingCardLookUpButton}
                >
                  Add New
                </Button>
              )}
            </div>
          </div>
        }
      >
        <LookupTableComponent
          rowData={lookData[
            clientTabsConfig[params.get("table")]?.reduxKey
          ]?.filter((item) => parseInt(item.clientId) === parseInt(clientId))}
          columnDefs={columnDefs}
          setColumnDefs={setColumnDefs}
          actionColumnRenderer={actionColumnRenderer}
          gridRef={gridRef}
          setSorting={setSorting}
          actionRef={actionRef}
          isVisibleRender={isVisibleRender}
          isVisibleTooltipRender={tooltipIsVisibleRender}
          userNameRender={userNameRender}
          tooltipUserNameRender={tooltipUserNameRender}
          updatedAtRender={updatedAtRender}
          tooltipUpdatedAtRender={tooltipUpdatedAtRender}
        />
      </Card>
      <ClientLookupTableModal
        isVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        editData={editData}
        setEditData={setEditData}
        title={clientTabsConfig[params.get("table")]?.name}
        clientId={clientId}
        sorting={sorting}
        clearSort={clearFilters}
      />
    </>
  );
}
