import {
  Button,
  Card,
  Col,
  Form,
  Grid,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Typography,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import md5 from "md5";
import { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  noTrailingSpaceAllowedRule,
  validateMobileNumber,
} from "utilities/generalUtility";
import styles from "./profile.module.scss";

const { useBreakpoint } = Grid;
const { Title, Paragraph } = Typography;

export default function Profile() {
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const [changePasswordForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = useSelector(selectUser).details;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    changePasswordForm.submit();
  };

  const handleCancel = () => {
    changePasswordForm.resetFields();
    setIsModalOpen(false);
  };

  const afterModalClose = () => {
    changePasswordForm.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      fullname: userData?.name,
      phonenumber: userData?.phone,
      email: userData?.email,
    });
  });

  const onFinish = async (values) => {
    const body = {
      userId: userData.id,
      name: values.fullname,
      phone: values.phonenumber,
    };
    try {
      await dispatch(User.editProfile(body));
      message.success("Profile updated successfully !");
    } catch (err) {
      console.error(err);
    }
  };
  const changePasswordHandler = async (values) => {
    setIsLoading(true);
    const encryptedPasswords = {
      oldPassword: md5(values.oldPassword),
      password: md5(values.password),
    };
    try {
      await User.changePassword(encryptedPasswords);
      setIsLoading(false);
      setIsModalOpen(false);
      message.success("Password changed successfully !");
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  return (
    <>
      <PageHeader
        ghost={false}
        className={LayoutCss.appPageHeader}
        title="Profile"
      />
      <Card
        className={styles.profileCardWrapper}
        bodyStyle={{ padding: screens["xs"] ? "2rem" : "46px 56px" }}
      >
        <Title level={4} className={styles.biHeading}>
          Basic Information
        </Title>
        <Paragraph className={styles.biText}>
          This is your personal information that you can update anytime.
        </Paragraph>
        {/* <div className={`${styles.pInfoWrapper} ${styles.profilePhotoRow}`}>
          <div className={styles.headingsWrapper}>
            <Title level={4} className={styles.biHeading}>
              Profile Photo
            </Title>
            <Paragraph className={styles.biText}>
              This image will be shown publicly as your profile picture, it will
              help recruiters recognize you!
            </Paragraph>
          </div>
          <div className={styles.pFromWrapper}>
            <div className="profile-image-wrapper">
              <Avatar size={100} icon={<UserOutlined />} />
            </div>
          </div>
        </div> */}
        <div className={styles.pInfoWrapper}>
          <div className={styles.headingsWrapper}>
            <Title level={4} className={styles.biHeading}>
              Personal Details
            </Title>
          </div>
          <div className={styles.pFromWrapper}>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="required-mark-right"
                    label="Full Name"
                    name="fullname"
                    rules={[
                      {
                        required: true,
                        message: "Please input your fullname!",
                      },
                      noTrailingSpaceAllowedRule(),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} xl={12}>
                  <Form.Item
                    className="required-mark-right"
                    label="Phone Number"
                    name="phonenumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || validateMobileNumber(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Please enter correct number",
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <PatternFormat
                      placeholder="Enter Phone Number"
                      customInput={Input}
                      format={K.PhoneFormat.USNational}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} xl={12}>
                  <Form.Item
                    className="required-mark-right"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={{ span: 12, offset: 12 }}>
                  <Form.Item noStyle>
                    <Button
                      type={"ghost"}
                      onClick={showModal}
                      className={styles.changePasswordBtn}
                    >
                      Change Password
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Button
                type="primary"
                className={styles.saveProfileBtn}
                htmlType="submit"
              >
                Save Profile
              </Button>
            </Form>
          </div>
        </div>

        <Modal
          centered
          open={isModalOpen}
          okText="Confirm"
          wrapClassName="vertical-center-modal"
          closeIcon={<i className="icon-closeable"></i>}
          width={462}
          title="Change Password"
          className="s2-theme-style billingClient"
          okButtonProps={{ loading: isLoading }}
          onOk={handleOk}
          onCancel={handleCancel}
          afterClose={afterModalClose}
        >
          <Form
            layout="vertical"
            form={changePasswordForm}
            onFinish={changePasswordHandler}
          >
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[
                { required: true, message: "Old Password is required." },
                { min: 8, message: "Password must be minimum 8 characters." },
              ]}
            >
              <Input.Password placeholder="Enter" size={"medium"} />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                { required: true, message: "New Password is required" },
                { min: 8, message: "Password must be minimum 8 characters." },
              ]}
            >
              <Input.Password placeholder="Enter" size={"medium"} />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmpassword"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Confirm Password is required" },
                { min: 8, message: "Password must be minimum 8 characters." },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered does not match.",
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Enter" size={"medium"} />
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </>
  );
}
