import {
  Button,
  Card,
  Collapse,
  Form,
  Modal,
  Popconfirm,
  Divider,
  Typography,
  Row,
  Col,
} from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import { JobBillingRulesForm } from "features/clients/create/jobBillingRulesForm";
import TestBillingRule from "features/clients/testBillingRules/testBillingRules";
import LayoutCss from "layout/layout.module.scss";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Client from "redux/models/client";
import { selectClient, setIsTestPassed } from "redux/slices/clientSlice";
import {
  convertIntoUtcLocalTime,
  epochTimeToDashUSFormat,
} from "utilities/dateUtility";
import {
  deleteKeyFromObject,
  formatJobRulesEntries,
  stopPropagationFunction,
} from "utilities/generalUtility";
import ClientAndJobBillingComponent from "./clientAndJobBillingComponent";

const { Panel } = Collapse;
const { Text } = Typography;

export default function JobUpcomingRule({
  tierData,
  setPageStates,
  configrationSlice,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const selectedRuleId = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isTestPassed = useSelector(selectClient).isTestPassed;

  const showEditModal = (index, ruleId) => {
    selectedRuleId.current = ruleId;
    populateFormValues(index);
    setIsModalVisible(true);
  };

  const populateFormValues = (index) => {
    const formValues = formatJobRulesEntries(tierData.upcomingRules[index]);
    form.setFieldsValue(formValues);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const afterModalClose = () => {
    dispatch(setIsTestPassed(false));
    form.resetFields();
  };

  const confirm = async (jobBillingRuleId) => {
    try {
      const data = await Client.abortJobBillingRule(jobBillingRuleId);
      if (data.message) {
        setPageStates((prevRule) => {
          const upcomingRules = prevRule.tierData.upcomingRules.filter(
            (item) => item.id !== jobBillingRuleId,
          );
          return {
            ...prevRule,
            tierData: { ...tierData, upcomingRules: upcomingRules },
          };
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onFinish = async (values) => {
    const jobLevelBilligRulesValues = values.billingTypes.reduce(
      (prev, curr) => {
        const ruleObj = values[curr];
        ruleObj.jobBillingTypesId = curr;
        return [
          ...prev,
          {
            ...ruleObj,
            endAfterOccurances:
              ruleObj.isInfinite === 1 ? 0 : ruleObj.endAfterOccurances,
          },
        ];
      },
      [],
    );
    values.billingTypes.forEach((typeId) => {
      values = deleteKeyFromObject(values, typeId);
    });
    values = deleteKeyFromObject(values, "billingTypes");
    const body = {
      clientId: id,
      jobTierId: tierData.id,
      billingRuleId: selectedRuleId.current,
      effectiveSince: values["effectiveSince"].unix(),
      jobLevelBillingRules: [{ jobLevelBilligRulesValues }],
    };
    setIsLoading(true);
    try {
      await Client.editUpcomingJobBillingRule(body);
      setIsModalVisible(false);
      setPageStates((prev) => ({
        ...prev,
        render: !prev.render,
      }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      {tierData?.upcomingRules.length === 0 ? (
        <Card
          title="Upcoming"
          bordered={false}
          className={`${styles.collapseCard} {LayoutCss.appListingCard}`}
          style={{ marginTop: "20px" }}
        >
          <EmptyPlaceholder />
        </Card>
      ) : (
        <Card
          title="Upcoming Rule"
          bordered={false}
          className={`${styles.collapseCard}  appListingCard`}
          style={{ marginTop: "20px" }}
          bodyStyle={{ paddingTop: 0 }}
        >
          {tierData?.upcomingRules.map(
            (item, i) =>
              item.billingRuleEntries.length !== 0 && (
                <Collapse
                  key={item.id}
                  defaultActiveKey={["0"]}
                  expandIconPosition="end"
                  bordered={false}
                  className={
                    LayoutCss.appListingCardPanel + " appListingCardPanel"
                  }
                >
                  <Panel
                    header={
                      <Row>
                        <Col xs={24} sm={4}>
                          <Text className="upcoming-date">
                            <span>Created Date:</span>

                            {convertIntoUtcLocalTime(item.createdAt)}
                          </Text>
                        </Col>
                        <Divider type="vertical" />
                        <Col xs={24} sm={4}>
                          <Text className="upcoming-date">
                            <span>Effective Date:</span>{" "}
                            {epochTimeToDashUSFormat(item.startDate)}
                          </Text>
                        </Col>
                        <Divider type="vertical" />
                        <Col xs={24} sm={3}>
                          <Text className="upcoming-date">
                            <span>Guarantee Days:</span>{" "}
                            {item.guaranteeDays ?? 0}
                          </Text>
                        </Col>
                        <Divider type="vertical" />
                        <Col xs={24} sm={3}>
                          <Text className="upcoming-date">
                            <span>Working Days:</span>{" "}
                            {item.isWorkingDays ? "Yes" : "No"}
                          </Text>
                        </Col>
                        <Divider type="vertical" />
                        <Col xs={24} sm={4}>
                          <Text className="upcoming-date">
                            <span>Updated By:</span> {item.user?.name ?? "N/A"}
                          </Text>
                        </Col>
                      </Row>
                    }
                    key="1"
                    className={LayoutCss.appListingCardPanelBorder}
                    extra={
                      <div onClick={stopPropagationFunction}>
                        <Button
                          type="link"
                          icon={<i className="icon-edit"></i>}
                          onClick={() => showEditModal(i, item.id)}
                        />
                        <Popconfirm
                          title="Are you sure to delete this record?"
                          onConfirm={() => {
                            confirm(item.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="link"
                            icon={
                              <i
                                className={"icon-delet " + styles.deletIcon}
                              ></i>
                            }
                          />
                        </Popconfirm>
                      </div>
                    }
                  >
                    <Card className={styles.clientBillingRulesCard}>
                      <ClientAndJobBillingComponent currentRule={item} />
                    </Card>
                  </Panel>
                </Collapse>
              ),
          )}
        </Card>
      )}

      <Modal
        width={568}
        centered
        className={styles.addButtonModule + " modal-overflow-auto"}
        open={isModalVisible}
        closable={false}
        onCancel={handleCancel}
        destroyOnClose
        afterClose={afterModalClose}
        footer={
          <>
            <Button
              className={styles.cancelBillingRules}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={form.submit}
              disabled={!isTestPassed}
            >
              Save
            </Button>
          </>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <JobBillingRulesForm
            form={form}
            styles={styles}
            currencyList={configrationSlice.currencies}
            showEffectiveSince={true}
            billingJobsList={configrationSlice.billingJobs.filter(
              ({ isAssignable }) => isAssignable,
            )}
          />
          <TestBillingRule form={form} />
        </Form>
      </Modal>
    </>
  );
}
