import { Row, Col, Typography, Divider } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import K from "utilities/constants";
import { displayDollar, displayPercentage } from "utilities/tableUtility";

const { Title, Paragraph } = Typography;

export default function CommissionPlansDetailComponent({ plans }) {
  return (
    <>
      <div className="commission-default-card">
        <h1>Default Plans</h1>
        <Row gutter={50}>
          <Col lg={6}>
            <Title level={5} className={styles.cbrLabel}>
              Default Billing Threshold
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.thresholdType ===
              K.CommissionThresholdType.Amount
                ? displayDollar(plans.defaultPlan.plan.defaultBillingThreshold)
                : `${plans.defaultPlan.plan.defaultBillingThreshold} hires`}
            </Paragraph>
          </Col>

          <Col lg={6}>
            <Title level={5} className={styles.cbrLabel}>
              Applied Hires
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.appliedHireUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.appliedHire)
                : displayPercentage(plans.defaultPlan.plan.appliedHire)}
            </Paragraph>
          </Col>

          <Col lg={6}>
            <Title level={5} className={styles.cbrLabel}>
              Sourced Hires
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.sourceHiredUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.sourceHired)
                : displayPercentage(plans.defaultPlan.plan.sourceHired)}
            </Paragraph>
          </Col>
        </Row>

        <Row gutter={50}>
          <Col lg={6}>
            <Title level={5} className={styles.cbrLabel}>
              Referrals
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.referralUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.referral)
                : displayPercentage(plans.defaultPlan.plan.referral)}
            </Paragraph>
          </Col>

          <Col lg={6}>
            <Title level={5} className={styles.cbrLabel}>
              Cancellations
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.cancellationsUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.cancellations)
                : displayPercentage(plans.defaultPlan.plan.cancellations)}
            </Paragraph>
          </Col>
          <Col lg={6}>
            <Title level={5} className={styles.cbrLabel}>
              Hiring Events
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.hiringEventUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.hiringEvent)
                : displayPercentage(plans.defaultPlan.plan.hiringEvent)}
            </Paragraph>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Rehire Referral
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.reHireReferralUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.reHireReferral)
                : displayPercentage(plans.defaultPlan.plan.reHireReferral)}
            </Paragraph>
          </Col>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Rehire Applied
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.reHireAppliedUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.reHireApplied)
                : displayPercentage(plans.defaultPlan.plan.reHireApplied)}
            </Paragraph>
          </Col>
          <Col xs={24} sm={12} md={8} xl={6}>
            <Title level={5} className={styles.cbrLabel}>
              Rehire Sourced
            </Title>
            <Paragraph className={styles.cbrValue}>
              {plans.defaultPlan.plan.reHireSourcedUnit === K.Format.Dollar
                ? displayDollar(plans.defaultPlan.plan.reHireSourced)
                : displayPercentage(plans.defaultPlan.plan.reHireSourced)}
            </Paragraph>
          </Col>
        </Row>
      </div>

      {plans.clinetException.map((item, index) => (
        <div key={index} className="commission-client-card">
          <h1>{item.plan.client.name}</h1>
          <Row gutter={50}>
            <Col lg={6}>
              <Title level={5} className={styles.cbrLabel}>
                Applied Hires
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.appliedHireUnit === K.Format.Dollar
                  ? displayDollar(item.plan.appliedHire)
                  : displayPercentage(item.plan.appliedHire)}
              </Paragraph>
            </Col>

            <Col lg={6}>
              <Title level={5} className={styles.cbrLabel}>
                Sourced Hires
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.sourceHiredUnit === K.Format.Dollar
                  ? displayDollar(item.plan.sourceHired)
                  : displayPercentage(item.plan.sourceHired)}
              </Paragraph>
            </Col>

            <Col lg={6}>
              <Title level={5} className={styles.cbrLabel}>
                Referrals
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.referralUnit === K.Format.Dollar
                  ? displayDollar(item.plan.referral)
                  : displayPercentage(item.plan.referral)}
              </Paragraph>
            </Col>
          </Row>
          <Row gutter={50}>
            <Col lg={6}>
              <Title level={5} className={styles.cbrLabel}>
                Cancellations
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.cancellationsUnit === K.Format.Dollar
                  ? displayDollar(item.plan.cancellations)
                  : displayPercentage(item.plan.cancellations)}
              </Paragraph>
            </Col>
            <Col lg={6}>
              <Title level={5} className={styles.cbrLabel}>
                Hiring Events
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.hiringEventUnit === K.Format.Dollar
                  ? displayDollar(item.plan.hiringEvent)
                  : displayPercentage(item.plan.hiringEvent)}
              </Paragraph>
            </Col>

            <Col xs={24} sm={12} md={8} xl={6}>
              <Title level={5} className={styles.cbrLabel}>
                Rehire Referral
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.rehireReferralUnit === K.Format.Dollar
                  ? displayDollar(item.plan.reHireReferral)
                  : displayPercentage(item.plan.reHireReferral)}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={8} xl={6}>
              <Title level={5} className={styles.cbrLabel}>
                Rehire Applied
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.rehireAppliedUnit === K.Format.Dollar
                  ? displayDollar(item.plan.reHireApplied)
                  : displayPercentage(item.plan.reHireApplied)}
              </Paragraph>
            </Col>
            <Col xs={24} sm={12} md={8} xl={6}>
              <Title level={5} className={styles.cbrLabel}>
                Rehire Sourced
              </Title>
              <Paragraph className={styles.cbrValue}>
                {item.plan.rehireSourcedUnit === K.Format.Dollar
                  ? displayDollar(item.plan.reHireSourced)
                  : displayPercentage(item.plan.reHireSourced)}
              </Paragraph>
            </Col>
          </Row>
          <Divider />
        </div>
      ))}
    </>
  );
}
