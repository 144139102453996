import { Card, Collapse } from "antd";
import styles from "assets/scss/jobAndClientBillingRules.module.scss";
import LayoutCss from "layout/layout.module.scss";
import CommissionPlansDetailComponent from "./commissionPlansDetailComponent.jsx";
import { epochTimeToDashUSFormat } from "utilities/dateUtility";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder.jsx";

const { Panel } = Collapse;

export default function HistoryCommissionPlans({ plansDetails }) {
  return (
    <>
      {plansDetails.histroyRules.length === 0 ? (
        <Card
          title="History"
          bordered={false}
          className={`${styles.collapseCard} ${LayoutCss.appListingCard}`}
          style={{ marginTop: "20px" }}
        >
          <EmptyPlaceholder />
        </Card>
      ) : (
        <Card
          title="History Plans"
          bordered={false}
          className={LayoutCss.appListingCard}
          style={{ marginTop: "20px" }}
        >
          {plansDetails.histroyRules.map((item, index) => (
            <Collapse
              key={index}
              defaultActiveKey={["0"]}
              expandIconPosition="end"
              bordered={false}
            >
              <Panel
                header={epochTimeToDashUSFormat(item.defaultPlan.startDate)}
                key="1"
              >
                <Card className={styles.clientBillingRulesCard}>
                  <CommissionPlansDetailComponent plans={item} />
                </Card>
              </Panel>
            </Collapse>
          ))}
        </Card>
      )}
    </>
  );
}
