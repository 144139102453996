import NetworkCall from "network/networkCall";
import Request from "network/request";

import { camelCaseKeys } from "utilities/generalUtility";

export default class RoleAndPermission {
  static async getAllRoles() {
    const res = await NetworkCall.fetch(Request.getAllRoles());
    return res.data.map((obj) => camelCaseKeys(obj));
  }

  static async getAllPermissions() {
    const res = await NetworkCall.fetch(Request.getAllPermissions());
    return res.data.map((obj) => camelCaseKeys(obj));
  }

  static async createRole(data) {
    const res = await NetworkCall.fetch(Request.createRole(data), false);
    return camelCaseKeys(res.data);
  }

  static async getRoleById(id) {
    const res = await NetworkCall.fetch(Request.getRoleById(id));
    return camelCaseKeys(res.data);
  }

  static async updateRole(id, data) {
    const res = await NetworkCall.fetch(Request.updateRole(id, data));
    return camelCaseKeys(res.data);
  }
}
