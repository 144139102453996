import NetworkCall from "network/networkCall";
import Request from "network/request";
import { camelCaseKeysRecursively } from "utilities/generalUtility";

export default class BillingApproval {
  static async getClientSubmittedBillings(clientIds) {
    const res = await NetworkCall.fetch(
      Request.getClientSubmittedBillings(clientIds),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getSubmittedBillHistory(payload) {
    const res = await NetworkCall.fetch(
      Request.getSubmittedBillHistory(payload),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async editJobSubmittedBilling(payload) {
    const res = await NetworkCall.fetch(
      Request.editJobSubmittedBilling(payload),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async editClientSubmittedBilling(payload) {
    const res = await NetworkCall.fetch(
      Request.editClientSubmittedBilling(payload),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async currencyConversion(payload) {
    const res = await NetworkCall.fetch(
      Request.currencyConversion(payload),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async generateInvoice(payload) {
    const res = await NetworkCall.fetch(
      Request.generateBillingInvoice(payload),
    );
    return res;
  }

  static async issueJobCredit(body) {
    const res = await NetworkCall.fetch(Request.issueJobCredit(body));
    return res;
  }
  //Recalcuate offer salary Billing
  static async submitReCalculateBilling(
    billingRuleEntryId,
    candidateJobEntryId,
    salary,
  ) {
    const res = await NetworkCall.fetch(
      Request.submitReCalculateBillings(
        billingRuleEntryId,
        candidateJobEntryId,
        salary,
      ),
    );
    return camelCaseKeysRecursively(res.data);
  }

  //Runtime calcuate offer salary Billing
  static async submitRunTimeCalculation(
    billingRuleEntryId,
    candidateJobEntryId,
    salary,
  ) {
    const res = await NetworkCall.fetch(
      Request.submitRunTimeCalculation(
        billingRuleEntryId,
        candidateJobEntryId,
        salary,
      ),
    );
    return camelCaseKeysRecursively(res.data);
  }

  //Calcuate discount
  static async calculateDiscount(body) {
    const res = await NetworkCall.fetch(Request.submitCalculateDiscount(body));
    return camelCaseKeysRecursively(res.data);
  }
}
