import { Form, TimePicker } from "antd";
import moment from "moment-business-days";
import { useRef } from "react";
import K from "utilities/constants";
export default function UDFTimePicker({
  title = "Title",
  isFormItem = true,
  value,
  className,
  placeholder = "",
  formItemName = "",
  required = true,
  params,
}) {
  const dateRef = useRef(null);
  const onChange = (dateObj) => {
    dateRef.current = dateObj ? dateObj.format(K.TimeFomat.Response) : null;
  };
  return (
    <>
      {isFormItem ? (
        <Form.Item
          className={className}
          label={title}
          name={formItemName}
          initialValue={moment()}
          rules={required && [{ required: true, message: `Enter  ${title}` }]}
        >
          <TimePicker
            {...params}
            // use12Hours
            format={K.TimeFomat.Display}
            className={""}
            onChange={onChange}
            placeholder={placeholder}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </Form.Item>
      ) : (
        <TimePicker
          use12Hours
          onChange={onChange}
          {...params}
          format={K.TimeFomat.Display}
          className={""}
          placeholder={placeholder}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
      )}
    </>
  );
}
