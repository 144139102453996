import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Typography,
  Dropdown,
  Tag,
  Drawer,
} from "antd";
import { SettingFilled } from "@ant-design/icons";
import AddJobCandidate from "features/candidates/addJobCandidate";
import CandidateListing from "features/candidates/candidateListing";
import ViewActivity from "features/candidates/viewActivity";
import LayoutCss from "layout/layout.module.scss";
import { groupBy, map, debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Job from "redux/models/job";
import LookupTable from "redux/models/lookupTable";
import Status from "redux/models/status";

import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";

import CancelJobForm from "../details/jobs/cancelJobForm";
import JobSettingModal from "../details/jobs/jobSettingsModal";
import styles from "./clientsJob.module.scss";
import "./jobCard.scss";
import JobStatistics from "./jobStatistics";

import SubmitCancelBillingForm from "../details/jobs/submitCancelBilling";
import { setCandidateUdf } from "redux/slices/udfSlice";
import UDF from "redux/models/udf";
import ChangeCandidateStatus from "features/candidates/changeCandidateStatus";

import "./job.scss";
import { Content } from "antd/lib/layout/layout";
import { convertIntoDashUSFormat } from "utilities/dateUtility";

const { Search } = Input;
const { Title, Text } = Typography;
const { Lookup } = K.Network.URL;

export default function ClientJobs() {
  const gridRef = useRef();
  const searchRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId, jobId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);

  const [settingsForm] = Form.useForm();
  const [candidateForm] = Form.useForm();
  const [cancelJobForm] = Form.useForm();
  const [cancelBillingForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const userSlice = useSelector(selectUser);
  const [refreshTable, setRefreshTable] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    pageNo: 1,
    currentCount: 0,
    shouldUpdate: false,
  });

  const [candidateActivity, setCandidateActivity] = useState(null);
  const [modalVisible, setModalVisible] = useState({
    editCandidateId: null,
    editCandidateEntryId: null,
    isSettingModalVisible: false,
    isJobCancelModalVisible: false,
    isCandidateModalVisible: false,
    isCancelBillingModalVisible: false,
    isCandidateStatusChangeModalVisible: false,
  });
  const [jobData, setJobData] = useState({
    jobDetails: null,
    statistics: null,
    status: [],
    subStatus: {},
    settings: null,
    shouldUpdate: false,
    shouldUpdateListing: false,
    moveCandidates: false,
  });
  const jobStatus = {
    open: "open-flag",
    closed: "closed-flag",
    "on hold": "onhold-flag",
    custom: "blue-flag",
  };
  const jobStatusClass = {
    open: "open-dot",
    closed: "close-dot",
    "on hold": "hold-dot",
    cancel: "cancel-dot",
    filled: "filled-dot",
    custom: "blue-dot",
  };

  const [selectedCandidate, setSelectedCandidate] = useState({
    isDisabled: true,
    candidateJobEntryIds: [],
  });

  const lookupTables = useSelector(selectConfigration).lookup;

  const showModalCancelJob = () => {
    setModalVisible((prev) => {
      return { ...prev, isJobCancelModalVisible: true };
    });
  };

  const showModalCancelBilling = () => {
    setModalVisible((prev) => {
      return { ...prev, isCancelBillingModalVisible: true };
    });
  };
  const showModalChangeCandidateStatus = () => {
    setModalVisible((prev) => {
      return { ...prev, isCandidateStatusChangeModalVisible: true };
    });
  };
  const showModalAddCandidate = () => {
    candidateForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isCandidateModalVisible: true, editCandidateId: null };
    });
  };

  const handleOkCancelJob = () => {
    cancelJobForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isJobCancelModalVisible: false };
    });
  };
  const addCandidateOk = () => {
    candidateForm.submit();
  };

  const handleCancelJobCancel = () => {
    cancelJobForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isJobCancelModalVisible: false };
    });
  };

  const handleCancelBilling = () => {
    cancelBillingForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isCancelBillingModalVisible: false };
    });
  };
  const handleCancelBillingOk = () => {
    cancelBillingForm.resetFields();
    setModalVisible((prev) => {
      return { ...prev, isCancelBillingModalVisible: false };
    });
  };

  const addCandidateCancel = () => {
    setModalVisible((prev) => {
      return { ...prev, isCandidateModalVisible: false };
    });
  };

  const getJobStatus = async () => {
    try {
      await dispatch(LookupTable.getData(K.Redux.JobStatus, Lookup.JobStatus));
    } catch (err) {
      console.error(err);
    }
  };
  const getStatusBreakdown = async () => {
    try {
      const res = await Status.getSubStatusBreakdown(jobId);
      const subStatus = groupBy(res, (item) => item.status.name);
      const status = map(subStatus, (item) => {
        const total = item.reduce(
          (prev, curr) => curr.candidatesToSubStatusCount + prev,
          0,
        );
        return { id: item[0].status.id, name: item[0].status.name, total };
      });

      setJobData((prev) => ({ ...prev, status, subStatus }));
    } catch (err) {
      console.error(err);
    }
  };
  const getJobStatistics = async () => {
    try {
      const res = await Job.getStatistics(jobId);
      setJobData((prev) => ({ ...prev, statistics: res }));
    } catch (err) {
      console.error(err);
    }
  };

  const getJobSettings = async () => {
    try {
      const res = await Job.getSettings(jobId, clientId);
      setJobData((prev) => ({ ...prev, settings: res }));
    } catch (err) {
      console.error(err);
    }
  };

  const getJobDetails = async () => {
    try {
      const res = await Job.getById(clientId, jobId);

      setJobData((prev) => ({
        ...prev,
        jobDetails: {
          ...res.jobDetail,
          vacancies: res.jobDetail.vacancies.at(-1),
        },
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const onChange = (event) => {
    searchRef.current = event.target.value;
    setCurrentPage((prev) => ({
      ...prev,
      pageNo: 1,
      shouldUpdate: !prev.shouldUpdate,
    }));
    searchParams.set("pageNo", 1);
    history.push({ search: searchParams.toString() });
  };

  const onStatusChange = async (e) => {
    let statusId = e.key;

    if (statusId === undefined) {
      return null;
    }
    const body = {
      jobSourceId: jobId,
      jobStatusesId: statusId,
    };

    try {
      await Job.updateJobStatus(body);
      // setJobData((prev) => ({
      //   ...prev,
      //   jobDetails: { ...prev.jobDetails, jobStatusesId: statusId },
      // }));
      getJobDetails();
      message.success("Job Status Updated Successfully.");
    } catch (err) {
      console.error(err);
      message.error("Failed to Updated Job Status.");
    }
  };

  const getCandidateUdfs = async (entityKeyId = null) => {
    const candidateUdfs = await UDF.getEditUdfFields({
      clientId: clientId,
      entityId: K.UDF.Entities.Candidate.id,
      entityKey: K.UDF.Entities.Candidate.key,
      entityKeyId: entityKeyId,
      isVisible: 1,
    });

    dispatch(setCandidateUdf(candidateUdfs));
    return candidateUdfs;
  };

  useEffect(() => {
    getJobStatus();
  }, [clientId, jobId]);

  useEffect(() => {
    (async () => {
      await Promise.all([
        getJobDetails(),
        getJobSettings(),
        getStatusBreakdown(),
        // getCandidateUdfs(),
        getJobStatistics(),
      ]);
      setRefreshTable((prev) => !prev);
    })();
  }, [
    jobId,
    clientId,
    jobData.shouldUpdate,
    jobData.shouldUpdateListing,
    // candidatesSlice.shouldUpdate,
  ]);

  const getJobStatusName = (jobStatusId) => {
    return (
      lookupTables.jobStatus?.find((item) => item.id === jobStatusId)?.name ??
      K.NullPlaceholder
    );
  };
  const getJobStatusColor = (jobStatusId) => {
    const status = getJobStatusName(jobStatusId).toLowerCase();
    const statusColor = ["open", "closed", "on hold"].includes(status)
      ? status
      : "custom";

    return statusColor;
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [expanded, setExpanded] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const showDrawer = () => {
    setModalVisible((prev) => {
      return { ...prev, isSettingModalVisible: true };
    });
  };
  const onClose = () => {
    setModalVisible((prev) => {
      return { ...prev, isSettingModalVisible: false };
    });
  };
  const goBack = () => {
    history.push(`/clients/${clientId}?tab=jobs`);
  };

  return (
    <>
      <div className={styles.wrapPageHeader + " job-header-wrap"}>
        <PageHeader
          className={[
            LayoutCss.appPageHeader,
            LayoutCss.clientsJobPageHeader + " client-page-head",
          ]}
          ghost={false}
          onBack={goBack}
          title={
            <>
              <Text>{jobData.jobDetails?.title}</Text>
              <Text
                className={
                  jobStatus[
                    getJobStatusColor(jobData?.jobDetails?.jobStatusesId)
                  ]
                }
              >
                <Dropdown
                  menu={{
                    items: lookupTables.jobStatus.map(({ id, name }) => {
                      return {
                        label: (
                          <a>
                            <span
                              className={`dropdown-dot ${
                                jobStatusClass[getJobStatusColor(id)]
                              }`}
                            ></span>
                            {name}
                          </a>
                        ),
                        key: id,
                      };
                    }),
                    onClick: onStatusChange,
                  }}
                  trigger={["click"]}
                  placement="right"
                  className="job-options-dropdown"
                >
                  <a onClick={toggleDropdown}>
                    <span
                      className={`dropdown-dot ${
                        jobStatusClass[
                          getJobStatusColor(jobData?.jobDetails?.jobStatusesId)
                        ]
                      }`}
                    ></span>
                    {
                      lookupTables.jobStatus?.find(
                        (item) =>
                          item.id === jobData?.jobDetails?.jobStatusesId,
                      )?.name
                    }
                    {dropdownVisible ? (
                      <i className="icon-Vector"></i>
                    ) : (
                      <i className="icon-dropdown-arrow"></i>
                    )}
                  </a>
                </Dropdown>
              </Text>
            </>
          }
          subTitle={
            <>
              <div className="subtitle-wrap">
                <p> {jobData?.jobDetails?.jobLocation?.name}</p>
                <span>-</span>
                <a className="collapse-title-wrap" onClick={handleToggle}>
                  {expanded ? "Collapse Detail" : "Expand Detail"}
                </a>
              </div>
            </>
          }
          extra={
            <>
              {jobData?.jobDetails?.isCancel !== 0 && (
                <div className="alert-wrap">
                  <Alert
                    showIcon
                    type="warning"
                    message={
                      <>
                        Cancel Reason:&nbsp;
                        <Text strong>
                          {jobData?.jobDetails?.cancelledReason ??
                            K.NullPlaceholder}
                        </Text>
                        <br />
                        <a onClick={showModalCancelBilling}>
                          Submit Cancellation Fee
                        </a>
                      </>
                    }
                  />
                </div>
              )}
              <Button
                // onClick={showSettingsModal}
                onClick={showDrawer}
                className={styles.bottomCardIcon}
              >
                <SettingFilled />
              </Button>
            </>
          }
        >
          {expanded && (
            <Content className="collapse-details-wrap">
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={12} lg={6}>
                  <span>Tier </span>
                  <Title level={5}>{jobData.jobDetails?.jobTier?.name}</Title>
                </Col>

                <Col xs={24} sm={12} lg={6}>
                  <span>Job Locations </span>
                  <Title level={5}>
                    {jobData.jobDetails?.jobLocation.name}
                  </Title>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <span>Job Department </span>
                  <Title level={5}>
                    {jobData.jobDetails?.jobDepartment?.name ?? "N/A"}
                  </Title>
                </Col>

                <Col xs={24} sm={12} lg={6}>
                  <span>Recruitment Start Date </span>
                  <Title level={5}>
                    {convertIntoDashUSFormat(
                      jobData.jobDetails?.recruitingStartDate,
                      false,
                    )}
                  </Title>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <span>Min Pay </span>
                  <Title level={5}>
                    ${jobData.jobDetails?.minPay ?? "N/A"}
                  </Title>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <span>Max Pay </span>
                  <Title level={5}>
                    ${jobData.jobDetails?.maxPay ?? "N/A"}
                  </Title>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <span>ATS </span>
                  <Title level={5}>
                    {jobData.jobDetails?.extAtsId ?? "N/A"}
                  </Title>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <span>Work From Home </span>
                  <Title level={5}>
                    {jobData.jobDetails?.isWorkFromHome === 1 ? "Yes" : "No"}
                  </Title>
                </Col>

                <Col xs={24} sm={12} lg={6}>
                  <span className="tag-title">Job Recruiter </span>
                  {jobData.jobDetails?.jobRecruiters?.map((item) => {
                    return (
                      <div className="tag-wrap">
                        <Tag color="blue">{item.recruiter.name ?? "N/A"}</Tag>
                      </div>
                    );
                  })}
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <span className="tag-title">Hiring Managers </span>
                  {jobData.jobDetails?.jobHiringManagers?.map((item) => {
                    return (
                      <div className="tag-wrap">
                        <Tag color="blue">
                          {item.hiringManager.name ?? "N/A"}
                        </Tag>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Content>
          )}
        </PageHeader>
      </div>
      <JobStatistics
        styles={styles}
        jobData={jobData}
        setCurrentPage={setCurrentPage}
      />
      <Row gutter={[16, 16]} className={"job-card-wrap " + styles.jobCardWrap}>
        <Col xs={24} xl={candidateActivity ? 14 : 24}>
          <Card
            bordered={false}
            className={styles.bottomCard + " card-bottom-wrapper"}
          >
            <div
              className={`${styles.bottomCardInfo} ${
                "bottom-card-info-header client-card-header-info " +
                styles.bottomCardInfoHeader
              }`}
            >
              <Title level={5} className="title-card-bottom">
                Candidates
              </Title>

              <div className={styles.jobInfo + " right-side-info"}>
                {isPermissionPresent(
                  K.Permissions.CancelJob,
                  userSlice.roles,
                ) && (
                  // <div className={styles.cancelJobModal}>
                  <Button
                    danger
                    onClick={showModalCancelJob}
                    className={styles.cancelJob}
                    disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
                  >
                    Cancel Job
                  </Button>
                  // </div>
                )}

                {isPermissionPresent(
                  K.Permissions.AddJobCandidate,
                  userSlice.roles,
                ) && (
                  // <div className={styles.addCandidateModal}>
                  <Button
                    onClick={showModalAddCandidate}
                    className={styles.addCandidate + " btn-add-candidate"}
                    disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
                  >
                    Add Candidate
                  </Button>
                  // </div>
                )}
                <Button
                  type="primary"
                  onClick={showModalChangeCandidateStatus}
                  disabled={selectedCandidate.isDisabled}
                >
                  Change Candidate Status
                </Button>
                <div className={styles.jobSearch + " job-search-wrap"}>
                  <Search
                    allowClear
                    placeholder="Search"
                    onChange={debounce(onChange, 500)}
                  />
                </div>
              </div>
            </div>
            <CandidateListing
              gridRef={gridRef}
              setModalVisible={setModalVisible}
              candidateActivity={candidateActivity}
              setCandidateActivity={setCandidateActivity}
              shouldUpdateListing={jobData.shouldUpdateListing}
              setJobData={setJobData}
              setSelectedData={setSelectedCandidate}
              selectedData={selectedCandidate}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              searchRef={searchRef}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Card>
        </Col>

        {candidateActivity && (
          <Col xs={24} xl={10}>
            <ViewActivity
              jobId={jobId}
              styles={styles}
              candidateActivity={candidateActivity}
              setCandidateActivity={setCandidateActivity}
              setModalVisible={setModalVisible}
              candidateForm={candidateForm}
              jobDetails={jobData.jobDetails}
              setJobData={setJobData}
              getCandidateUdfs={getCandidateUdfs}
              getJobDetails={getJobDetails}
              setRefreshTable={setRefreshTable}
              isjobDetail={true}
              getStatusBreakdown={getStatusBreakdown}
            />
          </Col>
        )}
      </Row>

      <Modal
        title={`${modalVisible.editCandidateId ? "Edit" : "Add New"} Candidate`}
        className={styles.addNewCandidateModal + " modal-overflow-auto"}
        open={modalVisible.isCandidateModalVisible}
        width={632}
        centered
        destroyOnClose
        onCancel={addCandidateCancel}
        closeIcon={<i className={"icon-closeable " + styles.closeAble}></i>}
        footer={
          <>
            <Button
              className={styles.candidateCancelButton}
              onClick={addCandidateCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={addCandidateOk}
              loading={isLoading}
              className={styles.candidateSubmitButton}
            >
              Submit
            </Button>
          </>
        }
      >
        <AddJobCandidate
          styles={styles}
          form={candidateForm}
          setIsLoading={setIsLoading}
          addCandidateCancel={addCandidateCancel}
          editCandidateId={modalVisible.editCandidateId}
          editCandidateEntryId={modalVisible.editCandidateEntryId}
          setCandidateActivity={setCandidateActivity}
          candidateActivity={candidateActivity}
          getCandidateUdf={getCandidateUdfs}
          setRefreshTable={setRefreshTable}
          getStatusBreakdown={getStatusBreakdown}
        />
      </Modal>

      <Modal
        centered
        width={462}
        title="Cancel Job"
        className={styles.jobCancelModal}
        open={modalVisible.isJobCancelModalVisible}
        onCancel={handleCancelJobCancel}
        closeIcon={<i className={"icon-closeable " + styles.closeAble}></i>}
        footer={
          <>
            <Button
              className={styles.jobCancelButton}
              onClick={handleCancelJobCancel}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={cancelJobForm.submit}
              className={styles.jobConfirmButton}
              disabled={!jobData.moveCandidates}
            >
              Confirm
            </Button>
          </>
        }
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {" "}
            <Title level={5} className={styles.jobCancelSubTitle}>
              <i
                className={"icon-vacancies-briefcase " + styles.briefcaseIcon}
              ></i>
              Current Openings:{" "}
              {jobData.jobDetails?.vacancies?.currentOpenings ?? 0}
            </Title>
          </div>
          <div>
            <Title level={5} className={styles.jobCancelSubTitle}>
              <i className={"icon-user " + styles.briefcaseIcon}></i>
              No. of Submittals: {jobData.jobDetails?.submittedCount ?? 0}
            </Title>
          </div>
        </div>
        <CancelJobForm
          jobId={jobId}
          clientId={clientId}
          form={cancelJobForm}
          styles={styles}
          getJobSettings={getJobSettings}
          jobDetails={jobData.jobDetails}
          setJobData={setJobData}
          setIsLoading={setIsLoading}
          handleOkCancelJob={handleOkCancelJob}
        />
      </Modal>
      {/* Submit Cancel Billing  */}
      <Modal
        centered
        width={462}
        title="Submit Cancel Billings"
        className={styles.jobCancelModal + " cancelBilling"}
        open={modalVisible.isCancelBillingModalVisible}
        onCancel={handleCancelBilling}
        closeIcon={<i className={"icon-closeable " + styles.closeAble}></i>}
        footer={
          <>
            <Button
              className={styles.jobCancelButton}
              onClick={handleCancelBilling}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={cancelBillingForm.submit}
            >
              Confirm
            </Button>
          </>
        }
      >
        <Title level={5} className={styles.jobCancelSubTitle}>
          <i className={"icon-user" + styles.briefcaseIcon}></i>
          No. of Submittals: {jobData.jobDetails?.submittedCount ?? 0}
        </Title>

        <SubmitCancelBillingForm
          jobId={jobId}
          clientId={clientId}
          form={cancelBillingForm}
          styles={styles}
          setIsLoading={setIsLoading}
          handleCancelBillingOk={handleCancelBillingOk}
        />
      </Modal>

      <ChangeCandidateStatus
        selectedData={selectedCandidate}
        setSelectedData={setSelectedCandidate}
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        setJobData={setJobData}
        setRefreshTable={setRefreshTable}
      />
      <Drawer
        title="Settings"
        placement="right"
        onClose={onClose}
        open={modalVisible.isSettingModalVisible}
        className="settings-drawer-wrap"
      >
        <JobSettingModal
          jobId={jobId}
          jobData={jobData}
          form={settingsForm}
          setJobData={setJobData}
          jobDetails={jobData.jobDetails}
          jobStats={jobData.settings}
          setModalVisible={setModalVisible}
        />
      </Drawer>
    </>
  );
}
