import NetworkCall from "network/networkCall";
import Request from "network/request";
import { camelCaseKeysRecursively } from "utilities/generalUtility";

export default class BillingDiscount {
  static async createVBD(body) {
    const res = await NetworkCall.fetch(Request.createVBD(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async getVBDRule(clientId) {
    const res = await NetworkCall.fetch(Request.getVBDRule(clientId));
    return camelCaseKeysRecursively(res.data);
  }
}
